import React, {useState} from "react";
import {useQuery, gql} from "@apollo/client";
import {
  MenuItem,
} from "@mui/material";
import GetAllRegulationTypes from "./gql/GetAllRegulationTypes.graphql";
import {map} from "underscore";
import ControlledSelect from "../components/ControlledSelect";

const RegulationTypeSelect = () => {
  const [regulationTypes, setRegulationTypes] = useState();

  useQuery(gql(GetAllRegulationTypes), {
    onCompleted: (data) => {
      setRegulationTypes(data.regulationTypes);
    },
  });

  if (!regulationTypes) return null;

  const regulationTypeItems = map(regulationTypes, (regulationType) => {
    return <MenuItem key={regulationType.id} value={regulationType.name}>
      {regulationType.label ?? regulationType.name}
    </MenuItem>;
  });

  return <ControlledSelect
    name="regulationType"
    label="Regulation Type"
    options={regulationTypeItems}
    selectProps={{
      required: true,
    }}
  />;
};

export default RegulationTypeSelect;
