# import NotificationFragment from "./fragments/Notification.graphql"

mutation CreateOneNotification(
  $regulationId: Int!
  $conditions: String!
  $content: String!
  $type: NotificationType!
) {
  createOneNotification(data: {
    regulations: { 
      create: {
          regulation: {
            connect: {
            id: $regulationId
          }
        }
      } 
    }
    conditions: $conditions
    content: $content
    type: $type
  }) {
    ...NotificationFragment
  }
}