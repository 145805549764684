import {makeStyles} from "tss-react/mui";

export const useRegulationStyles = makeStyles()((theme) => ({
  wrapper: {
    justifyContent: "center",
    alignItems: "center",
  },
  regulationLeaderBox: {
    border: "1px solid",
    borderColor: theme.palette.primary.main,
    cursor: "pointer",
    display: "flex",
    marginLeft: theme.spacing(1),
    padding: theme.spacing(1),
    width: "fit-content",
  },
}));
