import React from "react";
import {Box, Typography, TextField, Button} from "@mui/material";
import {FormProvider, useForm} from "react-hook-form";
import {useQuery, useMutation, gql} from "@apollo/client";
import GetDomainDenyList from "./gql/GetDomainDenyList.graphql";
import UpdateDomainDenyList from "./gql/UpdateDomainDenyList.graphql";
import {version} from "../../package.json";

const SettingsPage = () => {
  const formMethods = useForm({
    defaultValues: {
      domainDenyList: "",
    },
  });

  const getDomainListQuery = useQuery(gql(GetDomainDenyList), {
    onCompleted: (data) => {
      formMethods.setValue("domainDenyList", data.getDomainDenyList.domains);
    },
  });

  const [updateDomainDenyListMutation, updateMetadata] = useMutation(gql(UpdateDomainDenyList));

  const updateDomainDenyList = async (data) => {
    updateDomainDenyListMutation({
      variables: {domainDenyList: data.domainDenyList},
    });
  };

  return <Box>
    <Box marginBottom={1}>
      <Typography variant="h4" mb={2}>Manage Settings</Typography>
    </Box>
    <Box mt={2}>
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(updateDomainDenyList)}>
          <Box padding={1}>
            <TextField
              {...formMethods.register("domainDenyList", {required: true})}
              variant="outlined"
              id="domainDenyList"
              label="Domain Deny List"
              size="small"
              fullWidth={true}
              InputLabelProps={{shrink: true}}
              multiline
              rows={4}
              disabled={getDomainListQuery.loading}
            />
          </Box>
          <Box padding={1} display="flex" justifyContent="space-between">
            <Button type="submit" variant="outlined" disabled={updateMetadata.loading} >
                Update
            </Button>
            <Typography variant="caption">
              UI: {version}
            </Typography>
          </Box>
        </form>
      </FormProvider>
    </Box>
  </Box>;
};

export default SettingsPage;
