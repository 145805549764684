# import RegulationFragment from "./fragments/Regulation.graphql"

mutation SyncGroupsForRegulation(
  $groups: [SyncGroupsInputType!],
  $regulation: RegulationWhereUniqueInput
) {
  syncGroupsForRegulation(
    groups: $groups,
    regulation: $regulation
  ) {
    ...RegulationFragment
  }
}