# import RegulationFragment from "./fragments/Regulation.graphql"

mutation SyncTagsForRegulation(
  $regulation: RegulationWhereUniqueInput!,
  $tags: [SyncTagsInputType!]!
) {
  syncTagsForRegulation(
    regulation: $regulation
    tags: $tags
  ) {
    ...RegulationFragment
  }
}
