import React, {useEffect} from "react";
import {Typography, Box, Button, TextField, Switch, FormControlLabel} from "@mui/material";
import {useUser} from "./UserContext";
import {useForm, FormProvider, Controller} from "react-hook-form";
import {contains, findWhere} from "underscore";

const UserDetailsUpdateFields = () => {
  const {user, refetch, updateUserPhoneAndMFA, isUpdateLoading} = useUser();
  const userPhone = findWhere(user?.UserAttributes, {Name: "phone_number"})?.Value ?? "";
  const userMFA = contains(user?.UserMFASettingList, "SMS_MFA");
  const formMethods = useForm({defaultValues: {phone: userPhone, MFA: userMFA}});

  useEffect(() => {
    if (!user) return;
    formMethods.reset({phone: userPhone, MFA: userMFA});
  }, [user, isUpdateLoading]);


  const handleAttributeUpdate = async (data) => {
    const hasPhoneChanged = data.phone !== userPhone;
    const hasMFAChanged = data.MFA !== userMFA;

    const dataToUpdate = {username: user.Username};
    if (hasPhoneChanged) dataToUpdate["phone"] = data.phone;
    if (hasMFAChanged) dataToUpdate["enableMfa"] = data.MFA;

    if (hasPhoneChanged || hasMFAChanged) {
      await updateUserPhoneAndMFA(dataToUpdate);
      await refetch();
    }
  };

  return (
    <Box>
      <Typography variant="h5" sx={{paddingLeft: 1, marginTop: 2}}>
        Attributes
      </Typography>
      <Typography variant="caption" sx={{paddingLeft: 1}}>
        Edits affect shared pool attributes only
      </Typography>
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(handleAttributeUpdate)}>
          <Box padding={1} mt={1}>
            <TextField
              {...formMethods.register("phone")}
              variant="outlined"
              id="phone"
              label="Phone"
              size="small"
              InputLabelProps={{shrink: true}}
              disabled={isUpdateLoading}
            />
          </Box>
          <Box padding={1}>
            <Controller
              name="MFA"
              render={({field: {value, onChange}}) => (
                <FormControlLabel
                  control={
                    <Switch
                      checked={value}
                      onChange={(event) => onChange(event.target.checked)}
                    />
                  }
                  label="MFA Enabled"
                />
              )}
            />
          </Box>
          <Box padding={1}>
            <Button type="submit" variant="outlined">
              Update
            </Button>
          </Box>
        </form>
      </FormProvider>
    </Box>
  );
};

export default UserDetailsUpdateFields;
