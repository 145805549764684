import React from "react";
import {Routes, Route, BrowserRouter} from "react-router-dom";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import Layout from "./components/layout/Layout";
import RegulationsTable from "./regulations/RegulationsTable";
import Users from "./users";
import Settings from "./settings";
import RegulationGroups from "./regulationGroups";
import RegulationDetailRoutes from "./regulations/RegulationDetailRoutes";

export default () => {
  return <LocalizationProvider dateAdapter={AdapterDayjs}>
    <BrowserRouter>
      <Layout>
        <Routes>
          <Route path="/*" element={<RegulationsTable />} />
          <Route path="/regulations/*" element={<RegulationDetailRoutes />} />
          <Route path="/users/*" element={<Users />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/groups/*" element={<RegulationGroups />} />
        </Routes>
      </Layout>
    </BrowserRouter>
  </LocalizationProvider>;
};
