import React from "react";
import PropTypes from "prop-types";
import {Box, Button, Checkbox, Divider, Tooltip, Typography} from "@mui/material";
import {isEmpty} from "underscore";

const GenerateTemplateButtons = ({
  display, formValues, handleGeneration, isGenerating, setFormValues,
}) => {
  if (isEmpty(formValues)) return null;

  return (
    <Box display={display ? "flex" : "none"}>
      <Divider orientation="vertical" sx={{marginRight: 2}} />
      <Tooltip
        title="Generate a notification template. This can take up to one minute."
        placement="top"
      >
        <Box>
          <Button
            onClick={handleGeneration}
            disabled={formValues?.revision === "none"}
            variant="outlined"
            sx={{
              paddingTop: 2,
              paddingBottom: 1.75,
              "@keyframes blink": isGenerating ?
                {"from, to": {opacity: 1}, "50%": {opacity: 0}} : undefined,
              animation: isGenerating ? "blink 1s ease-in infinite" : undefined,
            }}
          >
            Generate Template
          </Button>
        </Box>
      </Tooltip>
      <Tooltip
        title="Skip using any cached template and force a new template to be generated."
        placement="top"
      >
        <Box pt={0.75} ml={1}>
          <Checkbox
            checked={formValues.forceNewTemplate}
            onChange={() => setFormValues((currentValues) => (
              {...currentValues, forceNewTemplate: !currentValues.forceNewTemplate}
            ))}
            inputProps={{"aria-label": "controlled"}}
          />
          <Typography variant="caption" sx={{cursor: "default"}}>
            Force New Template
          </Typography>
        </Box>
      </Tooltip>
    </Box>
  );
};

GenerateTemplateButtons.propTypes = {
  display: PropTypes.bool,
  formValues: PropTypes.object,
  handleGeneration: PropTypes.func.isRequired,
  isGenerating: PropTypes.bool,
  setFormValues: PropTypes.func.isRequired,
};

export default GenerateTemplateButtons;
