import {makeStyles} from "tss-react/mui";

export const useLayoutStyles = makeStyles()((theme) => ({
  layout: {
    display: "flex",
    justifyContent: "center",
    minHeight: "100vh",
    width: "100%",
    background: theme.palette.background.default,
  },
  logo: {
    height: 43,
    width: 192,
    margin: "auto 0",
    backgroundImage: `url(${require("~/public/logo-light.png")})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
  },
}));
