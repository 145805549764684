import React, {useMemo, useEffect, useState} from "react";
import ControlledMultiSelect from "../components/ControlledMultiSelect";
import {countryData, getStates} from "../shared/geography";
import {useFormContext, useWatch} from "react-hook-form";
import {pluck, isEmpty} from "underscore";

const RegulationGeographySelect = () => {
  const formMethods = useFormContext();
  const regulationFormData = useWatch({
    control: formMethods.control,
  });
  const [stateOptions, setStateOptions] = useState([]);

  useEffect(() => {
    const regulationCountries = regulationFormData.regulationCountries;
    if (!regulationCountries) return;

    const newStateOptions = [];
    if (regulationCountries.includes("United States")) {
      newStateOptions.push(...pluck(getStates(["US"]), "name"));
    }
    if (regulationCountries.includes("Canada")) {
      newStateOptions.push(...pluck(getStates(["CA"]), "name"));
    }

    if (isEmpty(newStateOptions) && !isEmpty(regulationFormData?.regulationStates)) {
      newStateOptions.push(...regulationFormData.regulationStates);
    }

    setStateOptions(newStateOptions);
  }, [regulationFormData]);

  const countryNames = useMemo(() => pluck(countryData, "name"), []);

  return (
    <>
      <ControlledMultiSelect
        name="regulationCountries"
        label="Regulation Countries"
        values={countryNames}
        formControlProps={{style: {minWidth: "50%", maxWidth: "50%"}}}
      />
      {(!isEmpty(stateOptions) || !isEmpty(regulationFormData?.regulationStates)) &&
        <ControlledMultiSelect
          name="regulationStates"
          label="Regulation States"
          props={{sx: {marginLeft: 1}}}
          values={stateOptions}
          formControlProps={{style: {minWidth: "50%", maxWidth: "50%", paddingRight: "0.5rem"}}}
        />
      }
    </>
  );
};

export default RegulationGeographySelect;
