import React from "react";
import {PropTypes} from "prop-types";
import {DataGrid} from "@mui/x-data-grid";
import LinearProgress from "@mui/material/LinearProgress";

const Table = ({rows, columns, options, initialSort}) => {
  return <DataGrid
    rows={rows}
    columns={columns}
    autoHeight
    initialState={{
      pagination: {
        paginationModel: {
          pageSize: 100,
        },
      },
      sorting: initialSort,
    }}
    pageSizeOptions={[10, 20, 50, 100]}
    slots={{
      loadingOverlay: LinearProgress,
    }}
    {...options}
  />;
};

Table.propTypes = {
  rows: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  options: PropTypes.object,
  initialSort: PropTypes.object,
};

export const actionColumn = ({
  align: "center",
  disableClickEventBubbling: true,
  disableColumnMenu: true,
  disableExport: true,
  filterable: false,
  hideSortIcons: true,
  sortable: false,
});

export default Table;
