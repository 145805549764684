{
  "name": "regscout-admin-ui",
  "version": "1.11.1",
  "description": "The Admin UI for the RegScout service.",
  "private": true,
  "author": "BreachRx",
  "engines": {
    "node": ">=18"
  },
  "scripts": {
    "localdev": "GRAPHQL_API_URL='http://localhost:4000/graphql' AUTH_ENDPOINT='http://localhost:4001' npx parcel public/index.html -p 8081 --https",
    "remotedev": "GRAPHQL_API_URL='https://graphql.regscout.development.breachrx.io/graphql' AUTH_ENDPOINT='https://auth.development.breachrx.io' npx parcel public/index.html -p 8081 --https",
    "sbom": "npx @cyclonedx/cyclonedx-npm --output-file ./regscout-admin-ui-sbom-`date +%Y%m%d`.json --package-lock-only --mc-type application --spec-version 1.5",
    "build-dev": "npx parcel build public/index.html --no-optimize",
    "build-prod": "npx parcel build public/index.html --no-source-maps"
  },
  "browserslist": ">1% and not dead",
  "dependencies": {
    "@apollo/client": "^3.10.8",
    "@emotion/react": "^11.10.6",
    "@emotion/styled": "^11.10.6",
    "@fontsource/oswald": "^4.5.12",
    "@fontsource/roboto": "^4.5.8",
    "@hookform/error-message": "^2.0.1",
    "@hookform/resolvers": "^2.9.11",
    "@mui/icons-material": "^5.11.11",
    "@mui/material": "^5.11.12",
    "@mui/x-data-grid": "^6.0.1",
    "@mui/x-date-pickers": "^6.0.1",
    "axios": "^1.7.7",
    "camelcase": "^7.0.1",
    "dayjs": "^1.11.7",
    "graphql": "^16.8.1",
    "js-cookie": "^3.0.1",
    "jwt-decode": "^3.1.2",
    "notistack": "^3.0.1",
    "prop-types": "^15.8.1",
    "react": "^18.2.0",
    "react-dom": "^18.2.0",
    "react-hook-form": "^7.43.5",
    "react-papaparse": "^4.4.0",
    "react-router-dom": "^6.9.0",
    "rxjs": "^7.8.0",
    "sha.js": "^2.4.11",
    "suneditor": "^2.44.10",
    "suneditor-react": "^3.4.1",
    "tss-react": "^4.7.5",
    "underscore": "^1.13.6",
    "yup": "^1.0.2"
  },
  "devDependencies": {
    "@babel/core": "^7.23.6",
    "@cyclonedx/cyclonedx-npm": "^1.19.3",
    "@graphql-eslint/eslint-plugin": "^3.16.2",
    "@parcel/bundler-experimental": "^2.7.0",
    "@parcel/config-default": "^2.8.3",
    "@parcel/transformer-css": "^2.8.3",
    "@parcel/transformer-graphql": "^2.8.3",
    "buffer": "^5.7.1",
    "eslint": "^8.52.0",
    "eslint-config-google": "^0.14.0",
    "eslint-plugin-react": "^7.33.2",
    "parcel": "^2.8.3",
    "postcss": "^8.4.32",
    "postcss-modules": "^4.3.1",
    "process": "^0.11.10"
  }
}
