import React from "react";
import Table from "../components/Table";
import {Box, Button, Typography} from "@mui/material";
import {useQuery, gql} from "@apollo/client";
import GetRegulations from "./gql/getRegulations.graphql";
import {useNavigate} from "react-router-dom";
import dayjs from "dayjs";

const RegulationsTable = () => {
  const {loading, error, data, refetch} = useQuery(gql(GetRegulations));
  const navigate = useNavigate();

  const onRowClick = (params) => {
    navigate(`/regulations/edit/${params.id}`);
  };

  const columns = [
    {field: "regulator", headerName: "Regulator", width: 600},
    {field: "createdAt", headerName: "Created", width: 200,
      renderCell: (params) => dayjs(params?.row?.createdAt).format("lll"),
    },
    {field: "updatedAt", headerName: "Updated", width: 200,
      renderCell: (params) => dayjs(params?.row?.updatedAt).format("lll"),
    },
    {field: "isDraft", headerName: "Enabled", width: 100, type: "boolean",
      valueGetter: (params) => !params.value,
    },
  ];

  if (error) return error.message;

  return <>
    <Box marginBottom={1}>
      <Typography variant="h4" mb={2}>Manage Regulations</Typography>
      <Box display="flex" justifyContent="space-between">
        <Button variant="contained" onClick={() => navigate("/regulations/new")}>
          New Regulation
        </Button>
        <Button variant="contained" onClick={() => refetch()}>
          Refresh
        </Button>
      </Box>
    </Box>
    <Box mt={2}>
      <Table
        rows={data?.regulations ?? []}
        columns={columns}
        options={{
          onRowClick,
          sx: {cursor: "pointer"},
          loading,
        }}
        initialSort={{
          sortModel: [{field: "regulator", sort: "asc"}],
        }}
      />
    </Box>
  </>;
};

export default RegulationsTable;
