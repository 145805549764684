query GetRegulationGroups {
  regulationGroups {
    name
    id
    createdAt 
    updatedAt
    regulations {
      regulation {
        regulator
        id
      }
    }
  }
}