import React from "react";
import {PropTypes} from "prop-types";
import {Checkbox, FormControl, InputLabel, ListItemText, MenuItem, Select} from "@mui/material";
import {Controller, useFormContext} from "react-hook-form";
import {map} from "underscore";

const ControlledMultiSelect = ({name, label, formControlProps, props, values}) => {
  const formMethods = useFormContext();

  return (
    <Controller
      control={formMethods.control}
      name={name}
      defaultValue={[]}
      shouldUnregister
      render={({field: {onChange, value}}) => (
        <FormControl {...formControlProps}>
          <InputLabel id={`${name}-label`}>{label}</InputLabel>
          <Select
            labelId={`${name}-label`}
            id={name}
            value={value}
            multiple
            label={label}
            onChange={(event) => onChange(event.target.value)}
            renderValue={(value) => value.join(", ")}
            {...props}
            fullWidth
          >
            {map(values, (option, index) => (
              <MenuItem key={index} value={option}>
                <Checkbox checked={value.includes(option)} />
                <ListItemText primary={option} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    />
  );
};

ControlledMultiSelect.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string,
  label: PropTypes.string,
  props: PropTypes.object,
  formControlProps: PropTypes.object,
  values: PropTypes.array.isRequired,
};

export default ControlledMultiSelect;
