import React from "react";
import {FormGroup, FormControlLabel, Switch} from "@mui/material";
import {Controller, useFormContext} from "react-hook-form";
import {PropTypes} from "prop-types";

const ControlledSwitch = ({name, label, options}) => {
  const formMethods = useFormContext();

  return <Controller
    control={formMethods.control}
    name={name}
    render={({field: {onChange, value, name: fieldName}}) => (
      <FormGroup>
        <FormControlLabel control={<Switch
          {...options}
          checked={value ?? false}
          onChange={onChange}
          name={fieldName}
        />} label={label}/>
      </FormGroup>
    )}
  />;
};

ControlledSwitch.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  options: PropTypes.object,
};

export default ControlledSwitch;
