"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.createWithStyles = void 0;
/* eslint-disable @typescript-eslint/no-explicit-any */
const react_1 = __importStar(require("react"));
const makeStyles_1 = require("./makeStyles");
const capitalize_1 = require("./tools/capitalize");
function createWithStyles(params) {
    const { useTheme, cache } = params;
    const { makeStyles } = (0, makeStyles_1.createMakeStyles)({ useTheme, cache });
    function withStyles(Component, cssObjectByRuleNameOrGetCssObjectByRuleName, params) {
        const Component_ = typeof Component === "string"
            ? (() => {
                const tag = Component;
                const Out = function (_a) {
                    var { children } = _a, props = __rest(_a, ["children"]);
                    return (0, react_1.createElement)(tag, props, children);
                };
                Object.defineProperty(Out, "name", {
                    "value": (0, capitalize_1.capitalize)(tag)
                });
                return Out;
            })()
            : Component;
        /**
         * Get component name for wrapping
         * @see https://reactjs.org/docs/higher-order-components.html#convention-wrap-the-display-name-for-easy-debugging
         */
        const name = (() => {
            {
                const { name: nameOrWrappedName } = params !== null && params !== void 0 ? params : {};
                if (nameOrWrappedName !== undefined) {
                    return typeof nameOrWrappedName !== "object"
                        ? nameOrWrappedName
                        : Object.keys(nameOrWrappedName)[0];
                }
            }
            {
                const displayName = Component_.displayName;
                if (typeof displayName === "string" && displayName !== "") {
                    return displayName;
                }
            }
            {
                const { name } = Component_;
                if (name) {
                    return name;
                }
            }
        })();
        const useStyles = makeStyles(Object.assign(Object.assign({}, params), { name }))(typeof cssObjectByRuleNameOrGetCssObjectByRuleName === "function"
            ? (theme, props, classes) => incorporateMediaQueries(cssObjectByRuleNameOrGetCssObjectByRuleName(theme, props, classes))
            : incorporateMediaQueries(cssObjectByRuleNameOrGetCssObjectByRuleName));
        function getHasNonRootClasses(classes) {
            for (const name in classes) {
                if (name === "root") {
                    continue;
                }
                return true;
            }
            return false;
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const Out = (0, react_1.forwardRef)(function (props, ref) {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { className, classes: _classes } = props, rest = __rest(props, ["className", "classes"]);
            const { classes, cx } = useStyles(props, { props });
            const rootClassName = cx(classes.root, className);
            fixedClassesByClasses.set(classes, Object.assign(Object.assign({}, classes), { "root": rootClassName }));
            return (react_1.default.createElement(Component_, Object.assign({ ref: ref, className: getHasNonRootClasses(classes)
                    ? className
                    : rootClassName }, (typeof Component === "string" ? {} : { classes }), rest)));
        });
        if (name !== undefined) {
            Out.displayName = `${(0, capitalize_1.capitalize)(name)}WithStyles`;
            Object.defineProperty(Out, "name", { "value": Out.displayName });
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return Out;
    }
    withStyles.getClasses = getClasses;
    return { withStyles };
}
exports.createWithStyles = createWithStyles;
const fixedClassesByClasses = new WeakMap();
const errorMessageGetClasses = "getClasses should only be used in conjunction with withStyles";
function getClasses(props) {
    const classesIn = props.classes;
    if (classesIn === undefined) {
        throw new Error(errorMessageGetClasses);
    }
    const classes = fixedClassesByClasses.get(classesIn);
    if (classes === undefined) {
        throw new Error(errorMessageGetClasses);
    }
    return classes;
}
function incorporateMediaQueries(cssObjectByRuleNameWithMediaQueries) {
    const cssObjectByRuleName = {};
    const cssObjectByRuleNameWithMediaQueriesByMediaQuery = {};
    Object.keys(cssObjectByRuleNameWithMediaQueries).forEach(ruleNameOrMediaQuery => ((ruleNameOrMediaQuery.startsWith("@media")
        ? cssObjectByRuleNameWithMediaQueriesByMediaQuery
        : cssObjectByRuleName)[ruleNameOrMediaQuery] =
        cssObjectByRuleNameWithMediaQueries[ruleNameOrMediaQuery]));
    Object.keys(cssObjectByRuleNameWithMediaQueriesByMediaQuery).forEach(mediaQuery => {
        const cssObjectByRuleNameBis = cssObjectByRuleNameWithMediaQueriesByMediaQuery[mediaQuery];
        Object.keys(cssObjectByRuleNameBis).forEach(ruleName => {
            var _a;
            return (cssObjectByRuleName[ruleName] = Object.assign(Object.assign({}, ((_a = cssObjectByRuleName[ruleName]) !== null && _a !== void 0 ? _a : {})), { [mediaQuery]: cssObjectByRuleNameBis[ruleName] }));
        });
    });
    return cssObjectByRuleName;
}
