mutation UpdateNotificationTemplate(
	$templateId: Int!
	$isDraft: Boolean!,
	$content: String!
) {
	updateOneNotificationTemplate (
		where:{id:$templateId}
		data:{
			content: {set: $content}
			isDraft: {set: $isDraft}
		}
	) {
		id
		content
	}
}