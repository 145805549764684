import React from "react";
import {FormProvider, useForm} from "react-hook-form";
import {Box, Button, InputAdornment, Paper, Switch, TextField, Typography} from "@mui/material";
import AccountCircleOutlined from "@mui/icons-material/AccountCircleOutlined";
import LockOutlined from "@mui/icons-material/LockOutlined";
import {yupResolver} from "@hookform/resolvers/yup";
import {useLogin} from "./LoginProvider";
import {useLoginStyles} from "./LoginStyles";

export const Login = () => {
  const {isSubmitting, login, loginSchema} = useLogin();
  const {classes} = useLoginStyles();
  const formMethods = useForm(({resolver: yupResolver(loginSchema)}));

  const errorMessage = formMethods?.formState?.errors?.password?.message;

  const handleLogin = (data) => login(data, formMethods.setError);

  return (
    <Box className={classes.wrapper}>
      <Box>
        <FormProvider {...formMethods}>
          <form onSubmit={formMethods.handleSubmit(handleLogin)}>
            <Paper elevation={10} sx={{borderRadius: 0}}>
              <Box className={classes.loginPaper}>
                <center>
                  <Button disabled className={classes.logo} aria-label="BreachRx" />
                  <Typography id="tagline" variant="h5" className={classes.tagline}>
                    Cyber RegScout
                    <sup style={{fontSize: "1.5rem"}}>&trade;</sup> Admin
                  </Typography>
                </center>
                <TextField
                  {...formMethods.register("username", {required: true})}
                  id="username"
                  label="Email"
                  variant="outlined"
                  size="medium"
                  sx={{marginTop: 4}}
                  InputLabelProps={{sx: {userSelect: "none"}}}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AccountCircleOutlined className={classes.adornmentIcon} />
                      </InputAdornment>
                    ),
                    sx: {borderRadius: 0},
                  }}
                />
                <TextField
                  {...formMethods.register("password", {required: true})}
                  type="password"
                  id="password"
                  label="Password"
                  variant="outlined"
                  size="medium"
                  sx={{marginTop: 4}}
                  InputLabelProps={{sx: {userSelect: "none"}}}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LockOutlined className={classes.adornmentIcon} />
                      </InputAdornment>
                    ),
                    sx: {borderRadius: 0},
                  }}
                />
                <Box display="flex" justifyContent="center" alignItems="center" marginTop={1}>
                  <Switch
                    {...formMethods.register("isMemorable")}
                    id="isMemorable"
                    defaultChecked
                    className={classes.remember}
                  />
                  <Typography variant="caption" fontSize="1rem">
                    Remember me
                  </Typography>
                </Box>
                <Button
                  type="submit"
                  id="submit"
                  variant="outlined"
                  className={classes.submit}
                  sx={isSubmitting ? {
                    "@keyframes blink": {"from, to": {opacity: 1}, "50%": {opacity: 0}},
                    animation: "blink 1s ease-in infinite",
                  } : undefined}
                >
                  Sign in
                </Button>
                <Box
                  display={!errorMessage ? "none" : "flex"}
                  justifyContent="center"
                  alignItems="center"
                  marginTop={1}
                >
                  <Typography variant="caption" fontSize="1rem" color="error">
                    {errorMessage}
                  </Typography>
                </Box>
              </Box>
            </Paper>
          </form>
        </FormProvider>
      </Box>
    </Box>
  );
};
