import React from "react";
import PropTypes from "prop-types";
import {Box, Divider} from "@mui/material";
import ControlledSunEditor from "../../components/ControlledSunEditor";
import NotificationTemplateDialog from "./NotificationTemplates";
import {useRegulation} from "../RegulationContext";
import camelcase from "camelcase";

const NotificationField = ({notificationType, options}) => {
  const {notificationLabels} = useRegulation();

  return <>
    <Divider sx={{borderWidth: 2, marginY: 2}} />
    <Box padding={1}>
      <ControlledSunEditor
        label={`${notificationLabels[notificationType]} Notification Conditions`}
        name={`${camelcase(notificationType)}Conditions`}
        options={options}
      />
    </Box>
    <Box padding={1}>
      <ControlledSunEditor
        label={`${notificationLabels[notificationType]} Notification Content`}
        name={`${camelcase(notificationType)}Notification`}
        options={options}
      />
    </Box>
    <NotificationTemplateDialog notificationType={notificationType} />
  </>;
};

NotificationField.propTypes = {
  notificationType: PropTypes.string.isRequired,
  options: PropTypes.object,
};

export default NotificationField;
