import React from "react";
import {
  Stack, TextField, MenuItem, Select, InputLabel, FormControl,
} from "@mui/material";
import {useFormContext} from "react-hook-form";
import PropTypes from "prop-types";

const DurationPicker = ({index}) => {
  const formMethods = useFormContext();
  const value = formMethods.watch(`regulationTags.${index}.tag.value`);

  const intervals = [
    {name: "seconds"},
    {name: "minutes"},
    {name: "hours"},
    {name: "days"},
    {name: "weeks"},
    {name: "months"},
    {name: "years"},
  ];

  const notificationTypes = [
    {name: "REGULATOR", displayValue: "Regulator"},
    {name: "CONSUMER", displayValue: "Consumer"},
    {name: "CREDIT_RATING_AGENCY", displayValue: "Consumer Reporting Agencies"},
    {name: "SUBSTITUTE_NOTICE", displayValue: "Substitute Notice"},
  ];

  const intervalItems = intervals.map((interval) => {
    return <MenuItem key={interval.name} value={interval.name}>
      {interval.name}
    </MenuItem>;
  });

  const notificationItems = notificationTypes.map((notificationType) => {
    return <MenuItem key={notificationType.name} value={notificationType.name}>
      {notificationType.displayValue}
    </MenuItem>;
  });

  return <Stack spacing={1} sx={{marginLeft: 1}}>
    <Stack direction="row" spacing={1}>
      <TextField
        label="Description"
        value={value.description ?? ""}
        onChange={(event) => {
          formMethods.setValue(`regulationTags.${index}.tag.value.description`, event.target.value);
        }}
        size="small"
        sx={{width: 600}}
      />
    </Stack>
    <Stack direction="row" spacing={1}>
      <FormControl fullWidth size="small">
        <InputLabel
          id={`datepicker-${index}-notification-type-label`}
        >
          Notification type
        </InputLabel>
        <Select
          labelId={`datepicker-${index}-notification-type-label`}
          id={`datepicker-${index}-notification-type`}
          value={value.notificationType ?? ""}
          onChange={(event) => {
            formMethods.setValue(
                `regulationTags.${index}.tag.value.notificationType`,
                event.target.value,
            );
          }}
          size="small"
          label="Interval"
        >
          {notificationItems}
        </Select>
      </FormControl>
    </Stack>
    <Stack direction="row" spacing={1}>
      <TextField
        label="Duration"
        value={value.duration?.length ?? ""}
        onChange={(event) => {
          formMethods.setValue(
              `regulationTags.${index}.tag.value.duration.length`,
              event.target.value,
          );
        }}
        size="small"
        sx={{width: 150}}
      />
      <FormControl fullWidth size="small">
        <InputLabel id={`datepicker-${index}-interval-label`}>Interval</InputLabel>
        <Select
          labelId={`datepicker-${index}-interval-label`}
          id={`${index}-interval`}
          value={value.duration?.interval ?? ""}
          onChange={(event) => {
            formMethods.setValue(
                `regulationTags.${index}.tag.value.duration.interval`,
                event.target.value,
            );
          }}
          size="small"
          label="Interval"
        >
          {intervalItems}
        </Select>
      </FormControl>
    </Stack>
  </Stack>;
};

DurationPicker.propTypes = {
  index: PropTypes.number.isRequired,
};

export default DurationPicker;
