# import RegulatorLeaderFragment from "./fragments/RegulatorLeader.graphql"

mutation UpsertRegulatorLeader(
  $regulationId: Int!,
  $upsertData: RegulatorLeaderUpsertWithoutRegulationInput!
)
 {
	updateOneRegulation (
		where: {id: $regulationId}
		data: {
			leader: {
				upsert: $upsertData
			}
		}
	) {
		id
		leader {
      ...RegulatorLeaderFragment
		}
	}
}