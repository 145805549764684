# import NewsItemFragment from "./fragments/NewsItem.graphql"
# import RegulatorLeaderFragment from "./fragments/RegulatorLeader.graphql"

query GetOneRegulation($id: Int) {
  regulation(where: { id: $id }) {
    id
    isDeleted
    createdAt
    updatedAt
    isDraft
    regulationTypeId
    regulator
    description
    exceptions
    source
    penalties
    piiDefinition
    otherRequirements
    legislationPassedAt
    effectiveAt
    legalType
    leader {
      ...RegulatorLeaderFragment
    }
    type {
      id
      isDeleted
      createdAt
      updatedAt
      name
      displayName
    }
    notifications {
      id
      notification {
        id
        isDeleted
        createdAt
        updatedAt
        isDraft
        content
        destination
        type
        conditions
        templateRevision
        templates(orderBy:{revision:desc}) {
          id
          isDraft
          content
          revision
          createdAt
          updatedAt
        }
      }
    }
    regulationTags: tags {
      id
      tag {
        id
        isDeleted
        createdAt
        updatedAt
        value
        type {
          id
          isDeleted
          createdAt
          updatedAt
          isDraft
          name
          displayName
          isVisible
          typeName
        }
      }
    }
    newsItems {
      id
      newsItem {
        ...NewsItemFragment
      }
    }
    existingGroups: groups {
      id
      regulationGroup {
        id
        name
      }
    }
  }
}
