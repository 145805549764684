import React from "react";
import {Route, Routes} from "react-router-dom";
import RegulationDetails from "./RegulationDetails";
import RegulatorLeader from "./RegulatorLeader";

const RegulationDetailRoutes = () => (
  <Routes>
    <Route path="/edit/:id" element={<RegulationDetails />} />
    <Route path="/edit/:id/leader" element={<RegulatorLeader />} />
    <Route path="/new" element={<RegulationDetails />} />
  </Routes>
);

export default RegulationDetailRoutes;
