import {useEffect} from "react";
import {ALERT} from "../environment";
import {useSnackbar} from "notistack";

const Alerts = () => {
  const snackbar = useSnackbar();
  let subscription = null;

  useEffect(() => {
    subscription = ALERT.messages.subscribe({
      next: (messages) => {
        if (snackbar.enqueueSnackbar && messages[0]) {
          snackbar.enqueueSnackbar(messages[0].text, {variant: messages[0].type});
        }
      },
    });

    return () => {
      if (subscription) subscription.unsubscribe();
    };
  }, []);

  return null;
};

export default Alerts;
