"use strict";
"use client";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TssCacheProvider = exports.createMakeStyles = void 0;
/* eslint-disable @typescript-eslint/no-explicit-any */
const react_1 = __importStar(require("react"));
const Object_fromEntries_1 = require("./tools/polyfills/Object.fromEntries");
const objectKeys_1 = require("./tools/objectKeys");
const cssAndCx_1 = require("./cssAndCx");
const getDependencyArrayRef_1 = require("./tools/getDependencyArrayRef");
const typeGuard_1 = require("./tools/typeGuard");
const assert_1 = require("./tools/assert");
const mergeClasses_1 = require("./mergeClasses");
const react_2 = require("react");
const react_3 = require("@emotion/react");
let counter = 0;
function createMakeStyles(params) {
    const { useTheme, cache: cacheProvidedAtInception } = params;
    function useCache() {
        var _a;
        const contextualCache = (0, react_3.__unsafe_useEmotionCache)();
        const cacheExplicitlyProvidedForTss = useCacheProvidedByProvider();
        const cacheToBeUsed = (_a = cacheProvidedAtInception !== null && cacheProvidedAtInception !== void 0 ? cacheProvidedAtInception : cacheExplicitlyProvidedForTss) !== null && _a !== void 0 ? _a : contextualCache;
        if (cacheToBeUsed === null) {
            throw new Error([
                "In order to get SSR working with tss-react you need to explicitly provide an Emotion cache.",
                "MUI users be aware: This is not an error strictly related to tss-react, with or without tss-react,",
                "MUI needs an Emotion cache to be provided for SSR to work.",
                "Here is the MUI documentation related to SSR setup: https://mui.com/material-ui/guides/server-rendering/",
                "TSS provides helper that makes the process of setting up SSR easier: https://docs.tss-react.dev/ssr"
            ].join("\n"));
        }
        return cacheToBeUsed;
    }
    const { useCssAndCx } = (0, cssAndCx_1.createUseCssAndCx)({ useCache });
    /** returns useStyle. */
    function makeStyles(params) {
        const { name: nameOrWrappedName, uniqId = counter++ } = params !== null && params !== void 0 ? params : {};
        const name = typeof nameOrWrappedName !== "object"
            ? nameOrWrappedName
            : Object.keys(nameOrWrappedName)[0];
        return function (cssObjectByRuleNameOrGetCssObjectByRuleName) {
            const getCssObjectByRuleName = typeof cssObjectByRuleNameOrGetCssObjectByRuleName ===
                "function"
                ? cssObjectByRuleNameOrGetCssObjectByRuleName
                : () => cssObjectByRuleNameOrGetCssObjectByRuleName;
            return function useStyles(params, styleOverrides) {
                var _a, _b;
                //See: https://github.com/garronej/tss-react/issues/158
                const styleOverrides_props = styleOverrides === null || styleOverrides === void 0 ? void 0 : styleOverrides.props;
                const theme = useTheme();
                const { css, cx } = useCssAndCx();
                const cache = useCache();
                let classes = (0, react_1.useMemo)(() => {
                    const refClassesCache = {};
                    const refClasses = typeof Proxy !== "undefined" &&
                        new Proxy({}, {
                            "get": (_target, propertyKey) => {
                                if (typeof propertyKey === "symbol") {
                                    (0, assert_1.assert)(false);
                                }
                                return (refClassesCache[propertyKey] = `${cache.key}-${uniqId}${name !== undefined ? `-${name}` : ""}-${propertyKey}-ref`);
                            }
                        });
                    const cssObjectByRuleName = getCssObjectByRuleName(theme, params, refClasses || {});
                    const classes = (0, Object_fromEntries_1.objectFromEntries)((0, objectKeys_1.objectKeys)(cssObjectByRuleName).map(ruleName => {
                        const cssObject = cssObjectByRuleName[ruleName];
                        if (!cssObject.label) {
                            cssObject.label = `${name !== undefined ? `${name}-` : ""}${ruleName}`;
                        }
                        return [
                            ruleName,
                            `${css(cssObject)}${(0, typeGuard_1.typeGuard)(ruleName, ruleName in refClassesCache)
                                ? ` ${refClassesCache[ruleName]}`
                                : ""}`
                        ];
                    }));
                    (0, objectKeys_1.objectKeys)(refClassesCache).forEach(ruleName => {
                        if (ruleName in classes) {
                            return;
                        }
                        classes[ruleName] =
                            refClassesCache[ruleName];
                    });
                    return classes;
                }, [cache, css, cx, theme, (0, getDependencyArrayRef_1.getDependencyArrayRef)(params)]);
                const propsClasses = styleOverrides_props === null || styleOverrides_props === void 0 ? void 0 : styleOverrides_props.classes;
                classes = (0, react_1.useMemo)(() => (0, mergeClasses_1.mergeClasses)(classes, propsClasses, cx), [classes, (0, getDependencyArrayRef_1.getDependencyArrayRef)(propsClasses), cx]);
                {
                    let cssObjectByRuleNameOrGetCssObjectByRuleName = undefined;
                    try {
                        cssObjectByRuleNameOrGetCssObjectByRuleName =
                            name !== undefined
                                ? (_b = (_a = theme.components) === null || _a === void 0 ? void 0 : _a[name]) === null || _b === void 0 ? void 0 : _b.styleOverrides
                                : undefined;
                        // eslint-disable-next-line no-empty
                    }
                    catch (_c) { }
                    const themeClasses = (0, react_1.useMemo)(() => {
                        if (!cssObjectByRuleNameOrGetCssObjectByRuleName) {
                            return undefined;
                        }
                        const themeClasses = {};
                        for (const ruleName in cssObjectByRuleNameOrGetCssObjectByRuleName) {
                            const cssObjectOrGetCssObject = cssObjectByRuleNameOrGetCssObjectByRuleName[ruleName];
                            if (!(cssObjectOrGetCssObject instanceof Object)) {
                                continue;
                            }
                            themeClasses[ruleName] = css(typeof cssObjectOrGetCssObject === "function"
                                ? cssObjectOrGetCssObject(Object.assign({ theme, "ownerState": styleOverrides === null || styleOverrides === void 0 ? void 0 : styleOverrides.ownerState }, styleOverrides_props))
                                : cssObjectOrGetCssObject);
                        }
                        return themeClasses;
                    }, [
                        cssObjectByRuleNameOrGetCssObjectByRuleName ===
                            undefined
                            ? undefined
                            : typeof cssObjectByRuleNameOrGetCssObjectByRuleName ===
                                "function"
                                ? cssObjectByRuleNameOrGetCssObjectByRuleName
                                : JSON.stringify(cssObjectByRuleNameOrGetCssObjectByRuleName),
                        (0, getDependencyArrayRef_1.getDependencyArrayRef)(styleOverrides_props),
                        (0, getDependencyArrayRef_1.getDependencyArrayRef)(styleOverrides === null || styleOverrides === void 0 ? void 0 : styleOverrides.ownerState),
                        css
                    ]);
                    classes = (0, react_1.useMemo)(() => (0, mergeClasses_1.mergeClasses)(classes, themeClasses, cx), [classes, themeClasses, cx]);
                }
                return {
                    classes,
                    theme,
                    css,
                    cx
                };
            };
        };
    }
    function useStyles() {
        const theme = useTheme();
        const { css, cx } = useCssAndCx();
        return { theme, css, cx };
    }
    return { makeStyles, useStyles };
}
exports.createMakeStyles = createMakeStyles;
const reactContext = (0, react_2.createContext)(undefined);
function useCacheProvidedByProvider() {
    const cacheExplicitlyProvidedForTss = (0, react_2.useContext)(reactContext);
    return cacheExplicitlyProvidedForTss;
}
function TssCacheProvider(props) {
    const { children, value } = props;
    return (react_1.default.createElement(reactContext.Provider, { value: value }, children));
}
exports.TssCacheProvider = TssCacheProvider;
