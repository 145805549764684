import React, {useState, useEffect} from "react";
import {useFormContext, useFieldArray, useWatch} from "react-hook-form";
import {
  Box,
  Divider,
  Stack,
  IconButton,
  TextField,
  MenuItem,
  CircularProgress,
  Checkbox,
  Autocomplete,
  Select,
  FormControl,
} from "@mui/material";
import PropTypes from "prop-types";
import AddBoxRoundedIcon from "@mui/icons-material/AddBoxRounded";
import ClearIcon from "@mui/icons-material/Clear";
import {isEmpty, isString, map, sortBy} from "underscore";
import {useRegulation} from "./RegulationContext";
import DurationPicker from "./DurationPicker";
import {useQuery, gql} from "@apollo/client";
import GetDistinctTagValuesOfType from "./gql/GetDistinctTagValuesOfType.graphql";

const StringTagAutocomplete = ({index}) => {
  const [tagOptions, setTagOptions] = useState([]);
  const formMethods = useFormContext();
  const tagType = useWatch({
    control: formMethods.control,
    name: `regulationTags.${index}.tag.type`,
  });

  const tagValuesQuery = useQuery(gql(GetDistinctTagValuesOfType), {
    variables: {tagTypeName: tagType?.name},
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      setTagOptions(map(data.tags, (tag) => ({
        ...tag,
        value: JSON.parse(tag.value),
      })));
    },
    skip: isEmpty(tagType?.name),
  });

  if (tagValuesQuery.loading) {
    return <CircularProgress />;
  }

  return <Autocomplete
    onChange={(event) => {
      formMethods.setValue(`regulationTags.${index}.tag.value`, event.target.value);
    }}
    onBlur={(event) => {
      formMethods.setValue(`regulationTags.${index}.tag.value`, event.target.value);
    }}
    value={formMethods.getValues(`regulationTags.${index}.tag.value`) || ""}
    options={tagOptions}
    freeSolo
    renderInput={(params) => (<TextField {...params} variant="outlined" />)}
    variant="outlined"
    size="small"
    fullWidth
    sx={{marginLeft: 1}}
    getOptionLabel={(option) => isString(option) ? option : option.value ?? ""}
  />;
};

StringTagAutocomplete.propTypes = {
  index: PropTypes.number.isRequired,
};

const StringTag = ({index}) => {
  const formMethods = useFormContext();
  const value = formMethods.watch(`regulationTags.${index}.tag.value`);

  return <TextField
    onChange={(event) => {
      formMethods.setValue(`regulationTags.${index}.tag.value`, event.target.value);
    }}
    onBlur={(event) => {
      formMethods.setValue(`regulationTags.${index}.tag.value`, event.target.value);
    }}
    value={value}
    variant="outlined"
    size="small"
    fullWidth={true}
    InputLabelProps={{shrink: true}}
    sx={{marginLeft: 1}}
  />;
};

StringTag.propTypes = {
  index: PropTypes.number.isRequired,
};

const NumberTag = ({index}) => {
  const formMethods = useFormContext();
  const value = formMethods.watch(`regulationTags.${index}.tag.value`);

  return <TextField
    onChange={(event) => {
      formMethods.setValue(`regulationTags.${index}.tag.value`, event.target.value);
    }}
    onBlur={(event) => {
      formMethods.setValue(`regulationTags.${index}.tag.value`, event.target.value);
    }}
    value={value}
    type="number"
    variant="outlined"
    size="small"
    InputLabelProps={{shrink: true}}
    InputProps={{style: {texAlign: "right"}}}
    sx={{marginLeft: 1, width: 200}}
  />;
};

NumberTag.propTypes = {
  index: PropTypes.number.isRequired,
};

const BooleanTag = ({index}) => {
  const formMethods = useFormContext();
  const value = formMethods.watch(`regulationTags.${index}.tag.value`);

  useEffect(() => {
    if (value === "") formMethods.setValue(`regulationTags.${index}.tag.value`, false);
  }, []);

  return <Checkbox
    onChange={(event) => {
      formMethods.setValue(`regulationTags.${index}.tag.value`, event.target.checked);
    }}
    onBlur={(event) => {
      formMethods.setValue(`regulationTags.${index}.tag.value`, event.target.checked);
    }}
    checked={isString(value) ? false : value}
  />;
};

BooleanTag.propTypes = {
  index: PropTypes.number.isRequired,
};

const TagTypeSelect = ({index}) => {
  const {tagTypes} = useRegulation();
  const formMethods = useFormContext();
  const typeName = formMethods.watch(`regulationTags.${index}.tag.type.name`);
  const value = formMethods.watch(`regulationTags.${index}.tag.value`);
  const {update} = useFieldArray({name: "regulationTags"});

  const tagTypeItems = map(sortBy(tagTypes, (item) => item.displayName ?? item.name), (tagType) => {
    if (tagType.name === "Geography") return;
    return <MenuItem key={tagType.id} value={tagType.name}>
      {tagType.displayName ?? tagType.name}
    </MenuItem>;
  }).filter((item) => !isEmpty(item));

  return <FormControl style={{minWidth: 250}}>
    <Select
      value={typeName}
      onChange={(event) => {
        const selectedTagType = tagTypes.find((tagType) => tagType.name === event.target.value);
        update(index, {tag: {type: selectedTagType, value}});
      }}
      fullWidth
      size="small"
      padding={1}
    >
      {tagTypeItems}
    </Select>
  </FormControl>;
};

TagTypeSelect.propTypes = {
  index: PropTypes.number.isRequired,
};

const TagValueComponent = ({index}) => {
  const formMethods = useFormContext();
  const tagType = useWatch({
    control: formMethods.control,
    name: `regulationTags.${index}.tag.type`,
  });

  switch (tagType?.typeName) {
    case "String":
      return <StringTagAutocomplete index={index} />;
    case "Number":
      return <NumberTag index={index} />;
    case "Boolean":
      return <BooleanTag index={index} />;
    case "Deadline":
      return <DurationPicker index={index} />;
  }
};

TagValueComponent.propTypes = {
  index: PropTypes.number.isRequired,
};

const RegulationTag = ({index, removeTagRow}) => {
  return <Stack direction="row" padding={1}>
    <TagTypeSelect index={index} />
    <TagValueComponent index={index} />
    <IconButton color="error" onClick={removeTagRow}>
      <ClearIcon />
    </IconButton>
  </Stack>;
};

RegulationTag.propTypes = {
  index: PropTypes.number.isRequired,
  removeTagRow: PropTypes.func.isRequired,
};

const RegulationTagManager = () => {
  const tagsFieldArrayMethods = useFieldArray({
    name: "regulationTags",
  });

  const addTagRow = () => {
    tagsFieldArrayMethods.append({tag: {
      type: {
        name: "",
      },
      value: "",
    }});
  };

  const removeTagRow = (index) => {
    tagsFieldArrayMethods.remove(index);
  };

  const tagRows = tagsFieldArrayMethods.fields.map((field, index) => {
    if (field.tag.type.name === "Geography") return;
    return <RegulationTag
      key={field.id}
      index={index}
      removeTagRow={() => removeTagRow(index)}
    />;
  }).filter((tagRow) => !isEmpty(tagRow));

  return (
    <Box>
      <Divider sx={{borderWidth: 2, marginY: 2}} />
      <Box padding={1}>
        Tags
        <IconButton color="success" onClick={addTagRow}>
          <AddBoxRoundedIcon />
        </IconButton>
        <Stack>{tagRows}</Stack>
      </Box>
      <Divider sx={{borderWidth: 2, marginY: 2}} />
    </Box>
  );
};

export default RegulationTagManager;
