import React from "react";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
  fromPromise,
} from "@apollo/client";
import {setContext} from "@apollo/client/link/context";
import {onError} from "@apollo/client/link/error";
import {ApolloLink} from "@apollo/client";
import {isEmpty} from "underscore";
import PropTypes from "prop-types";
import {useLogin} from "../components/login/LoginProvider";

const GRAPHQL_API_URL = process.env.GRAPHQL_API_URL;

const ApolloClientProvider = ({children}) => {
  const {user, refreshSession} = useLogin();

  const httpLink = createHttpLink({
    uri: GRAPHQL_API_URL,
  });

  const authLink = setContext((_, {headers}) => {
    return {
      headers: {
        ...headers,
        authorization: !isEmpty(user) ? `Bearer ${user.IdToken}` : "",
      },
    };
  });

  const errorLink = onError(({operation, forward, networkError}) => {
    if (networkError?.statusCode === 401) {
      return fromPromise(refreshSession().then((result) => {
        const token = result.IdToken;
        const oldHeaders = operation.getContext().headers;

        operation.setContext({
          headers: {
            ...oldHeaders,
            authorization: `Bearer ${token}`,
          },
        });

        return forward(operation);
      }));
    }
  });

  const links = ApolloLink.from([authLink, errorLink, httpLink]);

  const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: links,
  });

  return (
    <ApolloProvider client={client}>
      {children}
    </ApolloProvider>
  );
};

ApolloClientProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ApolloClientProvider;


