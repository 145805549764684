import React from "react";
import {useFieldArray, useFormContext} from "react-hook-form";
import {
  Checkbox, FormControl, Select, InputLabel, OutlinedInput, Box, Chip, MenuItem,
} from "@mui/material";
import {useUsers} from "./UsersContext";
import {findWhere, map} from "underscore";

const UserGroupsSelect = () => {
  const formMethods = useFormContext();
  const {groups} = useUsers();
  const groupsFieldArray = useFieldArray({
    name: "Groups",
  });

  const onChange = (event) => {
    const {value} = event.target;
    const newGroups = groups.filter((group) => value.includes(group.name));
    formMethods.setValue("Groups", newGroups);
  };

  const renderValue = (selected) => {
    return <Box sx={{display: "flex", flexWrap: "wrap", gap: 0.5}}>
      {map(selected, (value) => {
        return <Chip key={value} label={value} variant="outlined" color="primary" size="small" />;
      })}
    </Box>;
  };

  const GroupOptionsMenuItems = map(groups, (group, index) => {
    const isActive = findWhere(groupsFieldArray.fields, {name: group.name});
    return <MenuItem key={index} value={group.name}>
      <Checkbox checked={!!isActive} />
      {group.name}
    </MenuItem>;
  });

  return <div>
    <FormControl sx={{width: 300}}>
      <InputLabel id="user-groups-select-label">Groups</InputLabel>
      <Select
        labelId="user-groups-select-label"
        id="user-groups-select"
        multiple
        value={map(groupsFieldArray.fields, (field) => field.name)}
        onChange={onChange}
        input={<OutlinedInput id="select-multiple-chip" label="Groups" />}
        renderValue={renderValue}
        size="small"
      >
        {GroupOptionsMenuItems}
      </Select>
    </FormControl>
  </div>;
};

export default UserGroupsSelect;
