import {Subject} from "rxjs";

const messageBus = new Subject().pipe();

export const ALERT = {
  messages: messageBus,
  success: (text) => messageBus.next([{type: "success", text}]),
  warning: (text) => messageBus.next([{type: "warning", text}]),
  error: (text) => messageBus.next([{type: "error", text}]),
  info: (text) => messageBus.next([{type: "info", text}]),
  redirect: new Subject(),
  duration: 2500,
  maxSnack: 5,
};
