import React, {useEffect, useState} from "react";
import {Box, Button, Typography} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {usePapaParse} from "react-papaparse";
import {useNavigate} from "react-router-dom";
import {useUsers} from "./UsersContext";
import {countBy, each, isEmpty, keys, map, omit, reject, sortBy} from "underscore";

const UsersReport = () => {
  const {
    users,
    getUsersQuery,
    usersGroupsDetails,
    setUsersGroupsDetails,
    getUsersGroupsDetailsQuery,
    groupsDetails,
    setGroupsDetails,
    getGroupsDetailsQuery,
  } = useUsers();
  const [uniqueDomains, setUniqueDomains] = useState([]);
  const [isFetchingParsingData, setIsFetchingParsingData] = useState(false);
  const navigate = useNavigate();
  const {jsonToCSV} = usePapaParse();

  const exclusionList = ["breachrx.com", "gmail.com", "hotmail.com"];

  useEffect(() => {
    if (isEmpty(users) || getUsersQuery.loading) return;
    const activeEnabledUsers = reject(users, (user) =>
      !user?.Enabled || user?.UserStatus !== "CONFIRMED",
    );
    const sortedActiveEnabledUsers = sortBy(activeEnabledUsers, (user) =>
      user?.Username?.toLowerCase()?.split("@")?.[1],
    );
    const domainsAndCounts = countBy(sortedActiveEnabledUsers, (user) =>
      user?.Username?.toLowerCase()?.split("@")?.[1],
    );
    const uniqueDomainsAndCounts = omit(domainsAndCounts, (_, domain) =>
      isEmpty(domain) || exclusionList.includes(domain),
    );
    setUniqueDomains(uniqueDomainsAndCounts);
  }, [getUsersQuery.loading, users]);

  useEffect(() => {
    if (isEmpty(usersGroupsDetails) || isEmpty(groupsDetails)) return;
    const usersGroupsCsvData = jsonToCSV(usersGroupsDetails);
    const groupsCsvData = jsonToCSV(groupsDetails);

    each([usersGroupsCsvData, groupsCsvData], (csvData, index) => {
      const blob = new Blob([csvData], {type: "text/csv"});
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = index === 0 ?
        "BreachRx Detailed User Group Report.csv" :
        "BreachRx Groups Details.csv";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    });

    setUsersGroupsDetails();
    setGroupsDetails();
    setIsFetchingParsingData(false);
  }, [isFetchingParsingData, usersGroupsDetails, groupsDetails]);

  if (getUsersQuery.loading) return null;

  const fetchAndDownloadReports = async () => {
    setUsersGroupsDetails();
    setGroupsDetails();
    setIsFetchingParsingData(true);
    getUsersGroupsDetailsQuery();
    getGroupsDetailsQuery();
  };

  return (
    <Box>
      <Box display="flex" justifyContent="space-between">
        <Box display="flex">
          <Button onClick={() => navigate("/users")}><ArrowBackIcon /></Button>
          <Typography variant="h4" mb={2} mt={1}>Users Report</Typography>
        </Box>
        <Box>
          <span style={{marginRight: 8}}>{isFetchingParsingData ? "LOADING" : ""}</span>
          <Button
            variant="contained"
            onClick={fetchAndDownloadReports}
            sx={{marginRight: 1}}
          >
            Detailed CSVs
          </Button>
          <Button
            variant="contained"
            onClick={() => getUsersQuery.refetch()}
            sx={{marginBottom: 1.5, marginTop: 1.5}}
          >
            Refresh
          </Button>
        </Box>
      </Box>
      <Typography variant="h5">Unique Domains ({keys(uniqueDomains)?.length ?? 0})</Typography>
      <Typography variant="caption">Excludes {exclusionList.join(", ")}</Typography>
      <ul>
        {map(uniqueDomains, (count, domain) => (
          <li key={domain}>{domain} ({count})</li>
        ))}
      </ul>
    </Box>
  );
};

export default UsersReport;
