import React from "react";
import {Select, InputLabel, FormControl} from "@mui/material";
import {Controller, useFormContext} from "react-hook-form";
import {PropTypes} from "prop-types";

const ControlledSelect = ({name, label, options, selectProps, defaultValue}) => {
  const formMethods = useFormContext();

  return <Controller
    control={formMethods.control}
    name={name}
    defaultValue={defaultValue ?? ""}
    render={({field: {onChange, value}}) => (
      <FormControl style={{minWidth: 450}}>
        <InputLabel id={`${name}-label`} required={selectProps?.required}>{label}</InputLabel>
        <Select
          labelId={`${name}-label`}
          label={label}
          id={name}
          value={value}
          onChange={onChange}
          {...selectProps}
          fullWidth
        >
          {options}
        </Select>
      </FormControl>
    )}
  />;
};

ControlledSelect.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string,
  label: PropTypes.string,
  options: PropTypes.array,
  selectProps: PropTypes.object,
  defaultValue: PropTypes.any,
};

export default ControlledSelect;
