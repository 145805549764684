import { areDatesEqual, replaceInvalidDateByNull } from './date-utils';
import { addPositionPropertiesToSections, createDateStrForInputFromSections, splitFormatIntoSections } from '../hooks/useField/useField.utils';
export const singleItemValueManager = {
  emptyValue: null,
  getTodayValue: utils => utils.date(),
  cleanValue: replaceInvalidDateByNull,
  areValuesEqual: areDatesEqual,
  isSameError: (a, b) => a === b,
  defaultErrorState: null
};
export const singleItemFieldValueManager = {
  updateReferenceValue: (utils, value, prevReferenceValue) => value == null || !utils.isValid(value) ? prevReferenceValue : value,
  getSectionsFromValue: (utils, localeText, prevSections, date, format) => {
    const shouldReUsePrevDateSections = !utils.isValid(date) && !!prevSections;
    if (shouldReUsePrevDateSections) {
      return prevSections;
    }
    return addPositionPropertiesToSections(splitFormatIntoSections(utils, localeText, format, date));
  },
  getValueStrFromSections: sections => createDateStrForInputFromSections(sections),
  getActiveDateSections: sections => sections,
  getActiveDateManager: (utils, state) => ({
    activeDate: state.value,
    referenceActiveDate: state.referenceValue,
    getNewValueFromNewActiveDate: newActiveDate => ({
      value: newActiveDate,
      referenceValue: newActiveDate == null || !utils.isValid(newActiveDate) ? state.referenceValue : newActiveDate
    })
  }),
  parseValueStr: (valueStr, referenceValue, parseDate) => parseDate(valueStr.trim(), referenceValue),
  hasError: error => error != null
};