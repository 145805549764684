import React, {useEffect} from "react";
import {Box, Button, IconButton, TextField, Typography} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import FollowTheSignsIcon from "@mui/icons-material/FollowTheSigns";
import {NavLink, useNavigate} from "react-router-dom";
import {FormProvider, useForm} from "react-hook-form";
import {ErrorMessage} from "@hookform/error-message";
import {useRegulationStyles} from "./RegulationStyles";
import ControlledDatePicker from "../components/ControlledDatePicker";
import ControlledSunEditor from "../components/ControlledSunEditor";
import ControlledSwitch from "../components/ControlledSwitch";
import {RegulationProvider, useRegulation} from "./RegulationContext";
import RegulationTypeSelect from "./RegulationTypeSelect";
import RegulationLegalTypeSelect from "./RegulationLegalTypeSelect";
import RegulationTagManager from "./RegulationTagManager";
import RegulationGeographySelect from "./RegulationGeographySelect";
import RegulationNews from "./RegulationNews";
import RegulationGroupSelect from "./RegulationGroupSelect";
import NotificationField from "./Notifications/NotificationField";
import camelcase from "camelcase";
import {map, reduce} from "underscore";

const RegulationDetailsContent = () => {
  const formMethods = useForm({defaultValues: {isDraft: true}});
  const {regulation, notificationLabels, create, update, loading} = useRegulation();
  const navigate = useNavigate();
  const {classes} = useRegulationStyles();

  useEffect(() => {
    if (!regulation) return;

    const parsedRegulationTags = map(regulation.regulationTags, (regulationTag) => ({
      ...regulationTag,
      tag: {
        ...regulationTag.tag,
        value: JSON.parse(regulationTag.tag.value),
      },
    }));

    const geographies = parsedRegulationTags.filter(
        (regulationTag) => regulationTag.tag.type.name === "Geography",
    );
    const countries = geographies.filter(
        (geography) => geography.tag.value.geographyType === "Country",
    );
    const states = geographies.filter((geography) => geography.tag.value.geographyType === "State");
    const countryNames = map(countries, (country) => country.tag.value.country.name);
    const stateNames = map(states, (state) => state.tag.value.state.name);

    const notificationFormData = reduce(
        regulation?.notifications,
        (memo, regulationNotification) => {
          const notification = regulationNotification.notification;

          memo[`${camelcase(notification.type)}Notification`] = notification.content;
          memo[`${camelcase(notification.type)}Conditions`] = notification.conditions;
          memo[`${camelcase(notification.type)}Template`] = notification.templates;

          return memo;
        },
        {},
    );

    formMethods.reset({
      ...regulation,
      ...notificationFormData,
      regulationType: regulation.type.name,
      regulationCountries: countryNames,
      regulationStates: stateNames,
      regulationTags: parsedRegulationTags,
    });
  }, [regulation]);

  if (loading) return <center><Typography variant="h3">Loading</Typography></center>;

  const title = regulation ? `Regulation Details: ${regulation.id}` : "New Regulation";

  const notificationFields = map(notificationLabels, (value, key) => (
    <NotificationField notificationType={key} key={key} options={{disable: loading}}/>
  ));

  return (
    <Box className={classes.wrapper}>
      <Box alignContent="center" justifyContent="center">
        <Box padding={1} display="flex">
          <IconButton
            color="primary"
            aria-label="go-back"
            component={NavLink}
            to="/"
            size="large"
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h4">{title}</Typography>
        </Box>
        <FormProvider {...formMethods}>
          <form onSubmit={formMethods.handleSubmit(regulation ? update : create)}>
            <Box padding={1}>
              <ControlledSwitch name="isDraft" label="is draft?" />
            </Box>
            <Box padding={1}>
              <TextField
                {...formMethods.register("regulator", {required: true})}
                variant="outlined"
                id="regulator"
                label="Regulator"
                size="small"
                fullWidth={true}
                InputLabelProps={{shrink: true}}
                required
              />
            </Box>
            <Box padding={1} sx={{minWidth: 400}}>
              <RegulationTypeSelect />
            </Box>
            <Box padding={1} sx={{minWidth: 400}}>
              <RegulationLegalTypeSelect />
            </Box>
            <Box padding={1} sx={{minWidth: 400}}>
              <RegulationGroupSelect />
            </Box>
            <Box padding={1}>
              <ControlledSunEditor
                label="Source"
                name="source"
                rules={{required: true}}
              />
            </Box>
            <Box
              onClick={() => navigate(`/regulations/edit/${regulation?.id}/leader`)}
              className={classes.regulationLeaderBox}
              id="regulator-leader-element"
            >
              <FollowTheSignsIcon color="primary" sx={{marginRight: 1}} />
              <Typography color="primary" variant="button">
                {`${regulation?.leader?.name ? "Update" : "Create"} regulator leader`}
              </Typography>
            </Box>
            <Box padding={1}>
              <ControlledSunEditor
                label="Description"
                name="description"
                rules={{required: true}}
              />
              <ErrorMessage errors={formMethods?.formState?.errors} name="description" />
            </Box>
            <Box padding={1}>
              <ControlledSunEditor
                label="Exceptions & Exemptions"
                name="exceptions"
                rules={{required: true}}
              />
            </Box>
            <Box padding={1}>
              <RegulationGeographySelect />
            </Box>
            <Box padding={1}>
              <ControlledSunEditor
                label="Penalties"
                name="penalties"
                rules={{required: true}}
              />
            </Box>
            <Box padding={1}>
              <ControlledSunEditor
                label="PII Definition"
                name="piiDefinition"
              />
            </Box>
            <Box padding={1}>
              <ControlledDatePicker
                name="legislationPassedAt"
                options={{
                  label: "Legislation passed at",
                }}
              />
            </Box>
            <Box padding={1}>
              <ControlledDatePicker
                name="effectiveAt"
                options={{
                  label: "Effective at",
                }}
              />
            </Box>
            <Box padding={1}>
              <ControlledSunEditor
                label="Other Requirements"
                name="otherRequirements"
              />
            </Box>
            {notificationFields}
            <RegulationTagManager />
            <RegulationNews />
            <Box padding={1}>
              <Button
                type="submit"
                variant="outlined"
                disabled={loading}
              >
                {regulation ? "Update" : "Create"}
              </Button>
            </Box>
          </form>
        </FormProvider>
      </Box>
    </Box>
  );
};

const RegulationDetails = () => {
  return (
    <RegulationProvider>
      <RegulationDetailsContent />
    </RegulationProvider>
  );
};

export default RegulationDetails;
