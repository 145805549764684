query GetRegulations {
  regulations {
    id
    isDeleted
    createdAt
    updatedAt
    isDraft
    regulationTypeId
    regulator
    description
    source
    penalties
    piiDefinition
    otherRequirements
    legislationPassedAt
    effectiveAt
    legalType
    exceptions
  }
}
