import React, {useEffect} from "react";
import {RegulationProvider, useRegulation} from "./RegulationContext";
import {Box, Button, IconButton, TextField, Typography} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {NavLink} from "react-router-dom";
import {FormProvider, useForm} from "react-hook-form";
import {isEmpty, mapObject, pick} from "underscore";
import {useRegulationStyles} from "./RegulationStyles";

const RegulatorLeaderDetails = () => {
  const {regulation, loading, updateRegulatorLeader, deleteRegulatorLeader} = useRegulation();
  const defaultRegulatorLeader = regulation?.leader ?? {};
  const formMethods = useForm();
  const {classes} = useRegulationStyles();

  useEffect(() => {
    if (loading) return;
    if (!isEmpty(defaultRegulatorLeader)) {
      const defaultValueToUse = formMethods?.deleted ? {} : defaultRegulatorLeader;
      formMethods.reset(defaultValueToUse);
    };
  }, [loading, regulation]);

  const handleDelete = async () => {
    await deleteRegulatorLeader();
    formMethods.reset({deleted: true});
  };

  const handleSubmit = async (formData) => {
    if (isEmpty(formData.name)) return;
    const cleanFormData = pick(formData, [
      "name", "title", "office", "building", "address", "address2", "alternate", "email", "phone",
    ]);
    const formDataToSubmit = ({
      create: cleanFormData,
      update: mapObject(cleanFormData, (item) => ({set: item})),
    });
    await updateRegulatorLeader(formDataToSubmit);
    formMethods.reset(cleanFormData);
  };

  if (loading) return <center><Typography variant="h3">Loading</Typography></center>;

  return (
    <Box className={classes.wrapper}>
      <Box alignContent="center" justifyContent="center">
        <Box padding={1} display="flex">
          <IconButton
            color="primary"
            aria-label="go-back"
            component={NavLink}
            to={`/regulations/edit/${regulation?.id}`}
            size="large"
          >
            <ArrowBackIcon />
          </IconButton>
          <Box display="flex" flexGrow={1} flexDirection="row">
            <Box>
              <Typography variant="h4">Regulator Leader</Typography>
              <Typography fontWeight="500">{regulation?.regulator}</Typography>
            </Box>
            {!isEmpty(defaultRegulatorLeader) &&
              <Button
                variant="outlined"
                color="error"
                sx={{position: "absolute", right: 0, marginRight: 22, marginY: 2}}
                onClick={() => handleDelete()}
                disabled={loading}
              >
                Delete
              </Button>
            }
          </Box>
        </Box>
        <Box px={5} pt={1}>
          <FormProvider {...formMethods}>
            <form onSubmit={formMethods.handleSubmit(handleSubmit)}>
              <Box padding={1}>
                <TextField
                  {...formMethods.register("name", {required: true})}
                  variant="outlined"
                  id="name"
                  label="Name"
                  size="small"
                  fullWidth
                  InputLabelProps={{shrink: true}}
                  required
                />
              </Box>
              <Box padding={1}>
                <TextField
                  {...formMethods.register("title")}
                  variant="outlined"
                  id="title"
                  label="Title"
                  size="small"
                  fullWidth
                  InputLabelProps={{shrink: true}}
                />
              </Box>
              <Box padding={1}>
                <TextField
                  {...formMethods.register("office")}
                  variant="outlined"
                  id="office"
                  label="Office"
                  size="small"
                  fullWidth
                  InputLabelProps={{shrink: true}}
                />
              </Box>
              <Box padding={1}>
                <TextField
                  {...formMethods.register("building")}
                  variant="outlined"
                  id="building"
                  label="Building (or similar)"
                  size="small"
                  fullWidth
                  InputLabelProps={{shrink: true}}
                />
              </Box>
              <Box padding={1}>
                <TextField
                  {...formMethods.register("address")}
                  variant="outlined"
                  id="address"
                  label="Address Line 1"
                  size="small"
                  fullWidth
                  InputLabelProps={{shrink: true}}
                />
              </Box>
              <Box padding={1}>
                <TextField
                  {...formMethods.register("address2")}
                  variant="outlined"
                  id="address2"
                  label="Address Line 2"
                  size="small"
                  fullWidth
                  InputLabelProps={{shrink: true}}
                />
              </Box>
              <Box padding={1}>
                <TextField
                  {...formMethods.register("alternate")}
                  variant="outlined"
                  id="alternate"
                  label="Alternate (full address)"
                  size="small"
                  fullWidth
                  InputLabelProps={{shrink: true}}
                />
              </Box>
              <Box padding={1}>
                <TextField
                  {...formMethods.register("email")}
                  variant="outlined"
                  id="email"
                  label="Email"
                  size="small"
                  fullWidth
                  InputLabelProps={{shrink: true}}
                />
              </Box>
              <Box padding={1}>
                <TextField
                  {...formMethods.register("phone")}
                  variant="outlined"
                  id="phone"
                  label="Phone"
                  size="small"
                  fullWidth
                  InputLabelProps={{shrink: true}}
                />
              </Box>
              <Box padding={1}>
                <Button
                  type="submit"
                  variant="outlined"
                  disabled={loading}
                >
                  {defaultRegulatorLeader?.name ? "Update" : "Create"}
                </Button>
              </Box>
            </form>
          </FormProvider>
        </Box>
      </Box>
    </Box>
  );
};

const RegulatorLeader = () => {
  return (
    <RegulationProvider>
      <RegulatorLeaderDetails />
    </RegulationProvider>
  );
};

export default RegulatorLeader;
