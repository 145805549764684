import countryData from "../../public/geographies/countries_states.json";
import {findWhere, isArray, isEmpty, flatten, pluck} from "underscore";

const coreCountryData = countryData.map((country) => {
  return {
    name: country.name,
    isoCode: country.iso2,
  };
});

const getAllStateData = () => flatten(pluck(countryData, "states"));

const getStates = (countryIsoCodes = ["US"]) => {
  const states = [];

  if (isArray(countryIsoCodes)) {
    for (const countryIsoCode of isEmpty(countryIsoCodes) ? ["US"] : countryIsoCodes) {
      const countryData = getCountryData(countryIsoCode, false);
      states.push(...countryData.states);
    }
  } else {
    states.push(...getCountryData(countryIsoCodes, false).states);
  }

  return states;
};

const getCountryData = (isoCode, onlyCoreData=true) => {
  if (onlyCoreData) {
    return findWhere(coreCountryData, {isoCode: isoCode});
  } else {
    return findWhere(countryData, {iso2: isoCode});
  }
};

const getCountryByName = (countryName, onlyCoreData=true) => {
  if (onlyCoreData) return findWhere(coreCountryData, {name: countryName});
  else return findWhere(countryData, {name: countryName});
};

const getStateData = (stateCode, countryCode) => {
  const countryData = getCountryData(countryCode, false);
  return findWhere(countryData.states, {state_code: stateCode});
};

const getStateById = (stateId) => {
  for (const country of countryData) {
    const foundState = findWhere(country.states, {id: parseInt(stateId, 10)});
    if (!isEmpty(foundState)) return {countryIsoCode: country.iso2, ...foundState};
  }
};

const getStateByName = (countryCode, stateName) => {
  const countryData = getCountryData(countryCode, false);
  return findWhere(countryData.states, {name: stateName});
};

export {
  countryData,
  coreCountryData,
  getAllStateData,
  getStates,
  getCountryData,
  getCountryByName,
  getStateData,
  getStateById,
  getStateByName,
};
