[
  {
    "name": "Afghanistan",
    "iso3": "AFG",
    "iso2": "AF",
    "numeric_code": "004",
    "phone_code": "93",
    "capital": "Kabul",
    "currency": "AFN",
    "currency_name": "Afghan afghani",
    "currency_symbol": "؋",
    "tld": ".af",
    "native": "افغانستان",
    "region": "Asia",
    "subregion": "Southern Asia",
    "emoji": "🇦🇫",
    "emojiU": "U+1F1E6 U+1F1EB",
    "states": [
      {
        "id": 3901,
        "name": "Badakhshan",
        "state_code": "BDS",
        "type": null
      },
      {
        "id": 3871,
        "name": "Badghis",
        "state_code": "BDG",
        "type": null
      },
      {
        "id": 3875,
        "name": "Baghlan",
        "state_code": "BGL",
        "type": null
      },
      {
        "id": 3884,
        "name": "Balkh",
        "state_code": "BAL",
        "type": null
      },
      {
        "id": 3872,
        "name": "Bamyan",
        "state_code": "BAM",
        "type": null
      },
      {
        "id": 3892,
        "name": "Daykundi",
        "state_code": "DAY",
        "type": null
      },
      {
        "id": 3899,
        "name": "Farah",
        "state_code": "FRA",
        "type": null
      },
      {
        "id": 3889,
        "name": "Faryab",
        "state_code": "FYB",
        "type": null
      },
      {
        "id": 3870,
        "name": "Ghazni",
        "state_code": "GHA",
        "type": null
      },
      {
        "id": 3888,
        "name": "Ghōr",
        "state_code": "GHO",
        "type": null
      },
      {
        "id": 3873,
        "name": "Helmand",
        "state_code": "HEL",
        "type": null
      },
      {
        "id": 3887,
        "name": "Herat",
        "state_code": "HER",
        "type": null
      },
      {
        "id": 3886,
        "name": "Jowzjan",
        "state_code": "JOW",
        "type": null
      },
      {
        "id": 3902,
        "name": "Kabul",
        "state_code": "KAB",
        "type": null
      },
      {
        "id": 3890,
        "name": "Kandahar",
        "state_code": "KAN",
        "type": null
      },
      {
        "id": 3879,
        "name": "Kapisa",
        "state_code": "KAP",
        "type": null
      },
      {
        "id": 3878,
        "name": "Khost",
        "state_code": "KHO",
        "type": null
      },
      {
        "id": 3876,
        "name": "Kunar",
        "state_code": "KNR",
        "type": null
      },
      {
        "id": 3900,
        "name": "Kunduz Province",
        "state_code": "KDZ",
        "type": null
      },
      {
        "id": 3891,
        "name": "Laghman",
        "state_code": "LAG",
        "type": null
      },
      {
        "id": 3897,
        "name": "Logar",
        "state_code": "LOG",
        "type": null
      },
      {
        "id": 3882,
        "name": "Nangarhar",
        "state_code": "NAN",
        "type": null
      },
      {
        "id": 3896,
        "name": "Nimruz",
        "state_code": "NIM",
        "type": null
      },
      {
        "id": 3880,
        "name": "Nuristan",
        "state_code": "NUR",
        "type": null
      },
      {
        "id": 3894,
        "name": "Paktia",
        "state_code": "PIA",
        "type": null
      },
      {
        "id": 3877,
        "name": "Paktika",
        "state_code": "PKA",
        "type": null
      },
      {
        "id": 3881,
        "name": "Panjshir",
        "state_code": "PAN",
        "type": null
      },
      {
        "id": 3895,
        "name": "Parwan",
        "state_code": "PAR",
        "type": null
      },
      {
        "id": 3883,
        "name": "Samangan",
        "state_code": "SAM",
        "type": null
      },
      {
        "id": 3885,
        "name": "Sar-e Pol",
        "state_code": "SAR",
        "type": null
      },
      {
        "id": 3893,
        "name": "Takhar",
        "state_code": "TAK",
        "type": null
      },
      {
        "id": 3898,
        "name": "Urozgan",
        "state_code": "URU",
        "type": null
      },
      {
        "id": 3874,
        "name": "Zabul",
        "state_code": "ZAB",
        "type": null
      }
    ]
  },
  {
    "name": "Aland Islands",
    "iso3": "ALA",
    "iso2": "AX",
    "numeric_code": "248",
    "phone_code": "+358-18",
    "capital": "Mariehamn",
    "currency": "EUR",
    "currency_name": "Euro",
    "currency_symbol": "€",
    "tld": ".ax",
    "native": "Åland",
    "region": "Europe",
    "subregion": "Northern Europe",
    "emoji": "🇦🇽",
    "emojiU": "U+1F1E6 U+1F1FD",
    "states": []
  },
  {
    "name": "Albania",
    "iso3": "ALB",
    "iso2": "AL",
    "numeric_code": "008",
    "phone_code": "355",
    "capital": "Tirana",
    "currency": "ALL",
    "currency_name": "Albanian lek",
    "currency_symbol": "Lek",
    "tld": ".al",
    "native": "Shqipëria",
    "region": "Europe",
    "subregion": "Southern Europe",
    "emoji": "🇦🇱",
    "emojiU": "U+1F1E6 U+1F1F1",
    "states": [
      {
        "id": 603,
        "name": "Berat County",
        "state_code": "01",
        "type": null
      },
      {
        "id": 629,
        "name": "Berat District",
        "state_code": "BR",
        "type": null
      },
      {
        "id": 607,
        "name": "Bulqizë District",
        "state_code": "BU",
        "type": null
      },
      {
        "id": 618,
        "name": "Delvinë District",
        "state_code": "DL",
        "type": null
      },
      {
        "id": 608,
        "name": "Devoll District",
        "state_code": "DV",
        "type": null
      },
      {
        "id": 610,
        "name": "Dibër County",
        "state_code": "09",
        "type": null
      },
      {
        "id": 605,
        "name": "Dibër District",
        "state_code": "DI",
        "type": null
      },
      {
        "id": 632,
        "name": "Durrës County",
        "state_code": "02",
        "type": null
      },
      {
        "id": 639,
        "name": "Durrës District",
        "state_code": "DR",
        "type": null
      },
      {
        "id": 598,
        "name": "Elbasan County",
        "state_code": "03",
        "type": null
      },
      {
        "id": 631,
        "name": "Fier County",
        "state_code": "04",
        "type": null
      },
      {
        "id": 627,
        "name": "Fier District",
        "state_code": "FR",
        "type": null
      },
      {
        "id": 604,
        "name": "Gjirokastër County",
        "state_code": "05",
        "type": null
      },
      {
        "id": 621,
        "name": "Gjirokastër District",
        "state_code": "GJ",
        "type": null
      },
      {
        "id": 617,
        "name": "Gramsh District",
        "state_code": "GR",
        "type": null
      },
      {
        "id": 600,
        "name": "Has District",
        "state_code": "HA",
        "type": null
      },
      {
        "id": 594,
        "name": "Kavajë District",
        "state_code": "KA",
        "type": null
      },
      {
        "id": 628,
        "name": "Kolonjë District",
        "state_code": "ER",
        "type": null
      },
      {
        "id": 630,
        "name": "Korçë County",
        "state_code": "06",
        "type": null
      },
      {
        "id": 597,
        "name": "Korçë District",
        "state_code": "KO",
        "type": null
      },
      {
        "id": 614,
        "name": "Krujë District",
        "state_code": "KR",
        "type": null
      },
      {
        "id": 612,
        "name": "Kuçovë District",
        "state_code": "KC",
        "type": null
      },
      {
        "id": 601,
        "name": "Kukës County",
        "state_code": "07",
        "type": null
      },
      {
        "id": 623,
        "name": "Kukës District",
        "state_code": "KU",
        "type": null
      },
      {
        "id": 622,
        "name": "Kurbin District",
        "state_code": "KB",
        "type": null
      },
      {
        "id": 609,
        "name": "Lezhë County",
        "state_code": "08",
        "type": null
      },
      {
        "id": 595,
        "name": "Lezhë District",
        "state_code": "LE",
        "type": null
      },
      {
        "id": 596,
        "name": "Librazhd District",
        "state_code": "LB",
        "type": null
      },
      {
        "id": 599,
        "name": "Lushnjë District",
        "state_code": "LU",
        "type": null
      },
      {
        "id": 602,
        "name": "Malësi e Madhe District",
        "state_code": "MM",
        "type": null
      },
      {
        "id": 637,
        "name": "Mallakastër District",
        "state_code": "MK",
        "type": null
      },
      {
        "id": 635,
        "name": "Mat District",
        "state_code": "MT",
        "type": null
      },
      {
        "id": 638,
        "name": "Mirditë District",
        "state_code": "MR",
        "type": null
      },
      {
        "id": 619,
        "name": "Peqin District",
        "state_code": "PQ",
        "type": null
      },
      {
        "id": 625,
        "name": "Përmet District",
        "state_code": "PR",
        "type": null
      },
      {
        "id": 606,
        "name": "Pogradec District",
        "state_code": "PG",
        "type": null
      },
      {
        "id": 620,
        "name": "Pukë District",
        "state_code": "PU",
        "type": null
      },
      {
        "id": 624,
        "name": "Sarandë District",
        "state_code": "SR",
        "type": null
      },
      {
        "id": 611,
        "name": "Shkodër County",
        "state_code": "10",
        "type": null
      },
      {
        "id": 626,
        "name": "Shkodër District",
        "state_code": "SH",
        "type": null
      },
      {
        "id": 593,
        "name": "Skrapar District",
        "state_code": "SK",
        "type": null
      },
      {
        "id": 616,
        "name": "Tepelenë District",
        "state_code": "TE",
        "type": null
      },
      {
        "id": 615,
        "name": "Tirana County",
        "state_code": "11",
        "type": null
      },
      {
        "id": 633,
        "name": "Tirana District",
        "state_code": "TR",
        "type": null
      },
      {
        "id": 636,
        "name": "Tropojë District",
        "state_code": "TP",
        "type": null
      },
      {
        "id": 634,
        "name": "Vlorë County",
        "state_code": "12",
        "type": null
      },
      {
        "id": 613,
        "name": "Vlorë District",
        "state_code": "VL",
        "type": null
      }
    ]
  },
  {
    "name": "Algeria",
    "iso3": "DZA",
    "iso2": "DZ",
    "numeric_code": "012",
    "phone_code": "213",
    "capital": "Algiers",
    "currency": "DZD",
    "currency_name": "Algerian dinar",
    "currency_symbol": "دج",
    "tld": ".dz",
    "native": "الجزائر",
    "region": "Africa",
    "subregion": "Northern Africa",
    "emoji": "🇩🇿",
    "emojiU": "U+1F1E9 U+1F1FF",
    "states": [
      {
        "id": 1118,
        "name": "Adrar",
        "state_code": "01",
        "type": null
      },
      {
        "id": 1119,
        "name": "Aïn Defla",
        "state_code": "44",
        "type": null
      },
      {
        "id": 1122,
        "name": "Aïn Témouchent",
        "state_code": "46",
        "type": null
      },
      {
        "id": 1144,
        "name": "Algiers",
        "state_code": "16",
        "type": null
      },
      {
        "id": 1103,
        "name": "Annaba",
        "state_code": "23",
        "type": null
      },
      {
        "id": 1142,
        "name": "Batna",
        "state_code": "05",
        "type": null
      },
      {
        "id": 1108,
        "name": "Béchar",
        "state_code": "08",
        "type": null
      },
      {
        "id": 1128,
        "name": "Béjaïa",
        "state_code": "06",
        "type": null
      },
      {
        "id": 4909,
        "name": "Béni Abbès",
        "state_code": "53",
        "type": null
      },
      {
        "id": 1114,
        "name": "Biskra",
        "state_code": "07",
        "type": null
      },
      {
        "id": 1111,
        "name": "Blida",
        "state_code": "09",
        "type": null
      },
      {
        "id": 4908,
        "name": "Bordj Baji Mokhtar",
        "state_code": "52",
        "type": null
      },
      {
        "id": 1116,
        "name": "Bordj Bou Arréridj",
        "state_code": "34",
        "type": null
      },
      {
        "id": 1104,
        "name": "Bouïra",
        "state_code": "10",
        "type": null
      },
      {
        "id": 1125,
        "name": "Boumerdès",
        "state_code": "35",
        "type": null
      },
      {
        "id": 1105,
        "name": "Chlef",
        "state_code": "02",
        "type": null
      },
      {
        "id": 1121,
        "name": "Constantine",
        "state_code": "25",
        "type": null
      },
      {
        "id": 4912,
        "name": "Djanet",
        "state_code": "56",
        "type": null
      },
      {
        "id": 1098,
        "name": "Djelfa",
        "state_code": "17",
        "type": null
      },
      {
        "id": 1129,
        "name": "El Bayadh",
        "state_code": "32",
        "type": null
      },
      {
        "id": 4905,
        "name": "El M'ghair",
        "state_code": "49",
        "type": null
      },
      {
        "id": 4906,
        "name": "El Menia",
        "state_code": "50",
        "type": null
      },
      {
        "id": 1099,
        "name": "El Oued",
        "state_code": "39",
        "type": null
      },
      {
        "id": 1100,
        "name": "El Tarf",
        "state_code": "36",
        "type": null
      },
      {
        "id": 1127,
        "name": "Ghardaïa",
        "state_code": "47",
        "type": null
      },
      {
        "id": 1137,
        "name": "Guelma",
        "state_code": "24",
        "type": null
      },
      {
        "id": 1112,
        "name": "Illizi",
        "state_code": "33",
        "type": null
      },
      {
        "id": 4914,
        "name": "In Guezzam",
        "state_code": "58",
        "type": null
      },
      {
        "id": 4913,
        "name": "In Salah",
        "state_code": "57",
        "type": null
      },
      {
        "id": 1113,
        "name": "Jijel",
        "state_code": "18",
        "type": null
      },
      {
        "id": 1126,
        "name": "Khenchela",
        "state_code": "40",
        "type": null
      },
      {
        "id": 1138,
        "name": "Laghouat",
        "state_code": "03",
        "type": null
      },
      {
        "id": 1134,
        "name": "M'Sila",
        "state_code": "28",
        "type": null
      },
      {
        "id": 1124,
        "name": "Mascara",
        "state_code": "29",
        "type": null
      },
      {
        "id": 1109,
        "name": "Médéa",
        "state_code": "26",
        "type": null
      },
      {
        "id": 1132,
        "name": "Mila",
        "state_code": "43",
        "type": null
      },
      {
        "id": 1140,
        "name": "Mostaganem",
        "state_code": "27",
        "type": null
      },
      {
        "id": 1102,
        "name": "Naama",
        "state_code": "45",
        "type": null
      },
      {
        "id": 1101,
        "name": "Oran",
        "state_code": "31",
        "type": null
      },
      {
        "id": 1139,
        "name": "Ouargla",
        "state_code": "30",
        "type": null
      },
      {
        "id": 4907,
        "name": "Ouled Djellal",
        "state_code": "51",
        "type": null
      },
      {
        "id": 1136,
        "name": "Oum El Bouaghi",
        "state_code": "04",
        "type": null
      },
      {
        "id": 1130,
        "name": "Relizane",
        "state_code": "48",
        "type": null
      },
      {
        "id": 1123,
        "name": "Saïda",
        "state_code": "20",
        "type": null
      },
      {
        "id": 1141,
        "name": "Sétif",
        "state_code": "19",
        "type": null
      },
      {
        "id": 4902,
        "name": "Sidi Bel Abbès",
        "state_code": "22",
        "type": null
      },
      {
        "id": 1110,
        "name": "Skikda",
        "state_code": "21",
        "type": null
      },
      {
        "id": 1143,
        "name": "Souk Ahras",
        "state_code": "41",
        "type": null
      },
      {
        "id": 1135,
        "name": "Tamanghasset",
        "state_code": "11",
        "type": null
      },
      {
        "id": 1117,
        "name": "Tébessa",
        "state_code": "12",
        "type": null
      },
      {
        "id": 1106,
        "name": "Tiaret",
        "state_code": "14",
        "type": null
      },
      {
        "id": 4910,
        "name": "Timimoun",
        "state_code": "54",
        "type": null
      },
      {
        "id": 1120,
        "name": "Tindouf",
        "state_code": "37",
        "type": null
      },
      {
        "id": 1115,
        "name": "Tipasa",
        "state_code": "42",
        "type": null
      },
      {
        "id": 1133,
        "name": "Tissemsilt",
        "state_code": "38",
        "type": null
      },
      {
        "id": 1131,
        "name": "Tizi Ouzou",
        "state_code": "15",
        "type": null
      },
      {
        "id": 1107,
        "name": "Tlemcen",
        "state_code": "13",
        "type": null
      },
      {
        "id": 4911,
        "name": "Touggourt",
        "state_code": "55",
        "type": null
      }
    ]
  },
  {
    "name": "American Samoa",
    "iso3": "ASM",
    "iso2": "AS",
    "numeric_code": "016",
    "phone_code": "+1-684",
    "capital": "Pago Pago",
    "currency": "USD",
    "currency_name": "US Dollar",
    "currency_symbol": "$",
    "tld": ".as",
    "native": "American Samoa",
    "region": "Oceania",
    "subregion": "Polynesia",
    "emoji": "🇦🇸",
    "emojiU": "U+1F1E6 U+1F1F8",
    "states": []
  },
  {
    "name": "Andorra",
    "iso3": "AND",
    "iso2": "AD",
    "numeric_code": "020",
    "phone_code": "376",
    "capital": "Andorra la Vella",
    "currency": "EUR",
    "currency_name": "Euro",
    "currency_symbol": "€",
    "tld": ".ad",
    "native": "Andorra",
    "region": "Europe",
    "subregion": "Southern Europe",
    "emoji": "🇦🇩",
    "emojiU": "U+1F1E6 U+1F1E9",
    "states": [
      {
        "id": 488,
        "name": "Andorra la Vella",
        "state_code": "07",
        "type": null
      },
      {
        "id": 489,
        "name": "Canillo",
        "state_code": "02",
        "type": null
      },
      {
        "id": 487,
        "name": "Encamp",
        "state_code": "03",
        "type": null
      },
      {
        "id": 492,
        "name": "Escaldes-Engordany",
        "state_code": "08",
        "type": null
      },
      {
        "id": 493,
        "name": "La Massana",
        "state_code": "04",
        "type": null
      },
      {
        "id": 491,
        "name": "Ordino",
        "state_code": "05",
        "type": null
      },
      {
        "id": 490,
        "name": "Sant Julià de Lòria",
        "state_code": "06",
        "type": null
      }
    ]
  },
  {
    "name": "Angola",
    "iso3": "AGO",
    "iso2": "AO",
    "numeric_code": "024",
    "phone_code": "244",
    "capital": "Luanda",
    "currency": "AOA",
    "currency_name": "Angolan kwanza",
    "currency_symbol": "Kz",
    "tld": ".ao",
    "native": "Angola",
    "region": "Africa",
    "subregion": "Middle Africa",
    "emoji": "🇦🇴",
    "emojiU": "U+1F1E6 U+1F1F4",
    "states": [
      {
        "id": 221,
        "name": "Bengo Province",
        "state_code": "BGO",
        "type": null
      },
      {
        "id": 218,
        "name": "Benguela Province",
        "state_code": "BGU",
        "type": null
      },
      {
        "id": 212,
        "name": "Bié Province",
        "state_code": "BIE",
        "type": null
      },
      {
        "id": 228,
        "name": "Cabinda Province",
        "state_code": "CAB",
        "type": null
      },
      {
        "id": 226,
        "name": "Cuando Cubango Province",
        "state_code": "CCU",
        "type": null
      },
      {
        "id": 217,
        "name": "Cuanza Norte Province",
        "state_code": "CNO",
        "type": null
      },
      {
        "id": 216,
        "name": "Cuanza Sul",
        "state_code": "CUS",
        "type": null
      },
      {
        "id": 215,
        "name": "Cunene Province",
        "state_code": "CNN",
        "type": null
      },
      {
        "id": 213,
        "name": "Huambo Province",
        "state_code": "HUA",
        "type": null
      },
      {
        "id": 225,
        "name": "Huíla Province",
        "state_code": "HUI",
        "type": null
      },
      {
        "id": 222,
        "name": "Luanda Province",
        "state_code": "LUA",
        "type": null
      },
      {
        "id": 223,
        "name": "Lunda Norte Province",
        "state_code": "LNO",
        "type": null
      },
      {
        "id": 220,
        "name": "Lunda Sul Province",
        "state_code": "LSU",
        "type": null
      },
      {
        "id": 227,
        "name": "Malanje Province",
        "state_code": "MAL",
        "type": null
      },
      {
        "id": 219,
        "name": "Moxico Province",
        "state_code": "MOX",
        "type": null
      },
      {
        "id": 224,
        "name": "Uíge Province",
        "state_code": "UIG",
        "type": null
      },
      {
        "id": 214,
        "name": "Zaire Province",
        "state_code": "ZAI",
        "type": null
      }
    ]
  },
  {
    "name": "Anguilla",
    "iso3": "AIA",
    "iso2": "AI",
    "numeric_code": "660",
    "phone_code": "+1-264",
    "capital": "The Valley",
    "currency": "XCD",
    "currency_name": "East Caribbean dollar",
    "currency_symbol": "$",
    "tld": ".ai",
    "native": "Anguilla",
    "region": "Americas",
    "subregion": "Caribbean",
    "emoji": "🇦🇮",
    "emojiU": "U+1F1E6 U+1F1EE",
    "states": []
  },
  {
    "name": "Antarctica",
    "iso3": "ATA",
    "iso2": "AQ",
    "numeric_code": "010",
    "phone_code": "672",
    "capital": "",
    "currency": "AAD",
    "currency_name": "Antarctican dollar",
    "currency_symbol": "$",
    "tld": ".aq",
    "native": "Antarctica",
    "region": "Polar",
    "subregion": "",
    "emoji": "🇦🇶",
    "emojiU": "U+1F1E6 U+1F1F6",
    "states": []
  },
  {
    "name": "Antigua And Barbuda",
    "iso3": "ATG",
    "iso2": "AG",
    "numeric_code": "028",
    "phone_code": "+1-268",
    "capital": "St. John's",
    "currency": "XCD",
    "currency_name": "Eastern Caribbean dollar",
    "currency_symbol": "$",
    "tld": ".ag",
    "native": "Antigua and Barbuda",
    "region": "Americas",
    "subregion": "Caribbean",
    "emoji": "🇦🇬",
    "emojiU": "U+1F1E6 U+1F1EC",
    "states": [
      {
        "id": 3708,
        "name": "Barbuda",
        "state_code": "10",
        "type": null
      },
      {
        "id": 3703,
        "name": "Redonda",
        "state_code": "11",
        "type": null
      },
      {
        "id": 3709,
        "name": "Saint George Parish",
        "state_code": "03",
        "type": null
      },
      {
        "id": 3706,
        "name": "Saint John Parish",
        "state_code": "04",
        "type": null
      },
      {
        "id": 3707,
        "name": "Saint Mary Parish",
        "state_code": "05",
        "type": null
      },
      {
        "id": 3705,
        "name": "Saint Paul Parish",
        "state_code": "06",
        "type": null
      },
      {
        "id": 3704,
        "name": "Saint Peter Parish",
        "state_code": "07",
        "type": null
      },
      {
        "id": 3710,
        "name": "Saint Philip Parish",
        "state_code": "08",
        "type": null
      }
    ]
  },
  {
    "name": "Argentina",
    "iso3": "ARG",
    "iso2": "AR",
    "numeric_code": "032",
    "phone_code": "54",
    "capital": "Buenos Aires",
    "currency": "ARS",
    "currency_name": "Argentine peso",
    "currency_symbol": "$",
    "tld": ".ar",
    "native": "Argentina",
    "region": "Americas",
    "subregion": "South America",
    "emoji": "🇦🇷",
    "emojiU": "U+1F1E6 U+1F1F7",
    "states": [
      {
        "id": 4880,
        "name": "Autonomous City Of Buenos Aires",
        "state_code": "C",
        "type": null
      },
      {
        "id": 3656,
        "name": "Buenos Aires Province",
        "state_code": "B",
        "type": null
      },
      {
        "id": 3647,
        "name": "Catamarca Province",
        "state_code": "K",
        "type": null
      },
      {
        "id": 3640,
        "name": "Chaco Province",
        "state_code": "H",
        "type": null
      },
      {
        "id": 3651,
        "name": "Chubut Province",
        "state_code": "U",
        "type": null
      },
      {
        "id": 3642,
        "name": "Córdoba Province",
        "state_code": "X",
        "type": null
      },
      {
        "id": 3638,
        "name": "Corrientes",
        "state_code": "W",
        "type": null
      },
      {
        "id": 3654,
        "name": "Entre Ríos Province",
        "state_code": "E",
        "type": null
      },
      {
        "id": 3652,
        "name": "Formosa Province",
        "state_code": "P",
        "type": null
      },
      {
        "id": 3645,
        "name": "Jujuy Province",
        "state_code": "Y",
        "type": null
      },
      {
        "id": 3655,
        "name": "La Pampa",
        "state_code": "L",
        "type": null
      },
      {
        "id": 3653,
        "name": "La Rioja Province",
        "state_code": "F",
        "type": null
      },
      {
        "id": 3646,
        "name": "Mendoza",
        "state_code": "M",
        "type": null
      },
      {
        "id": 3644,
        "name": "Misiones Province",
        "state_code": "N",
        "type": null
      },
      {
        "id": 3648,
        "name": "Neuquén Province",
        "state_code": "Q",
        "type": null
      },
      {
        "id": 3639,
        "name": "Río Negro Province",
        "state_code": "R",
        "type": null
      },
      {
        "id": 3643,
        "name": "Salta Province",
        "state_code": "A",
        "type": null
      },
      {
        "id": 3634,
        "name": "San Juan Province",
        "state_code": "J",
        "type": null
      },
      {
        "id": 3636,
        "name": "San Luis Province",
        "state_code": "D",
        "type": null
      },
      {
        "id": 3649,
        "name": "Santa Cruz Province",
        "state_code": "Z",
        "type": null
      },
      {
        "id": 3641,
        "name": "Santa Fe Province",
        "state_code": "S",
        "type": null
      },
      {
        "id": 3635,
        "name": "Santiago del Estero Province",
        "state_code": "G",
        "type": null
      },
      {
        "id": 3650,
        "name": "Tierra del Fuego Province",
        "state_code": "V",
        "type": null
      },
      {
        "id": 3637,
        "name": "Tucumán Province",
        "state_code": "T",
        "type": null
      }
    ]
  },
  {
    "name": "Armenia",
    "iso3": "ARM",
    "iso2": "AM",
    "numeric_code": "051",
    "phone_code": "374",
    "capital": "Yerevan",
    "currency": "AMD",
    "currency_name": "Armenian dram",
    "currency_symbol": "֏",
    "tld": ".am",
    "native": "Հայաստան",
    "region": "Asia",
    "subregion": "Western Asia",
    "emoji": "🇦🇲",
    "emojiU": "U+1F1E6 U+1F1F2",
    "states": [
      {
        "id": 2023,
        "name": "Aragatsotn Region",
        "state_code": "AG",
        "type": null
      },
      {
        "id": 2024,
        "name": "Ararat Province",
        "state_code": "AR",
        "type": null
      },
      {
        "id": 2026,
        "name": "Armavir Region",
        "state_code": "AV",
        "type": null
      },
      {
        "id": 2028,
        "name": "Gegharkunik Province",
        "state_code": "GR",
        "type": null
      },
      {
        "id": 2033,
        "name": "Kotayk Region",
        "state_code": "KT",
        "type": null
      },
      {
        "id": 2029,
        "name": "Lori Region",
        "state_code": "LO",
        "type": null
      },
      {
        "id": 2031,
        "name": "Shirak Region",
        "state_code": "SH",
        "type": null
      },
      {
        "id": 2027,
        "name": "Syunik Province",
        "state_code": "SU",
        "type": null
      },
      {
        "id": 2032,
        "name": "Tavush Region",
        "state_code": "TV",
        "type": null
      },
      {
        "id": 2025,
        "name": "Vayots Dzor Region",
        "state_code": "VD",
        "type": null
      },
      {
        "id": 2030,
        "name": "Yerevan",
        "state_code": "ER",
        "type": null
      }
    ]
  },
  {
    "name": "Aruba",
    "iso3": "ABW",
    "iso2": "AW",
    "numeric_code": "533",
    "phone_code": "297",
    "capital": "Oranjestad",
    "currency": "AWG",
    "currency_name": "Aruban florin",
    "currency_symbol": "ƒ",
    "tld": ".aw",
    "native": "Aruba",
    "region": "Americas",
    "subregion": "Caribbean",
    "emoji": "🇦🇼",
    "emojiU": "U+1F1E6 U+1F1FC",
    "states": []
  },
  {
    "name": "Australia",
    "iso3": "AUS",
    "iso2": "AU",
    "numeric_code": "036",
    "phone_code": "61",
    "capital": "Canberra",
    "currency": "AUD",
    "currency_name": "Australian dollar",
    "currency_symbol": "$",
    "tld": ".au",
    "native": "Australia",
    "region": "Oceania",
    "subregion": "Australia and New Zealand",
    "emoji": "🇦🇺",
    "emojiU": "U+1F1E6 U+1F1FA",
    "states": [
      {
        "id": 3907,
        "name": "Australian Capital Territory",
        "state_code": "ACT",
        "type": null
      },
      {
        "id": 3909,
        "name": "New South Wales",
        "state_code": "NSW",
        "type": null
      },
      {
        "id": 3910,
        "name": "Northern Territory",
        "state_code": "NT",
        "type": null
      },
      {
        "id": 3905,
        "name": "Queensland",
        "state_code": "QLD",
        "type": null
      },
      {
        "id": 3904,
        "name": "South Australia",
        "state_code": "SA",
        "type": null
      },
      {
        "id": 3908,
        "name": "Tasmania",
        "state_code": "TAS",
        "type": null
      },
      {
        "id": 3903,
        "name": "Victoria",
        "state_code": "VIC",
        "type": null
      },
      {
        "id": 3906,
        "name": "Western Australia",
        "state_code": "WA",
        "type": null
      }
    ]
  },
  {
    "name": "Austria",
    "iso3": "AUT",
    "iso2": "AT",
    "numeric_code": "040",
    "phone_code": "43",
    "capital": "Vienna",
    "currency": "EUR",
    "currency_name": "Euro",
    "currency_symbol": "€",
    "tld": ".at",
    "native": "Österreich",
    "region": "Europe",
    "subregion": "Western Europe",
    "emoji": "🇦🇹",
    "emojiU": "U+1F1E6 U+1F1F9",
    "states": [
      {
        "id": 2062,
        "name": "Burgenland",
        "state_code": "1",
        "type": null
      },
      {
        "id": 2057,
        "name": "Carinthia",
        "state_code": "2",
        "type": null
      },
      {
        "id": 2065,
        "name": "Lower Austria",
        "state_code": "3",
        "type": null
      },
      {
        "id": 2061,
        "name": "Salzburg",
        "state_code": "5",
        "type": null
      },
      {
        "id": 2059,
        "name": "Styria",
        "state_code": "6",
        "type": null
      },
      {
        "id": 2064,
        "name": "Tyrol",
        "state_code": "7",
        "type": null
      },
      {
        "id": 2058,
        "name": "Upper Austria",
        "state_code": "4",
        "type": null
      },
      {
        "id": 2060,
        "name": "Vienna",
        "state_code": "9",
        "type": null
      },
      {
        "id": 2063,
        "name": "Vorarlberg",
        "state_code": "8",
        "type": null
      }
    ]
  },
  {
    "name": "Azerbaijan",
    "iso3": "AZE",
    "iso2": "AZ",
    "numeric_code": "031",
    "phone_code": "994",
    "capital": "Baku",
    "currency": "AZN",
    "currency_name": "Azerbaijani manat",
    "currency_symbol": "m",
    "tld": ".az",
    "native": "Azərbaycan",
    "region": "Asia",
    "subregion": "Western Asia",
    "emoji": "🇦🇿",
    "emojiU": "U+1F1E6 U+1F1FF",
    "states": [
      {
        "id": 540,
        "name": "Absheron District",
        "state_code": "ABS",
        "type": null
      },
      {
        "id": 559,
        "name": "Agdam District",
        "state_code": "AGM",
        "type": null
      },
      {
        "id": 553,
        "name": "Agdash District",
        "state_code": "AGS",
        "type": null
      },
      {
        "id": 577,
        "name": "Aghjabadi District",
        "state_code": "AGC",
        "type": null
      },
      {
        "id": 543,
        "name": "Agstafa District",
        "state_code": "AGA",
        "type": null
      },
      {
        "id": 547,
        "name": "Agsu District",
        "state_code": "AGU",
        "type": null
      },
      {
        "id": 528,
        "name": "Astara District",
        "state_code": "AST",
        "type": null
      },
      {
        "id": 575,
        "name": "Babek District",
        "state_code": "BAB",
        "type": null
      },
      {
        "id": 552,
        "name": "Baku",
        "state_code": "BA",
        "type": null
      },
      {
        "id": 560,
        "name": "Balakan District",
        "state_code": "BAL",
        "type": null
      },
      {
        "id": 569,
        "name": "Barda District",
        "state_code": "BAR",
        "type": null
      },
      {
        "id": 554,
        "name": "Beylagan District",
        "state_code": "BEY",
        "type": null
      },
      {
        "id": 532,
        "name": "Bilasuvar District",
        "state_code": "BIL",
        "type": null
      },
      {
        "id": 561,
        "name": "Dashkasan District",
        "state_code": "DAS",
        "type": null
      },
      {
        "id": 527,
        "name": "Fizuli District",
        "state_code": "FUZ",
        "type": null
      },
      {
        "id": 585,
        "name": "Ganja",
        "state_code": "GA",
        "type": null
      },
      {
        "id": 589,
        "name": "Gədəbəy",
        "state_code": "GAD",
        "type": null
      },
      {
        "id": 573,
        "name": "Gobustan District",
        "state_code": "QOB",
        "type": null
      },
      {
        "id": 551,
        "name": "Goranboy District",
        "state_code": "GOR",
        "type": null
      },
      {
        "id": 531,
        "name": "Goychay",
        "state_code": "GOY",
        "type": null
      },
      {
        "id": 574,
        "name": "Goygol District",
        "state_code": "GYG",
        "type": null
      },
      {
        "id": 571,
        "name": "Hajigabul District",
        "state_code": "HAC",
        "type": null
      },
      {
        "id": 544,
        "name": "Imishli District",
        "state_code": "IMI",
        "type": null
      },
      {
        "id": 564,
        "name": "Ismailli District",
        "state_code": "ISM",
        "type": null
      },
      {
        "id": 570,
        "name": "Jabrayil District",
        "state_code": "CAB",
        "type": null
      },
      {
        "id": 578,
        "name": "Jalilabad District",
        "state_code": "CAL",
        "type": null
      },
      {
        "id": 572,
        "name": "Julfa District",
        "state_code": "CUL",
        "type": null
      },
      {
        "id": 525,
        "name": "Kalbajar District",
        "state_code": "KAL",
        "type": null
      },
      {
        "id": 567,
        "name": "Kangarli District",
        "state_code": "KAN",
        "type": null
      },
      {
        "id": 590,
        "name": "Khachmaz District",
        "state_code": "XAC",
        "type": null
      },
      {
        "id": 537,
        "name": "Khizi District",
        "state_code": "XIZ",
        "type": null
      },
      {
        "id": 524,
        "name": "Khojali District",
        "state_code": "XCI",
        "type": null
      },
      {
        "id": 549,
        "name": "Kurdamir District",
        "state_code": "KUR",
        "type": null
      },
      {
        "id": 541,
        "name": "Lachin District",
        "state_code": "LAC",
        "type": null
      },
      {
        "id": 587,
        "name": "Lankaran",
        "state_code": "LAN",
        "type": null
      },
      {
        "id": 558,
        "name": "Lankaran District",
        "state_code": "LA",
        "type": null
      },
      {
        "id": 546,
        "name": "Lerik District",
        "state_code": "LER",
        "type": null
      },
      {
        "id": 568,
        "name": "Martuni",
        "state_code": "XVD",
        "type": null
      },
      {
        "id": 555,
        "name": "Masally District",
        "state_code": "MAS",
        "type": null
      },
      {
        "id": 580,
        "name": "Mingachevir",
        "state_code": "MI",
        "type": null
      },
      {
        "id": 562,
        "name": "Nakhchivan Autonomous Republic",
        "state_code": "NX",
        "type": null
      },
      {
        "id": 530,
        "name": "Neftchala District",
        "state_code": "NEF",
        "type": null
      },
      {
        "id": 556,
        "name": "Oghuz District",
        "state_code": "OGU",
        "type": null
      },
      {
        "id": 534,
        "name": "Ordubad District",
        "state_code": "ORD",
        "type": null
      },
      {
        "id": 542,
        "name": "Qabala District",
        "state_code": "QAB",
        "type": null
      },
      {
        "id": 526,
        "name": "Qakh District",
        "state_code": "QAX",
        "type": null
      },
      {
        "id": 521,
        "name": "Qazakh District",
        "state_code": "QAZ",
        "type": null
      },
      {
        "id": 563,
        "name": "Quba District",
        "state_code": "QBA",
        "type": null
      },
      {
        "id": 548,
        "name": "Qubadli District",
        "state_code": "QBI",
        "type": null
      },
      {
        "id": 588,
        "name": "Qusar District",
        "state_code": "QUS",
        "type": null
      },
      {
        "id": 557,
        "name": "Saatly District",
        "state_code": "SAT",
        "type": null
      },
      {
        "id": 565,
        "name": "Sabirabad District",
        "state_code": "SAB",
        "type": null
      },
      {
        "id": 522,
        "name": "Sadarak District",
        "state_code": "SAD",
        "type": null
      },
      {
        "id": 545,
        "name": "Salyan District",
        "state_code": "SAL",
        "type": null
      },
      {
        "id": 536,
        "name": "Samukh District",
        "state_code": "SMX",
        "type": null
      },
      {
        "id": 591,
        "name": "Shabran District",
        "state_code": "SBN",
        "type": null
      },
      {
        "id": 579,
        "name": "Shahbuz District",
        "state_code": "SAH",
        "type": null
      },
      {
        "id": 518,
        "name": "Shaki",
        "state_code": "SA",
        "type": null
      },
      {
        "id": 586,
        "name": "Shaki District",
        "state_code": "SAK",
        "type": null
      },
      {
        "id": 529,
        "name": "Shamakhi District",
        "state_code": "SMI",
        "type": null
      },
      {
        "id": 583,
        "name": "Shamkir District",
        "state_code": "SKR",
        "type": null
      },
      {
        "id": 535,
        "name": "Sharur District",
        "state_code": "SAR",
        "type": null
      },
      {
        "id": 520,
        "name": "Shirvan",
        "state_code": "SR",
        "type": null
      },
      {
        "id": 592,
        "name": "Shusha District",
        "state_code": "SUS",
        "type": null
      },
      {
        "id": 584,
        "name": "Siazan District",
        "state_code": "SIY",
        "type": null
      },
      {
        "id": 582,
        "name": "Sumqayit",
        "state_code": "SM",
        "type": null
      },
      {
        "id": 519,
        "name": "Tartar District",
        "state_code": "TAR",
        "type": null
      },
      {
        "id": 533,
        "name": "Tovuz District",
        "state_code": "TOV",
        "type": null
      },
      {
        "id": 539,
        "name": "Ujar District",
        "state_code": "UCA",
        "type": null
      },
      {
        "id": 550,
        "name": "Yardymli District",
        "state_code": "YAR",
        "type": null
      },
      {
        "id": 538,
        "name": "Yevlakh",
        "state_code": "YE",
        "type": null
      },
      {
        "id": 523,
        "name": "Yevlakh District",
        "state_code": "YEV",
        "type": null
      },
      {
        "id": 581,
        "name": "Zangilan District",
        "state_code": "ZAN",
        "type": null
      },
      {
        "id": 566,
        "name": "Zaqatala District",
        "state_code": "ZAQ",
        "type": null
      },
      {
        "id": 576,
        "name": "Zardab District",
        "state_code": "ZAR",
        "type": null
      }
    ]
  },
  {
    "name": "Bahamas The",
    "iso3": "BHS",
    "iso2": "BS",
    "numeric_code": "044",
    "phone_code": "+1-242",
    "capital": "Nassau",
    "currency": "BSD",
    "currency_name": "Bahamian dollar",
    "currency_symbol": "B$",
    "tld": ".bs",
    "native": "Bahamas",
    "region": "Americas",
    "subregion": "Caribbean",
    "emoji": "🇧🇸",
    "emojiU": "U+1F1E7 U+1F1F8",
    "states": [
      {
        "id": 3601,
        "name": "Acklins",
        "state_code": "AK",
        "type": null
      },
      {
        "id": 3628,
        "name": "Acklins and Crooked Islands",
        "state_code": "AC",
        "type": null
      },
      {
        "id": 3593,
        "name": "Berry Islands",
        "state_code": "BY",
        "type": null
      },
      {
        "id": 3629,
        "name": "Bimini",
        "state_code": "BI",
        "type": null
      },
      {
        "id": 3605,
        "name": "Black Point",
        "state_code": "BP",
        "type": null
      },
      {
        "id": 3611,
        "name": "Cat Island",
        "state_code": "CI",
        "type": null
      },
      {
        "id": 3603,
        "name": "Central Abaco",
        "state_code": "CO",
        "type": null
      },
      {
        "id": 3631,
        "name": "Central Andros",
        "state_code": "CS",
        "type": null
      },
      {
        "id": 3596,
        "name": "Central Eleuthera",
        "state_code": "CE",
        "type": null
      },
      {
        "id": 3621,
        "name": "Crooked Island",
        "state_code": "CK",
        "type": null
      },
      {
        "id": 3614,
        "name": "East Grand Bahama",
        "state_code": "EG",
        "type": null
      },
      {
        "id": 3612,
        "name": "Exuma",
        "state_code": "EX",
        "type": null
      },
      {
        "id": 3626,
        "name": "Freeport",
        "state_code": "FP",
        "type": null
      },
      {
        "id": 3619,
        "name": "Fresh Creek",
        "state_code": "FC",
        "type": null
      },
      {
        "id": 3597,
        "name": "Governor's Harbour",
        "state_code": "GH",
        "type": null
      },
      {
        "id": 3632,
        "name": "Grand Cay",
        "state_code": "GC",
        "type": null
      },
      {
        "id": 3595,
        "name": "Green Turtle Cay",
        "state_code": "GT",
        "type": null
      },
      {
        "id": 3613,
        "name": "Harbour Island",
        "state_code": "HI",
        "type": null
      },
      {
        "id": 3598,
        "name": "High Rock",
        "state_code": "HR",
        "type": null
      },
      {
        "id": 3624,
        "name": "Hope Town",
        "state_code": "HT",
        "type": null
      },
      {
        "id": 3609,
        "name": "Inagua",
        "state_code": "IN",
        "type": null
      },
      {
        "id": 3618,
        "name": "Kemps Bay",
        "state_code": "KB",
        "type": null
      },
      {
        "id": 3610,
        "name": "Long Island",
        "state_code": "LI",
        "type": null
      },
      {
        "id": 3625,
        "name": "Mangrove Cay",
        "state_code": "MC",
        "type": null
      },
      {
        "id": 3604,
        "name": "Marsh Harbour",
        "state_code": "MH",
        "type": null
      },
      {
        "id": 3633,
        "name": "Mayaguana District",
        "state_code": "MG",
        "type": null
      },
      {
        "id": 4881,
        "name": "New Providence",
        "state_code": "NP",
        "type": null
      },
      {
        "id": 3594,
        "name": "Nichollstown and Berry Islands",
        "state_code": "NB",
        "type": null
      },
      {
        "id": 3616,
        "name": "North Abaco",
        "state_code": "NO",
        "type": null
      },
      {
        "id": 3617,
        "name": "North Andros",
        "state_code": "NS",
        "type": null
      },
      {
        "id": 3602,
        "name": "North Eleuthera",
        "state_code": "NE",
        "type": null
      },
      {
        "id": 3615,
        "name": "Ragged Island",
        "state_code": "RI",
        "type": null
      },
      {
        "id": 3623,
        "name": "Rock Sound",
        "state_code": "RS",
        "type": null
      },
      {
        "id": 3600,
        "name": "Rum Cay District",
        "state_code": "RC",
        "type": null
      },
      {
        "id": 3620,
        "name": "San Salvador and Rum Cay",
        "state_code": "SR",
        "type": null
      },
      {
        "id": 3627,
        "name": "San Salvador Island",
        "state_code": "SS",
        "type": null
      },
      {
        "id": 3606,
        "name": "Sandy Point",
        "state_code": "SP",
        "type": null
      },
      {
        "id": 3608,
        "name": "South Abaco",
        "state_code": "SO",
        "type": null
      },
      {
        "id": 3622,
        "name": "South Andros",
        "state_code": "SA",
        "type": null
      },
      {
        "id": 3607,
        "name": "South Eleuthera",
        "state_code": "SE",
        "type": null
      },
      {
        "id": 3630,
        "name": "Spanish Wells",
        "state_code": "SW",
        "type": null
      },
      {
        "id": 3599,
        "name": "West Grand Bahama",
        "state_code": "WG",
        "type": null
      }
    ]
  },
  {
    "name": "Bahrain",
    "iso3": "BHR",
    "iso2": "BH",
    "numeric_code": "048",
    "phone_code": "973",
    "capital": "Manama",
    "currency": "BHD",
    "currency_name": "Bahraini dinar",
    "currency_symbol": ".د.ب",
    "tld": ".bh",
    "native": "‏البحرين",
    "region": "Asia",
    "subregion": "Western Asia",
    "emoji": "🇧🇭",
    "emojiU": "U+1F1E7 U+1F1ED",
    "states": [
      {
        "id": 1992,
        "name": "Capital Governorate",
        "state_code": "13",
        "type": null
      },
      {
        "id": 1996,
        "name": "Central Governorate",
        "state_code": "16",
        "type": null
      },
      {
        "id": 1995,
        "name": "Muharraq Governorate",
        "state_code": "15",
        "type": null
      },
      {
        "id": 1994,
        "name": "Northern Governorate",
        "state_code": "17",
        "type": null
      },
      {
        "id": 1993,
        "name": "Southern Governorate",
        "state_code": "14",
        "type": null
      }
    ]
  },
  {
    "name": "Bangladesh",
    "iso3": "BGD",
    "iso2": "BD",
    "numeric_code": "050",
    "phone_code": "880",
    "capital": "Dhaka",
    "currency": "BDT",
    "currency_name": "Bangladeshi taka",
    "currency_symbol": "৳",
    "tld": ".bd",
    "native": "Bangladesh",
    "region": "Asia",
    "subregion": "Southern Asia",
    "emoji": "🇧🇩",
    "emojiU": "U+1F1E7 U+1F1E9",
    "states": [
      {
        "id": 796,
        "name": "Bagerhat District",
        "state_code": "05",
        "type": null
      },
      {
        "id": 802,
        "name": "Bahadia",
        "state_code": "33",
        "type": null
      },
      {
        "id": 752,
        "name": "Bandarban District",
        "state_code": "01",
        "type": null
      },
      {
        "id": 784,
        "name": "Barguna District",
        "state_code": "02",
        "type": null
      },
      {
        "id": 818,
        "name": "Barisal District",
        "state_code": "06",
        "type": null
      },
      {
        "id": 807,
        "name": "Barisal Division",
        "state_code": "A",
        "type": null
      },
      {
        "id": 756,
        "name": "Bhola District",
        "state_code": "07",
        "type": null
      },
      {
        "id": 797,
        "name": "Bogra District",
        "state_code": "03",
        "type": null
      },
      {
        "id": 810,
        "name": "Brahmanbaria District",
        "state_code": "04",
        "type": null
      },
      {
        "id": 768,
        "name": "Chandpur District",
        "state_code": "09",
        "type": null
      },
      {
        "id": 761,
        "name": "Chapai Nawabganj District",
        "state_code": "45",
        "type": null
      },
      {
        "id": 785,
        "name": "Chittagong District",
        "state_code": "10",
        "type": null
      },
      {
        "id": 803,
        "name": "Chittagong Division",
        "state_code": "B",
        "type": null
      },
      {
        "id": 788,
        "name": "Chuadanga District",
        "state_code": "12",
        "type": null
      },
      {
        "id": 763,
        "name": "Comilla District",
        "state_code": "08",
        "type": null
      },
      {
        "id": 751,
        "name": "Cox's Bazar District",
        "state_code": "11",
        "type": null
      },
      {
        "id": 771,
        "name": "Dhaka District",
        "state_code": "13",
        "type": null
      },
      {
        "id": 760,
        "name": "Dhaka Division",
        "state_code": "C",
        "type": null
      },
      {
        "id": 783,
        "name": "Dinajpur District",
        "state_code": "14",
        "type": null
      },
      {
        "id": 762,
        "name": "Faridpur District",
        "state_code": "15",
        "type": null
      },
      {
        "id": 816,
        "name": "Feni District",
        "state_code": "16",
        "type": null
      },
      {
        "id": 795,
        "name": "Gaibandha District",
        "state_code": "19",
        "type": null
      },
      {
        "id": 798,
        "name": "Gazipur District",
        "state_code": "18",
        "type": null
      },
      {
        "id": 792,
        "name": "Gopalganj District",
        "state_code": "17",
        "type": null
      },
      {
        "id": 805,
        "name": "Habiganj District",
        "state_code": "20",
        "type": null
      },
      {
        "id": 808,
        "name": "Jamalpur District",
        "state_code": "21",
        "type": null
      },
      {
        "id": 757,
        "name": "Jessore District",
        "state_code": "22",
        "type": null
      },
      {
        "id": 778,
        "name": "Jhalokati District",
        "state_code": "25",
        "type": null
      },
      {
        "id": 789,
        "name": "Jhenaidah District",
        "state_code": "23",
        "type": null
      },
      {
        "id": 806,
        "name": "Joypurhat District",
        "state_code": "24",
        "type": null
      },
      {
        "id": 786,
        "name": "Khagrachari District",
        "state_code": "29",
        "type": null
      },
      {
        "id": 811,
        "name": "Khulna District",
        "state_code": "27",
        "type": null
      },
      {
        "id": 775,
        "name": "Khulna Division",
        "state_code": "D",
        "type": null
      },
      {
        "id": 779,
        "name": "Kishoreganj District",
        "state_code": "26",
        "type": null
      },
      {
        "id": 793,
        "name": "Kurigram District",
        "state_code": "28",
        "type": null
      },
      {
        "id": 774,
        "name": "Kushtia District",
        "state_code": "30",
        "type": null
      },
      {
        "id": 819,
        "name": "Lakshmipur District",
        "state_code": "31",
        "type": null
      },
      {
        "id": 780,
        "name": "Lalmonirhat District",
        "state_code": "32",
        "type": null
      },
      {
        "id": 817,
        "name": "Madaripur District",
        "state_code": "36",
        "type": null
      },
      {
        "id": 776,
        "name": "Meherpur District",
        "state_code": "39",
        "type": null
      },
      {
        "id": 794,
        "name": "Moulvibazar District",
        "state_code": "38",
        "type": null
      },
      {
        "id": 790,
        "name": "Munshiganj District",
        "state_code": "35",
        "type": null
      },
      {
        "id": 766,
        "name": "Mymensingh District",
        "state_code": "34",
        "type": null
      },
      {
        "id": 758,
        "name": "Mymensingh Division",
        "state_code": "H",
        "type": null
      },
      {
        "id": 814,
        "name": "Naogaon District",
        "state_code": "48",
        "type": null
      },
      {
        "id": 769,
        "name": "Narail District",
        "state_code": "43",
        "type": null
      },
      {
        "id": 770,
        "name": "Narayanganj District",
        "state_code": "40",
        "type": null
      },
      {
        "id": 787,
        "name": "Natore District",
        "state_code": "44",
        "type": null
      },
      {
        "id": 764,
        "name": "Netrokona District",
        "state_code": "41",
        "type": null
      },
      {
        "id": 772,
        "name": "Nilphamari District",
        "state_code": "46",
        "type": null
      },
      {
        "id": 815,
        "name": "Noakhali District",
        "state_code": "47",
        "type": null
      },
      {
        "id": 754,
        "name": "Pabna District",
        "state_code": "49",
        "type": null
      },
      {
        "id": 800,
        "name": "Panchagarh District",
        "state_code": "52",
        "type": null
      },
      {
        "id": 777,
        "name": "Patuakhali District",
        "state_code": "51",
        "type": null
      },
      {
        "id": 791,
        "name": "Pirojpur District",
        "state_code": "50",
        "type": null
      },
      {
        "id": 773,
        "name": "Rajbari District",
        "state_code": "53",
        "type": null
      },
      {
        "id": 813,
        "name": "Rajshahi District",
        "state_code": "54",
        "type": null
      },
      {
        "id": 753,
        "name": "Rajshahi Division",
        "state_code": "E",
        "type": null
      },
      {
        "id": 809,
        "name": "Rangamati Hill District",
        "state_code": "56",
        "type": null
      },
      {
        "id": 759,
        "name": "Rangpur District",
        "state_code": "55",
        "type": null
      },
      {
        "id": 750,
        "name": "Rangpur Division",
        "state_code": "F",
        "type": null
      },
      {
        "id": 799,
        "name": "Satkhira District",
        "state_code": "58",
        "type": null
      },
      {
        "id": 801,
        "name": "Shariatpur District",
        "state_code": "62",
        "type": null
      },
      {
        "id": 755,
        "name": "Sherpur District",
        "state_code": "57",
        "type": null
      },
      {
        "id": 781,
        "name": "Sirajganj District",
        "state_code": "59",
        "type": null
      },
      {
        "id": 812,
        "name": "Sunamganj District",
        "state_code": "61",
        "type": null
      },
      {
        "id": 767,
        "name": "Sylhet District",
        "state_code": "60",
        "type": null
      },
      {
        "id": 765,
        "name": "Sylhet Division",
        "state_code": "G",
        "type": null
      },
      {
        "id": 782,
        "name": "Tangail District",
        "state_code": "63",
        "type": null
      },
      {
        "id": 804,
        "name": "Thakurgaon District",
        "state_code": "64",
        "type": null
      }
    ]
  },
  {
    "name": "Barbados",
    "iso3": "BRB",
    "iso2": "BB",
    "numeric_code": "052",
    "phone_code": "+1-246",
    "capital": "Bridgetown",
    "currency": "BBD",
    "currency_name": "Barbadian dollar",
    "currency_symbol": "Bds$",
    "tld": ".bb",
    "native": "Barbados",
    "region": "Americas",
    "subregion": "Caribbean",
    "emoji": "🇧🇧",
    "emojiU": "U+1F1E7 U+1F1E7",
    "states": [
      {
        "id": 1228,
        "name": "Christ Church",
        "state_code": "01",
        "type": null
      },
      {
        "id": 1229,
        "name": "Saint Andrew",
        "state_code": "02",
        "type": null
      },
      {
        "id": 1226,
        "name": "Saint George",
        "state_code": "03",
        "type": null
      },
      {
        "id": 1224,
        "name": "Saint James",
        "state_code": "04",
        "type": null
      },
      {
        "id": 1227,
        "name": "Saint John",
        "state_code": "05",
        "type": null
      },
      {
        "id": 1223,
        "name": "Saint Joseph",
        "state_code": "06",
        "type": null
      },
      {
        "id": 1221,
        "name": "Saint Lucy",
        "state_code": "07",
        "type": null
      },
      {
        "id": 1230,
        "name": "Saint Michael",
        "state_code": "08",
        "type": null
      },
      {
        "id": 1222,
        "name": "Saint Peter",
        "state_code": "09",
        "type": null
      },
      {
        "id": 1220,
        "name": "Saint Philip",
        "state_code": "10",
        "type": null
      },
      {
        "id": 1225,
        "name": "Saint Thomas",
        "state_code": "11",
        "type": null
      }
    ]
  },
  {
    "name": "Belarus",
    "iso3": "BLR",
    "iso2": "BY",
    "numeric_code": "112",
    "phone_code": "375",
    "capital": "Minsk",
    "currency": "BYN",
    "currency_name": "Belarusian ruble",
    "currency_symbol": "Br",
    "tld": ".by",
    "native": "Белару́сь",
    "region": "Europe",
    "subregion": "Eastern Europe",
    "emoji": "🇧🇾",
    "emojiU": "U+1F1E7 U+1F1FE",
    "states": [
      {
        "id": 2959,
        "name": "Brest Region",
        "state_code": "BR",
        "type": null
      },
      {
        "id": 2955,
        "name": "Gomel Region",
        "state_code": "HO",
        "type": null
      },
      {
        "id": 2956,
        "name": "Grodno Region",
        "state_code": "HR",
        "type": null
      },
      {
        "id": 2958,
        "name": "Minsk",
        "state_code": "HM",
        "type": null
      },
      {
        "id": 2957,
        "name": "Minsk Region",
        "state_code": "MI",
        "type": null
      },
      {
        "id": 2954,
        "name": "Mogilev Region",
        "state_code": "MA",
        "type": null
      },
      {
        "id": 2960,
        "name": "Vitebsk Region",
        "state_code": "VI",
        "type": null
      }
    ]
  },
  {
    "name": "Belgium",
    "iso3": "BEL",
    "iso2": "BE",
    "numeric_code": "056",
    "phone_code": "32",
    "capital": "Brussels",
    "currency": "EUR",
    "currency_name": "Euro",
    "currency_symbol": "€",
    "tld": ".be",
    "native": "België",
    "region": "Europe",
    "subregion": "Western Europe",
    "emoji": "🇧🇪",
    "emojiU": "U+1F1E7 U+1F1EA",
    "states": [
      {
        "id": 1381,
        "name": "Antwerp",
        "state_code": "VAN",
        "type": null
      },
      {
        "id": 1376,
        "name": "Brussels-Capital Region",
        "state_code": "BRU",
        "type": null
      },
      {
        "id": 1377,
        "name": "East Flanders",
        "state_code": "VOV",
        "type": null
      },
      {
        "id": 1373,
        "name": "Flanders",
        "state_code": "VLG",
        "type": null
      },
      {
        "id": 1374,
        "name": "Flemish Brabant",
        "state_code": "VBR",
        "type": null
      },
      {
        "id": 1375,
        "name": "Hainaut",
        "state_code": "WHT",
        "type": null
      },
      {
        "id": 1384,
        "name": "Liège",
        "state_code": "WLG",
        "type": null
      },
      {
        "id": 1372,
        "name": "Limburg",
        "state_code": "VLI",
        "type": null
      },
      {
        "id": 1379,
        "name": "Luxembourg",
        "state_code": "WLX",
        "type": null
      },
      {
        "id": 1378,
        "name": "Namur",
        "state_code": "WNA",
        "type": null
      },
      {
        "id": 1380,
        "name": "Wallonia",
        "state_code": "WAL",
        "type": null
      },
      {
        "id": 1382,
        "name": "Walloon Brabant",
        "state_code": "WBR",
        "type": null
      },
      {
        "id": 1383,
        "name": "West Flanders",
        "state_code": "VWV",
        "type": null
      }
    ]
  },
  {
    "name": "Belize",
    "iso3": "BLZ",
    "iso2": "BZ",
    "numeric_code": "084",
    "phone_code": "501",
    "capital": "Belmopan",
    "currency": "BZD",
    "currency_name": "Belize dollar",
    "currency_symbol": "$",
    "tld": ".bz",
    "native": "Belize",
    "region": "Americas",
    "subregion": "Central America",
    "emoji": "🇧🇿",
    "emojiU": "U+1F1E7 U+1F1FF",
    "states": [
      {
        "id": 264,
        "name": "Belize District",
        "state_code": "BZ",
        "type": null
      },
      {
        "id": 269,
        "name": "Cayo District",
        "state_code": "CY",
        "type": null
      },
      {
        "id": 266,
        "name": "Corozal District",
        "state_code": "CZL",
        "type": null
      },
      {
        "id": 268,
        "name": "Orange Walk District",
        "state_code": "OW",
        "type": null
      },
      {
        "id": 265,
        "name": "Stann Creek District",
        "state_code": "SC",
        "type": null
      },
      {
        "id": 267,
        "name": "Toledo District",
        "state_code": "TOL",
        "type": null
      }
    ]
  },
  {
    "name": "Benin",
    "iso3": "BEN",
    "iso2": "BJ",
    "numeric_code": "204",
    "phone_code": "229",
    "capital": "Porto-Novo",
    "currency": "XOF",
    "currency_name": "West African CFA franc",
    "currency_symbol": "CFA",
    "tld": ".bj",
    "native": "Bénin",
    "region": "Africa",
    "subregion": "Western Africa",
    "emoji": "🇧🇯",
    "emojiU": "U+1F1E7 U+1F1EF",
    "states": [
      {
        "id": 3077,
        "name": "Alibori Department",
        "state_code": "AL",
        "type": null
      },
      {
        "id": 3076,
        "name": "Atakora Department",
        "state_code": "AK",
        "type": null
      },
      {
        "id": 3079,
        "name": "Atlantique Department",
        "state_code": "AQ",
        "type": null
      },
      {
        "id": 3078,
        "name": "Borgou Department",
        "state_code": "BO",
        "type": null
      },
      {
        "id": 3070,
        "name": "Collines Department",
        "state_code": "CO",
        "type": null
      },
      {
        "id": 3072,
        "name": "Donga Department",
        "state_code": "DO",
        "type": null
      },
      {
        "id": 3071,
        "name": "Kouffo Department",
        "state_code": "KO",
        "type": null
      },
      {
        "id": 3081,
        "name": "Littoral Department",
        "state_code": "LI",
        "type": null
      },
      {
        "id": 3075,
        "name": "Mono Department",
        "state_code": "MO",
        "type": null
      },
      {
        "id": 3080,
        "name": "Ouémé Department",
        "state_code": "OU",
        "type": null
      },
      {
        "id": 3074,
        "name": "Plateau Department",
        "state_code": "PL",
        "type": null
      },
      {
        "id": 3073,
        "name": "Zou Department",
        "state_code": "ZO",
        "type": null
      }
    ]
  },
  {
    "name": "Bermuda",
    "iso3": "BMU",
    "iso2": "BM",
    "numeric_code": "060",
    "phone_code": "+1-441",
    "capital": "Hamilton",
    "currency": "BMD",
    "currency_name": "Bermudian dollar",
    "currency_symbol": "$",
    "tld": ".bm",
    "native": "Bermuda",
    "region": "Americas",
    "subregion": "Northern America",
    "emoji": "🇧🇲",
    "emojiU": "U+1F1E7 U+1F1F2",
    "states": [
      {
        "id": 4860,
        "name": "Devonshire Parish",
        "state_code": "DEV",
        "type": null
      },
      {
        "id": 4862,
        "name": "Hamilton Municipality",
        "state_code": "HAM",
        "type": null
      },
      {
        "id": 4861,
        "name": "Hamilton Parish",
        "state_code": "HA",
        "type": null
      },
      {
        "id": 4863,
        "name": "Paget Parish",
        "state_code": "PAG",
        "type": null
      },
      {
        "id": 4864,
        "name": "Pembroke Parish",
        "state_code": "PEM",
        "type": null
      },
      {
        "id": 4865,
        "name": "Saint George's Municipality",
        "state_code": "SG",
        "type": null
      },
      {
        "id": 4866,
        "name": "Saint George's Parish",
        "state_code": "SGE",
        "type": null
      },
      {
        "id": 4867,
        "name": "Sandys Parish",
        "state_code": "SAN",
        "type": null
      },
      {
        "id": 4868,
        "name": "Smith's Parish,",
        "state_code": "SMI",
        "type": null
      },
      {
        "id": 4869,
        "name": "Southampton Parish",
        "state_code": "SOU",
        "type": null
      },
      {
        "id": 4870,
        "name": "Warwick Parish",
        "state_code": "WAR",
        "type": null
      }
    ]
  },
  {
    "name": "Bhutan",
    "iso3": "BTN",
    "iso2": "BT",
    "numeric_code": "064",
    "phone_code": "975",
    "capital": "Thimphu",
    "currency": "BTN",
    "currency_name": "Bhutanese ngultrum",
    "currency_symbol": "Nu.",
    "tld": ".bt",
    "native": "ʼbrug-yul",
    "region": "Asia",
    "subregion": "Southern Asia",
    "emoji": "🇧🇹",
    "emojiU": "U+1F1E7 U+1F1F9",
    "states": [
      {
        "id": 240,
        "name": "Bumthang District",
        "state_code": "33",
        "type": null
      },
      {
        "id": 239,
        "name": "Chukha District",
        "state_code": "12",
        "type": null
      },
      {
        "id": 238,
        "name": "Dagana District",
        "state_code": "22",
        "type": null
      },
      {
        "id": 229,
        "name": "Gasa District",
        "state_code": "GA",
        "type": null
      },
      {
        "id": 232,
        "name": "Haa District",
        "state_code": "13",
        "type": null
      },
      {
        "id": 234,
        "name": "Lhuntse District",
        "state_code": "44",
        "type": null
      },
      {
        "id": 242,
        "name": "Mongar District",
        "state_code": "42",
        "type": null
      },
      {
        "id": 237,
        "name": "Paro District",
        "state_code": "11",
        "type": null
      },
      {
        "id": 244,
        "name": "Pemagatshel District",
        "state_code": "43",
        "type": null
      },
      {
        "id": 235,
        "name": "Punakha District",
        "state_code": "23",
        "type": null
      },
      {
        "id": 243,
        "name": "Samdrup Jongkhar District",
        "state_code": "45",
        "type": null
      },
      {
        "id": 246,
        "name": "Samtse District",
        "state_code": "14",
        "type": null
      },
      {
        "id": 247,
        "name": "Sarpang District",
        "state_code": "31",
        "type": null
      },
      {
        "id": 241,
        "name": "Thimphu District",
        "state_code": "15",
        "type": null
      },
      {
        "id": 236,
        "name": "Trashigang District",
        "state_code": "41",
        "type": null
      },
      {
        "id": 245,
        "name": "Trongsa District",
        "state_code": "32",
        "type": null
      },
      {
        "id": 230,
        "name": "Tsirang District",
        "state_code": "21",
        "type": null
      },
      {
        "id": 231,
        "name": "Wangdue Phodrang District",
        "state_code": "24",
        "type": null
      },
      {
        "id": 233,
        "name": "Zhemgang District",
        "state_code": "34",
        "type": null
      }
    ]
  },
  {
    "name": "Bolivia",
    "iso3": "BOL",
    "iso2": "BO",
    "numeric_code": "068",
    "phone_code": "591",
    "capital": "Sucre",
    "currency": "BOB",
    "currency_name": "Bolivian boliviano",
    "currency_symbol": "Bs.",
    "tld": ".bo",
    "native": "Bolivia",
    "region": "Americas",
    "subregion": "South America",
    "emoji": "🇧🇴",
    "emojiU": "U+1F1E7 U+1F1F4",
    "states": [
      {
        "id": 3375,
        "name": "Beni Department",
        "state_code": "B",
        "type": null
      },
      {
        "id": 3382,
        "name": "Chuquisaca Department",
        "state_code": "H",
        "type": null
      },
      {
        "id": 3381,
        "name": "Cochabamba Department",
        "state_code": "C",
        "type": null
      },
      {
        "id": 3380,
        "name": "La Paz Department",
        "state_code": "L",
        "type": null
      },
      {
        "id": 3376,
        "name": "Oruro Department",
        "state_code": "O",
        "type": null
      },
      {
        "id": 3379,
        "name": "Pando Department",
        "state_code": "N",
        "type": null
      },
      {
        "id": 3383,
        "name": "Potosí Department",
        "state_code": "P",
        "type": null
      },
      {
        "id": 3377,
        "name": "Santa Cruz Department",
        "state_code": "S",
        "type": null
      },
      {
        "id": 3378,
        "name": "Tarija Department",
        "state_code": "T",
        "type": null
      }
    ]
  },
  {
    "name": "Bonaire, Sint Eustatius and Saba",
    "iso3": "BES",
    "iso2": "BQ",
    "numeric_code": "535",
    "phone_code": "599",
    "capital": "Kralendijk",
    "currency": "USD",
    "currency_name": "United States dollar",
    "currency_symbol": "$",
    "tld": ".an",
    "native": "Caribisch Nederland",
    "region": "Americas",
    "subregion": "Caribbean",
    "emoji": "🇧🇶",
    "emojiU": "U+1F1E7 U+1F1F6",
    "states": []
  },
  {
    "name": "Bosnia and Herzegovina",
    "iso3": "BIH",
    "iso2": "BA",
    "numeric_code": "070",
    "phone_code": "387",
    "capital": "Sarajevo",
    "currency": "BAM",
    "currency_name": "Bosnia and Herzegovina convertible mark",
    "currency_symbol": "KM",
    "tld": ".ba",
    "native": "Bosna i Hercegovina",
    "region": "Europe",
    "subregion": "Southern Europe",
    "emoji": "🇧🇦",
    "emojiU": "U+1F1E7 U+1F1E6",
    "states": [
      {
        "id": 472,
        "name": "Bosnian Podrinje Canton",
        "state_code": "05",
        "type": null
      },
      {
        "id": 460,
        "name": "Brčko District",
        "state_code": "BRC",
        "type": null
      },
      {
        "id": 471,
        "name": "Canton 10",
        "state_code": "10",
        "type": null
      },
      {
        "id": 462,
        "name": "Central Bosnia Canton",
        "state_code": "06",
        "type": null
      },
      {
        "id": 467,
        "name": "Federation of Bosnia and Herzegovina",
        "state_code": "BIH",
        "type": null
      },
      {
        "id": 463,
        "name": "Herzegovina-Neretva Canton",
        "state_code": "07",
        "type": null
      },
      {
        "id": 464,
        "name": "Posavina Canton",
        "state_code": "02",
        "type": null
      },
      {
        "id": 470,
        "name": "Republika Srpska",
        "state_code": "SRP",
        "type": null
      },
      {
        "id": 466,
        "name": "Sarajevo Canton",
        "state_code": "09",
        "type": null
      },
      {
        "id": 461,
        "name": "Tuzla Canton",
        "state_code": "03",
        "type": null
      },
      {
        "id": 465,
        "name": "Una-Sana Canton",
        "state_code": "01",
        "type": null
      },
      {
        "id": 469,
        "name": "West Herzegovina Canton",
        "state_code": "08",
        "type": null
      },
      {
        "id": 468,
        "name": "Zenica-Doboj Canton",
        "state_code": "04",
        "type": null
      }
    ]
  },
  {
    "name": "Botswana",
    "iso3": "BWA",
    "iso2": "BW",
    "numeric_code": "072",
    "phone_code": "267",
    "capital": "Gaborone",
    "currency": "BWP",
    "currency_name": "Botswana pula",
    "currency_symbol": "P",
    "tld": ".bw",
    "native": "Botswana",
    "region": "Africa",
    "subregion": "Southern Africa",
    "emoji": "🇧🇼",
    "emojiU": "U+1F1E7 U+1F1FC",
    "states": [
      {
        "id": 3067,
        "name": "Central District",
        "state_code": "CE",
        "type": null
      },
      {
        "id": 3061,
        "name": "Ghanzi District",
        "state_code": "GH",
        "type": null
      },
      {
        "id": 3066,
        "name": "Kgalagadi District",
        "state_code": "KG",
        "type": null
      },
      {
        "id": 3062,
        "name": "Kgatleng District",
        "state_code": "KL",
        "type": null
      },
      {
        "id": 3069,
        "name": "Kweneng District",
        "state_code": "KW",
        "type": null
      },
      {
        "id": 3060,
        "name": "Ngamiland",
        "state_code": "NG",
        "type": null
      },
      {
        "id": 3068,
        "name": "North-East District",
        "state_code": "NE",
        "type": null
      },
      {
        "id": 3065,
        "name": "North-West District",
        "state_code": "NW",
        "type": null
      },
      {
        "id": 3064,
        "name": "South-East District",
        "state_code": "SE",
        "type": null
      },
      {
        "id": 3063,
        "name": "Southern District",
        "state_code": "SO",
        "type": null
      }
    ]
  },
  {
    "name": "Bouvet Island",
    "iso3": "BVT",
    "iso2": "BV",
    "numeric_code": "074",
    "phone_code": "0055",
    "capital": "",
    "currency": "NOK",
    "currency_name": "Norwegian Krone",
    "currency_symbol": "kr",
    "tld": ".bv",
    "native": "Bouvetøya",
    "region": "",
    "subregion": "",
    "emoji": "🇧🇻",
    "emojiU": "U+1F1E7 U+1F1FB",
    "states": []
  },
  {
    "name": "Brazil",
    "iso3": "BRA",
    "iso2": "BR",
    "numeric_code": "076",
    "phone_code": "55",
    "capital": "Brasilia",
    "currency": "BRL",
    "currency_name": "Brazilian real",
    "currency_symbol": "R$",
    "tld": ".br",
    "native": "Brasil",
    "region": "Americas",
    "subregion": "South America",
    "emoji": "🇧🇷",
    "emojiU": "U+1F1E7 U+1F1F7",
    "states": [
      {
        "id": 2012,
        "name": "Acre",
        "state_code": "AC",
        "type": null
      },
      {
        "id": 2007,
        "name": "Alagoas",
        "state_code": "AL",
        "type": null
      },
      {
        "id": 1999,
        "name": "Amapá",
        "state_code": "AP",
        "type": null
      },
      {
        "id": 2004,
        "name": "Amazonas",
        "state_code": "AM",
        "type": null
      },
      {
        "id": 2002,
        "name": "Bahia",
        "state_code": "BA",
        "type": null
      },
      {
        "id": 2016,
        "name": "Ceará",
        "state_code": "CE",
        "type": null
      },
      {
        "id": 2017,
        "name": "Distrito Federal",
        "state_code": "DF",
        "type": null
      },
      {
        "id": 2018,
        "name": "Espírito Santo",
        "state_code": "ES",
        "type": null
      },
      {
        "id": 2000,
        "name": "Goiás",
        "state_code": "GO",
        "type": null
      },
      {
        "id": 2015,
        "name": "Maranhão",
        "state_code": "MA",
        "type": null
      },
      {
        "id": 2011,
        "name": "Mato Grosso",
        "state_code": "MT",
        "type": null
      },
      {
        "id": 2010,
        "name": "Mato Grosso do Sul",
        "state_code": "MS",
        "type": null
      },
      {
        "id": 1998,
        "name": "Minas Gerais",
        "state_code": "MG",
        "type": null
      },
      {
        "id": 2009,
        "name": "Pará",
        "state_code": "PA",
        "type": null
      },
      {
        "id": 2005,
        "name": "Paraíba",
        "state_code": "PB",
        "type": null
      },
      {
        "id": 2022,
        "name": "Paraná",
        "state_code": "PR",
        "type": null
      },
      {
        "id": 2006,
        "name": "Pernambuco",
        "state_code": "PE",
        "type": null
      },
      {
        "id": 2008,
        "name": "Piauí",
        "state_code": "PI",
        "type": null
      },
      {
        "id": 1997,
        "name": "Rio de Janeiro",
        "state_code": "RJ",
        "type": null
      },
      {
        "id": 2019,
        "name": "Rio Grande do Norte",
        "state_code": "RN",
        "type": null
      },
      {
        "id": 2001,
        "name": "Rio Grande do Sul",
        "state_code": "RS",
        "type": null
      },
      {
        "id": 2013,
        "name": "Rondônia",
        "state_code": "RO",
        "type": null
      },
      {
        "id": 4858,
        "name": "Roraima",
        "state_code": "RR",
        "type": null
      },
      {
        "id": 2014,
        "name": "Santa Catarina",
        "state_code": "SC",
        "type": null
      },
      {
        "id": 2021,
        "name": "São Paulo",
        "state_code": "SP",
        "type": null
      },
      {
        "id": 2003,
        "name": "Sergipe",
        "state_code": "SE",
        "type": null
      },
      {
        "id": 2020,
        "name": "Tocantins",
        "state_code": "TO",
        "type": null
      }
    ]
  },
  {
    "name": "British Indian Ocean Territory",
    "iso3": "IOT",
    "iso2": "IO",
    "numeric_code": "086",
    "phone_code": "246",
    "capital": "Diego Garcia",
    "currency": "USD",
    "currency_name": "United States dollar",
    "currency_symbol": "$",
    "tld": ".io",
    "native": "British Indian Ocean Territory",
    "region": "Africa",
    "subregion": "Eastern Africa",
    "emoji": "🇮🇴",
    "emojiU": "U+1F1EE U+1F1F4",
    "states": []
  },
  {
    "name": "Brunei",
    "iso3": "BRN",
    "iso2": "BN",
    "numeric_code": "096",
    "phone_code": "673",
    "capital": "Bandar Seri Begawan",
    "currency": "BND",
    "currency_name": "Brunei dollar",
    "currency_symbol": "B$",
    "tld": ".bn",
    "native": "Negara Brunei Darussalam",
    "region": "Asia",
    "subregion": "South-Eastern Asia",
    "emoji": "🇧🇳",
    "emojiU": "U+1F1E7 U+1F1F3",
    "states": [
      {
        "id": 1217,
        "name": "Belait District",
        "state_code": "BE",
        "type": null
      },
      {
        "id": 1216,
        "name": "Brunei-Muara District",
        "state_code": "BM",
        "type": null
      },
      {
        "id": 1218,
        "name": "Temburong District",
        "state_code": "TE",
        "type": null
      },
      {
        "id": 1219,
        "name": "Tutong District",
        "state_code": "TU",
        "type": null
      }
    ]
  },
  {
    "name": "Bulgaria",
    "iso3": "BGR",
    "iso2": "BG",
    "numeric_code": "100",
    "phone_code": "359",
    "capital": "Sofia",
    "currency": "BGN",
    "currency_name": "Bulgarian lev",
    "currency_symbol": "Лв.",
    "tld": ".bg",
    "native": "България",
    "region": "Europe",
    "subregion": "Eastern Europe",
    "emoji": "🇧🇬",
    "emojiU": "U+1F1E7 U+1F1EC",
    "states": [
      {
        "id": 4699,
        "name": "Blagoevgrad Province",
        "state_code": "01",
        "type": null
      },
      {
        "id": 4715,
        "name": "Burgas Province",
        "state_code": "02",
        "type": null
      },
      {
        "id": 4718,
        "name": "Dobrich Province",
        "state_code": "08",
        "type": null
      },
      {
        "id": 4693,
        "name": "Gabrovo Province",
        "state_code": "07",
        "type": null
      },
      {
        "id": 4704,
        "name": "Haskovo Province",
        "state_code": "26",
        "type": null
      },
      {
        "id": 4702,
        "name": "Kardzhali Province",
        "state_code": "09",
        "type": null
      },
      {
        "id": 4703,
        "name": "Kyustendil Province",
        "state_code": "10",
        "type": null
      },
      {
        "id": 4710,
        "name": "Lovech Province",
        "state_code": "11",
        "type": null
      },
      {
        "id": 4696,
        "name": "Montana Province",
        "state_code": "12",
        "type": null
      },
      {
        "id": 4712,
        "name": "Pazardzhik Province",
        "state_code": "13",
        "type": null
      },
      {
        "id": 4695,
        "name": "Pernik Province",
        "state_code": "14",
        "type": null
      },
      {
        "id": 4706,
        "name": "Pleven Province",
        "state_code": "15",
        "type": null
      },
      {
        "id": 4701,
        "name": "Plovdiv Province",
        "state_code": "16",
        "type": null
      },
      {
        "id": 4698,
        "name": "Razgrad Province",
        "state_code": "17",
        "type": null
      },
      {
        "id": 4713,
        "name": "Ruse Province",
        "state_code": "18",
        "type": null
      },
      {
        "id": 4882,
        "name": "Shumen",
        "state_code": "27",
        "type": null
      },
      {
        "id": 4708,
        "name": "Silistra Province",
        "state_code": "19",
        "type": null
      },
      {
        "id": 4700,
        "name": "Sliven Province",
        "state_code": "20",
        "type": null
      },
      {
        "id": 4694,
        "name": "Smolyan Province",
        "state_code": "21",
        "type": null
      },
      {
        "id": 4705,
        "name": "Sofia City Province",
        "state_code": "22",
        "type": null
      },
      {
        "id": 4719,
        "name": "Sofia Province",
        "state_code": "23",
        "type": null
      },
      {
        "id": 4707,
        "name": "Stara Zagora Province",
        "state_code": "24",
        "type": null
      },
      {
        "id": 4714,
        "name": "Targovishte Province",
        "state_code": "25",
        "type": null
      },
      {
        "id": 4717,
        "name": "Varna Province",
        "state_code": "03",
        "type": null
      },
      {
        "id": 4709,
        "name": "Veliko Tarnovo Province",
        "state_code": "04",
        "type": null
      },
      {
        "id": 4697,
        "name": "Vidin Province",
        "state_code": "05",
        "type": null
      },
      {
        "id": 4711,
        "name": "Vratsa Province",
        "state_code": "06",
        "type": null
      },
      {
        "id": 4716,
        "name": "Yambol Province",
        "state_code": "28",
        "type": null
      }
    ]
  },
  {
    "name": "Burkina Faso",
    "iso3": "BFA",
    "iso2": "BF",
    "numeric_code": "854",
    "phone_code": "226",
    "capital": "Ouagadougou",
    "currency": "XOF",
    "currency_name": "West African CFA franc",
    "currency_symbol": "CFA",
    "tld": ".bf",
    "native": "Burkina Faso",
    "region": "Africa",
    "subregion": "Western Africa",
    "emoji": "🇧🇫",
    "emojiU": "U+1F1E7 U+1F1EB",
    "states": [
      {
        "id": 3160,
        "name": "Balé Province",
        "state_code": "BAL",
        "type": null
      },
      {
        "id": 3155,
        "name": "Bam Province",
        "state_code": "BAM",
        "type": null
      },
      {
        "id": 3120,
        "name": "Banwa Province",
        "state_code": "BAN",
        "type": null
      },
      {
        "id": 3152,
        "name": "Bazèga Province",
        "state_code": "BAZ",
        "type": null
      },
      {
        "id": 3138,
        "name": "Boucle du Mouhoun Region",
        "state_code": "01",
        "type": null
      },
      {
        "id": 3121,
        "name": "Bougouriba Province",
        "state_code": "BGR",
        "type": null
      },
      {
        "id": 3131,
        "name": "Boulgou",
        "state_code": "BLG",
        "type": null
      },
      {
        "id": 3153,
        "name": "Cascades Region",
        "state_code": "02",
        "type": null
      },
      {
        "id": 3136,
        "name": "Centre",
        "state_code": "03",
        "type": null
      },
      {
        "id": 3162,
        "name": "Centre-Est Region",
        "state_code": "04",
        "type": null
      },
      {
        "id": 3127,
        "name": "Centre-Nord Region",
        "state_code": "05",
        "type": null
      },
      {
        "id": 3115,
        "name": "Centre-Ouest Region",
        "state_code": "06",
        "type": null
      },
      {
        "id": 3149,
        "name": "Centre-Sud Region",
        "state_code": "07",
        "type": null
      },
      {
        "id": 3167,
        "name": "Comoé Province",
        "state_code": "COM",
        "type": null
      },
      {
        "id": 3158,
        "name": "Est Region",
        "state_code": "08",
        "type": null
      },
      {
        "id": 3148,
        "name": "Ganzourgou Province",
        "state_code": "GAN",
        "type": null
      },
      {
        "id": 3122,
        "name": "Gnagna Province",
        "state_code": "GNA",
        "type": null
      },
      {
        "id": 3143,
        "name": "Gourma Province",
        "state_code": "GOU",
        "type": null
      },
      {
        "id": 3165,
        "name": "Hauts-Bassins Region",
        "state_code": "09",
        "type": null
      },
      {
        "id": 3129,
        "name": "Houet Province",
        "state_code": "HOU",
        "type": null
      },
      {
        "id": 3135,
        "name": "Ioba Province",
        "state_code": "IOB",
        "type": null
      },
      {
        "id": 3168,
        "name": "Kadiogo Province",
        "state_code": "KAD",
        "type": null
      },
      {
        "id": 3112,
        "name": "Kénédougou Province",
        "state_code": "KEN",
        "type": null
      },
      {
        "id": 3132,
        "name": "Komondjari Province",
        "state_code": "KMD",
        "type": null
      },
      {
        "id": 3157,
        "name": "Kompienga Province",
        "state_code": "KMP",
        "type": null
      },
      {
        "id": 3146,
        "name": "Kossi Province",
        "state_code": "KOS",
        "type": null
      },
      {
        "id": 3133,
        "name": "Koulpélogo Province",
        "state_code": "KOP",
        "type": null
      },
      {
        "id": 3161,
        "name": "Kouritenga Province",
        "state_code": "KOT",
        "type": null
      },
      {
        "id": 3147,
        "name": "Kourwéogo Province",
        "state_code": "KOW",
        "type": null
      },
      {
        "id": 3159,
        "name": "Léraba Province",
        "state_code": "LER",
        "type": null
      },
      {
        "id": 3151,
        "name": "Loroum Province",
        "state_code": "LOR",
        "type": null
      },
      {
        "id": 3123,
        "name": "Mouhoun",
        "state_code": "MOU",
        "type": null
      },
      {
        "id": 3116,
        "name": "Nahouri Province",
        "state_code": "NAO",
        "type": null
      },
      {
        "id": 3113,
        "name": "Namentenga Province",
        "state_code": "NAM",
        "type": null
      },
      {
        "id": 3142,
        "name": "Nayala Province",
        "state_code": "NAY",
        "type": null
      },
      {
        "id": 3164,
        "name": "Nord Region, Burkina Faso",
        "state_code": "10",
        "type": null
      },
      {
        "id": 3156,
        "name": "Noumbiel Province",
        "state_code": "NOU",
        "type": null
      },
      {
        "id": 3141,
        "name": "Oubritenga Province",
        "state_code": "OUB",
        "type": null
      },
      {
        "id": 3144,
        "name": "Oudalan Province",
        "state_code": "OUD",
        "type": null
      },
      {
        "id": 3117,
        "name": "Passoré Province",
        "state_code": "PAS",
        "type": null
      },
      {
        "id": 3125,
        "name": "Plateau-Central Region",
        "state_code": "11",
        "type": null
      },
      {
        "id": 3163,
        "name": "Poni Province",
        "state_code": "PON",
        "type": null
      },
      {
        "id": 3114,
        "name": "Sahel Region",
        "state_code": "12",
        "type": null
      },
      {
        "id": 3154,
        "name": "Sanguié Province",
        "state_code": "SNG",
        "type": null
      },
      {
        "id": 3126,
        "name": "Sanmatenga Province",
        "state_code": "SMT",
        "type": null
      },
      {
        "id": 3139,
        "name": "Séno Province",
        "state_code": "SEN",
        "type": null
      },
      {
        "id": 3119,
        "name": "Sissili Province",
        "state_code": "SIS",
        "type": null
      },
      {
        "id": 3166,
        "name": "Soum Province",
        "state_code": "SOM",
        "type": null
      },
      {
        "id": 3137,
        "name": "Sourou Province",
        "state_code": "SOR",
        "type": null
      },
      {
        "id": 3140,
        "name": "Sud-Ouest Region",
        "state_code": "13",
        "type": null
      },
      {
        "id": 3128,
        "name": "Tapoa Province",
        "state_code": "TAP",
        "type": null
      },
      {
        "id": 3134,
        "name": "Tuy Province",
        "state_code": "TUI",
        "type": null
      },
      {
        "id": 3124,
        "name": "Yagha Province",
        "state_code": "YAG",
        "type": null
      },
      {
        "id": 3150,
        "name": "Yatenga Province",
        "state_code": "YAT",
        "type": null
      },
      {
        "id": 3145,
        "name": "Ziro Province",
        "state_code": "ZIR",
        "type": null
      },
      {
        "id": 3130,
        "name": "Zondoma Province",
        "state_code": "ZON",
        "type": null
      },
      {
        "id": 3118,
        "name": "Zoundwéogo Province",
        "state_code": "ZOU",
        "type": null
      }
    ]
  },
  {
    "name": "Burundi",
    "iso3": "BDI",
    "iso2": "BI",
    "numeric_code": "108",
    "phone_code": "257",
    "capital": "Bujumbura",
    "currency": "BIF",
    "currency_name": "Burundian franc",
    "currency_symbol": "FBu",
    "tld": ".bi",
    "native": "Burundi",
    "region": "Africa",
    "subregion": "Eastern Africa",
    "emoji": "🇧🇮",
    "emojiU": "U+1F1E7 U+1F1EE",
    "states": [
      {
        "id": 3196,
        "name": "Bubanza Province",
        "state_code": "BB",
        "type": null
      },
      {
        "id": 3198,
        "name": "Bujumbura Mairie Province",
        "state_code": "BM",
        "type": null
      },
      {
        "id": 3200,
        "name": "Bujumbura Rural Province",
        "state_code": "BL",
        "type": null
      },
      {
        "id": 3202,
        "name": "Bururi Province",
        "state_code": "BR",
        "type": null
      },
      {
        "id": 3201,
        "name": "Cankuzo Province",
        "state_code": "CA",
        "type": null
      },
      {
        "id": 3190,
        "name": "Cibitoke Province",
        "state_code": "CI",
        "type": null
      },
      {
        "id": 3197,
        "name": "Gitega Province",
        "state_code": "GI",
        "type": null
      },
      {
        "id": 3194,
        "name": "Karuzi Province",
        "state_code": "KR",
        "type": null
      },
      {
        "id": 3192,
        "name": "Kayanza Province",
        "state_code": "KY",
        "type": null
      },
      {
        "id": 3195,
        "name": "Kirundo Province",
        "state_code": "KI",
        "type": null
      },
      {
        "id": 3188,
        "name": "Makamba Province",
        "state_code": "MA",
        "type": null
      },
      {
        "id": 3193,
        "name": "Muramvya Province",
        "state_code": "MU",
        "type": null
      },
      {
        "id": 3186,
        "name": "Muyinga Province",
        "state_code": "MY",
        "type": null
      },
      {
        "id": 3187,
        "name": "Mwaro Province",
        "state_code": "MW",
        "type": null
      },
      {
        "id": 3199,
        "name": "Ngozi Province",
        "state_code": "NG",
        "type": null
      },
      {
        "id": 3185,
        "name": "Rumonge Province",
        "state_code": "RM",
        "type": null
      },
      {
        "id": 3189,
        "name": "Rutana Province",
        "state_code": "RT",
        "type": null
      },
      {
        "id": 3191,
        "name": "Ruyigi Province",
        "state_code": "RY",
        "type": null
      }
    ]
  },
  {
    "name": "Cambodia",
    "iso3": "KHM",
    "iso2": "KH",
    "numeric_code": "116",
    "phone_code": "855",
    "capital": "Phnom Penh",
    "currency": "KHR",
    "currency_name": "Cambodian riel",
    "currency_symbol": "KHR",
    "tld": ".kh",
    "native": "Kâmpŭchéa",
    "region": "Asia",
    "subregion": "South-Eastern Asia",
    "emoji": "🇰🇭",
    "emojiU": "U+1F1F0 U+1F1ED",
    "states": [
      {
        "id": 3984,
        "name": "Banteay Meanchey Province",
        "state_code": "1",
        "type": null
      },
      {
        "id": 3976,
        "name": "Battambang Province",
        "state_code": "2",
        "type": null
      },
      {
        "id": 3991,
        "name": "Kampong Cham Province",
        "state_code": "3",
        "type": null
      },
      {
        "id": 3979,
        "name": "Kampong Chhnang Province",
        "state_code": "4",
        "type": null
      },
      {
        "id": 3988,
        "name": "Kampong Speu Province",
        "state_code": "5",
        "type": null
      },
      {
        "id": 3981,
        "name": "Kampot Province",
        "state_code": "7",
        "type": null
      },
      {
        "id": 3983,
        "name": "Kandal Province",
        "state_code": "8",
        "type": null
      },
      {
        "id": 3978,
        "name": "Kep Province",
        "state_code": "23",
        "type": null
      },
      {
        "id": 3982,
        "name": "Koh Kong Province",
        "state_code": "9",
        "type": null
      },
      {
        "id": 3986,
        "name": "Kratié Province",
        "state_code": "10",
        "type": null
      },
      {
        "id": 3985,
        "name": "Mondulkiri Province",
        "state_code": "11",
        "type": null
      },
      {
        "id": 3987,
        "name": "Oddar Meanchey Province",
        "state_code": "22",
        "type": null
      },
      {
        "id": 3980,
        "name": "Pailin Province",
        "state_code": "24",
        "type": null
      },
      {
        "id": 3994,
        "name": "Phnom Penh",
        "state_code": "12",
        "type": null
      },
      {
        "id": 3973,
        "name": "Preah Vihear Province",
        "state_code": "13",
        "type": null
      },
      {
        "id": 3974,
        "name": "Prey Veng Province",
        "state_code": "14",
        "type": null
      },
      {
        "id": 3977,
        "name": "Pursat Province",
        "state_code": "15",
        "type": null
      },
      {
        "id": 3990,
        "name": "Ratanakiri Province",
        "state_code": "16",
        "type": null
      },
      {
        "id": 3992,
        "name": "Siem Reap Province",
        "state_code": "17",
        "type": null
      },
      {
        "id": 3989,
        "name": "Sihanoukville Province",
        "state_code": "18",
        "type": null
      },
      {
        "id": 3993,
        "name": "Stung Treng Province",
        "state_code": "19",
        "type": null
      },
      {
        "id": 3972,
        "name": "Svay Rieng Province",
        "state_code": "20",
        "type": null
      },
      {
        "id": 3975,
        "name": "Takéo Province",
        "state_code": "21",
        "type": null
      }
    ]
  },
  {
    "name": "Cameroon",
    "iso3": "CMR",
    "iso2": "CM",
    "numeric_code": "120",
    "phone_code": "237",
    "capital": "Yaounde",
    "currency": "XAF",
    "currency_name": "Central African CFA franc",
    "currency_symbol": "FCFA",
    "tld": ".cm",
    "native": "Cameroon",
    "region": "Africa",
    "subregion": "Middle Africa",
    "emoji": "🇨🇲",
    "emojiU": "U+1F1E8 U+1F1F2",
    "states": [
      {
        "id": 2663,
        "name": "Adamawa",
        "state_code": "AD",
        "type": null
      },
      {
        "id": 2660,
        "name": "Centre",
        "state_code": "CE",
        "type": null
      },
      {
        "id": 2661,
        "name": "East",
        "state_code": "ES",
        "type": null
      },
      {
        "id": 2656,
        "name": "Far North",
        "state_code": "EN",
        "type": null
      },
      {
        "id": 2662,
        "name": "Littoral",
        "state_code": "LT",
        "type": null
      },
      {
        "id": 2665,
        "name": "North",
        "state_code": "NO",
        "type": null
      },
      {
        "id": 2657,
        "name": "Northwest",
        "state_code": "NW",
        "type": null
      },
      {
        "id": 2659,
        "name": "South",
        "state_code": "SU",
        "type": null
      },
      {
        "id": 2658,
        "name": "Southwest",
        "state_code": "SW",
        "type": null
      },
      {
        "id": 2664,
        "name": "West",
        "state_code": "OU",
        "type": null
      }
    ]
  },
  {
    "name": "Canada",
    "iso3": "CAN",
    "iso2": "CA",
    "numeric_code": "124",
    "phone_code": "1",
    "capital": "Ottawa",
    "currency": "CAD",
    "currency_name": "Canadian dollar",
    "currency_symbol": "$",
    "tld": ".ca",
    "native": "Canada",
    "region": "Americas",
    "subregion": "Northern America",
    "emoji": "🇨🇦",
    "emojiU": "U+1F1E8 U+1F1E6",
    "states": [
      {
        "id": 872,
        "name": "Alberta",
        "state_code": "AB",
        "type": null
      },
      {
        "id": 875,
        "name": "British Columbia",
        "state_code": "BC",
        "type": null
      },
      {
        "id": 867,
        "name": "Manitoba",
        "state_code": "MB",
        "type": null
      },
      {
        "id": 868,
        "name": "New Brunswick",
        "state_code": "NB",
        "type": null
      },
      {
        "id": 877,
        "name": "Newfoundland and Labrador",
        "state_code": "NL",
        "type": null
      },
      {
        "id": 878,
        "name": "Northwest Territories",
        "state_code": "NT",
        "type": null
      },
      {
        "id": 874,
        "name": "Nova Scotia",
        "state_code": "NS",
        "type": null
      },
      {
        "id": 876,
        "name": "Nunavut",
        "state_code": "NU",
        "type": null
      },
      {
        "id": 866,
        "name": "Ontario",
        "state_code": "ON",
        "type": null
      },
      {
        "id": 871,
        "name": "Prince Edward Island",
        "state_code": "PE",
        "type": null
      },
      {
        "id": 873,
        "name": "Quebec",
        "state_code": "QC",
        "type": null
      },
      {
        "id": 870,
        "name": "Saskatchewan",
        "state_code": "SK",
        "type": null
      },
      {
        "id": 869,
        "name": "Yukon",
        "state_code": "YT",
        "type": null
      }
    ]
  },
  {
    "name": "Cape Verde",
    "iso3": "CPV",
    "iso2": "CV",
    "numeric_code": "132",
    "phone_code": "238",
    "capital": "Praia",
    "currency": "CVE",
    "currency_name": "Cape Verdean escudo",
    "currency_symbol": "$",
    "tld": ".cv",
    "native": "Cabo Verde",
    "region": "Africa",
    "subregion": "Western Africa",
    "emoji": "🇨🇻",
    "emojiU": "U+1F1E8 U+1F1FB",
    "states": [
      {
        "id": 2994,
        "name": "Barlavento Islands",
        "state_code": "B",
        "type": null
      },
      {
        "id": 2999,
        "name": "Boa Vista",
        "state_code": "BV",
        "type": null
      },
      {
        "id": 2996,
        "name": "Brava",
        "state_code": "BR",
        "type": null
      },
      {
        "id": 2991,
        "name": "Maio Municipality",
        "state_code": "MA",
        "type": null
      },
      {
        "id": 2987,
        "name": "Mosteiros",
        "state_code": "MO",
        "type": null
      },
      {
        "id": 2997,
        "name": "Paul",
        "state_code": "PA",
        "type": null
      },
      {
        "id": 2989,
        "name": "Porto Novo",
        "state_code": "PN",
        "type": null
      },
      {
        "id": 2988,
        "name": "Praia",
        "state_code": "PR",
        "type": null
      },
      {
        "id": 2982,
        "name": "Ribeira Brava Municipality",
        "state_code": "RB",
        "type": null
      },
      {
        "id": 3002,
        "name": "Ribeira Grande",
        "state_code": "RG",
        "type": null
      },
      {
        "id": 2984,
        "name": "Ribeira Grande de Santiago",
        "state_code": "RS",
        "type": null
      },
      {
        "id": 2998,
        "name": "Sal",
        "state_code": "SL",
        "type": null
      },
      {
        "id": 2985,
        "name": "Santa Catarina",
        "state_code": "CA",
        "type": null
      },
      {
        "id": 2995,
        "name": "Santa Catarina do Fogo",
        "state_code": "CF",
        "type": null
      },
      {
        "id": 3004,
        "name": "Santa Cruz",
        "state_code": "CR",
        "type": null
      },
      {
        "id": 2986,
        "name": "São Domingos",
        "state_code": "SD",
        "type": null
      },
      {
        "id": 3000,
        "name": "São Filipe",
        "state_code": "SF",
        "type": null
      },
      {
        "id": 2993,
        "name": "São Lourenço dos Órgãos",
        "state_code": "SO",
        "type": null
      },
      {
        "id": 2990,
        "name": "São Miguel",
        "state_code": "SM",
        "type": null
      },
      {
        "id": 3001,
        "name": "São Vicente",
        "state_code": "SV",
        "type": null
      },
      {
        "id": 2992,
        "name": "Sotavento Islands",
        "state_code": "S",
        "type": null
      },
      {
        "id": 2983,
        "name": "Tarrafal",
        "state_code": "TA",
        "type": null
      },
      {
        "id": 3003,
        "name": "Tarrafal de São Nicolau",
        "state_code": "TS",
        "type": null
      }
    ]
  },
  {
    "name": "Cayman Islands",
    "iso3": "CYM",
    "iso2": "KY",
    "numeric_code": "136",
    "phone_code": "+1-345",
    "capital": "George Town",
    "currency": "KYD",
    "currency_name": "Cayman Islands dollar",
    "currency_symbol": "$",
    "tld": ".ky",
    "native": "Cayman Islands",
    "region": "Americas",
    "subregion": "Caribbean",
    "emoji": "🇰🇾",
    "emojiU": "U+1F1F0 U+1F1FE",
    "states": []
  },
  {
    "name": "Central African Republic",
    "iso3": "CAF",
    "iso2": "CF",
    "numeric_code": "140",
    "phone_code": "236",
    "capital": "Bangui",
    "currency": "XAF",
    "currency_name": "Central African CFA franc",
    "currency_symbol": "FCFA",
    "tld": ".cf",
    "native": "Ködörösêse tî Bêafrîka",
    "region": "Africa",
    "subregion": "Middle Africa",
    "emoji": "🇨🇫",
    "emojiU": "U+1F1E8 U+1F1EB",
    "states": [
      {
        "id": 1259,
        "name": "Bamingui-Bangoran Prefecture",
        "state_code": "BB",
        "type": null
      },
      {
        "id": 1262,
        "name": "Bangui",
        "state_code": "BGF",
        "type": null
      },
      {
        "id": 1264,
        "name": "Basse-Kotto Prefecture",
        "state_code": "BK",
        "type": null
      },
      {
        "id": 1258,
        "name": "Haut-Mbomou Prefecture",
        "state_code": "HM",
        "type": null
      },
      {
        "id": 1268,
        "name": "Haute-Kotto Prefecture",
        "state_code": "HK",
        "type": null
      },
      {
        "id": 1263,
        "name": "Kémo Prefecture",
        "state_code": "KG",
        "type": null
      },
      {
        "id": 1256,
        "name": "Lobaye Prefecture",
        "state_code": "LB",
        "type": null
      },
      {
        "id": 1257,
        "name": "Mambéré-Kadéï",
        "state_code": "HS",
        "type": null
      },
      {
        "id": 1266,
        "name": "Mbomou Prefecture",
        "state_code": "MB",
        "type": null
      },
      {
        "id": 1253,
        "name": "Nana-Grébizi Economic Prefecture",
        "state_code": "KB",
        "type": null
      },
      {
        "id": 1260,
        "name": "Nana-Mambéré Prefecture",
        "state_code": "NM",
        "type": null
      },
      {
        "id": 1255,
        "name": "Ombella-M'Poko Prefecture",
        "state_code": "MP",
        "type": null
      },
      {
        "id": 1265,
        "name": "Ouaka Prefecture",
        "state_code": "UK",
        "type": null
      },
      {
        "id": 1254,
        "name": "Ouham Prefecture",
        "state_code": "AC",
        "type": null
      },
      {
        "id": 1267,
        "name": "Ouham-Pendé Prefecture",
        "state_code": "OP",
        "type": null
      },
      {
        "id": 1252,
        "name": "Sangha-Mbaéré",
        "state_code": "SE",
        "type": null
      },
      {
        "id": 1261,
        "name": "Vakaga Prefecture",
        "state_code": "VK",
        "type": null
      }
    ]
  },
  {
    "name": "Chad",
    "iso3": "TCD",
    "iso2": "TD",
    "numeric_code": "148",
    "phone_code": "235",
    "capital": "N'Djamena",
    "currency": "XAF",
    "currency_name": "Central African CFA franc",
    "currency_symbol": "FCFA",
    "tld": ".td",
    "native": "Tchad",
    "region": "Africa",
    "subregion": "Middle Africa",
    "emoji": "🇹🇩",
    "emojiU": "U+1F1F9 U+1F1E9",
    "states": [
      {
        "id": 3583,
        "name": "Bahr el Gazel",
        "state_code": "BG",
        "type": null
      },
      {
        "id": 3590,
        "name": "Batha Region",
        "state_code": "BA",
        "type": null
      },
      {
        "id": 3574,
        "name": "Borkou",
        "state_code": "BO",
        "type": null
      },
      {
        "id": 3578,
        "name": "Ennedi Region",
        "state_code": "EN",
        "type": null
      },
      {
        "id": 3575,
        "name": "Ennedi-Est",
        "state_code": "EE",
        "type": null
      },
      {
        "id": 3584,
        "name": "Ennedi-Ouest",
        "state_code": "EO",
        "type": null
      },
      {
        "id": 3576,
        "name": "Guéra Region",
        "state_code": "GR",
        "type": null
      },
      {
        "id": 3573,
        "name": "Hadjer-Lamis",
        "state_code": "HL",
        "type": null
      },
      {
        "id": 3588,
        "name": "Kanem Region",
        "state_code": "KA",
        "type": null
      },
      {
        "id": 3577,
        "name": "Lac Region",
        "state_code": "LC",
        "type": null
      },
      {
        "id": 3585,
        "name": "Logone Occidental Region",
        "state_code": "LO",
        "type": null
      },
      {
        "id": 3591,
        "name": "Logone Oriental Region",
        "state_code": "LR",
        "type": null
      },
      {
        "id": 3589,
        "name": "Mandoul Region",
        "state_code": "MA",
        "type": null
      },
      {
        "id": 3580,
        "name": "Mayo-Kebbi Est Region",
        "state_code": "ME",
        "type": null
      },
      {
        "id": 3571,
        "name": "Mayo-Kebbi Ouest Region",
        "state_code": "MO",
        "type": null
      },
      {
        "id": 3570,
        "name": "Moyen-Chari Region",
        "state_code": "MC",
        "type": null
      },
      {
        "id": 3586,
        "name": "N'Djamena",
        "state_code": "ND",
        "type": null
      },
      {
        "id": 3582,
        "name": "Ouaddaï Region",
        "state_code": "OD",
        "type": null
      },
      {
        "id": 3592,
        "name": "Salamat Region",
        "state_code": "SA",
        "type": null
      },
      {
        "id": 3572,
        "name": "Sila Region",
        "state_code": "SI",
        "type": null
      },
      {
        "id": 3579,
        "name": "Tandjilé Region",
        "state_code": "TA",
        "type": null
      },
      {
        "id": 3587,
        "name": "Tibesti Region",
        "state_code": "TI",
        "type": null
      },
      {
        "id": 3581,
        "name": "Wadi Fira Region",
        "state_code": "WF",
        "type": null
      }
    ]
  },
  {
    "name": "Chile",
    "iso3": "CHL",
    "iso2": "CL",
    "numeric_code": "152",
    "phone_code": "56",
    "capital": "Santiago",
    "currency": "CLP",
    "currency_name": "Chilean peso",
    "currency_symbol": "$",
    "tld": ".cl",
    "native": "Chile",
    "region": "Americas",
    "subregion": "South America",
    "emoji": "🇨🇱",
    "emojiU": "U+1F1E8 U+1F1F1",
    "states": [
      {
        "id": 2828,
        "name": "Aisén del General Carlos Ibañez del Campo",
        "state_code": "AI",
        "type": null
      },
      {
        "id": 2832,
        "name": "Antofagasta",
        "state_code": "AN",
        "type": null
      },
      {
        "id": 2829,
        "name": "Arica y Parinacota",
        "state_code": "AP",
        "type": null
      },
      {
        "id": 2823,
        "name": "Atacama",
        "state_code": "AT",
        "type": null
      },
      {
        "id": 2827,
        "name": "Biobío",
        "state_code": "BI",
        "type": null
      },
      {
        "id": 2825,
        "name": "Coquimbo",
        "state_code": "CO",
        "type": null
      },
      {
        "id": 2826,
        "name": "La Araucanía",
        "state_code": "AR",
        "type": null
      },
      {
        "id": 2838,
        "name": "Libertador General Bernardo O'Higgins",
        "state_code": "LI",
        "type": null
      },
      {
        "id": 2835,
        "name": "Los Lagos",
        "state_code": "LL",
        "type": null
      },
      {
        "id": 2834,
        "name": "Los Ríos",
        "state_code": "LR",
        "type": null
      },
      {
        "id": 2836,
        "name": "Magallanes y de la Antártica Chilena",
        "state_code": "MA",
        "type": null
      },
      {
        "id": 2833,
        "name": "Maule",
        "state_code": "ML",
        "type": null
      },
      {
        "id": 2831,
        "name": "Ñuble",
        "state_code": "NB",
        "type": null
      },
      {
        "id": 2824,
        "name": "Región Metropolitana de Santiago",
        "state_code": "RM",
        "type": null
      },
      {
        "id": 2837,
        "name": "Tarapacá",
        "state_code": "TA",
        "type": null
      },
      {
        "id": 2830,
        "name": "Valparaíso",
        "state_code": "VS",
        "type": null
      }
    ]
  },
  {
    "name": "China",
    "iso3": "CHN",
    "iso2": "CN",
    "numeric_code": "156",
    "phone_code": "86",
    "capital": "Beijing",
    "currency": "CNY",
    "currency_name": "Chinese yuan",
    "currency_symbol": "¥",
    "tld": ".cn",
    "native": "中国",
    "region": "Asia",
    "subregion": "Eastern Asia",
    "emoji": "🇨🇳",
    "emojiU": "U+1F1E8 U+1F1F3",
    "states": [
      {
        "id": 2251,
        "name": "Anhui",
        "state_code": "AH",
        "type": "province"
      },
      {
        "id": 2257,
        "name": "Beijing",
        "state_code": "BJ",
        "type": "municipality"
      },
      {
        "id": 2271,
        "name": "Chongqing",
        "state_code": "CQ",
        "type": "municipality"
      },
      {
        "id": 2248,
        "name": "Fujian",
        "state_code": "FJ",
        "type": "province"
      },
      {
        "id": 2275,
        "name": "Gansu",
        "state_code": "GS",
        "type": "province"
      },
      {
        "id": 2279,
        "name": "Guangdong",
        "state_code": "GD",
        "type": "province"
      },
      {
        "id": 2278,
        "name": "Guangxi Zhuang",
        "state_code": "GX",
        "type": "autonomous region\t"
      },
      {
        "id": 2261,
        "name": "Guizhou",
        "state_code": "GZ",
        "type": "province"
      },
      {
        "id": 2273,
        "name": "Hainan",
        "state_code": "HI",
        "type": "province"
      },
      {
        "id": 2280,
        "name": "Hebei",
        "state_code": "HE",
        "type": "province"
      },
      {
        "id": 2265,
        "name": "Heilongjiang",
        "state_code": "HL",
        "type": "province"
      },
      {
        "id": 2259,
        "name": "Henan",
        "state_code": "HA",
        "type": "province"
      },
      {
        "id": 2267,
        "name": "Hong Kong SAR",
        "state_code": "HK",
        "type": "special administrative region\t"
      },
      {
        "id": 2274,
        "name": "Hubei",
        "state_code": "HB",
        "type": "province"
      },
      {
        "id": 2258,
        "name": "Hunan",
        "state_code": "HN",
        "type": "province"
      },
      {
        "id": 2269,
        "name": "Inner Mongolia",
        "state_code": "NM",
        "type": "autonomous region\t"
      },
      {
        "id": 2250,
        "name": "Jiangsu",
        "state_code": "JS",
        "type": "province"
      },
      {
        "id": 2256,
        "name": "Jiangxi",
        "state_code": "JX",
        "type": "province"
      },
      {
        "id": 2253,
        "name": "Jilin",
        "state_code": "JL",
        "type": "province"
      },
      {
        "id": 2268,
        "name": "Liaoning",
        "state_code": "LN",
        "type": "province"
      },
      {
        "id": 2266,
        "name": "Macau SAR",
        "state_code": "MO",
        "type": "special administrative region"
      },
      {
        "id": 2262,
        "name": "Ningxia Huizu",
        "state_code": "NX",
        "type": "autonomous region\t"
      },
      {
        "id": 2270,
        "name": "Qinghai",
        "state_code": "QH",
        "type": "province"
      },
      {
        "id": 2272,
        "name": "Shaanxi",
        "state_code": "SN",
        "type": "province"
      },
      {
        "id": 2252,
        "name": "Shandong",
        "state_code": "SD",
        "type": "province"
      },
      {
        "id": 2249,
        "name": "Shanghai",
        "state_code": "SH",
        "type": "municipality"
      },
      {
        "id": 2254,
        "name": "Shanxi",
        "state_code": "SX",
        "type": "province"
      },
      {
        "id": 2277,
        "name": "Sichuan",
        "state_code": "SC",
        "type": "province"
      },
      {
        "id": 2255,
        "name": "Taiwan",
        "state_code": "TW",
        "type": "province"
      },
      {
        "id": 2276,
        "name": "Tianjin",
        "state_code": "TJ",
        "type": "municipality"
      },
      {
        "id": 2263,
        "name": "Xinjiang",
        "state_code": "XJ",
        "type": "autonomous region"
      },
      {
        "id": 2264,
        "name": "Xizang",
        "state_code": "XZ",
        "type": "autonomous region"
      },
      {
        "id": 2260,
        "name": "Yunnan",
        "state_code": "YN",
        "type": "province"
      },
      {
        "id": 2247,
        "name": "Zhejiang",
        "state_code": "ZJ",
        "type": "province"
      }
    ]
  },
  {
    "name": "Christmas Island",
    "iso3": "CXR",
    "iso2": "CX",
    "numeric_code": "162",
    "phone_code": "61",
    "capital": "Flying Fish Cove",
    "currency": "AUD",
    "currency_name": "Australian dollar",
    "currency_symbol": "$",
    "tld": ".cx",
    "native": "Christmas Island",
    "region": "Oceania",
    "subregion": "Australia and New Zealand",
    "emoji": "🇨🇽",
    "emojiU": "U+1F1E8 U+1F1FD",
    "states": []
  },
  {
    "name": "Cocos (Keeling) Islands",
    "iso3": "CCK",
    "iso2": "CC",
    "numeric_code": "166",
    "phone_code": "61",
    "capital": "West Island",
    "currency": "AUD",
    "currency_name": "Australian dollar",
    "currency_symbol": "$",
    "tld": ".cc",
    "native": "Cocos (Keeling) Islands",
    "region": "Oceania",
    "subregion": "Australia and New Zealand",
    "emoji": "🇨🇨",
    "emojiU": "U+1F1E8 U+1F1E8",
    "states": []
  },
  {
    "name": "Colombia",
    "iso3": "COL",
    "iso2": "CO",
    "numeric_code": "170",
    "phone_code": "57",
    "capital": "Bogota",
    "currency": "COP",
    "currency_name": "Colombian peso",
    "currency_symbol": "$",
    "tld": ".co",
    "native": "Colombia",
    "region": "Americas",
    "subregion": "South America",
    "emoji": "🇨🇴",
    "emojiU": "U+1F1E8 U+1F1F4",
    "states": [
      {
        "id": 2895,
        "name": "Amazonas",
        "state_code": "AMA",
        "type": null
      },
      {
        "id": 2890,
        "name": "Antioquia",
        "state_code": "ANT",
        "type": null
      },
      {
        "id": 2881,
        "name": "Arauca",
        "state_code": "ARA",
        "type": null
      },
      {
        "id": 2880,
        "name": "Atlántico",
        "state_code": "ATL",
        "type": null
      },
      {
        "id": 4921,
        "name": "Bogotá",
        "state_code": "DC",
        "type": null
      },
      {
        "id": 2893,
        "name": "Bolívar",
        "state_code": "BOL",
        "type": null
      },
      {
        "id": 2903,
        "name": "Boyacá",
        "state_code": "BOY",
        "type": null
      },
      {
        "id": 2887,
        "name": "Caldas",
        "state_code": "CAL",
        "type": null
      },
      {
        "id": 2891,
        "name": "Caquetá",
        "state_code": "CAQ",
        "type": null
      },
      {
        "id": 2892,
        "name": "Casanare",
        "state_code": "CAS",
        "type": null
      },
      {
        "id": 2884,
        "name": "Cauca",
        "state_code": "CAU",
        "type": null
      },
      {
        "id": 2899,
        "name": "Cesar",
        "state_code": "CES",
        "type": null
      },
      {
        "id": 2876,
        "name": "Chocó",
        "state_code": "CHO",
        "type": null
      },
      {
        "id": 2898,
        "name": "Córdoba",
        "state_code": "COR",
        "type": null
      },
      {
        "id": 2875,
        "name": "Cundinamarca",
        "state_code": "CUN",
        "type": null
      },
      {
        "id": 2882,
        "name": "Guainía",
        "state_code": "GUA",
        "type": null
      },
      {
        "id": 2888,
        "name": "Guaviare",
        "state_code": "GUV",
        "type": null
      },
      {
        "id": 4871,
        "name": "Huila",
        "state_code": "HUI",
        "type": null
      },
      {
        "id": 2889,
        "name": "La Guajira",
        "state_code": "LAG",
        "type": null
      },
      {
        "id": 2886,
        "name": "Magdalena",
        "state_code": "MAG",
        "type": null
      },
      {
        "id": 2878,
        "name": "Meta",
        "state_code": "MET",
        "type": null
      },
      {
        "id": 2897,
        "name": "Nariño",
        "state_code": "NAR",
        "type": null
      },
      {
        "id": 2877,
        "name": "Norte de Santander",
        "state_code": "NSA",
        "type": null
      },
      {
        "id": 2896,
        "name": "Putumayo",
        "state_code": "PUT",
        "type": null
      },
      {
        "id": 2874,
        "name": "Quindío",
        "state_code": "QUI",
        "type": null
      },
      {
        "id": 2879,
        "name": "Risaralda",
        "state_code": "RIS",
        "type": null
      },
      {
        "id": 2900,
        "name": "San Andrés, Providencia y Santa Catalina",
        "state_code": "SAP",
        "type": null
      },
      {
        "id": 2901,
        "name": "Santander",
        "state_code": "SAN",
        "type": null
      },
      {
        "id": 2902,
        "name": "Sucre",
        "state_code": "SUC",
        "type": null
      },
      {
        "id": 2883,
        "name": "Tolima",
        "state_code": "TOL",
        "type": null
      },
      {
        "id": 2904,
        "name": "Valle del Cauca",
        "state_code": "VAC",
        "type": null
      },
      {
        "id": 2885,
        "name": "Vaupés",
        "state_code": "VAU",
        "type": null
      },
      {
        "id": 2894,
        "name": "Vichada",
        "state_code": "VID",
        "type": null
      }
    ]
  },
  {
    "name": "Comoros",
    "iso3": "COM",
    "iso2": "KM",
    "numeric_code": "174",
    "phone_code": "269",
    "capital": "Moroni",
    "currency": "KMF",
    "currency_name": "Comorian franc",
    "currency_symbol": "CF",
    "tld": ".km",
    "native": "Komori",
    "region": "Africa",
    "subregion": "Eastern Africa",
    "emoji": "🇰🇲",
    "emojiU": "U+1F1F0 U+1F1F2",
    "states": [
      {
        "id": 2821,
        "name": "Anjouan",
        "state_code": "A",
        "type": null
      },
      {
        "id": 2822,
        "name": "Grande Comore",
        "state_code": "G",
        "type": null
      },
      {
        "id": 2820,
        "name": "Mohéli",
        "state_code": "M",
        "type": null
      }
    ]
  },
  {
    "name": "Congo",
    "iso3": "COG",
    "iso2": "CG",
    "numeric_code": "178",
    "phone_code": "242",
    "capital": "Brazzaville",
    "currency": "XAF",
    "currency_name": "Central African CFA franc",
    "currency_symbol": "FC",
    "tld": ".cg",
    "native": "République du Congo",
    "region": "Africa",
    "subregion": "Middle Africa",
    "emoji": "🇨🇬",
    "emojiU": "U+1F1E8 U+1F1EC",
    "states": [
      {
        "id": 2866,
        "name": "Bouenza Department",
        "state_code": "11",
        "type": null
      },
      {
        "id": 2870,
        "name": "Brazzaville",
        "state_code": "BZV",
        "type": null
      },
      {
        "id": 2864,
        "name": "Cuvette Department",
        "state_code": "8",
        "type": null
      },
      {
        "id": 2869,
        "name": "Cuvette-Ouest Department",
        "state_code": "15",
        "type": null
      },
      {
        "id": 2867,
        "name": "Kouilou Department",
        "state_code": "5",
        "type": null
      },
      {
        "id": 2868,
        "name": "Lékoumou Department",
        "state_code": "2",
        "type": null
      },
      {
        "id": 2865,
        "name": "Likouala Department",
        "state_code": "7",
        "type": null
      },
      {
        "id": 2872,
        "name": "Niari Department",
        "state_code": "9",
        "type": null
      },
      {
        "id": 2862,
        "name": "Plateaux Department",
        "state_code": "14",
        "type": null
      },
      {
        "id": 2863,
        "name": "Pointe-Noire",
        "state_code": "16",
        "type": null
      },
      {
        "id": 2873,
        "name": "Pool Department",
        "state_code": "12",
        "type": null
      },
      {
        "id": 2871,
        "name": "Sangha Department",
        "state_code": "13",
        "type": null
      }
    ]
  },
  {
    "name": "Cook Islands",
    "iso3": "COK",
    "iso2": "CK",
    "numeric_code": "184",
    "phone_code": "682",
    "capital": "Avarua",
    "currency": "NZD",
    "currency_name": "Cook Islands dollar",
    "currency_symbol": "$",
    "tld": ".ck",
    "native": "Cook Islands",
    "region": "Oceania",
    "subregion": "Polynesia",
    "emoji": "🇨🇰",
    "emojiU": "U+1F1E8 U+1F1F0",
    "states": []
  },
  {
    "name": "Costa Rica",
    "iso3": "CRI",
    "iso2": "CR",
    "numeric_code": "188",
    "phone_code": "506",
    "capital": "San Jose",
    "currency": "CRC",
    "currency_name": "Costa Rican colón",
    "currency_symbol": "₡",
    "tld": ".cr",
    "native": "Costa Rica",
    "region": "Americas",
    "subregion": "Central America",
    "emoji": "🇨🇷",
    "emojiU": "U+1F1E8 U+1F1F7",
    "states": [
      {
        "id": 1215,
        "name": "Alajuela Province",
        "state_code": "A",
        "type": null
      },
      {
        "id": 1209,
        "name": "Guanacaste Province",
        "state_code": "G",
        "type": null
      },
      {
        "id": 1212,
        "name": "Heredia Province",
        "state_code": "H",
        "type": null
      },
      {
        "id": 1213,
        "name": "Limón Province",
        "state_code": "L",
        "type": null
      },
      {
        "id": 1211,
        "name": "Provincia de Cartago",
        "state_code": "C",
        "type": null
      },
      {
        "id": 1210,
        "name": "Puntarenas Province",
        "state_code": "P",
        "type": null
      },
      {
        "id": 1214,
        "name": "San José Province",
        "state_code": "SJ",
        "type": null
      }
    ]
  },
  {
    "name": "Cote D'Ivoire (Ivory Coast)",
    "iso3": "CIV",
    "iso2": "CI",
    "numeric_code": "384",
    "phone_code": "225",
    "capital": "Yamoussoukro",
    "currency": "XOF",
    "currency_name": "West African CFA franc",
    "currency_symbol": "CFA",
    "tld": ".ci",
    "native": null,
    "region": "Africa",
    "subregion": "Western Africa",
    "emoji": "🇨🇮",
    "emojiU": "U+1F1E8 U+1F1EE",
    "states": [
      {
        "id": 2634,
        "name": "Abidjan",
        "state_code": "AB",
        "type": null
      },
      {
        "id": 2626,
        "name": "Agnéby",
        "state_code": "16",
        "type": null
      },
      {
        "id": 2636,
        "name": "Bafing Region",
        "state_code": "17",
        "type": null
      },
      {
        "id": 2643,
        "name": "Bas-Sassandra District",
        "state_code": "BS",
        "type": null
      },
      {
        "id": 2635,
        "name": "Bas-Sassandra Region",
        "state_code": "09",
        "type": null
      },
      {
        "id": 2654,
        "name": "Comoé District",
        "state_code": "CM",
        "type": null
      },
      {
        "id": 2644,
        "name": "Denguélé District",
        "state_code": "DN",
        "type": null
      },
      {
        "id": 2642,
        "name": "Denguélé Region",
        "state_code": "10",
        "type": null
      },
      {
        "id": 2645,
        "name": "Dix-Huit Montagnes",
        "state_code": "06",
        "type": null
      },
      {
        "id": 2633,
        "name": "Fromager",
        "state_code": "18",
        "type": null
      },
      {
        "id": 2651,
        "name": "Gôh-Djiboua District",
        "state_code": "GD",
        "type": null
      },
      {
        "id": 2638,
        "name": "Haut-Sassandra",
        "state_code": "02",
        "type": null
      },
      {
        "id": 2632,
        "name": "Lacs District",
        "state_code": "LC",
        "type": null
      },
      {
        "id": 2640,
        "name": "Lacs Region",
        "state_code": "07",
        "type": null
      },
      {
        "id": 2627,
        "name": "Lagunes District",
        "state_code": "LG",
        "type": null
      },
      {
        "id": 2639,
        "name": "Lagunes region",
        "state_code": "01",
        "type": null
      },
      {
        "id": 2631,
        "name": "Marahoué Region",
        "state_code": "12",
        "type": null
      },
      {
        "id": 2629,
        "name": "Montagnes District",
        "state_code": "MG",
        "type": null
      },
      {
        "id": 2646,
        "name": "Moyen-Cavally",
        "state_code": "19",
        "type": null
      },
      {
        "id": 2630,
        "name": "Moyen-Comoé",
        "state_code": "05",
        "type": null
      },
      {
        "id": 2655,
        "name": "N'zi-Comoé",
        "state_code": "11",
        "type": null
      },
      {
        "id": 2648,
        "name": "Sassandra-Marahoué District",
        "state_code": "SM",
        "type": null
      },
      {
        "id": 2625,
        "name": "Savanes Region",
        "state_code": "03",
        "type": null
      },
      {
        "id": 2628,
        "name": "Sud-Bandama",
        "state_code": "15",
        "type": null
      },
      {
        "id": 2652,
        "name": "Sud-Comoé",
        "state_code": "13",
        "type": null
      },
      {
        "id": 2637,
        "name": "Vallée du Bandama District",
        "state_code": "VB",
        "type": null
      },
      {
        "id": 2647,
        "name": "Vallée du Bandama Region",
        "state_code": "04",
        "type": null
      },
      {
        "id": 2650,
        "name": "Woroba District",
        "state_code": "WR",
        "type": null
      },
      {
        "id": 2649,
        "name": "Worodougou",
        "state_code": "14",
        "type": null
      },
      {
        "id": 2653,
        "name": "Yamoussoukro",
        "state_code": "YM",
        "type": null
      },
      {
        "id": 2641,
        "name": "Zanzan Region",
        "state_code": "ZZ",
        "type": null
      }
    ]
  },
  {
    "name": "Croatia",
    "iso3": "HRV",
    "iso2": "HR",
    "numeric_code": "191",
    "phone_code": "385",
    "capital": "Zagreb",
    "currency": "HRK",
    "currency_name": "Croatian kuna",
    "currency_symbol": "kn",
    "tld": ".hr",
    "native": "Hrvatska",
    "region": "Europe",
    "subregion": "Southern Europe",
    "emoji": "🇭🇷",
    "emojiU": "U+1F1ED U+1F1F7",
    "states": [
      {
        "id": 734,
        "name": "Bjelovar-Bilogora County",
        "state_code": "07",
        "type": null
      },
      {
        "id": 737,
        "name": "Brod-Posavina County",
        "state_code": "12",
        "type": null
      },
      {
        "id": 728,
        "name": "Dubrovnik-Neretva County",
        "state_code": "19",
        "type": null
      },
      {
        "id": 743,
        "name": "Istria County",
        "state_code": "18",
        "type": null
      },
      {
        "id": 742,
        "name": "Koprivnica-Križevci County",
        "state_code": "06",
        "type": null
      },
      {
        "id": 729,
        "name": "Krapina-Zagorje County",
        "state_code": "02",
        "type": null
      },
      {
        "id": 731,
        "name": "Lika-Senj County",
        "state_code": "09",
        "type": null
      },
      {
        "id": 726,
        "name": "Međimurje County",
        "state_code": "20",
        "type": null
      },
      {
        "id": 740,
        "name": "Osijek-Baranja County",
        "state_code": "14",
        "type": null
      },
      {
        "id": 724,
        "name": "Požega-Slavonia County",
        "state_code": "11",
        "type": null
      },
      {
        "id": 735,
        "name": "Primorje-Gorski Kotar County",
        "state_code": "08",
        "type": null
      },
      {
        "id": 730,
        "name": "Šibenik-Knin County",
        "state_code": "15",
        "type": null
      },
      {
        "id": 733,
        "name": "Sisak-Moslavina County",
        "state_code": "03",
        "type": null
      },
      {
        "id": 725,
        "name": "Split-Dalmatia County",
        "state_code": "17",
        "type": null
      },
      {
        "id": 739,
        "name": "Varaždin County",
        "state_code": "05",
        "type": null
      },
      {
        "id": 732,
        "name": "Virovitica-Podravina County",
        "state_code": "10",
        "type": null
      },
      {
        "id": 741,
        "name": "Vukovar-Syrmia County",
        "state_code": "16",
        "type": null
      },
      {
        "id": 727,
        "name": "Zadar County",
        "state_code": "13",
        "type": null
      },
      {
        "id": 738,
        "name": "Zagreb",
        "state_code": "21",
        "type": null
      },
      {
        "id": 736,
        "name": "Zagreb County",
        "state_code": "01",
        "type": null
      }
    ]
  },
  {
    "name": "Cuba",
    "iso3": "CUB",
    "iso2": "CU",
    "numeric_code": "192",
    "phone_code": "53",
    "capital": "Havana",
    "currency": "CUP",
    "currency_name": "Cuban peso",
    "currency_symbol": "$",
    "tld": ".cu",
    "native": "Cuba",
    "region": "Americas",
    "subregion": "Caribbean",
    "emoji": "🇨🇺",
    "emojiU": "U+1F1E8 U+1F1FA",
    "states": [
      {
        "id": 283,
        "name": "Artemisa Province",
        "state_code": "15",
        "type": null
      },
      {
        "id": 286,
        "name": "Camagüey Province",
        "state_code": "09",
        "type": null
      },
      {
        "id": 282,
        "name": "Ciego de Ávila Province",
        "state_code": "08",
        "type": null
      },
      {
        "id": 287,
        "name": "Cienfuegos Province",
        "state_code": "06",
        "type": null
      },
      {
        "id": 275,
        "name": "Granma Province",
        "state_code": "12",
        "type": null
      },
      {
        "id": 285,
        "name": "Guantánamo Province",
        "state_code": "14",
        "type": null
      },
      {
        "id": 272,
        "name": "Havana Province",
        "state_code": "03",
        "type": null
      },
      {
        "id": 279,
        "name": "Holguín Province",
        "state_code": "11",
        "type": null
      },
      {
        "id": 278,
        "name": "Isla de la Juventud",
        "state_code": "99",
        "type": null
      },
      {
        "id": 281,
        "name": "Las Tunas Province",
        "state_code": "10",
        "type": null
      },
      {
        "id": 284,
        "name": "Matanzas Province",
        "state_code": "04",
        "type": null
      },
      {
        "id": 276,
        "name": "Mayabeque Province",
        "state_code": "16",
        "type": null
      },
      {
        "id": 277,
        "name": "Pinar del Río Province",
        "state_code": "01",
        "type": null
      },
      {
        "id": 274,
        "name": "Sancti Spíritus Province",
        "state_code": "07",
        "type": null
      },
      {
        "id": 273,
        "name": "Santiago de Cuba Province",
        "state_code": "13",
        "type": null
      },
      {
        "id": 280,
        "name": "Villa Clara Province",
        "state_code": "05",
        "type": null
      }
    ]
  },
  {
    "name": "Curaçao",
    "iso3": "CUW",
    "iso2": "CW",
    "numeric_code": "531",
    "phone_code": "599",
    "capital": "Willemstad",
    "currency": "ANG",
    "currency_name": "Netherlands Antillean guilder",
    "currency_symbol": "ƒ",
    "tld": ".cw",
    "native": "Curaçao",
    "region": "Americas",
    "subregion": "Caribbean",
    "emoji": "🇨🇼",
    "emojiU": "U+1F1E8 U+1F1FC",
    "states": []
  },
  {
    "name": "Cyprus",
    "iso3": "CYP",
    "iso2": "CY",
    "numeric_code": "196",
    "phone_code": "357",
    "capital": "Nicosia",
    "currency": "EUR",
    "currency_name": "Euro",
    "currency_symbol": "€",
    "tld": ".cy",
    "native": "Κύπρος",
    "region": "Europe",
    "subregion": "Southern Europe",
    "emoji": "🇨🇾",
    "emojiU": "U+1F1E8 U+1F1FE",
    "states": [
      {
        "id": 749,
        "name": "Famagusta District (Mağusa)",
        "state_code": "04",
        "type": "district"
      },
      {
        "id": 744,
        "name": "Kyrenia District (Keryneia)",
        "state_code": "06",
        "type": "district"
      },
      {
        "id": 747,
        "name": "Larnaca District (Larnaka)",
        "state_code": "03",
        "type": "district"
      },
      {
        "id": 748,
        "name": "Limassol District (Leymasun)",
        "state_code": "02",
        "type": "district"
      },
      {
        "id": 745,
        "name": "Nicosia District (Lefkoşa)",
        "state_code": "01",
        "type": "district"
      },
      {
        "id": 746,
        "name": "Paphos District (Pafos)",
        "state_code": "05",
        "type": "district"
      }
    ]
  },
  {
    "name": "Czech Republic",
    "iso3": "CZE",
    "iso2": "CZ",
    "numeric_code": "203",
    "phone_code": "420",
    "capital": "Prague",
    "currency": "CZK",
    "currency_name": "Czech koruna",
    "currency_symbol": "Kč",
    "tld": ".cz",
    "native": "Česká republika",
    "region": "Europe",
    "subregion": "Eastern Europe",
    "emoji": "🇨🇿",
    "emojiU": "U+1F1E8 U+1F1FF",
    "states": [
      {
        "id": 4627,
        "name": "Benešov",
        "state_code": "201",
        "type": null
      },
      {
        "id": 4620,
        "name": "Beroun",
        "state_code": "202",
        "type": null
      },
      {
        "id": 4615,
        "name": "Blansko",
        "state_code": "641",
        "type": null
      },
      {
        "id": 4542,
        "name": "Břeclav",
        "state_code": "644",
        "type": null
      },
      {
        "id": 4568,
        "name": "Brno-město",
        "state_code": "642",
        "type": null
      },
      {
        "id": 4545,
        "name": "Brno-venkov",
        "state_code": "643",
        "type": null
      },
      {
        "id": 4644,
        "name": "Bruntál",
        "state_code": "801",
        "type": null
      },
      {
        "id": 4633,
        "name": "Česká Lípa",
        "state_code": "511",
        "type": null
      },
      {
        "id": 4556,
        "name": "České Budějovice",
        "state_code": "311",
        "type": null
      },
      {
        "id": 4543,
        "name": "Český Krumlov",
        "state_code": "312",
        "type": null
      },
      {
        "id": 4573,
        "name": "Cheb",
        "state_code": "411",
        "type": null
      },
      {
        "id": 4553,
        "name": "Chomutov",
        "state_code": "422",
        "type": null
      },
      {
        "id": 4634,
        "name": "Chrudim",
        "state_code": "531",
        "type": null
      },
      {
        "id": 4609,
        "name": "Děčín",
        "state_code": "421",
        "type": null
      },
      {
        "id": 4641,
        "name": "Domažlice",
        "state_code": "321",
        "type": null
      },
      {
        "id": 4559,
        "name": "Frýdek-Místek",
        "state_code": "802",
        "type": null
      },
      {
        "id": 4611,
        "name": "Havlíčkův Brod",
        "state_code": "631",
        "type": null
      },
      {
        "id": 4561,
        "name": "Hodonín",
        "state_code": "645",
        "type": null
      },
      {
        "id": 4580,
        "name": "Hradec Králové",
        "state_code": "521",
        "type": null
      },
      {
        "id": 4612,
        "name": "Jablonec nad Nisou",
        "state_code": "512",
        "type": null
      },
      {
        "id": 4625,
        "name": "Jeseník",
        "state_code": "711",
        "type": null
      },
      {
        "id": 4640,
        "name": "Jičín",
        "state_code": "522",
        "type": null
      },
      {
        "id": 4613,
        "name": "Jihlava",
        "state_code": "632",
        "type": null
      },
      {
        "id": 4639,
        "name": "Jihočeský kraj",
        "state_code": "31",
        "type": null
      },
      {
        "id": 4602,
        "name": "Jihomoravský kraj",
        "state_code": "64",
        "type": null
      },
      {
        "id": 4624,
        "name": "Jindřichův Hradec",
        "state_code": "313",
        "type": null
      },
      {
        "id": 4581,
        "name": "Karlovarský kraj",
        "state_code": "41",
        "type": null
      },
      {
        "id": 4604,
        "name": "Karlovy Vary",
        "state_code": "412",
        "type": null
      },
      {
        "id": 4586,
        "name": "Karviná",
        "state_code": "803",
        "type": null
      },
      {
        "id": 4631,
        "name": "Kladno",
        "state_code": "203",
        "type": null
      },
      {
        "id": 4591,
        "name": "Klatovy",
        "state_code": "322",
        "type": null
      },
      {
        "id": 4618,
        "name": "Kolín",
        "state_code": "204",
        "type": null
      },
      {
        "id": 4575,
        "name": "Kraj Vysočina",
        "state_code": "63",
        "type": null
      },
      {
        "id": 4614,
        "name": "Královéhradecký kraj",
        "state_code": "52",
        "type": null
      },
      {
        "id": 4593,
        "name": "Kroměříž",
        "state_code": "721",
        "type": null
      },
      {
        "id": 4923,
        "name": "Kutná Hora",
        "state_code": "205",
        "type": null
      },
      {
        "id": 4590,
        "name": "Liberec",
        "state_code": "513",
        "type": null
      },
      {
        "id": 4601,
        "name": "Liberecký kraj",
        "state_code": "51",
        "type": null
      },
      {
        "id": 4605,
        "name": "Litoměřice",
        "state_code": "423",
        "type": null
      },
      {
        "id": 4617,
        "name": "Louny",
        "state_code": "424",
        "type": null
      },
      {
        "id": 4638,
        "name": "Mělník",
        "state_code": "206",
        "type": null
      },
      {
        "id": 4643,
        "name": "Mladá Boleslav",
        "state_code": "207",
        "type": null
      },
      {
        "id": 4600,
        "name": "Moravskoslezský kraj",
        "state_code": "80",
        "type": null
      },
      {
        "id": 4629,
        "name": "Most",
        "state_code": "425",
        "type": null
      },
      {
        "id": 4550,
        "name": "Náchod",
        "state_code": "523",
        "type": null
      },
      {
        "id": 4548,
        "name": "Nový Jičín",
        "state_code": "804",
        "type": null
      },
      {
        "id": 4582,
        "name": "Nymburk",
        "state_code": "208",
        "type": null
      },
      {
        "id": 4574,
        "name": "Olomouc",
        "state_code": "712",
        "type": null
      },
      {
        "id": 4589,
        "name": "Olomoucký kraj",
        "state_code": "71",
        "type": null
      },
      {
        "id": 4623,
        "name": "Opava",
        "state_code": "805",
        "type": null
      },
      {
        "id": 4584,
        "name": "Ostrava-město",
        "state_code": "806",
        "type": null
      },
      {
        "id": 4547,
        "name": "Pardubice",
        "state_code": "532",
        "type": null
      },
      {
        "id": 4588,
        "name": "Pardubický kraj",
        "state_code": "53",
        "type": null
      },
      {
        "id": 4645,
        "name": "Pelhřimov",
        "state_code": "633",
        "type": null
      },
      {
        "id": 4560,
        "name": "Písek",
        "state_code": "314",
        "type": null
      },
      {
        "id": 4608,
        "name": "Plzeň-jih",
        "state_code": "324",
        "type": null
      },
      {
        "id": 4544,
        "name": "Plzeň-město",
        "state_code": "323",
        "type": null
      },
      {
        "id": 4564,
        "name": "Plzeň-sever",
        "state_code": "325",
        "type": null
      },
      {
        "id": 4607,
        "name": "Plzeňský kraj",
        "state_code": "32",
        "type": null
      },
      {
        "id": 4578,
        "name": "Prachatice",
        "state_code": "315",
        "type": null
      },
      {
        "id": 4606,
        "name": "Praha-východ",
        "state_code": "209",
        "type": null
      },
      {
        "id": 4619,
        "name": "Praha-západ",
        "state_code": "20A",
        "type": null
      },
      {
        "id": 4598,
        "name": "Praha, Hlavní město",
        "state_code": "10",
        "type": null
      },
      {
        "id": 4626,
        "name": "Přerov",
        "state_code": "714",
        "type": null
      },
      {
        "id": 4546,
        "name": "Příbram",
        "state_code": "20B",
        "type": null
      },
      {
        "id": 4551,
        "name": "Prostějov",
        "state_code": "713",
        "type": null
      },
      {
        "id": 4558,
        "name": "Rakovník",
        "state_code": "20C",
        "type": null
      },
      {
        "id": 4583,
        "name": "Rokycany",
        "state_code": "326",
        "type": null
      },
      {
        "id": 4636,
        "name": "Rychnov nad Kněžnou",
        "state_code": "524",
        "type": null
      },
      {
        "id": 4596,
        "name": "Semily",
        "state_code": "514",
        "type": null
      },
      {
        "id": 4595,
        "name": "Sokolov",
        "state_code": "413",
        "type": null
      },
      {
        "id": 4628,
        "name": "Strakonice",
        "state_code": "316",
        "type": null
      },
      {
        "id": 4554,
        "name": "Středočeský kraj",
        "state_code": "20",
        "type": null
      },
      {
        "id": 4642,
        "name": "Šumperk",
        "state_code": "715",
        "type": null
      },
      {
        "id": 4571,
        "name": "Svitavy",
        "state_code": "533",
        "type": null
      },
      {
        "id": 4565,
        "name": "Tábor",
        "state_code": "317",
        "type": null
      },
      {
        "id": 4646,
        "name": "Tachov",
        "state_code": "327",
        "type": null
      },
      {
        "id": 4621,
        "name": "Teplice",
        "state_code": "426",
        "type": null
      },
      {
        "id": 4597,
        "name": "Třebíč",
        "state_code": "634",
        "type": null
      },
      {
        "id": 4579,
        "name": "Trutnov",
        "state_code": "525",
        "type": null
      },
      {
        "id": 4592,
        "name": "Uherské Hradiště",
        "state_code": "722",
        "type": null
      },
      {
        "id": 4576,
        "name": "Ústecký kraj",
        "state_code": "42",
        "type": null
      },
      {
        "id": 4599,
        "name": "Ústí nad Labem",
        "state_code": "427",
        "type": null
      },
      {
        "id": 4647,
        "name": "Ústí nad Orlicí",
        "state_code": "534",
        "type": null
      },
      {
        "id": 4572,
        "name": "Vsetín",
        "state_code": "723",
        "type": null
      },
      {
        "id": 4622,
        "name": "Vyškov",
        "state_code": "646",
        "type": null
      },
      {
        "id": 4648,
        "name": "Žďár nad Sázavou",
        "state_code": "635",
        "type": null
      },
      {
        "id": 4563,
        "name": "Zlín",
        "state_code": "724",
        "type": null
      },
      {
        "id": 4552,
        "name": "Zlínský kraj",
        "state_code": "72",
        "type": null
      },
      {
        "id": 4630,
        "name": "Znojmo",
        "state_code": "647",
        "type": null
      }
    ]
  },
  {
    "name": "Democratic Republic of the Congo",
    "iso3": "COD",
    "iso2": "CD",
    "numeric_code": "180",
    "phone_code": "243",
    "capital": "Kinshasa",
    "currency": "CDF",
    "currency_name": "Congolese Franc",
    "currency_symbol": "FC",
    "tld": ".cd",
    "native": "République démocratique du Congo",
    "region": "Africa",
    "subregion": "Middle Africa",
    "emoji": "🇨🇩",
    "emojiU": "U+1F1E8 U+1F1E9",
    "states": [
      {
        "id": 2753,
        "name": "Bas-Uélé",
        "state_code": "BU",
        "type": null
      },
      {
        "id": 2744,
        "name": "Équateur",
        "state_code": "EQ",
        "type": null
      },
      {
        "id": 2750,
        "name": "Haut-Katanga",
        "state_code": "HK",
        "type": null
      },
      {
        "id": 2758,
        "name": "Haut-Lomami",
        "state_code": "HL",
        "type": null
      },
      {
        "id": 2734,
        "name": "Haut-Uélé",
        "state_code": "HU",
        "type": null
      },
      {
        "id": 2751,
        "name": "Ituri",
        "state_code": "IT",
        "type": null
      },
      {
        "id": 2757,
        "name": "Kasaï",
        "state_code": "KS",
        "type": null
      },
      {
        "id": 2742,
        "name": "Kasaï Central",
        "state_code": "KC",
        "type": null
      },
      {
        "id": 2735,
        "name": "Kasaï Oriental",
        "state_code": "KE",
        "type": null
      },
      {
        "id": 2741,
        "name": "Kinshasa",
        "state_code": "KN",
        "type": null
      },
      {
        "id": 2746,
        "name": "Kongo Central",
        "state_code": "BC",
        "type": null
      },
      {
        "id": 2740,
        "name": "Kwango",
        "state_code": "KG",
        "type": null
      },
      {
        "id": 2759,
        "name": "Kwilu",
        "state_code": "KL",
        "type": null
      },
      {
        "id": 2747,
        "name": "Lomami",
        "state_code": "LO",
        "type": null
      },
      {
        "id": 4953,
        "name": "Lualaba",
        "state_code": "LU",
        "type": null
      },
      {
        "id": 2755,
        "name": "Mai-Ndombe",
        "state_code": "MN",
        "type": null
      },
      {
        "id": 2745,
        "name": "Maniema",
        "state_code": "MA",
        "type": null
      },
      {
        "id": 2752,
        "name": "Mongala",
        "state_code": "MO",
        "type": null
      },
      {
        "id": 2749,
        "name": "Nord-Kivu",
        "state_code": "NK",
        "type": null
      },
      {
        "id": 2739,
        "name": "Nord-Ubangi",
        "state_code": "NU",
        "type": null
      },
      {
        "id": 2743,
        "name": "Sankuru",
        "state_code": "SA",
        "type": null
      },
      {
        "id": 2738,
        "name": "Sud-Kivu",
        "state_code": "SK",
        "type": null
      },
      {
        "id": 2748,
        "name": "Sud-Ubangi",
        "state_code": "SU",
        "type": null
      },
      {
        "id": 2733,
        "name": "Tanganyika",
        "state_code": "TA",
        "type": null
      },
      {
        "id": 2756,
        "name": "Tshopo",
        "state_code": "TO",
        "type": null
      },
      {
        "id": 2732,
        "name": "Tshuapa",
        "state_code": "TU",
        "type": null
      }
    ]
  },
  {
    "name": "Denmark",
    "iso3": "DNK",
    "iso2": "DK",
    "numeric_code": "208",
    "phone_code": "45",
    "capital": "Copenhagen",
    "currency": "DKK",
    "currency_name": "Danish krone",
    "currency_symbol": "Kr.",
    "tld": ".dk",
    "native": "Danmark",
    "region": "Europe",
    "subregion": "Northern Europe",
    "emoji": "🇩🇰",
    "emojiU": "U+1F1E9 U+1F1F0",
    "states": [
      {
        "id": 1530,
        "name": "Capital Region of Denmark",
        "state_code": "84",
        "type": null
      },
      {
        "id": 1531,
        "name": "Central Denmark Region",
        "state_code": "82",
        "type": null
      },
      {
        "id": 1532,
        "name": "North Denmark Region",
        "state_code": "81",
        "type": null
      },
      {
        "id": 1529,
        "name": "Region of Southern Denmark",
        "state_code": "83",
        "type": null
      },
      {
        "id": 1528,
        "name": "Region Zealand",
        "state_code": "85",
        "type": null
      }
    ]
  },
  {
    "name": "Djibouti",
    "iso3": "DJI",
    "iso2": "DJ",
    "numeric_code": "262",
    "phone_code": "253",
    "capital": "Djibouti",
    "currency": "DJF",
    "currency_name": "Djiboutian franc",
    "currency_symbol": "Fdj",
    "tld": ".dj",
    "native": "Djibouti",
    "region": "Africa",
    "subregion": "Eastern Africa",
    "emoji": "🇩🇯",
    "emojiU": "U+1F1E9 U+1F1EF",
    "states": [
      {
        "id": 2933,
        "name": "Ali Sabieh Region",
        "state_code": "AS",
        "type": null
      },
      {
        "id": 2932,
        "name": "Arta Region",
        "state_code": "AR",
        "type": null
      },
      {
        "id": 2930,
        "name": "Dikhil Region",
        "state_code": "DI",
        "type": null
      },
      {
        "id": 2929,
        "name": "Djibouti",
        "state_code": "DJ",
        "type": null
      },
      {
        "id": 2928,
        "name": "Obock Region",
        "state_code": "OB",
        "type": null
      },
      {
        "id": 2931,
        "name": "Tadjourah Region",
        "state_code": "TA",
        "type": null
      }
    ]
  },
  {
    "name": "Dominica",
    "iso3": "DMA",
    "iso2": "DM",
    "numeric_code": "212",
    "phone_code": "+1-767",
    "capital": "Roseau",
    "currency": "XCD",
    "currency_name": "Eastern Caribbean dollar",
    "currency_symbol": "$",
    "tld": ".dm",
    "native": "Dominica",
    "region": "Americas",
    "subregion": "Caribbean",
    "emoji": "🇩🇲",
    "emojiU": "U+1F1E9 U+1F1F2",
    "states": [
      {
        "id": 4082,
        "name": "Saint Andrew Parish",
        "state_code": "02",
        "type": null
      },
      {
        "id": 4078,
        "name": "Saint David Parish",
        "state_code": "03",
        "type": null
      },
      {
        "id": 4079,
        "name": "Saint George Parish",
        "state_code": "04",
        "type": null
      },
      {
        "id": 4076,
        "name": "Saint John Parish",
        "state_code": "05",
        "type": null
      },
      {
        "id": 4085,
        "name": "Saint Joseph Parish",
        "state_code": "06",
        "type": null
      },
      {
        "id": 4083,
        "name": "Saint Luke Parish",
        "state_code": "07",
        "type": null
      },
      {
        "id": 4077,
        "name": "Saint Mark Parish",
        "state_code": "08",
        "type": null
      },
      {
        "id": 4080,
        "name": "Saint Patrick Parish",
        "state_code": "09",
        "type": null
      },
      {
        "id": 4084,
        "name": "Saint Paul Parish",
        "state_code": "10",
        "type": null
      },
      {
        "id": 4081,
        "name": "Saint Peter Parish",
        "state_code": "11",
        "type": null
      }
    ]
  },
  {
    "name": "Dominican Republic",
    "iso3": "DOM",
    "iso2": "DO",
    "numeric_code": "214",
    "phone_code": "+1-809 and 1-829",
    "capital": "Santo Domingo",
    "currency": "DOP",
    "currency_name": "Dominican peso",
    "currency_symbol": "$",
    "tld": ".do",
    "native": "República Dominicana",
    "region": "Americas",
    "subregion": "Caribbean",
    "emoji": "🇩🇴",
    "emojiU": "U+1F1E9 U+1F1F4",
    "states": [
      {
        "id": 4114,
        "name": "Azua Province",
        "state_code": "02",
        "type": null
      },
      {
        "id": 4105,
        "name": "Baoruco Province",
        "state_code": "03",
        "type": null
      },
      {
        "id": 4090,
        "name": "Barahona Province",
        "state_code": "04",
        "type": null
      },
      {
        "id": 4107,
        "name": "Dajabón Province",
        "state_code": "05",
        "type": null
      },
      {
        "id": 4095,
        "name": "Distrito Nacional",
        "state_code": "01",
        "type": null
      },
      {
        "id": 4113,
        "name": "Duarte Province",
        "state_code": "06",
        "type": null
      },
      {
        "id": 4086,
        "name": "El Seibo Province",
        "state_code": "08",
        "type": null
      },
      {
        "id": 4102,
        "name": "Espaillat Province",
        "state_code": "09",
        "type": null
      },
      {
        "id": 4106,
        "name": "Hato Mayor Province",
        "state_code": "30",
        "type": null
      },
      {
        "id": 4089,
        "name": "Hermanas Mirabal Province",
        "state_code": "19",
        "type": null
      },
      {
        "id": 4097,
        "name": "Independencia",
        "state_code": "10",
        "type": null
      },
      {
        "id": 4109,
        "name": "La Altagracia Province",
        "state_code": "11",
        "type": null
      },
      {
        "id": 4087,
        "name": "La Romana Province",
        "state_code": "12",
        "type": null
      },
      {
        "id": 4116,
        "name": "La Vega Province",
        "state_code": "13",
        "type": null
      },
      {
        "id": 4094,
        "name": "María Trinidad Sánchez Province",
        "state_code": "14",
        "type": null
      },
      {
        "id": 4099,
        "name": "Monseñor Nouel Province",
        "state_code": "28",
        "type": null
      },
      {
        "id": 4115,
        "name": "Monte Cristi Province",
        "state_code": "15",
        "type": null
      },
      {
        "id": 4111,
        "name": "Monte Plata Province",
        "state_code": "29",
        "type": null
      },
      {
        "id": 4101,
        "name": "Pedernales Province",
        "state_code": "16",
        "type": null
      },
      {
        "id": 4096,
        "name": "Peravia Province",
        "state_code": "17",
        "type": null
      },
      {
        "id": 4092,
        "name": "Puerto Plata Province",
        "state_code": "18",
        "type": null
      },
      {
        "id": 4103,
        "name": "Samaná Province",
        "state_code": "20",
        "type": null
      },
      {
        "id": 4091,
        "name": "San Cristóbal Province",
        "state_code": "21",
        "type": null
      },
      {
        "id": 4112,
        "name": "San José de Ocoa Province",
        "state_code": "31",
        "type": null
      },
      {
        "id": 4098,
        "name": "San Juan Province",
        "state_code": "22",
        "type": null
      },
      {
        "id": 4110,
        "name": "San Pedro de Macorís",
        "state_code": "23",
        "type": null
      },
      {
        "id": 4088,
        "name": "Sánchez Ramírez Province",
        "state_code": "24",
        "type": null
      },
      {
        "id": 4108,
        "name": "Santiago Province",
        "state_code": "25",
        "type": null
      },
      {
        "id": 4100,
        "name": "Santiago Rodríguez Province",
        "state_code": "26",
        "type": null
      },
      {
        "id": 4093,
        "name": "Santo Domingo Province",
        "state_code": "32",
        "type": null
      },
      {
        "id": 4104,
        "name": "Valverde Province",
        "state_code": "27",
        "type": null
      }
    ]
  },
  {
    "name": "East Timor",
    "iso3": "TLS",
    "iso2": "TL",
    "numeric_code": "626",
    "phone_code": "670",
    "capital": "Dili",
    "currency": "USD",
    "currency_name": "United States dollar",
    "currency_symbol": "$",
    "tld": ".tl",
    "native": "Timor-Leste",
    "region": "Asia",
    "subregion": "South-Eastern Asia",
    "emoji": "🇹🇱",
    "emojiU": "U+1F1F9 U+1F1F1",
    "states": [
      {
        "id": 4520,
        "name": "Aileu municipality",
        "state_code": "AL",
        "type": null
      },
      {
        "id": 4518,
        "name": "Ainaro Municipality",
        "state_code": "AN",
        "type": null
      },
      {
        "id": 4521,
        "name": "Baucau Municipality",
        "state_code": "BA",
        "type": null
      },
      {
        "id": 4525,
        "name": "Bobonaro Municipality",
        "state_code": "BO",
        "type": null
      },
      {
        "id": 4522,
        "name": "Cova Lima Municipality",
        "state_code": "CO",
        "type": null
      },
      {
        "id": 4524,
        "name": "Dili municipality",
        "state_code": "DI",
        "type": null
      },
      {
        "id": 4516,
        "name": "Ermera District",
        "state_code": "ER",
        "type": null
      },
      {
        "id": 4523,
        "name": "Lautém Municipality",
        "state_code": "LA",
        "type": null
      },
      {
        "id": 4515,
        "name": "Liquiçá Municipality",
        "state_code": "LI",
        "type": null
      },
      {
        "id": 4517,
        "name": "Manatuto District",
        "state_code": "MT",
        "type": null
      },
      {
        "id": 4519,
        "name": "Manufahi Municipality",
        "state_code": "MF",
        "type": null
      },
      {
        "id": 4514,
        "name": "Viqueque Municipality",
        "state_code": "VI",
        "type": null
      }
    ]
  },
  {
    "name": "Ecuador",
    "iso3": "ECU",
    "iso2": "EC",
    "numeric_code": "218",
    "phone_code": "593",
    "capital": "Quito",
    "currency": "USD",
    "currency_name": "United States dollar",
    "currency_symbol": "$",
    "tld": ".ec",
    "native": "Ecuador",
    "region": "Americas",
    "subregion": "South America",
    "emoji": "🇪🇨",
    "emojiU": "U+1F1EA U+1F1E8",
    "states": [
      {
        "id": 2923,
        "name": "Azuay Province",
        "state_code": "A",
        "type": null
      },
      {
        "id": 2920,
        "name": "Bolívar Province",
        "state_code": "B",
        "type": null
      },
      {
        "id": 2917,
        "name": "Cañar Province",
        "state_code": "F",
        "type": null
      },
      {
        "id": 2915,
        "name": "Carchi Province",
        "state_code": "C",
        "type": null
      },
      {
        "id": 2925,
        "name": "Chimborazo Province",
        "state_code": "H",
        "type": null
      },
      {
        "id": 2921,
        "name": "Cotopaxi Province",
        "state_code": "X",
        "type": null
      },
      {
        "id": 2924,
        "name": "El Oro Province",
        "state_code": "O",
        "type": null
      },
      {
        "id": 2922,
        "name": "Esmeraldas",
        "state_code": "E",
        "type": null
      },
      {
        "id": 2905,
        "name": "Galápagos Province",
        "state_code": "W",
        "type": null
      },
      {
        "id": 2914,
        "name": "Guayas Province",
        "state_code": "G",
        "type": null
      },
      {
        "id": 2911,
        "name": "Imbabura Province",
        "state_code": "I",
        "type": null
      },
      {
        "id": 2910,
        "name": "Los Ríos Province",
        "state_code": "R",
        "type": null
      },
      {
        "id": 2913,
        "name": "Manabí Province",
        "state_code": "M",
        "type": null
      },
      {
        "id": 2918,
        "name": "Morona-Santiago Province",
        "state_code": "S",
        "type": null
      },
      {
        "id": 2916,
        "name": "Napo Province",
        "state_code": "N",
        "type": null
      },
      {
        "id": 2926,
        "name": "Orellana Province",
        "state_code": "D",
        "type": null
      },
      {
        "id": 2907,
        "name": "Pastaza Province",
        "state_code": "Y",
        "type": null
      },
      {
        "id": 2927,
        "name": "Pichincha Province",
        "state_code": "P",
        "type": null
      },
      {
        "id": 2912,
        "name": "Santa Elena Province",
        "state_code": "SE",
        "type": null
      },
      {
        "id": 2919,
        "name": "Santo Domingo de los Tsáchilas Province",
        "state_code": "SD",
        "type": null
      },
      {
        "id": 2906,
        "name": "Sucumbíos Province",
        "state_code": "U",
        "type": null
      },
      {
        "id": 2908,
        "name": "Tungurahua Province",
        "state_code": "T",
        "type": null
      },
      {
        "id": 2909,
        "name": "Zamora-Chinchipe Province",
        "state_code": "Z",
        "type": null
      }
    ]
  },
  {
    "name": "Egypt",
    "iso3": "EGY",
    "iso2": "EG",
    "numeric_code": "818",
    "phone_code": "20",
    "capital": "Cairo",
    "currency": "EGP",
    "currency_name": "Egyptian pound",
    "currency_symbol": "ج.م",
    "tld": ".eg",
    "native": "مصر‎",
    "region": "Africa",
    "subregion": "Northern Africa",
    "emoji": "🇪🇬",
    "emojiU": "U+1F1EA U+1F1EC",
    "states": [
      {
        "id": 3235,
        "name": "Alexandria Governorate",
        "state_code": "ALX",
        "type": null
      },
      {
        "id": 3225,
        "name": "Aswan Governorate",
        "state_code": "ASN",
        "type": null
      },
      {
        "id": 3236,
        "name": "Asyut Governorate",
        "state_code": "AST",
        "type": null
      },
      {
        "id": 3241,
        "name": "Beheira Governorate",
        "state_code": "BH",
        "type": null
      },
      {
        "id": 3230,
        "name": "Beni Suef Governorate",
        "state_code": "BNS",
        "type": null
      },
      {
        "id": 3223,
        "name": "Cairo Governorate",
        "state_code": "C",
        "type": null
      },
      {
        "id": 3245,
        "name": "Dakahlia Governorate",
        "state_code": "DK",
        "type": null
      },
      {
        "id": 3224,
        "name": "Damietta Governorate",
        "state_code": "DT",
        "type": null
      },
      {
        "id": 3238,
        "name": "Faiyum Governorate",
        "state_code": "FYM",
        "type": null
      },
      {
        "id": 3234,
        "name": "Gharbia Governorate",
        "state_code": "GH",
        "type": null
      },
      {
        "id": 3239,
        "name": "Giza Governorate",
        "state_code": "GZ",
        "type": null
      },
      {
        "id": 3244,
        "name": "Ismailia Governorate",
        "state_code": "IS",
        "type": null
      },
      {
        "id": 3222,
        "name": "Kafr el-Sheikh Governorate",
        "state_code": "KFS",
        "type": null
      },
      {
        "id": 3242,
        "name": "Luxor Governorate",
        "state_code": "LX",
        "type": null
      },
      {
        "id": 3231,
        "name": "Matrouh Governorate",
        "state_code": "MT",
        "type": null
      },
      {
        "id": 3243,
        "name": "Minya Governorate",
        "state_code": "MN",
        "type": null
      },
      {
        "id": 3228,
        "name": "Monufia Governorate",
        "state_code": "MNF",
        "type": null
      },
      {
        "id": 3246,
        "name": "New Valley Governorate",
        "state_code": "WAD",
        "type": null
      },
      {
        "id": 3227,
        "name": "North Sinai Governorate",
        "state_code": "SIN",
        "type": null
      },
      {
        "id": 3229,
        "name": "Port Said Governorate",
        "state_code": "PTS",
        "type": null
      },
      {
        "id": 3232,
        "name": "Qalyubia Governorate",
        "state_code": "KB",
        "type": null
      },
      {
        "id": 3247,
        "name": "Qena Governorate",
        "state_code": "KN",
        "type": null
      },
      {
        "id": 3240,
        "name": "Red Sea Governorate",
        "state_code": "BA",
        "type": null
      },
      {
        "id": 3226,
        "name": "Sohag Governorate",
        "state_code": "SHG",
        "type": null
      },
      {
        "id": 3237,
        "name": "South Sinai Governorate",
        "state_code": "JS",
        "type": null
      },
      {
        "id": 3233,
        "name": "Suez Governorate",
        "state_code": "SUZ",
        "type": null
      }
    ]
  },
  {
    "name": "El Salvador",
    "iso3": "SLV",
    "iso2": "SV",
    "numeric_code": "222",
    "phone_code": "503",
    "capital": "San Salvador",
    "currency": "USD",
    "currency_name": "United States dollar",
    "currency_symbol": "$",
    "tld": ".sv",
    "native": "El Salvador",
    "region": "Americas",
    "subregion": "Central America",
    "emoji": "🇸🇻",
    "emojiU": "U+1F1F8 U+1F1FB",
    "states": [
      {
        "id": 4139,
        "name": "Ahuachapán Department",
        "state_code": "AH",
        "type": null
      },
      {
        "id": 4132,
        "name": "Cabañas Department",
        "state_code": "CA",
        "type": null
      },
      {
        "id": 4131,
        "name": "Chalatenango Department",
        "state_code": "CH",
        "type": null
      },
      {
        "id": 4137,
        "name": "Cuscatlán Department",
        "state_code": "CU",
        "type": null
      },
      {
        "id": 4134,
        "name": "La Libertad Department",
        "state_code": "LI",
        "type": null
      },
      {
        "id": 4136,
        "name": "La Paz Department",
        "state_code": "PA",
        "type": null
      },
      {
        "id": 4138,
        "name": "La Unión Department",
        "state_code": "UN",
        "type": null
      },
      {
        "id": 4130,
        "name": "Morazán Department",
        "state_code": "MO",
        "type": null
      },
      {
        "id": 4135,
        "name": "San Miguel Department",
        "state_code": "SM",
        "type": null
      },
      {
        "id": 4133,
        "name": "San Salvador Department",
        "state_code": "SS",
        "type": null
      },
      {
        "id": 4127,
        "name": "San Vicente Department",
        "state_code": "SV",
        "type": null
      },
      {
        "id": 4128,
        "name": "Santa Ana Department",
        "state_code": "SA",
        "type": null
      },
      {
        "id": 4140,
        "name": "Sonsonate Department",
        "state_code": "SO",
        "type": null
      },
      {
        "id": 4129,
        "name": "Usulután Department",
        "state_code": "US",
        "type": null
      }
    ]
  },
  {
    "name": "Equatorial Guinea",
    "iso3": "GNQ",
    "iso2": "GQ",
    "numeric_code": "226",
    "phone_code": "240",
    "capital": "Malabo",
    "currency": "XAF",
    "currency_name": "Central African CFA franc",
    "currency_symbol": "FCFA",
    "tld": ".gq",
    "native": "Guinea Ecuatorial",
    "region": "Africa",
    "subregion": "Middle Africa",
    "emoji": "🇬🇶",
    "emojiU": "U+1F1EC U+1F1F6",
    "states": [
      {
        "id": 3444,
        "name": "Annobón Province",
        "state_code": "AN",
        "type": null
      },
      {
        "id": 3446,
        "name": "Bioko Norte Province",
        "state_code": "BN",
        "type": null
      },
      {
        "id": 3443,
        "name": "Bioko Sur Province",
        "state_code": "BS",
        "type": null
      },
      {
        "id": 3445,
        "name": "Centro Sur Province",
        "state_code": "CS",
        "type": null
      },
      {
        "id": 3442,
        "name": "Insular Region",
        "state_code": "I",
        "type": null
      },
      {
        "id": 3439,
        "name": "Kié-Ntem Province",
        "state_code": "KN",
        "type": null
      },
      {
        "id": 3441,
        "name": "Litoral Province",
        "state_code": "LI",
        "type": null
      },
      {
        "id": 3438,
        "name": "Río Muni",
        "state_code": "C",
        "type": null
      },
      {
        "id": 3440,
        "name": "Wele-Nzas Province",
        "state_code": "WN",
        "type": null
      }
    ]
  },
  {
    "name": "Eritrea",
    "iso3": "ERI",
    "iso2": "ER",
    "numeric_code": "232",
    "phone_code": "291",
    "capital": "Asmara",
    "currency": "ERN",
    "currency_name": "Eritrean nakfa",
    "currency_symbol": "Nfk",
    "tld": ".er",
    "native": "ኤርትራ",
    "region": "Africa",
    "subregion": "Eastern Africa",
    "emoji": "🇪🇷",
    "emojiU": "U+1F1EA U+1F1F7",
    "states": [
      {
        "id": 3425,
        "name": "Anseba Region",
        "state_code": "AN",
        "type": null
      },
      {
        "id": 3427,
        "name": "Debub Region",
        "state_code": "DU",
        "type": null
      },
      {
        "id": 3428,
        "name": "Gash-Barka Region",
        "state_code": "GB",
        "type": null
      },
      {
        "id": 3426,
        "name": "Maekel Region",
        "state_code": "MA",
        "type": null
      },
      {
        "id": 3424,
        "name": "Northern Red Sea Region",
        "state_code": "SK",
        "type": null
      },
      {
        "id": 3429,
        "name": "Southern Red Sea Region",
        "state_code": "DK",
        "type": null
      }
    ]
  },
  {
    "name": "Estonia",
    "iso3": "EST",
    "iso2": "EE",
    "numeric_code": "233",
    "phone_code": "372",
    "capital": "Tallinn",
    "currency": "EUR",
    "currency_name": "Euro",
    "currency_symbol": "€",
    "tld": ".ee",
    "native": "Eesti",
    "region": "Europe",
    "subregion": "Northern Europe",
    "emoji": "🇪🇪",
    "emojiU": "U+1F1EA U+1F1EA",
    "states": [
      {
        "id": 3567,
        "name": "Harju County",
        "state_code": "37",
        "type": null
      },
      {
        "id": 3555,
        "name": "Hiiu County",
        "state_code": "39",
        "type": null
      },
      {
        "id": 3569,
        "name": "Ida-Viru County",
        "state_code": "44",
        "type": null
      },
      {
        "id": 3566,
        "name": "Järva County",
        "state_code": "51",
        "type": null
      },
      {
        "id": 3565,
        "name": "Jõgeva County",
        "state_code": "49",
        "type": null
      },
      {
        "id": 3568,
        "name": "Lääne County",
        "state_code": "57",
        "type": null
      },
      {
        "id": 3564,
        "name": "Lääne-Viru County",
        "state_code": "59",
        "type": null
      },
      {
        "id": 3562,
        "name": "Pärnu County",
        "state_code": "67",
        "type": null
      },
      {
        "id": 3563,
        "name": "Põlva County",
        "state_code": "65",
        "type": null
      },
      {
        "id": 3559,
        "name": "Rapla County",
        "state_code": "70",
        "type": null
      },
      {
        "id": 3561,
        "name": "Saare County",
        "state_code": "74",
        "type": null
      },
      {
        "id": 3557,
        "name": "Tartu County",
        "state_code": "78",
        "type": null
      },
      {
        "id": 3558,
        "name": "Valga County",
        "state_code": "82",
        "type": null
      },
      {
        "id": 3556,
        "name": "Viljandi County",
        "state_code": "84",
        "type": null
      },
      {
        "id": 3560,
        "name": "Võru County",
        "state_code": "86",
        "type": null
      }
    ]
  },
  {
    "name": "Ethiopia",
    "iso3": "ETH",
    "iso2": "ET",
    "numeric_code": "231",
    "phone_code": "251",
    "capital": "Addis Ababa",
    "currency": "ETB",
    "currency_name": "Ethiopian birr",
    "currency_symbol": "Nkf",
    "tld": ".et",
    "native": "ኢትዮጵያ",
    "region": "Africa",
    "subregion": "Eastern Africa",
    "emoji": "🇪🇹",
    "emojiU": "U+1F1EA U+1F1F9",
    "states": [
      {
        "id": 11,
        "name": "Addis Ababa",
        "state_code": "AA",
        "type": null
      },
      {
        "id": 6,
        "name": "Afar Region",
        "state_code": "AF",
        "type": null
      },
      {
        "id": 3,
        "name": "Amhara Region",
        "state_code": "AM",
        "type": null
      },
      {
        "id": 9,
        "name": "Benishangul-Gumuz Region",
        "state_code": "BE",
        "type": null
      },
      {
        "id": 8,
        "name": "Dire Dawa",
        "state_code": "DD",
        "type": null
      },
      {
        "id": 10,
        "name": "Gambela Region",
        "state_code": "GA",
        "type": null
      },
      {
        "id": 7,
        "name": "Harari Region",
        "state_code": "HA",
        "type": null
      },
      {
        "id": 5,
        "name": "Oromia Region",
        "state_code": "OR",
        "type": null
      },
      {
        "id": 2,
        "name": "Somali Region",
        "state_code": "SO",
        "type": null
      },
      {
        "id": 1,
        "name": "Southern Nations, Nationalities, and Peoples' Region",
        "state_code": "SN",
        "type": null
      },
      {
        "id": 4,
        "name": "Tigray Region",
        "state_code": "TI",
        "type": null
      }
    ]
  },
  {
    "name": "Falkland Islands",
    "iso3": "FLK",
    "iso2": "FK",
    "numeric_code": "238",
    "phone_code": "500",
    "capital": "Stanley",
    "currency": "FKP",
    "currency_name": "Falkland Islands pound",
    "currency_symbol": "£",
    "tld": ".fk",
    "native": "Falkland Islands",
    "region": "Americas",
    "subregion": "South America",
    "emoji": "🇫🇰",
    "emojiU": "U+1F1EB U+1F1F0",
    "states": []
  },
  {
    "name": "Faroe Islands",
    "iso3": "FRO",
    "iso2": "FO",
    "numeric_code": "234",
    "phone_code": "298",
    "capital": "Torshavn",
    "currency": "DKK",
    "currency_name": "Danish krone",
    "currency_symbol": "Kr.",
    "tld": ".fo",
    "native": "Føroyar",
    "region": "Europe",
    "subregion": "Northern Europe",
    "emoji": "🇫🇴",
    "emojiU": "U+1F1EB U+1F1F4",
    "states": []
  },
  {
    "name": "Fiji Islands",
    "iso3": "FJI",
    "iso2": "FJ",
    "numeric_code": "242",
    "phone_code": "679",
    "capital": "Suva",
    "currency": "FJD",
    "currency_name": "Fijian dollar",
    "currency_symbol": "FJ$",
    "tld": ".fj",
    "native": "Fiji",
    "region": "Oceania",
    "subregion": "Melanesia",
    "emoji": "🇫🇯",
    "emojiU": "U+1F1EB U+1F1EF",
    "states": [
      {
        "id": 1917,
        "name": "Ba",
        "state_code": "01",
        "type": null
      },
      {
        "id": 1930,
        "name": "Bua",
        "state_code": "02",
        "type": null
      },
      {
        "id": 1924,
        "name": "Cakaudrove",
        "state_code": "03",
        "type": null
      },
      {
        "id": 1929,
        "name": "Central Division",
        "state_code": "C",
        "type": null
      },
      {
        "id": 1932,
        "name": "Eastern Division",
        "state_code": "E",
        "type": null
      },
      {
        "id": 1934,
        "name": "Kadavu",
        "state_code": "04",
        "type": null
      },
      {
        "id": 1933,
        "name": "Lau",
        "state_code": "05",
        "type": null
      },
      {
        "id": 1916,
        "name": "Lomaiviti",
        "state_code": "06",
        "type": null
      },
      {
        "id": 1922,
        "name": "Macuata",
        "state_code": "07",
        "type": null
      },
      {
        "id": 1919,
        "name": "Nadroga-Navosa",
        "state_code": "08",
        "type": null
      },
      {
        "id": 1927,
        "name": "Naitasiri",
        "state_code": "09",
        "type": null
      },
      {
        "id": 1928,
        "name": "Namosi",
        "state_code": "10",
        "type": null
      },
      {
        "id": 1921,
        "name": "Northern Division",
        "state_code": "N",
        "type": null
      },
      {
        "id": 1926,
        "name": "Ra",
        "state_code": "11",
        "type": null
      },
      {
        "id": 1920,
        "name": "Rewa",
        "state_code": "12",
        "type": null
      },
      {
        "id": 1931,
        "name": "Rotuma",
        "state_code": "R",
        "type": null
      },
      {
        "id": 1925,
        "name": "Serua",
        "state_code": "13",
        "type": null
      },
      {
        "id": 1918,
        "name": "Tailevu",
        "state_code": "14",
        "type": null
      },
      {
        "id": 1923,
        "name": "Western Division",
        "state_code": "W",
        "type": null
      }
    ]
  },
  {
    "name": "Finland",
    "iso3": "FIN",
    "iso2": "FI",
    "numeric_code": "246",
    "phone_code": "358",
    "capital": "Helsinki",
    "currency": "EUR",
    "currency_name": "Euro",
    "currency_symbol": "€",
    "tld": ".fi",
    "native": "Suomi",
    "region": "Europe",
    "subregion": "Northern Europe",
    "emoji": "🇫🇮",
    "emojiU": "U+1F1EB U+1F1EE",
    "states": [
      {
        "id": 1509,
        "name": "Åland Islands",
        "state_code": "01",
        "type": null
      },
      {
        "id": 1511,
        "name": "Central Finland",
        "state_code": "08",
        "type": null
      },
      {
        "id": 1494,
        "name": "Central Ostrobothnia",
        "state_code": "07",
        "type": null
      },
      {
        "id": 1492,
        "name": "Eastern Finland Province",
        "state_code": "IS",
        "type": null
      },
      {
        "id": 1507,
        "name": "Finland Proper",
        "state_code": "19",
        "type": null
      },
      {
        "id": 1496,
        "name": "Kainuu",
        "state_code": "05",
        "type": null
      },
      {
        "id": 1512,
        "name": "Kymenlaakso",
        "state_code": "09",
        "type": null
      },
      {
        "id": 1500,
        "name": "Lapland",
        "state_code": "LL",
        "type": null
      },
      {
        "id": 1504,
        "name": "North Karelia",
        "state_code": "13",
        "type": null
      },
      {
        "id": 1505,
        "name": "Northern Ostrobothnia",
        "state_code": "14",
        "type": null
      },
      {
        "id": 1503,
        "name": "Northern Savonia",
        "state_code": "15",
        "type": null
      },
      {
        "id": 1508,
        "name": "Ostrobothnia",
        "state_code": "12",
        "type": null
      },
      {
        "id": 1499,
        "name": "Oulu Province",
        "state_code": "OL",
        "type": null
      },
      {
        "id": 1502,
        "name": "Päijänne Tavastia",
        "state_code": "16",
        "type": null
      },
      {
        "id": 1506,
        "name": "Pirkanmaa",
        "state_code": "11",
        "type": null
      },
      {
        "id": 1501,
        "name": "Satakunta",
        "state_code": "17",
        "type": null
      },
      {
        "id": 1497,
        "name": "South Karelia",
        "state_code": "02",
        "type": null
      },
      {
        "id": 1498,
        "name": "Southern Ostrobothnia",
        "state_code": "03",
        "type": null
      },
      {
        "id": 1495,
        "name": "Southern Savonia",
        "state_code": "04",
        "type": null
      },
      {
        "id": 1493,
        "name": "Tavastia Proper",
        "state_code": "06",
        "type": null
      },
      {
        "id": 1510,
        "name": "Uusimaa",
        "state_code": "18",
        "type": null
      }
    ]
  },
  {
    "name": "France",
    "iso3": "FRA",
    "iso2": "FR",
    "numeric_code": "250",
    "phone_code": "33",
    "capital": "Paris",
    "currency": "EUR",
    "currency_name": "Euro",
    "currency_symbol": "€",
    "tld": ".fr",
    "native": "France",
    "region": "Europe",
    "subregion": "Western Europe",
    "emoji": "🇫🇷",
    "emojiU": "U+1F1EB U+1F1F7",
    "states": [
      {
        "id": 4967,
        "name": "Ain",
        "state_code": "01",
        "type": "metropolitan department"
      },
      {
        "id": 4968,
        "name": "Aisne",
        "state_code": "02",
        "type": "metropolitan department"
      },
      {
        "id": 4969,
        "name": "Allier",
        "state_code": "03",
        "type": "metropolitan department"
      },
      {
        "id": 4970,
        "name": "Alpes-de-Haute-Provence",
        "state_code": "04",
        "type": "metropolitan department"
      },
      {
        "id": 4972,
        "name": "Alpes-Maritimes",
        "state_code": "06",
        "type": "metropolitan department"
      },
      {
        "id": 4811,
        "name": "Alsace",
        "state_code": "6AE",
        "type": "European collectivity"
      },
      {
        "id": 4973,
        "name": "Ardèche",
        "state_code": "07",
        "type": "metropolitan department"
      },
      {
        "id": 4974,
        "name": "Ardennes",
        "state_code": "08",
        "type": "metropolitan department"
      },
      {
        "id": 4975,
        "name": "Ariège",
        "state_code": "09",
        "type": "metropolitan department"
      },
      {
        "id": 4976,
        "name": "Aube",
        "state_code": "10",
        "type": "metropolitan department"
      },
      {
        "id": 4977,
        "name": "Aude",
        "state_code": "11",
        "type": "metropolitan department"
      },
      {
        "id": 4798,
        "name": "Auvergne-Rhône-Alpes",
        "state_code": "ARA",
        "type": "metropolitan region"
      },
      {
        "id": 4978,
        "name": "Aveyron",
        "state_code": "12",
        "type": "metropolitan department"
      },
      {
        "id": 5035,
        "name": "Bas-Rhin\t",
        "state_code": "67",
        "type": "metropolitan department"
      },
      {
        "id": 4979,
        "name": "Bouches-du-Rhône",
        "state_code": "13",
        "type": "metropolitan department"
      },
      {
        "id": 4825,
        "name": "Bourgogne-Franche-Comté",
        "state_code": "BFC",
        "type": "metropolitan region"
      },
      {
        "id": 4807,
        "name": "Bretagne",
        "state_code": "BRE",
        "type": "metropolitan region"
      },
      {
        "id": 4981,
        "name": "Calvados",
        "state_code": "14",
        "type": "metropolitan department"
      },
      {
        "id": 4982,
        "name": "Cantal",
        "state_code": "15",
        "type": "metropolitan department"
      },
      {
        "id": 4818,
        "name": "Centre-Val de Loire",
        "state_code": "CVL",
        "type": "metropolitan region"
      },
      {
        "id": 4983,
        "name": "Charente",
        "state_code": "16",
        "type": "metropolitan department"
      },
      {
        "id": 4984,
        "name": "Charente-Maritime",
        "state_code": "17",
        "type": "metropolitan department"
      },
      {
        "id": 4985,
        "name": "Cher",
        "state_code": "18",
        "type": "metropolitan department"
      },
      {
        "id": 5064,
        "name": "Clipperton",
        "state_code": "CP",
        "type": "dependency"
      },
      {
        "id": 4986,
        "name": "Corrèze",
        "state_code": "19",
        "type": "metropolitan department"
      },
      {
        "id": 4806,
        "name": "Corse",
        "state_code": "20R",
        "type": "metropolitan collectivity with special status"
      },
      {
        "id": 4996,
        "name": "Corse-du-Sud",
        "state_code": "2A",
        "type": "metropolitan department"
      },
      {
        "id": 4987,
        "name": "Côte-d'Or",
        "state_code": "21",
        "type": "metropolitan department"
      },
      {
        "id": 4988,
        "name": "Côtes-d'Armor",
        "state_code": "22",
        "type": "metropolitan department"
      },
      {
        "id": 4989,
        "name": "Creuse",
        "state_code": "23",
        "type": "metropolitan department"
      },
      {
        "id": 5047,
        "name": "Deux-Sèvres",
        "state_code": "79",
        "type": "metropolitan department"
      },
      {
        "id": 4990,
        "name": "Dordogne",
        "state_code": "24",
        "type": "metropolitan department"
      },
      {
        "id": 4991,
        "name": "Doubs",
        "state_code": "25",
        "type": "metropolitan department"
      },
      {
        "id": 4992,
        "name": "Drôme",
        "state_code": "26",
        "type": "metropolitan department"
      },
      {
        "id": 5059,
        "name": "Essonne",
        "state_code": "91",
        "type": "metropolitan department"
      },
      {
        "id": 4993,
        "name": "Eure",
        "state_code": "27",
        "type": "metropolitan department"
      },
      {
        "id": 4994,
        "name": "Eure-et-Loir",
        "state_code": "28",
        "type": "metropolitan department"
      },
      {
        "id": 4995,
        "name": "Finistère",
        "state_code": "29",
        "type": "metropolitan department"
      },
      {
        "id": 4822,
        "name": "French Guiana",
        "state_code": "973",
        "type": "overseas region"
      },
      {
        "id": 4824,
        "name": "French Polynesia",
        "state_code": "PF",
        "type": "overseas collectivity"
      },
      {
        "id": 5065,
        "name": "French Southern and Antarctic Lands",
        "state_code": "TF",
        "type": "overseas territory"
      },
      {
        "id": 4998,
        "name": "Gard",
        "state_code": "30",
        "type": "metropolitan department"
      },
      {
        "id": 5000,
        "name": "Gers",
        "state_code": "32",
        "type": "metropolitan department"
      },
      {
        "id": 5001,
        "name": "Gironde",
        "state_code": "33",
        "type": "metropolitan department"
      },
      {
        "id": 4820,
        "name": "Grand-Est",
        "state_code": "GES",
        "type": "metropolitan region"
      },
      {
        "id": 4829,
        "name": "Guadeloupe",
        "state_code": "971",
        "type": "overseas region"
      },
      {
        "id": 5036,
        "name": "Haut-Rhin",
        "state_code": "68",
        "type": "metropolitan department"
      },
      {
        "id": 4997,
        "name": "Haute-Corse",
        "state_code": "2B",
        "type": "metropolitan department"
      },
      {
        "id": 4999,
        "name": "Haute-Garonne",
        "state_code": "31",
        "type": "metropolitan department"
      },
      {
        "id": 5011,
        "name": "Haute-Loire",
        "state_code": "43",
        "type": "metropolitan department"
      },
      {
        "id": 5020,
        "name": "Haute-Marne",
        "state_code": "52",
        "type": "metropolitan department"
      },
      {
        "id": 5039,
        "name": "Haute-Saône",
        "state_code": "70",
        "type": "metropolitan department"
      },
      {
        "id": 5043,
        "name": "Haute-Savoie",
        "state_code": "74",
        "type": "metropolitan department"
      },
      {
        "id": 5055,
        "name": "Haute-Vienne\t",
        "state_code": "87",
        "type": "metropolitan department"
      },
      {
        "id": 4971,
        "name": "Hautes-Alpes\t",
        "state_code": "05",
        "type": "metropolitan department"
      },
      {
        "id": 5033,
        "name": "Hautes-Pyrénées",
        "state_code": "65",
        "type": "metropolitan department"
      },
      {
        "id": 4828,
        "name": "Hauts-de-France",
        "state_code": "HDF",
        "type": "metropolitan region"
      },
      {
        "id": 5060,
        "name": "Hauts-de-Seine",
        "state_code": "92",
        "type": "metropolitan department"
      },
      {
        "id": 5002,
        "name": "Hérault",
        "state_code": "34",
        "type": "metropolitan department"
      },
      {
        "id": 4796,
        "name": "Île-de-France",
        "state_code": "IDF",
        "type": "metropolitan region"
      },
      {
        "id": 5003,
        "name": "Ille-et-Vilaine",
        "state_code": "35",
        "type": "metropolitan department"
      },
      {
        "id": 5004,
        "name": "Indre",
        "state_code": "36",
        "type": "metropolitan department"
      },
      {
        "id": 5005,
        "name": "Indre-et-Loire",
        "state_code": "37",
        "type": "metropolitan department"
      },
      {
        "id": 5006,
        "name": "Isère",
        "state_code": "38",
        "type": "metropolitan department"
      },
      {
        "id": 5007,
        "name": "Jura",
        "state_code": "39",
        "type": "metropolitan department"
      },
      {
        "id": 4823,
        "name": "La Réunion",
        "state_code": "974",
        "type": "overseas region"
      },
      {
        "id": 5008,
        "name": "Landes",
        "state_code": "40",
        "type": "metropolitan department"
      },
      {
        "id": 5009,
        "name": "Loir-et-Cher",
        "state_code": "41",
        "type": "metropolitan department"
      },
      {
        "id": 5010,
        "name": "Loire",
        "state_code": "42",
        "type": "metropolitan department"
      },
      {
        "id": 5012,
        "name": "Loire-Atlantique",
        "state_code": "44",
        "type": "metropolitan department"
      },
      {
        "id": 5013,
        "name": "Loiret",
        "state_code": "45",
        "type": "metropolitan department"
      },
      {
        "id": 5014,
        "name": "Lot",
        "state_code": "46",
        "type": "metropolitan department"
      },
      {
        "id": 5015,
        "name": "Lot-et-Garonne",
        "state_code": "47",
        "type": "metropolitan department"
      },
      {
        "id": 5016,
        "name": "Lozère",
        "state_code": "48",
        "type": "metropolitan department"
      },
      {
        "id": 5017,
        "name": "Maine-et-Loire",
        "state_code": "49",
        "type": "metropolitan department"
      },
      {
        "id": 5018,
        "name": "Manche",
        "state_code": "50",
        "type": "metropolitan department"
      },
      {
        "id": 5019,
        "name": "Marne",
        "state_code": "51",
        "type": "metropolitan department"
      },
      {
        "id": 4827,
        "name": "Martinique",
        "state_code": "972",
        "type": "overseas region"
      },
      {
        "id": 5021,
        "name": "Mayenne",
        "state_code": "53",
        "type": "metropolitan department"
      },
      {
        "id": 4797,
        "name": "Mayotte",
        "state_code": "976",
        "type": "overseas region"
      },
      {
        "id": 5038,
        "name": "Métropole de Lyon",
        "state_code": "69M",
        "type": "metropolitan department"
      },
      {
        "id": 5022,
        "name": "Meurthe-et-Moselle",
        "state_code": "54",
        "type": "metropolitan department"
      },
      {
        "id": 5023,
        "name": "Meuse",
        "state_code": "55",
        "type": "metropolitan department"
      },
      {
        "id": 5024,
        "name": "Morbihan",
        "state_code": "56",
        "type": "metropolitan department"
      },
      {
        "id": 5025,
        "name": "Moselle",
        "state_code": "57",
        "type": "metropolitan department"
      },
      {
        "id": 5026,
        "name": "Nièvre",
        "state_code": "58",
        "type": "metropolitan department"
      },
      {
        "id": 5027,
        "name": "Nord",
        "state_code": "59",
        "type": "metropolitan department"
      },
      {
        "id": 4804,
        "name": "Normandie",
        "state_code": "NOR",
        "type": "metropolitan region"
      },
      {
        "id": 4795,
        "name": "Nouvelle-Aquitaine",
        "state_code": "NAQ",
        "type": "metropolitan region"
      },
      {
        "id": 4799,
        "name": "Occitanie",
        "state_code": "OCC",
        "type": "metropolitan region"
      },
      {
        "id": 5028,
        "name": "Oise",
        "state_code": "60",
        "type": "metropolitan department"
      },
      {
        "id": 5029,
        "name": "Orne",
        "state_code": "61",
        "type": "metropolitan department"
      },
      {
        "id": 4816,
        "name": "Paris",
        "state_code": "75C",
        "type": "metropolitan collectivity with special status"
      },
      {
        "id": 5030,
        "name": "Pas-de-Calais",
        "state_code": "62",
        "type": "metropolitan department"
      },
      {
        "id": 4802,
        "name": "Pays-de-la-Loire",
        "state_code": "PDL",
        "type": "metropolitan region"
      },
      {
        "id": 4812,
        "name": "Provence-Alpes-Côte-d’Azur",
        "state_code": "PAC",
        "type": "metropolitan region"
      },
      {
        "id": 5031,
        "name": "Puy-de-Dôme",
        "state_code": "63",
        "type": "metropolitan department"
      },
      {
        "id": 5032,
        "name": "Pyrénées-Atlantiques",
        "state_code": "64",
        "type": "metropolitan department"
      },
      {
        "id": 5034,
        "name": "Pyrénées-Orientales",
        "state_code": "66",
        "type": "metropolitan department"
      },
      {
        "id": 5037,
        "name": "Rhône",
        "state_code": "69",
        "type": "metropolitan department"
      },
      {
        "id": 4821,
        "name": "Saint Pierre and Miquelon",
        "state_code": "PM",
        "type": "overseas collectivity"
      },
      {
        "id": 4794,
        "name": "Saint-Barthélemy",
        "state_code": "BL",
        "type": "overseas collectivity"
      },
      {
        "id": 4809,
        "name": "Saint-Martin",
        "state_code": "MF",
        "type": "overseas collectivity"
      },
      {
        "id": 5040,
        "name": "Saône-et-Loire",
        "state_code": "71",
        "type": "metropolitan department"
      },
      {
        "id": 5041,
        "name": "Sarthe",
        "state_code": "72",
        "type": "metropolitan department"
      },
      {
        "id": 5042,
        "name": "Savoie",
        "state_code": "73",
        "type": "metropolitan department"
      },
      {
        "id": 5045,
        "name": "Seine-et-Marne",
        "state_code": "77",
        "type": "metropolitan department"
      },
      {
        "id": 5044,
        "name": "Seine-Maritime",
        "state_code": "76",
        "type": "metropolitan department"
      },
      {
        "id": 5061,
        "name": "Seine-Saint-Denis",
        "state_code": "93",
        "type": "metropolitan department"
      },
      {
        "id": 5048,
        "name": "Somme",
        "state_code": "80",
        "type": "metropolitan department"
      },
      {
        "id": 5049,
        "name": "Tarn",
        "state_code": "81",
        "type": "metropolitan department"
      },
      {
        "id": 5050,
        "name": "Tarn-et-Garonne",
        "state_code": "82",
        "type": "metropolitan department"
      },
      {
        "id": 5058,
        "name": "Territoire de Belfort",
        "state_code": "90",
        "type": "metropolitan department"
      },
      {
        "id": 5063,
        "name": "Val-d'Oise",
        "state_code": "95",
        "type": "metropolitan department"
      },
      {
        "id": 5062,
        "name": "Val-de-Marne\t",
        "state_code": "94",
        "type": "metropolitan department"
      },
      {
        "id": 5051,
        "name": "Var",
        "state_code": "83",
        "type": "metropolitan department"
      },
      {
        "id": 5052,
        "name": "Vaucluse",
        "state_code": "84",
        "type": "metropolitan department"
      },
      {
        "id": 5053,
        "name": "Vendée",
        "state_code": "85",
        "type": "metropolitan department"
      },
      {
        "id": 5054,
        "name": "Vienne",
        "state_code": "86",
        "type": "metropolitan department"
      },
      {
        "id": 5056,
        "name": "Vosges",
        "state_code": "88",
        "type": "metropolitan department"
      },
      {
        "id": 4810,
        "name": "Wallis and Futuna",
        "state_code": "WF",
        "type": "overseas collectivity"
      },
      {
        "id": 5057,
        "name": "Yonne",
        "state_code": "89",
        "type": "metropolitan department"
      },
      {
        "id": 5046,
        "name": "Yvelines",
        "state_code": "78",
        "type": "metropolitan department"
      }
    ]
  },
  {
    "name": "French Guiana",
    "iso3": "GUF",
    "iso2": "GF",
    "numeric_code": "254",
    "phone_code": "594",
    "capital": "Cayenne",
    "currency": "EUR",
    "currency_name": "Euro",
    "currency_symbol": "€",
    "tld": ".gf",
    "native": "Guyane française",
    "region": "Americas",
    "subregion": "South America",
    "emoji": "🇬🇫",
    "emojiU": "U+1F1EC U+1F1EB",
    "states": []
  },
  {
    "name": "French Polynesia",
    "iso3": "PYF",
    "iso2": "PF",
    "numeric_code": "258",
    "phone_code": "689",
    "capital": "Papeete",
    "currency": "XPF",
    "currency_name": "CFP franc",
    "currency_symbol": "₣",
    "tld": ".pf",
    "native": "Polynésie française",
    "region": "Oceania",
    "subregion": "Polynesia",
    "emoji": "🇵🇫",
    "emojiU": "U+1F1F5 U+1F1EB",
    "states": []
  },
  {
    "name": "French Southern Territories",
    "iso3": "ATF",
    "iso2": "TF",
    "numeric_code": "260",
    "phone_code": "262",
    "capital": "Port-aux-Francais",
    "currency": "EUR",
    "currency_name": "Euro",
    "currency_symbol": "€",
    "tld": ".tf",
    "native": "Territoire des Terres australes et antarctiques fr",
    "region": "Africa",
    "subregion": "Southern Africa",
    "emoji": "🇹🇫",
    "emojiU": "U+1F1F9 U+1F1EB",
    "states": []
  },
  {
    "name": "Gabon",
    "iso3": "GAB",
    "iso2": "GA",
    "numeric_code": "266",
    "phone_code": "241",
    "capital": "Libreville",
    "currency": "XAF",
    "currency_name": "Central African CFA franc",
    "currency_symbol": "FCFA",
    "tld": ".ga",
    "native": "Gabon",
    "region": "Africa",
    "subregion": "Middle Africa",
    "emoji": "🇬🇦",
    "emojiU": "U+1F1EC U+1F1E6",
    "states": [
      {
        "id": 2727,
        "name": "Estuaire Province",
        "state_code": "1",
        "type": null
      },
      {
        "id": 2726,
        "name": "Haut-Ogooué Province",
        "state_code": "2",
        "type": null
      },
      {
        "id": 2730,
        "name": "Moyen-Ogooué Province",
        "state_code": "3",
        "type": null
      },
      {
        "id": 2731,
        "name": "Ngounié Province",
        "state_code": "4",
        "type": null
      },
      {
        "id": 2725,
        "name": "Nyanga Province",
        "state_code": "5",
        "type": null
      },
      {
        "id": 2724,
        "name": "Ogooué-Ivindo Province",
        "state_code": "6",
        "type": null
      },
      {
        "id": 2729,
        "name": "Ogooué-Lolo Province",
        "state_code": "7",
        "type": null
      },
      {
        "id": 2728,
        "name": "Ogooué-Maritime Province",
        "state_code": "8",
        "type": null
      },
      {
        "id": 2723,
        "name": "Woleu-Ntem Province",
        "state_code": "9",
        "type": null
      }
    ]
  },
  {
    "name": "Gambia The",
    "iso3": "GMB",
    "iso2": "GM",
    "numeric_code": "270",
    "phone_code": "220",
    "capital": "Banjul",
    "currency": "GMD",
    "currency_name": "Gambian dalasi",
    "currency_symbol": "D",
    "tld": ".gm",
    "native": "Gambia",
    "region": "Africa",
    "subregion": "Western Africa",
    "emoji": "🇬🇲",
    "emojiU": "U+1F1EC U+1F1F2",
    "states": [
      {
        "id": 2666,
        "name": "Banjul",
        "state_code": "B",
        "type": null
      },
      {
        "id": 2669,
        "name": "Central River Division",
        "state_code": "M",
        "type": null
      },
      {
        "id": 2670,
        "name": "Lower River Division",
        "state_code": "L",
        "type": null
      },
      {
        "id": 2671,
        "name": "North Bank Division",
        "state_code": "N",
        "type": null
      },
      {
        "id": 2668,
        "name": "Upper River Division",
        "state_code": "U",
        "type": null
      },
      {
        "id": 2667,
        "name": "West Coast Division",
        "state_code": "W",
        "type": null
      }
    ]
  },
  {
    "name": "Georgia",
    "iso3": "GEO",
    "iso2": "GE",
    "numeric_code": "268",
    "phone_code": "995",
    "capital": "Tbilisi",
    "currency": "GEL",
    "currency_name": "Georgian lari",
    "currency_symbol": "ლ",
    "tld": ".ge",
    "native": "საქართველო",
    "region": "Asia",
    "subregion": "Western Asia",
    "emoji": "🇬🇪",
    "emojiU": "U+1F1EC U+1F1EA",
    "states": [
      {
        "id": 900,
        "name": "Adjara",
        "state_code": "AJ",
        "type": null
      },
      {
        "id": 901,
        "name": "Autonomous Republic of Abkhazia",
        "state_code": "AB",
        "type": null
      },
      {
        "id": 907,
        "name": "Guria",
        "state_code": "GU",
        "type": null
      },
      {
        "id": 905,
        "name": "Imereti",
        "state_code": "IM",
        "type": null
      },
      {
        "id": 910,
        "name": "Kakheti",
        "state_code": "KA",
        "type": null
      },
      {
        "id": 897,
        "name": "Khelvachauri Municipality",
        "state_code": "29",
        "type": null
      },
      {
        "id": 904,
        "name": "Kvemo Kartli",
        "state_code": "KK",
        "type": null
      },
      {
        "id": 902,
        "name": "Mtskheta-Mtianeti",
        "state_code": "MM",
        "type": null
      },
      {
        "id": 909,
        "name": "Racha-Lechkhumi and Kvemo Svaneti",
        "state_code": "RL",
        "type": null
      },
      {
        "id": 908,
        "name": "Samegrelo-Zemo Svaneti",
        "state_code": "SZ",
        "type": null
      },
      {
        "id": 906,
        "name": "Samtskhe-Javakheti",
        "state_code": "SJ",
        "type": null
      },
      {
        "id": 898,
        "name": "Senaki Municipality",
        "state_code": "50",
        "type": null
      },
      {
        "id": 903,
        "name": "Shida Kartli",
        "state_code": "SK",
        "type": null
      },
      {
        "id": 899,
        "name": "Tbilisi",
        "state_code": "TB",
        "type": null
      }
    ]
  },
  {
    "name": "Germany",
    "iso3": "DEU",
    "iso2": "DE",
    "numeric_code": "276",
    "phone_code": "49",
    "capital": "Berlin",
    "currency": "EUR",
    "currency_name": "Euro",
    "currency_symbol": "€",
    "tld": ".de",
    "native": "Deutschland",
    "region": "Europe",
    "subregion": "Western Europe",
    "emoji": "🇩🇪",
    "emojiU": "U+1F1E9 U+1F1EA",
    "states": [
      {
        "id": 3006,
        "name": "Baden-Württemberg",
        "state_code": "BW",
        "type": null
      },
      {
        "id": 3009,
        "name": "Bavaria",
        "state_code": "BY",
        "type": null
      },
      {
        "id": 3010,
        "name": "Berlin",
        "state_code": "BE",
        "type": null
      },
      {
        "id": 3013,
        "name": "Brandenburg",
        "state_code": "BB",
        "type": null
      },
      {
        "id": 3014,
        "name": "Bremen",
        "state_code": "HB",
        "type": null
      },
      {
        "id": 3016,
        "name": "Hamburg",
        "state_code": "HH",
        "type": null
      },
      {
        "id": 3018,
        "name": "Hesse",
        "state_code": "HE",
        "type": null
      },
      {
        "id": 3008,
        "name": "Lower Saxony",
        "state_code": "NI",
        "type": null
      },
      {
        "id": 3007,
        "name": "Mecklenburg-Vorpommern",
        "state_code": "MV",
        "type": null
      },
      {
        "id": 3017,
        "name": "North Rhine-Westphalia",
        "state_code": "NW",
        "type": null
      },
      {
        "id": 3019,
        "name": "Rhineland-Palatinate",
        "state_code": "RP",
        "type": null
      },
      {
        "id": 3020,
        "name": "Saarland",
        "state_code": "SL",
        "type": null
      },
      {
        "id": 3021,
        "name": "Saxony",
        "state_code": "SN",
        "type": null
      },
      {
        "id": 3011,
        "name": "Saxony-Anhalt",
        "state_code": "ST",
        "type": null
      },
      {
        "id": 3005,
        "name": "Schleswig-Holstein",
        "state_code": "SH",
        "type": null
      },
      {
        "id": 3015,
        "name": "Thuringia",
        "state_code": "TH",
        "type": null
      }
    ]
  },
  {
    "name": "Ghana",
    "iso3": "GHA",
    "iso2": "GH",
    "numeric_code": "288",
    "phone_code": "233",
    "capital": "Accra",
    "currency": "GHS",
    "currency_name": "Ghanaian cedi",
    "currency_symbol": "GH₵",
    "tld": ".gh",
    "native": "Ghana",
    "region": "Africa",
    "subregion": "Western Africa",
    "emoji": "🇬🇭",
    "emojiU": "U+1F1EC U+1F1ED",
    "states": [
      {
        "id": 53,
        "name": "Ahafo",
        "state_code": "AF",
        "type": "region"
      },
      {
        "id": 48,
        "name": "Ashanti",
        "state_code": "AH",
        "type": "region"
      },
      {
        "id": 4959,
        "name": "Bono",
        "state_code": "BO",
        "type": "region"
      },
      {
        "id": 4958,
        "name": "Bono East",
        "state_code": "BE",
        "type": "region"
      },
      {
        "id": 52,
        "name": "Central",
        "state_code": "CP",
        "type": "region"
      },
      {
        "id": 50,
        "name": "Eastern",
        "state_code": "EP",
        "type": "region"
      },
      {
        "id": 54,
        "name": "Greater Accra",
        "state_code": "AA",
        "type": "region"
      },
      {
        "id": 4960,
        "name": "North East",
        "state_code": "NE",
        "type": "region"
      },
      {
        "id": 51,
        "name": "Northern",
        "state_code": "NP",
        "type": "region"
      },
      {
        "id": 4961,
        "name": "Oti",
        "state_code": "OT",
        "type": "region"
      },
      {
        "id": 4962,
        "name": "Savannah",
        "state_code": "SV",
        "type": "region"
      },
      {
        "id": 55,
        "name": "Upper East",
        "state_code": "UE",
        "type": "region"
      },
      {
        "id": 57,
        "name": "Upper West",
        "state_code": "UW",
        "type": "region"
      },
      {
        "id": 56,
        "name": "Volta",
        "state_code": "TV",
        "type": "region"
      },
      {
        "id": 49,
        "name": "Western",
        "state_code": "WP",
        "type": "region"
      },
      {
        "id": 4963,
        "name": "Western North",
        "state_code": "WN",
        "type": "region"
      }
    ]
  },
  {
    "name": "Gibraltar",
    "iso3": "GIB",
    "iso2": "GI",
    "numeric_code": "292",
    "phone_code": "350",
    "capital": "Gibraltar",
    "currency": "GIP",
    "currency_name": "Gibraltar pound",
    "currency_symbol": "£",
    "tld": ".gi",
    "native": "Gibraltar",
    "region": "Europe",
    "subregion": "Southern Europe",
    "emoji": "🇬🇮",
    "emojiU": "U+1F1EC U+1F1EE",
    "states": []
  },
  {
    "name": "Greece",
    "iso3": "GRC",
    "iso2": "GR",
    "numeric_code": "300",
    "phone_code": "30",
    "capital": "Athens",
    "currency": "EUR",
    "currency_name": "Euro",
    "currency_symbol": "€",
    "tld": ".gr",
    "native": "Ελλάδα",
    "region": "Europe",
    "subregion": "Southern Europe",
    "emoji": "🇬🇷",
    "emojiU": "U+1F1EC U+1F1F7",
    "states": [
      {
        "id": 2116,
        "name": "Achaea Regional Unit",
        "state_code": "13",
        "type": null
      },
      {
        "id": 2123,
        "name": "Aetolia-Acarnania Regional Unit",
        "state_code": "01",
        "type": null
      },
      {
        "id": 2098,
        "name": "Arcadia Prefecture",
        "state_code": "12",
        "type": null
      },
      {
        "id": 2105,
        "name": "Argolis Regional Unit",
        "state_code": "11",
        "type": null
      },
      {
        "id": 2122,
        "name": "Attica Region",
        "state_code": "I",
        "type": null
      },
      {
        "id": 2126,
        "name": "Boeotia Regional Unit",
        "state_code": "03",
        "type": null
      },
      {
        "id": 2128,
        "name": "Central Greece Region",
        "state_code": "H",
        "type": null
      },
      {
        "id": 2125,
        "name": "Central Macedonia",
        "state_code": "B",
        "type": null
      },
      {
        "id": 2115,
        "name": "Chania Regional Unit",
        "state_code": "94",
        "type": null
      },
      {
        "id": 2124,
        "name": "Corfu Prefecture",
        "state_code": "22",
        "type": null
      },
      {
        "id": 2129,
        "name": "Corinthia Regional Unit",
        "state_code": "15",
        "type": null
      },
      {
        "id": 2109,
        "name": "Crete Region",
        "state_code": "M",
        "type": null
      },
      {
        "id": 2130,
        "name": "Drama Regional Unit",
        "state_code": "52",
        "type": null
      },
      {
        "id": 2120,
        "name": "East Attica Regional Unit",
        "state_code": "A2",
        "type": null
      },
      {
        "id": 2117,
        "name": "East Macedonia and Thrace",
        "state_code": "A",
        "type": null
      },
      {
        "id": 2110,
        "name": "Epirus Region",
        "state_code": "D",
        "type": null
      },
      {
        "id": 2101,
        "name": "Euboea",
        "state_code": "04",
        "type": null
      },
      {
        "id": 2102,
        "name": "Grevena Prefecture",
        "state_code": "51",
        "type": null
      },
      {
        "id": 2099,
        "name": "Imathia Regional Unit",
        "state_code": "53",
        "type": null
      },
      {
        "id": 2113,
        "name": "Ioannina Regional Unit",
        "state_code": "33",
        "type": null
      },
      {
        "id": 2131,
        "name": "Ionian Islands Region",
        "state_code": "F",
        "type": null
      },
      {
        "id": 2095,
        "name": "Karditsa Regional Unit",
        "state_code": "41",
        "type": null
      },
      {
        "id": 2100,
        "name": "Kastoria Regional Unit",
        "state_code": "56",
        "type": null
      },
      {
        "id": 2127,
        "name": "Kefalonia Prefecture",
        "state_code": "23",
        "type": null
      },
      {
        "id": 2111,
        "name": "Kilkis Regional Unit",
        "state_code": "57",
        "type": null
      },
      {
        "id": 2112,
        "name": "Kozani Prefecture",
        "state_code": "58",
        "type": null
      },
      {
        "id": 2106,
        "name": "Laconia",
        "state_code": "16",
        "type": null
      },
      {
        "id": 2132,
        "name": "Larissa Prefecture",
        "state_code": "42",
        "type": null
      },
      {
        "id": 2104,
        "name": "Lefkada Regional Unit",
        "state_code": "24",
        "type": null
      },
      {
        "id": 2107,
        "name": "Pella Regional Unit",
        "state_code": "59",
        "type": null
      },
      {
        "id": 2119,
        "name": "Peloponnese Region",
        "state_code": "J",
        "type": null
      },
      {
        "id": 2114,
        "name": "Phthiotis Prefecture",
        "state_code": "06",
        "type": null
      },
      {
        "id": 2103,
        "name": "Preveza Prefecture",
        "state_code": "34",
        "type": null
      },
      {
        "id": 2121,
        "name": "Serres Prefecture",
        "state_code": "62",
        "type": null
      },
      {
        "id": 2118,
        "name": "South Aegean",
        "state_code": "L",
        "type": null
      },
      {
        "id": 2097,
        "name": "Thessaloniki Regional Unit",
        "state_code": "54",
        "type": null
      },
      {
        "id": 2096,
        "name": "West Greece Region",
        "state_code": "G",
        "type": null
      },
      {
        "id": 2108,
        "name": "West Macedonia Region",
        "state_code": "C",
        "type": null
      }
    ]
  },
  {
    "name": "Greenland",
    "iso3": "GRL",
    "iso2": "GL",
    "numeric_code": "304",
    "phone_code": "299",
    "capital": "Nuuk",
    "currency": "DKK",
    "currency_name": "Danish krone",
    "currency_symbol": "Kr.",
    "tld": ".gl",
    "native": "Kalaallit Nunaat",
    "region": "Americas",
    "subregion": "Northern America",
    "emoji": "🇬🇱",
    "emojiU": "U+1F1EC U+1F1F1",
    "states": []
  },
  {
    "name": "Grenada",
    "iso3": "GRD",
    "iso2": "GD",
    "numeric_code": "308",
    "phone_code": "+1-473",
    "capital": "St. George's",
    "currency": "XCD",
    "currency_name": "Eastern Caribbean dollar",
    "currency_symbol": "$",
    "tld": ".gd",
    "native": "Grenada",
    "region": "Americas",
    "subregion": "Caribbean",
    "emoji": "🇬🇩",
    "emojiU": "U+1F1EC U+1F1E9",
    "states": [
      {
        "id": 3867,
        "name": "Carriacou and Petite Martinique",
        "state_code": "10",
        "type": null
      },
      {
        "id": 3865,
        "name": "Saint Andrew Parish",
        "state_code": "01",
        "type": null
      },
      {
        "id": 3869,
        "name": "Saint David Parish",
        "state_code": "02",
        "type": null
      },
      {
        "id": 3864,
        "name": "Saint George Parish",
        "state_code": "03",
        "type": null
      },
      {
        "id": 3868,
        "name": "Saint John Parish",
        "state_code": "04",
        "type": null
      },
      {
        "id": 3866,
        "name": "Saint Mark Parish",
        "state_code": "05",
        "type": null
      },
      {
        "id": 3863,
        "name": "Saint Patrick Parish",
        "state_code": "06",
        "type": null
      }
    ]
  },
  {
    "name": "Guadeloupe",
    "iso3": "GLP",
    "iso2": "GP",
    "numeric_code": "312",
    "phone_code": "590",
    "capital": "Basse-Terre",
    "currency": "EUR",
    "currency_name": "Euro",
    "currency_symbol": "€",
    "tld": ".gp",
    "native": "Guadeloupe",
    "region": "Americas",
    "subregion": "Caribbean",
    "emoji": "🇬🇵",
    "emojiU": "U+1F1EC U+1F1F5",
    "states": []
  },
  {
    "name": "Guam",
    "iso3": "GUM",
    "iso2": "GU",
    "numeric_code": "316",
    "phone_code": "+1-671",
    "capital": "Hagatna",
    "currency": "USD",
    "currency_name": "US Dollar",
    "currency_symbol": "$",
    "tld": ".gu",
    "native": "Guam",
    "region": "Oceania",
    "subregion": "Micronesia",
    "emoji": "🇬🇺",
    "emojiU": "U+1F1EC U+1F1FA",
    "states": []
  },
  {
    "name": "Guatemala",
    "iso3": "GTM",
    "iso2": "GT",
    "numeric_code": "320",
    "phone_code": "502",
    "capital": "Guatemala City",
    "currency": "GTQ",
    "currency_name": "Guatemalan quetzal",
    "currency_symbol": "Q",
    "tld": ".gt",
    "native": "Guatemala",
    "region": "Americas",
    "subregion": "Central America",
    "emoji": "🇬🇹",
    "emojiU": "U+1F1EC U+1F1F9",
    "states": [
      {
        "id": 3671,
        "name": "Alta Verapaz Department",
        "state_code": "AV",
        "type": null
      },
      {
        "id": 3674,
        "name": "Baja Verapaz Department",
        "state_code": "BV",
        "type": null
      },
      {
        "id": 3675,
        "name": "Chimaltenango Department",
        "state_code": "CM",
        "type": null
      },
      {
        "id": 3666,
        "name": "Chiquimula Department",
        "state_code": "CQ",
        "type": null
      },
      {
        "id": 3662,
        "name": "El Progreso Department",
        "state_code": "PR",
        "type": null
      },
      {
        "id": 3677,
        "name": "Escuintla Department",
        "state_code": "ES",
        "type": null
      },
      {
        "id": 3672,
        "name": "Guatemala Department",
        "state_code": "GU",
        "type": null
      },
      {
        "id": 3670,
        "name": "Huehuetenango Department",
        "state_code": "HU",
        "type": null
      },
      {
        "id": 3659,
        "name": "Izabal Department",
        "state_code": "IZ",
        "type": null
      },
      {
        "id": 3658,
        "name": "Jalapa Department",
        "state_code": "JA",
        "type": null
      },
      {
        "id": 3673,
        "name": "Jutiapa Department",
        "state_code": "JU",
        "type": null
      },
      {
        "id": 3669,
        "name": "Petén Department",
        "state_code": "PE",
        "type": null
      },
      {
        "id": 3668,
        "name": "Quetzaltenango Department",
        "state_code": "QZ",
        "type": null
      },
      {
        "id": 3657,
        "name": "Quiché Department",
        "state_code": "QC",
        "type": null
      },
      {
        "id": 3664,
        "name": "Retalhuleu Department",
        "state_code": "RE",
        "type": null
      },
      {
        "id": 3676,
        "name": "Sacatepéquez Department",
        "state_code": "SA",
        "type": null
      },
      {
        "id": 3667,
        "name": "San Marcos Department",
        "state_code": "SM",
        "type": null
      },
      {
        "id": 3665,
        "name": "Santa Rosa Department",
        "state_code": "SR",
        "type": null
      },
      {
        "id": 3661,
        "name": "Sololá Department",
        "state_code": "SO",
        "type": null
      },
      {
        "id": 3660,
        "name": "Suchitepéquez Department",
        "state_code": "SU",
        "type": null
      },
      {
        "id": 3663,
        "name": "Totonicapán Department",
        "state_code": "TO",
        "type": null
      }
    ]
  },
  {
    "name": "Guernsey and Alderney",
    "iso3": "GGY",
    "iso2": "GG",
    "numeric_code": "831",
    "phone_code": "+44-1481",
    "capital": "St Peter Port",
    "currency": "GBP",
    "currency_name": "British pound",
    "currency_symbol": "£",
    "tld": ".gg",
    "native": "Guernsey",
    "region": "Europe",
    "subregion": "Northern Europe",
    "emoji": "🇬🇬",
    "emojiU": "U+1F1EC U+1F1EC",
    "states": []
  },
  {
    "name": "Guinea",
    "iso3": "GIN",
    "iso2": "GN",
    "numeric_code": "324",
    "phone_code": "224",
    "capital": "Conakry",
    "currency": "GNF",
    "currency_name": "Guinean franc",
    "currency_symbol": "FG",
    "tld": ".gn",
    "native": "Guinée",
    "region": "Africa",
    "subregion": "Western Africa",
    "emoji": "🇬🇳",
    "emojiU": "U+1F1EC U+1F1F3",
    "states": [
      {
        "id": 2672,
        "name": "Beyla Prefecture",
        "state_code": "BE",
        "type": null
      },
      {
        "id": 2699,
        "name": "Boffa Prefecture",
        "state_code": "BF",
        "type": null
      },
      {
        "id": 2709,
        "name": "Boké Prefecture",
        "state_code": "BK",
        "type": null
      },
      {
        "id": 2676,
        "name": "Boké Region",
        "state_code": "B",
        "type": null
      },
      {
        "id": 2686,
        "name": "Conakry",
        "state_code": "C",
        "type": null
      },
      {
        "id": 2705,
        "name": "Coyah Prefecture",
        "state_code": "CO",
        "type": null
      },
      {
        "id": 2679,
        "name": "Dabola Prefecture",
        "state_code": "DB",
        "type": null
      },
      {
        "id": 2706,
        "name": "Dalaba Prefecture",
        "state_code": "DL",
        "type": null
      },
      {
        "id": 2688,
        "name": "Dinguiraye Prefecture",
        "state_code": "DI",
        "type": null
      },
      {
        "id": 2681,
        "name": "Dubréka Prefecture",
        "state_code": "DU",
        "type": null
      },
      {
        "id": 2682,
        "name": "Faranah Prefecture",
        "state_code": "FA",
        "type": null
      },
      {
        "id": 2683,
        "name": "Forécariah Prefecture",
        "state_code": "FO",
        "type": null
      },
      {
        "id": 2675,
        "name": "Fria Prefecture",
        "state_code": "FR",
        "type": null
      },
      {
        "id": 2685,
        "name": "Gaoual Prefecture",
        "state_code": "GA",
        "type": null
      },
      {
        "id": 2711,
        "name": "Guéckédou Prefecture",
        "state_code": "GU",
        "type": null
      },
      {
        "id": 2704,
        "name": "Kankan Prefecture",
        "state_code": "KA",
        "type": null
      },
      {
        "id": 2697,
        "name": "Kankan Region",
        "state_code": "K",
        "type": null
      },
      {
        "id": 2710,
        "name": "Kérouané Prefecture",
        "state_code": "KE",
        "type": null
      },
      {
        "id": 2693,
        "name": "Kindia Prefecture",
        "state_code": "KD",
        "type": null
      },
      {
        "id": 2701,
        "name": "Kindia Region",
        "state_code": "D",
        "type": null
      },
      {
        "id": 2691,
        "name": "Kissidougou Prefecture",
        "state_code": "KS",
        "type": null
      },
      {
        "id": 2692,
        "name": "Koubia Prefecture",
        "state_code": "KB",
        "type": null
      },
      {
        "id": 2703,
        "name": "Koundara Prefecture",
        "state_code": "KN",
        "type": null
      },
      {
        "id": 2695,
        "name": "Kouroussa Prefecture",
        "state_code": "KO",
        "type": null
      },
      {
        "id": 2680,
        "name": "Labé Prefecture",
        "state_code": "LA",
        "type": null
      },
      {
        "id": 2677,
        "name": "Labé Region",
        "state_code": "L",
        "type": null
      },
      {
        "id": 2690,
        "name": "Lélouma Prefecture",
        "state_code": "LE",
        "type": null
      },
      {
        "id": 2708,
        "name": "Lola Prefecture",
        "state_code": "LO",
        "type": null
      },
      {
        "id": 2702,
        "name": "Macenta Prefecture",
        "state_code": "MC",
        "type": null
      },
      {
        "id": 2700,
        "name": "Mali Prefecture",
        "state_code": "ML",
        "type": null
      },
      {
        "id": 2689,
        "name": "Mamou Prefecture",
        "state_code": "MM",
        "type": null
      },
      {
        "id": 2698,
        "name": "Mamou Region",
        "state_code": "M",
        "type": null
      },
      {
        "id": 2673,
        "name": "Mandiana Prefecture",
        "state_code": "MD",
        "type": null
      },
      {
        "id": 2678,
        "name": "Nzérékoré Prefecture",
        "state_code": "NZ",
        "type": null
      },
      {
        "id": 2684,
        "name": "Nzérékoré Region",
        "state_code": "N",
        "type": null
      },
      {
        "id": 2694,
        "name": "Pita Prefecture",
        "state_code": "PI",
        "type": null
      },
      {
        "id": 2707,
        "name": "Siguiri Prefecture",
        "state_code": "SI",
        "type": null
      },
      {
        "id": 2687,
        "name": "Télimélé Prefecture",
        "state_code": "TE",
        "type": null
      },
      {
        "id": 2696,
        "name": "Tougué Prefecture",
        "state_code": "TO",
        "type": null
      },
      {
        "id": 2674,
        "name": "Yomou Prefecture",
        "state_code": "YO",
        "type": null
      }
    ]
  },
  {
    "name": "Guinea-Bissau",
    "iso3": "GNB",
    "iso2": "GW",
    "numeric_code": "624",
    "phone_code": "245",
    "capital": "Bissau",
    "currency": "XOF",
    "currency_name": "West African CFA franc",
    "currency_symbol": "CFA",
    "tld": ".gw",
    "native": "Guiné-Bissau",
    "region": "Africa",
    "subregion": "Western Africa",
    "emoji": "🇬🇼",
    "emojiU": "U+1F1EC U+1F1FC",
    "states": [
      {
        "id": 2720,
        "name": "Bafatá",
        "state_code": "BA",
        "type": null
      },
      {
        "id": 2714,
        "name": "Biombo Region",
        "state_code": "BM",
        "type": null
      },
      {
        "id": 2722,
        "name": "Bolama Region",
        "state_code": "BL",
        "type": null
      },
      {
        "id": 2713,
        "name": "Cacheu Region",
        "state_code": "CA",
        "type": null
      },
      {
        "id": 2719,
        "name": "Gabú Region",
        "state_code": "GA",
        "type": null
      },
      {
        "id": 2721,
        "name": "Leste Province",
        "state_code": "L",
        "type": null
      },
      {
        "id": 2717,
        "name": "Norte Province",
        "state_code": "N",
        "type": null
      },
      {
        "id": 2718,
        "name": "Oio Region",
        "state_code": "OI",
        "type": null
      },
      {
        "id": 2715,
        "name": "Quinara Region",
        "state_code": "QU",
        "type": null
      },
      {
        "id": 2716,
        "name": "Sul Province",
        "state_code": "S",
        "type": null
      },
      {
        "id": 2712,
        "name": "Tombali Region",
        "state_code": "TO",
        "type": null
      }
    ]
  },
  {
    "name": "Guyana",
    "iso3": "GUY",
    "iso2": "GY",
    "numeric_code": "328",
    "phone_code": "592",
    "capital": "Georgetown",
    "currency": "GYD",
    "currency_name": "Guyanese dollar",
    "currency_symbol": "$",
    "tld": ".gy",
    "native": "Guyana",
    "region": "Americas",
    "subregion": "South America",
    "emoji": "🇬🇾",
    "emojiU": "U+1F1EC U+1F1FE",
    "states": [
      {
        "id": 2764,
        "name": "Barima-Waini",
        "state_code": "BA",
        "type": null
      },
      {
        "id": 2760,
        "name": "Cuyuni-Mazaruni",
        "state_code": "CU",
        "type": null
      },
      {
        "id": 2767,
        "name": "Demerara-Mahaica",
        "state_code": "DE",
        "type": null
      },
      {
        "id": 2766,
        "name": "East Berbice-Corentyne",
        "state_code": "EB",
        "type": null
      },
      {
        "id": 2768,
        "name": "Essequibo Islands-West Demerara",
        "state_code": "ES",
        "type": null
      },
      {
        "id": 2762,
        "name": "Mahaica-Berbice",
        "state_code": "MA",
        "type": null
      },
      {
        "id": 2765,
        "name": "Pomeroon-Supenaam",
        "state_code": "PM",
        "type": null
      },
      {
        "id": 2761,
        "name": "Potaro-Siparuni",
        "state_code": "PT",
        "type": null
      },
      {
        "id": 2763,
        "name": "Upper Demerara-Berbice",
        "state_code": "UD",
        "type": null
      },
      {
        "id": 2769,
        "name": "Upper Takutu-Upper Essequibo",
        "state_code": "UT",
        "type": null
      }
    ]
  },
  {
    "name": "Haiti",
    "iso3": "HTI",
    "iso2": "HT",
    "numeric_code": "332",
    "phone_code": "509",
    "capital": "Port-au-Prince",
    "currency": "HTG",
    "currency_name": "Haitian gourde",
    "currency_symbol": "G",
    "tld": ".ht",
    "native": "Haïti",
    "region": "Americas",
    "subregion": "Caribbean",
    "emoji": "🇭🇹",
    "emojiU": "U+1F1ED U+1F1F9",
    "states": [
      {
        "id": 4123,
        "name": "Artibonite",
        "state_code": "AR",
        "type": null
      },
      {
        "id": 4125,
        "name": "Centre",
        "state_code": "CE",
        "type": null
      },
      {
        "id": 4119,
        "name": "Grand'Anse",
        "state_code": "GA",
        "type": null
      },
      {
        "id": 4118,
        "name": "Nippes",
        "state_code": "NI",
        "type": null
      },
      {
        "id": 4117,
        "name": "Nord",
        "state_code": "ND",
        "type": null
      },
      {
        "id": 4121,
        "name": "Nord-Est",
        "state_code": "NE",
        "type": null
      },
      {
        "id": 4126,
        "name": "Nord-Ouest",
        "state_code": "NO",
        "type": null
      },
      {
        "id": 4120,
        "name": "Ouest",
        "state_code": "OU",
        "type": null
      },
      {
        "id": 4122,
        "name": "Sud",
        "state_code": "SD",
        "type": null
      },
      {
        "id": 4124,
        "name": "Sud-Est",
        "state_code": "SE",
        "type": null
      }
    ]
  },
  {
    "name": "Heard Island and McDonald Islands",
    "iso3": "HMD",
    "iso2": "HM",
    "numeric_code": "334",
    "phone_code": "672",
    "capital": "",
    "currency": "AUD",
    "currency_name": "Australian dollar",
    "currency_symbol": "$",
    "tld": ".hm",
    "native": "Heard Island and McDonald Islands",
    "region": "",
    "subregion": "",
    "emoji": "🇭🇲",
    "emojiU": "U+1F1ED U+1F1F2",
    "states": []
  },
  {
    "name": "Honduras",
    "iso3": "HND",
    "iso2": "HN",
    "numeric_code": "340",
    "phone_code": "504",
    "capital": "Tegucigalpa",
    "currency": "HNL",
    "currency_name": "Honduran lempira",
    "currency_symbol": "L",
    "tld": ".hn",
    "native": "Honduras",
    "region": "Americas",
    "subregion": "Central America",
    "emoji": "🇭🇳",
    "emojiU": "U+1F1ED U+1F1F3",
    "states": [
      {
        "id": 4047,
        "name": "Atlántida Department",
        "state_code": "AT",
        "type": null
      },
      {
        "id": 4045,
        "name": "Bay Islands Department",
        "state_code": "IB",
        "type": null
      },
      {
        "id": 4041,
        "name": "Choluteca Department",
        "state_code": "CH",
        "type": null
      },
      {
        "id": 4051,
        "name": "Colón Department",
        "state_code": "CL",
        "type": null
      },
      {
        "id": 4042,
        "name": "Comayagua Department",
        "state_code": "CM",
        "type": null
      },
      {
        "id": 4049,
        "name": "Copán Department",
        "state_code": "CP",
        "type": null
      },
      {
        "id": 4046,
        "name": "Cortés Department",
        "state_code": "CR",
        "type": null
      },
      {
        "id": 4043,
        "name": "El Paraíso Department",
        "state_code": "EP",
        "type": null
      },
      {
        "id": 4052,
        "name": "Francisco Morazán Department",
        "state_code": "FM",
        "type": null
      },
      {
        "id": 4048,
        "name": "Gracias a Dios Department",
        "state_code": "GD",
        "type": null
      },
      {
        "id": 4044,
        "name": "Intibucá Department",
        "state_code": "IN",
        "type": null
      },
      {
        "id": 4058,
        "name": "La Paz Department",
        "state_code": "LP",
        "type": null
      },
      {
        "id": 4054,
        "name": "Lempira Department",
        "state_code": "LE",
        "type": null
      },
      {
        "id": 4056,
        "name": "Ocotepeque Department",
        "state_code": "OC",
        "type": null
      },
      {
        "id": 4050,
        "name": "Olancho Department",
        "state_code": "OL",
        "type": null
      },
      {
        "id": 4053,
        "name": "Santa Bárbara Department",
        "state_code": "SB",
        "type": null
      },
      {
        "id": 4055,
        "name": "Valle Department",
        "state_code": "VA",
        "type": null
      },
      {
        "id": 4057,
        "name": "Yoro Department",
        "state_code": "YO",
        "type": null
      }
    ]
  },
  {
    "name": "Hong Kong S.A.R.",
    "iso3": "HKG",
    "iso2": "HK",
    "numeric_code": "344",
    "phone_code": "852",
    "capital": "Hong Kong",
    "currency": "HKD",
    "currency_name": "Hong Kong dollar",
    "currency_symbol": "$",
    "tld": ".hk",
    "native": "香港",
    "region": "Asia",
    "subregion": "Eastern Asia",
    "emoji": "🇭🇰",
    "emojiU": "U+1F1ED U+1F1F0",
    "states": [
      {
        "id": 4889,
        "name": "Central and Western District",
        "state_code": "HCW",
        "type": null
      },
      {
        "id": 4891,
        "name": "Eastern",
        "state_code": "HEA",
        "type": null
      },
      {
        "id": 4888,
        "name": "Islands District",
        "state_code": "NIS",
        "type": null
      },
      {
        "id": 4895,
        "name": "Kowloon City",
        "state_code": "KKC",
        "type": null
      },
      {
        "id": 4898,
        "name": "Kwai Tsing",
        "state_code": "NKT",
        "type": null
      },
      {
        "id": 4897,
        "name": "Kwun Tong",
        "state_code": "KKT",
        "type": null
      },
      {
        "id": 4900,
        "name": "North",
        "state_code": "NNO",
        "type": null
      },
      {
        "id": 4887,
        "name": "Sai Kung District",
        "state_code": "NSK",
        "type": null
      },
      {
        "id": 4901,
        "name": "Sha Tin",
        "state_code": "NST",
        "type": null
      },
      {
        "id": 4894,
        "name": "Sham Shui Po",
        "state_code": "KSS",
        "type": null
      },
      {
        "id": 4892,
        "name": "Southern",
        "state_code": "HSO",
        "type": null
      },
      {
        "id": 4885,
        "name": "Tai Po District",
        "state_code": "NTP",
        "type": null
      },
      {
        "id": 4884,
        "name": "Tsuen Wan District",
        "state_code": "NTW",
        "type": null
      },
      {
        "id": 4899,
        "name": "Tuen Mun",
        "state_code": "NTM",
        "type": null
      },
      {
        "id": 4890,
        "name": "Wan Chai",
        "state_code": "HWC",
        "type": null
      },
      {
        "id": 4896,
        "name": "Wong Tai Sin",
        "state_code": "KWT",
        "type": null
      },
      {
        "id": 4893,
        "name": "Yau Tsim Mong",
        "state_code": "KYT",
        "type": null
      },
      {
        "id": 4883,
        "name": "Yuen Long District",
        "state_code": "NYL",
        "type": null
      }
    ]
  },
  {
    "name": "Hungary",
    "iso3": "HUN",
    "iso2": "HU",
    "numeric_code": "348",
    "phone_code": "36",
    "capital": "Budapest",
    "currency": "HUF",
    "currency_name": "Hungarian forint",
    "currency_symbol": "Ft",
    "tld": ".hu",
    "native": "Magyarország",
    "region": "Europe",
    "subregion": "Eastern Europe",
    "emoji": "🇭🇺",
    "emojiU": "U+1F1ED U+1F1FA",
    "states": [
      {
        "id": 1048,
        "name": "Bács-Kiskun County",
        "state_code": "BK",
        "type": null
      },
      {
        "id": 1055,
        "name": "Baranya County",
        "state_code": "BA",
        "type": null
      },
      {
        "id": 1060,
        "name": "Békés County",
        "state_code": "BE",
        "type": null
      },
      {
        "id": 1036,
        "name": "Békéscsaba",
        "state_code": "BC",
        "type": null
      },
      {
        "id": 1058,
        "name": "Borsod-Abaúj-Zemplén County",
        "state_code": "BZ",
        "type": null
      },
      {
        "id": 1064,
        "name": "Budapest",
        "state_code": "BU",
        "type": null
      },
      {
        "id": 1031,
        "name": "Csongrád County",
        "state_code": "CS",
        "type": null
      },
      {
        "id": 1032,
        "name": "Debrecen",
        "state_code": "DE",
        "type": null
      },
      {
        "id": 1049,
        "name": "Dunaújváros",
        "state_code": "DU",
        "type": null
      },
      {
        "id": 1037,
        "name": "Eger",
        "state_code": "EG",
        "type": null
      },
      {
        "id": 1028,
        "name": "Érd",
        "state_code": "ER",
        "type": null
      },
      {
        "id": 1044,
        "name": "Fejér County",
        "state_code": "FE",
        "type": null
      },
      {
        "id": 1041,
        "name": "Győr",
        "state_code": "GY",
        "type": null
      },
      {
        "id": 1042,
        "name": "Győr-Moson-Sopron County",
        "state_code": "GS",
        "type": null
      },
      {
        "id": 1063,
        "name": "Hajdú-Bihar County",
        "state_code": "HB",
        "type": null
      },
      {
        "id": 1040,
        "name": "Heves County",
        "state_code": "HE",
        "type": null
      },
      {
        "id": 1027,
        "name": "Hódmezővásárhely",
        "state_code": "HV",
        "type": null
      },
      {
        "id": 1043,
        "name": "Jász-Nagykun-Szolnok County",
        "state_code": "JN",
        "type": null
      },
      {
        "id": 1067,
        "name": "Kaposvár",
        "state_code": "KV",
        "type": null
      },
      {
        "id": 1056,
        "name": "Kecskemét",
        "state_code": "KM",
        "type": null
      },
      {
        "id": 1065,
        "name": "Miskolc",
        "state_code": "MI",
        "type": null
      },
      {
        "id": 1030,
        "name": "Nagykanizsa",
        "state_code": "NK",
        "type": null
      },
      {
        "id": 1051,
        "name": "Nógrád County",
        "state_code": "NO",
        "type": null
      },
      {
        "id": 1034,
        "name": "Nyíregyháza",
        "state_code": "NY",
        "type": null
      },
      {
        "id": 1053,
        "name": "Pécs",
        "state_code": "PS",
        "type": null
      },
      {
        "id": 1059,
        "name": "Pest County",
        "state_code": "PE",
        "type": null
      },
      {
        "id": 1068,
        "name": "Salgótarján",
        "state_code": "ST",
        "type": null
      },
      {
        "id": 1035,
        "name": "Somogy County",
        "state_code": "SO",
        "type": null
      },
      {
        "id": 1057,
        "name": "Sopron",
        "state_code": "SN",
        "type": null
      },
      {
        "id": 1045,
        "name": "Szabolcs-Szatmár-Bereg County",
        "state_code": "SZ",
        "type": null
      },
      {
        "id": 1029,
        "name": "Szeged",
        "state_code": "SD",
        "type": null
      },
      {
        "id": 1033,
        "name": "Székesfehérvár",
        "state_code": "SF",
        "type": null
      },
      {
        "id": 1061,
        "name": "Szekszárd",
        "state_code": "SS",
        "type": null
      },
      {
        "id": 1047,
        "name": "Szolnok",
        "state_code": "SK",
        "type": null
      },
      {
        "id": 1052,
        "name": "Szombathely",
        "state_code": "SH",
        "type": null
      },
      {
        "id": 1066,
        "name": "Tatabánya",
        "state_code": "TB",
        "type": null
      },
      {
        "id": 1038,
        "name": "Tolna County",
        "state_code": "TO",
        "type": null
      },
      {
        "id": 1039,
        "name": "Vas County",
        "state_code": "VA",
        "type": null
      },
      {
        "id": 1062,
        "name": "Veszprém",
        "state_code": "VM",
        "type": null
      },
      {
        "id": 1054,
        "name": "Veszprém County",
        "state_code": "VE",
        "type": null
      },
      {
        "id": 1046,
        "name": "Zala County",
        "state_code": "ZA",
        "type": null
      },
      {
        "id": 1050,
        "name": "Zalaegerszeg",
        "state_code": "ZE",
        "type": null
      }
    ]
  },
  {
    "name": "Iceland",
    "iso3": "ISL",
    "iso2": "IS",
    "numeric_code": "352",
    "phone_code": "354",
    "capital": "Reykjavik",
    "currency": "ISK",
    "currency_name": "Icelandic króna",
    "currency_symbol": "kr",
    "tld": ".is",
    "native": "Ísland",
    "region": "Europe",
    "subregion": "Northern Europe",
    "emoji": "🇮🇸",
    "emojiU": "U+1F1EE U+1F1F8",
    "states": [
      {
        "id": 3431,
        "name": "Capital Region",
        "state_code": "1",
        "type": null
      },
      {
        "id": 3433,
        "name": "Eastern Region",
        "state_code": "7",
        "type": null
      },
      {
        "id": 3437,
        "name": "Northeastern Region",
        "state_code": "6",
        "type": null
      },
      {
        "id": 3435,
        "name": "Northwestern Region",
        "state_code": "5",
        "type": null
      },
      {
        "id": 3430,
        "name": "Southern Peninsula Region",
        "state_code": "2",
        "type": null
      },
      {
        "id": 3434,
        "name": "Southern Region",
        "state_code": "8",
        "type": null
      },
      {
        "id": 3436,
        "name": "Western Region",
        "state_code": "3",
        "type": null
      },
      {
        "id": 3432,
        "name": "Westfjords",
        "state_code": "4",
        "type": null
      }
    ]
  },
  {
    "name": "India",
    "iso3": "IND",
    "iso2": "IN",
    "numeric_code": "356",
    "phone_code": "91",
    "capital": "New Delhi",
    "currency": "INR",
    "currency_name": "Indian rupee",
    "currency_symbol": "₹",
    "tld": ".in",
    "native": "भारत",
    "region": "Asia",
    "subregion": "Southern Asia",
    "emoji": "🇮🇳",
    "emojiU": "U+1F1EE U+1F1F3",
    "states": [
      {
        "id": 4023,
        "name": "Andaman and Nicobar Islands",
        "state_code": "AN",
        "type": null
      },
      {
        "id": 4017,
        "name": "Andhra Pradesh",
        "state_code": "AP",
        "type": null
      },
      {
        "id": 4024,
        "name": "Arunachal Pradesh",
        "state_code": "AR",
        "type": null
      },
      {
        "id": 4027,
        "name": "Assam",
        "state_code": "AS",
        "type": null
      },
      {
        "id": 4037,
        "name": "Bihar",
        "state_code": "BR",
        "type": null
      },
      {
        "id": 4031,
        "name": "Chandigarh",
        "state_code": "CH",
        "type": null
      },
      {
        "id": 4040,
        "name": "Chhattisgarh",
        "state_code": "CT",
        "type": null
      },
      {
        "id": 4033,
        "name": "Dadra and Nagar Haveli and Daman and Diu",
        "state_code": "DH",
        "type": null
      },
      {
        "id": 4021,
        "name": "Delhi",
        "state_code": "DL",
        "type": null
      },
      {
        "id": 4009,
        "name": "Goa",
        "state_code": "GA",
        "type": null
      },
      {
        "id": 4030,
        "name": "Gujarat",
        "state_code": "GJ",
        "type": null
      },
      {
        "id": 4007,
        "name": "Haryana",
        "state_code": "HR",
        "type": null
      },
      {
        "id": 4020,
        "name": "Himachal Pradesh",
        "state_code": "HP",
        "type": null
      },
      {
        "id": 4029,
        "name": "Jammu and Kashmir",
        "state_code": "JK",
        "type": null
      },
      {
        "id": 4025,
        "name": "Jharkhand",
        "state_code": "JH",
        "type": null
      },
      {
        "id": 4026,
        "name": "Karnataka",
        "state_code": "KA",
        "type": null
      },
      {
        "id": 4028,
        "name": "Kerala",
        "state_code": "KL",
        "type": null
      },
      {
        "id": 4852,
        "name": "Ladakh",
        "state_code": "LA",
        "type": null
      },
      {
        "id": 4019,
        "name": "Lakshadweep",
        "state_code": "LD",
        "type": null
      },
      {
        "id": 4039,
        "name": "Madhya Pradesh",
        "state_code": "MP",
        "type": null
      },
      {
        "id": 4008,
        "name": "Maharashtra",
        "state_code": "MH",
        "type": null
      },
      {
        "id": 4010,
        "name": "Manipur",
        "state_code": "MN",
        "type": null
      },
      {
        "id": 4006,
        "name": "Meghalaya",
        "state_code": "ML",
        "type": null
      },
      {
        "id": 4036,
        "name": "Mizoram",
        "state_code": "MZ",
        "type": null
      },
      {
        "id": 4018,
        "name": "Nagaland",
        "state_code": "NL",
        "type": null
      },
      {
        "id": 4013,
        "name": "Odisha",
        "state_code": "OR",
        "type": null
      },
      {
        "id": 4011,
        "name": "Puducherry",
        "state_code": "PY",
        "type": null
      },
      {
        "id": 4015,
        "name": "Punjab",
        "state_code": "PB",
        "type": null
      },
      {
        "id": 4014,
        "name": "Rajasthan",
        "state_code": "RJ",
        "type": null
      },
      {
        "id": 4034,
        "name": "Sikkim",
        "state_code": "SK",
        "type": null
      },
      {
        "id": 4035,
        "name": "Tamil Nadu",
        "state_code": "TN",
        "type": null
      },
      {
        "id": 4012,
        "name": "Telangana",
        "state_code": "TG",
        "type": null
      },
      {
        "id": 4038,
        "name": "Tripura",
        "state_code": "TR",
        "type": null
      },
      {
        "id": 4022,
        "name": "Uttar Pradesh",
        "state_code": "UP",
        "type": null
      },
      {
        "id": 4016,
        "name": "Uttarakhand",
        "state_code": "UT",
        "type": null
      },
      {
        "id": 4853,
        "name": "West Bengal",
        "state_code": "WB",
        "type": null
      }
    ]
  },
  {
    "name": "Indonesia",
    "iso3": "IDN",
    "iso2": "ID",
    "numeric_code": "360",
    "phone_code": "62",
    "capital": "Jakarta",
    "currency": "IDR",
    "currency_name": "Indonesian rupiah",
    "currency_symbol": "Rp",
    "tld": ".id",
    "native": "Indonesia",
    "region": "Asia",
    "subregion": "South-Eastern Asia",
    "emoji": "🇮🇩",
    "emojiU": "U+1F1EE U+1F1E9",
    "states": [
      {
        "id": 1822,
        "name": "Aceh",
        "state_code": "AC",
        "type": null
      },
      {
        "id": 1826,
        "name": "Bali",
        "state_code": "BA",
        "type": null
      },
      {
        "id": 1810,
        "name": "Banten",
        "state_code": "BT",
        "type": null
      },
      {
        "id": 1793,
        "name": "Bengkulu",
        "state_code": "BE",
        "type": null
      },
      {
        "id": 1829,
        "name": "DI Yogyakarta",
        "state_code": "YO",
        "type": null
      },
      {
        "id": 1805,
        "name": "DKI Jakarta",
        "state_code": "JK",
        "type": null
      },
      {
        "id": 1812,
        "name": "Gorontalo",
        "state_code": "GO",
        "type": null
      },
      {
        "id": 1815,
        "name": "Jambi",
        "state_code": "JA",
        "type": null
      },
      {
        "id": 1825,
        "name": "Jawa Barat",
        "state_code": "JB",
        "type": null
      },
      {
        "id": 1802,
        "name": "Jawa Tengah",
        "state_code": "JT",
        "type": null
      },
      {
        "id": 1827,
        "name": "Jawa Timur",
        "state_code": "JI",
        "type": null
      },
      {
        "id": 1806,
        "name": "Kalimantan Barat",
        "state_code": "KA",
        "type": null
      },
      {
        "id": 1819,
        "name": "Kalimantan Selatan",
        "state_code": "KS",
        "type": null
      },
      {
        "id": 1794,
        "name": "Kalimantan Tengah",
        "state_code": "KT",
        "type": null
      },
      {
        "id": 1804,
        "name": "Kalimantan Timur",
        "state_code": "KI",
        "type": null
      },
      {
        "id": 1824,
        "name": "Kalimantan Utara",
        "state_code": "KU",
        "type": null
      },
      {
        "id": 1820,
        "name": "Kepulauan Bangka Belitung",
        "state_code": "BB",
        "type": null
      },
      {
        "id": 1807,
        "name": "Kepulauan Riau",
        "state_code": "KR",
        "type": null
      },
      {
        "id": 1811,
        "name": "Lampung",
        "state_code": "LA",
        "type": null
      },
      {
        "id": 1800,
        "name": "Maluku",
        "state_code": "MA",
        "type": null
      },
      {
        "id": 1801,
        "name": "Maluku Utara",
        "state_code": "MU",
        "type": null
      },
      {
        "id": 1814,
        "name": "Nusa Tenggara Barat",
        "state_code": "NB",
        "type": null
      },
      {
        "id": 1818,
        "name": "Nusa Tenggara Timur",
        "state_code": "NT",
        "type": null
      },
      {
        "id": 1798,
        "name": "Papua",
        "state_code": "PA",
        "type": null
      },
      {
        "id": 1799,
        "name": "Papua Barat",
        "state_code": "PB",
        "type": null
      },
      {
        "id": 1809,
        "name": "Riau",
        "state_code": "RI",
        "type": null
      },
      {
        "id": 1817,
        "name": "Sulawesi Barat",
        "state_code": "SR",
        "type": null
      },
      {
        "id": 1795,
        "name": "Sulawesi Selatan",
        "state_code": "SN",
        "type": null
      },
      {
        "id": 1813,
        "name": "Sulawesi Tengah",
        "state_code": "ST",
        "type": null
      },
      {
        "id": 1796,
        "name": "Sulawesi Tenggara",
        "state_code": "SG",
        "type": null
      },
      {
        "id": 1808,
        "name": "Sulawesi Utara",
        "state_code": "SA",
        "type": null
      },
      {
        "id": 1828,
        "name": "Sumatera Barat",
        "state_code": "SB",
        "type": null
      },
      {
        "id": 1816,
        "name": "Sumatera Selatan",
        "state_code": "SS",
        "type": null
      },
      {
        "id": 1792,
        "name": "Sumatera Utara",
        "state_code": "SU",
        "type": null
      }
    ]
  },
  {
    "name": "Iran",
    "iso3": "IRN",
    "iso2": "IR",
    "numeric_code": "364",
    "phone_code": "98",
    "capital": "Tehran",
    "currency": "IRR",
    "currency_name": "Iranian rial",
    "currency_symbol": "﷼",
    "tld": ".ir",
    "native": "ایران",
    "region": "Asia",
    "subregion": "Southern Asia",
    "emoji": "🇮🇷",
    "emojiU": "U+1F1EE U+1F1F7",
    "states": [
      {
        "id": 3929,
        "name": "Alborz Province",
        "state_code": "30",
        "type": null
      },
      {
        "id": 3934,
        "name": "Ardabil Province",
        "state_code": "24",
        "type": null
      },
      {
        "id": 3932,
        "name": "Bushehr Province",
        "state_code": "18",
        "type": null
      },
      {
        "id": 3921,
        "name": "Chaharmahal and Bakhtiari Province",
        "state_code": "14",
        "type": null
      },
      {
        "id": 3944,
        "name": "East Azerbaijan Province",
        "state_code": "03",
        "type": null
      },
      {
        "id": 3939,
        "name": "Fars Province",
        "state_code": "07",
        "type": null
      },
      {
        "id": 3920,
        "name": "Gilan Province",
        "state_code": "01",
        "type": null
      },
      {
        "id": 3933,
        "name": "Golestan Province",
        "state_code": "27",
        "type": null
      },
      {
        "id": 4920,
        "name": "Hamadan Province",
        "state_code": "13",
        "type": null
      },
      {
        "id": 3937,
        "name": "Hormozgan Province",
        "state_code": "22",
        "type": null
      },
      {
        "id": 3918,
        "name": "Ilam Province",
        "state_code": "16",
        "type": null
      },
      {
        "id": 3923,
        "name": "Isfahan Province",
        "state_code": "10",
        "type": null
      },
      {
        "id": 3943,
        "name": "Kerman Province",
        "state_code": "08",
        "type": null
      },
      {
        "id": 3919,
        "name": "Kermanshah Province",
        "state_code": "05",
        "type": null
      },
      {
        "id": 3917,
        "name": "Khuzestan Province",
        "state_code": "06",
        "type": null
      },
      {
        "id": 3926,
        "name": "Kohgiluyeh and Boyer-Ahmad Province",
        "state_code": "17",
        "type": null
      },
      {
        "id": 3935,
        "name": "Kurdistan Province",
        "state_code": "12",
        "type": null
      },
      {
        "id": 3928,
        "name": "Lorestan Province",
        "state_code": "15",
        "type": null
      },
      {
        "id": 3916,
        "name": "Markazi Province",
        "state_code": "00",
        "type": null
      },
      {
        "id": 3938,
        "name": "Mazandaran Province",
        "state_code": "02",
        "type": null
      },
      {
        "id": 3942,
        "name": "North Khorasan Province",
        "state_code": "28",
        "type": null
      },
      {
        "id": 3941,
        "name": "Qazvin Province",
        "state_code": "26",
        "type": null
      },
      {
        "id": 3922,
        "name": "Qom Province",
        "state_code": "25",
        "type": null
      },
      {
        "id": 3927,
        "name": "Razavi Khorasan Province",
        "state_code": "09",
        "type": null
      },
      {
        "id": 3940,
        "name": "Semnan Province",
        "state_code": "20",
        "type": null
      },
      {
        "id": 3931,
        "name": "Sistan and Baluchestan",
        "state_code": "11",
        "type": null
      },
      {
        "id": 3930,
        "name": "South Khorasan Province",
        "state_code": "29",
        "type": null
      },
      {
        "id": 3945,
        "name": "Tehran Province",
        "state_code": "23",
        "type": null
      },
      {
        "id": 3924,
        "name": "West Azarbaijan Province",
        "state_code": "04",
        "type": null
      },
      {
        "id": 3936,
        "name": "Yazd Province",
        "state_code": "21",
        "type": null
      },
      {
        "id": 3925,
        "name": "Zanjan Province",
        "state_code": "19",
        "type": null
      }
    ]
  },
  {
    "name": "Iraq",
    "iso3": "IRQ",
    "iso2": "IQ",
    "numeric_code": "368",
    "phone_code": "964",
    "capital": "Baghdad",
    "currency": "IQD",
    "currency_name": "Iraqi dinar",
    "currency_symbol": "د.ع",
    "tld": ".iq",
    "native": "العراق",
    "region": "Asia",
    "subregion": "Western Asia",
    "emoji": "🇮🇶",
    "emojiU": "U+1F1EE U+1F1F6",
    "states": [
      {
        "id": 3964,
        "name": "Al Anbar Governorate",
        "state_code": "AN",
        "type": null
      },
      {
        "id": 3958,
        "name": "Al Muthanna Governorate",
        "state_code": "MU",
        "type": null
      },
      {
        "id": 3956,
        "name": "Al-Qādisiyyah Governorate",
        "state_code": "QA",
        "type": null
      },
      {
        "id": 3955,
        "name": "Babylon Governorate",
        "state_code": "BB",
        "type": null
      },
      {
        "id": 3959,
        "name": "Baghdad Governorate",
        "state_code": "BG",
        "type": null
      },
      {
        "id": 3960,
        "name": "Basra Governorate",
        "state_code": "BA",
        "type": null
      },
      {
        "id": 3954,
        "name": "Dhi Qar Governorate",
        "state_code": "DQ",
        "type": null
      },
      {
        "id": 3965,
        "name": "Diyala Governorate",
        "state_code": "DI",
        "type": null
      },
      {
        "id": 3967,
        "name": "Dohuk Governorate",
        "state_code": "DA",
        "type": null
      },
      {
        "id": 3968,
        "name": "Erbil Governorate",
        "state_code": "AR",
        "type": null
      },
      {
        "id": 3957,
        "name": "Karbala Governorate",
        "state_code": "KA",
        "type": null
      },
      {
        "id": 3971,
        "name": "Kirkuk Governorate",
        "state_code": "KI",
        "type": null
      },
      {
        "id": 3966,
        "name": "Maysan Governorate",
        "state_code": "MA",
        "type": null
      },
      {
        "id": 3962,
        "name": "Najaf Governorate",
        "state_code": "NA",
        "type": null
      },
      {
        "id": 3963,
        "name": "Nineveh Governorate",
        "state_code": "NI",
        "type": null
      },
      {
        "id": 3961,
        "name": "Saladin Governorate",
        "state_code": "SD",
        "type": null
      },
      {
        "id": 3969,
        "name": "Sulaymaniyah Governorate",
        "state_code": "SU",
        "type": null
      },
      {
        "id": 3970,
        "name": "Wasit Governorate",
        "state_code": "WA",
        "type": null
      }
    ]
  },
  {
    "name": "Ireland",
    "iso3": "IRL",
    "iso2": "IE",
    "numeric_code": "372",
    "phone_code": "353",
    "capital": "Dublin",
    "currency": "EUR",
    "currency_name": "Euro",
    "currency_symbol": "€",
    "tld": ".ie",
    "native": "Éire",
    "region": "Europe",
    "subregion": "Northern Europe",
    "emoji": "🇮🇪",
    "emojiU": "U+1F1EE U+1F1EA",
    "states": [
      {
        "id": 1087,
        "name": "Connacht",
        "state_code": "C",
        "type": null
      },
      {
        "id": 1095,
        "name": "County Carlow",
        "state_code": "CW",
        "type": null
      },
      {
        "id": 1088,
        "name": "County Cavan",
        "state_code": "CN",
        "type": null
      },
      {
        "id": 1091,
        "name": "County Clare",
        "state_code": "CE",
        "type": null
      },
      {
        "id": 1074,
        "name": "County Cork",
        "state_code": "CO",
        "type": null
      },
      {
        "id": 1071,
        "name": "County Donegal",
        "state_code": "DL",
        "type": null
      },
      {
        "id": 1072,
        "name": "County Dublin",
        "state_code": "D",
        "type": null
      },
      {
        "id": 1079,
        "name": "County Galway",
        "state_code": "G",
        "type": null
      },
      {
        "id": 1077,
        "name": "County Kerry",
        "state_code": "KY",
        "type": null
      },
      {
        "id": 1082,
        "name": "County Kildare",
        "state_code": "KE",
        "type": null
      },
      {
        "id": 1090,
        "name": "County Kilkenny",
        "state_code": "KK",
        "type": null
      },
      {
        "id": 1096,
        "name": "County Laois",
        "state_code": "LS",
        "type": null
      },
      {
        "id": 1094,
        "name": "County Limerick",
        "state_code": "LK",
        "type": null
      },
      {
        "id": 1076,
        "name": "County Longford",
        "state_code": "LD",
        "type": null
      },
      {
        "id": 1083,
        "name": "County Louth",
        "state_code": "LH",
        "type": null
      },
      {
        "id": 1084,
        "name": "County Mayo",
        "state_code": "MO",
        "type": null
      },
      {
        "id": 1092,
        "name": "County Meath",
        "state_code": "MH",
        "type": null
      },
      {
        "id": 1075,
        "name": "County Monaghan",
        "state_code": "MN",
        "type": null
      },
      {
        "id": 1078,
        "name": "County Offaly",
        "state_code": "OY",
        "type": null
      },
      {
        "id": 1081,
        "name": "County Roscommon",
        "state_code": "RN",
        "type": null
      },
      {
        "id": 1070,
        "name": "County Sligo",
        "state_code": "SO",
        "type": null
      },
      {
        "id": 1069,
        "name": "County Tipperary",
        "state_code": "TA",
        "type": null
      },
      {
        "id": 1089,
        "name": "County Waterford",
        "state_code": "WD",
        "type": null
      },
      {
        "id": 1097,
        "name": "County Westmeath",
        "state_code": "WH",
        "type": null
      },
      {
        "id": 1093,
        "name": "County Wexford",
        "state_code": "WX",
        "type": null
      },
      {
        "id": 1085,
        "name": "County Wicklow",
        "state_code": "WW",
        "type": null
      },
      {
        "id": 1073,
        "name": "Leinster",
        "state_code": "L",
        "type": null
      },
      {
        "id": 1080,
        "name": "Munster",
        "state_code": "M",
        "type": null
      },
      {
        "id": 1086,
        "name": "Ulster",
        "state_code": "U",
        "type": null
      }
    ]
  },
  {
    "name": "Israel",
    "iso3": "ISR",
    "iso2": "IL",
    "numeric_code": "376",
    "phone_code": "972",
    "capital": "Jerusalem",
    "currency": "ILS",
    "currency_name": "Israeli new shekel",
    "currency_symbol": "₪",
    "tld": ".il",
    "native": "יִשְׂרָאֵל",
    "region": "Asia",
    "subregion": "Western Asia",
    "emoji": "🇮🇱",
    "emojiU": "U+1F1EE U+1F1F1",
    "states": [
      {
        "id": 1367,
        "name": "Central District",
        "state_code": "M",
        "type": null
      },
      {
        "id": 1369,
        "name": "Haifa District",
        "state_code": "HA",
        "type": null
      },
      {
        "id": 1370,
        "name": "Jerusalem District",
        "state_code": "JM",
        "type": null
      },
      {
        "id": 1366,
        "name": "Northern District",
        "state_code": "Z",
        "type": null
      },
      {
        "id": 1368,
        "name": "Southern District",
        "state_code": "D",
        "type": null
      },
      {
        "id": 1371,
        "name": "Tel Aviv District",
        "state_code": "TA",
        "type": null
      }
    ]
  },
  {
    "name": "Italy",
    "iso3": "ITA",
    "iso2": "IT",
    "numeric_code": "380",
    "phone_code": "39",
    "capital": "Rome",
    "currency": "EUR",
    "currency_name": "Euro",
    "currency_symbol": "€",
    "tld": ".it",
    "native": "Italia",
    "region": "Europe",
    "subregion": "Southern Europe",
    "emoji": "🇮🇹",
    "emojiU": "U+1F1EE U+1F1F9",
    "states": [
      {
        "id": 1679,
        "name": "Abruzzo",
        "state_code": "65",
        "type": "region"
      },
      {
        "id": 1716,
        "name": "Aosta Valley",
        "state_code": "23",
        "type": "autonomous region"
      },
      {
        "id": 1688,
        "name": "Apulia",
        "state_code": "75",
        "type": "region"
      },
      {
        "id": 1706,
        "name": "Basilicata",
        "state_code": "77",
        "type": "region"
      },
      {
        "id": 1701,
        "name": "Benevento Province",
        "state_code": "BN",
        "type": "province"
      },
      {
        "id": 1703,
        "name": "Calabria",
        "state_code": "78",
        "type": "region"
      },
      {
        "id": 1669,
        "name": "Campania",
        "state_code": "72",
        "type": "region"
      },
      {
        "id": 1773,
        "name": "Emilia-Romagna",
        "state_code": "45",
        "type": "region"
      },
      {
        "id": 1756,
        "name": "Friuli–Venezia Giulia",
        "state_code": "36",
        "type": "autonomous region"
      },
      {
        "id": 1678,
        "name": "Lazio",
        "state_code": "62",
        "type": "region"
      },
      {
        "id": 1727,
        "name": "Libero consorzio comunale di Agrigento",
        "state_code": "AG",
        "type": "free municipal consortium"
      },
      {
        "id": 1718,
        "name": "Libero consorzio comunale di Caltanissetta",
        "state_code": "CL",
        "type": "free municipal consortium"
      },
      {
        "id": 1723,
        "name": "Libero consorzio comunale di Enna",
        "state_code": "EN",
        "type": "free municipal consortium"
      },
      {
        "id": 1729,
        "name": "Libero consorzio comunale di Ragusa",
        "state_code": "RG",
        "type": "free municipal consortium"
      },
      {
        "id": 1667,
        "name": "Libero consorzio comunale di Siracusa",
        "state_code": "SR",
        "type": "free municipal consortium"
      },
      {
        "id": 1733,
        "name": "Libero consorzio comunale di Trapani",
        "state_code": "TP",
        "type": "free municipal consortium"
      },
      {
        "id": 1768,
        "name": "Liguria",
        "state_code": "42",
        "type": "region"
      },
      {
        "id": 1705,
        "name": "Lombardy",
        "state_code": "25",
        "type": "region"
      },
      {
        "id": 1670,
        "name": "Marche",
        "state_code": "57",
        "type": "region"
      },
      {
        "id": 1772,
        "name": "Metropolitan City of Bari",
        "state_code": "BA",
        "type": "metropolitan city"
      },
      {
        "id": 1684,
        "name": "Metropolitan City of Bologna",
        "state_code": "BO",
        "type": "metropolitan city"
      },
      {
        "id": 1682,
        "name": "Metropolitan City of Cagliari",
        "state_code": "CA",
        "type": "metropolitan city"
      },
      {
        "id": 1766,
        "name": "Metropolitan City of Catania",
        "state_code": "CT",
        "type": "metropolitan city"
      },
      {
        "id": 1680,
        "name": "Metropolitan City of Florence",
        "state_code": "FI",
        "type": "metropolitan city"
      },
      {
        "id": 1699,
        "name": "Metropolitan City of Genoa",
        "state_code": "GE",
        "type": "metropolitan city"
      },
      {
        "id": 1770,
        "name": "Metropolitan City of Messina",
        "state_code": "ME",
        "type": "metropolitan city"
      },
      {
        "id": 1698,
        "name": "Metropolitan City of Milan",
        "state_code": "MI",
        "type": "metropolitan city"
      },
      {
        "id": 1724,
        "name": "Metropolitan City of Naples",
        "state_code": "NA",
        "type": "metropolitan city"
      },
      {
        "id": 1668,
        "name": "Metropolitan City of Palermo",
        "state_code": "PA",
        "type": "province"
      },
      {
        "id": 1671,
        "name": "Metropolitan City of Reggio Calabria",
        "state_code": "RC",
        "type": "metropolitan city"
      },
      {
        "id": 1711,
        "name": "Metropolitan City of Rome",
        "state_code": "RM",
        "type": "metropolitan city"
      },
      {
        "id": 1710,
        "name": "Metropolitan City of Turin",
        "state_code": "TO",
        "type": "metropolitan city"
      },
      {
        "id": 1673,
        "name": "Metropolitan City of Venice",
        "state_code": "VE",
        "type": "metropolitan city"
      },
      {
        "id": 1695,
        "name": "Molise",
        "state_code": "67",
        "type": "region"
      },
      {
        "id": 1693,
        "name": "Pesaro and Urbino Province",
        "state_code": "PU",
        "type": "province"
      },
      {
        "id": 1702,
        "name": "Piedmont",
        "state_code": "21",
        "type": "region"
      },
      {
        "id": 1783,
        "name": "Province of Alessandria",
        "state_code": "AL",
        "type": "province"
      },
      {
        "id": 1672,
        "name": "Province of Ancona",
        "state_code": "AN",
        "type": "province"
      },
      {
        "id": 1681,
        "name": "Province of Ascoli Piceno",
        "state_code": "AP",
        "type": "province"
      },
      {
        "id": 1780,
        "name": "Province of Asti",
        "state_code": "AT",
        "type": "province"
      },
      {
        "id": 1692,
        "name": "Province of Avellino",
        "state_code": "AV",
        "type": "province"
      },
      {
        "id": 1686,
        "name": "Province of Barletta-Andria-Trani",
        "state_code": "BT",
        "type": "province"
      },
      {
        "id": 1689,
        "name": "Province of Belluno",
        "state_code": "BL",
        "type": "province"
      },
      {
        "id": 1704,
        "name": "Province of Bergamo",
        "state_code": "BG",
        "type": "province"
      },
      {
        "id": 1778,
        "name": "Province of Biella",
        "state_code": "BI",
        "type": "province"
      },
      {
        "id": 1717,
        "name": "Province of Brescia",
        "state_code": "BS",
        "type": "province"
      },
      {
        "id": 1714,
        "name": "Province of Brindisi",
        "state_code": "BR",
        "type": "province"
      },
      {
        "id": 1721,
        "name": "Province of Campobasso",
        "state_code": "CB",
        "type": "province"
      },
      {
        "id": 1730,
        "name": "Province of Carbonia-Iglesias",
        "state_code": "CI",
        "type": "province"
      },
      {
        "id": 1731,
        "name": "Province of Caserta",
        "state_code": "CE",
        "type": "province"
      },
      {
        "id": 1728,
        "name": "Province of Catanzaro",
        "state_code": "CZ",
        "type": "province"
      },
      {
        "id": 1739,
        "name": "Province of Chieti",
        "state_code": "CH",
        "type": "province"
      },
      {
        "id": 1740,
        "name": "Province of Como",
        "state_code": "CO",
        "type": "province"
      },
      {
        "id": 1742,
        "name": "Province of Cosenza",
        "state_code": "CS",
        "type": "province"
      },
      {
        "id": 1751,
        "name": "Province of Cremona",
        "state_code": "CR",
        "type": "province"
      },
      {
        "id": 1754,
        "name": "Province of Crotone",
        "state_code": "KR",
        "type": "province"
      },
      {
        "id": 1775,
        "name": "Province of Cuneo",
        "state_code": "CN",
        "type": "province"
      },
      {
        "id": 1744,
        "name": "Province of Fermo",
        "state_code": "FM",
        "type": "province"
      },
      {
        "id": 1746,
        "name": "Province of Ferrara",
        "state_code": "FE",
        "type": "province"
      },
      {
        "id": 1771,
        "name": "Province of Foggia",
        "state_code": "FG",
        "type": "province"
      },
      {
        "id": 1779,
        "name": "Province of Forlì-Cesena",
        "state_code": "FC",
        "type": "province"
      },
      {
        "id": 1776,
        "name": "Province of Frosinone",
        "state_code": "FR",
        "type": "province"
      },
      {
        "id": 1777,
        "name": "Province of Gorizia",
        "state_code": "GO",
        "type": "decentralized regional entity"
      },
      {
        "id": 1787,
        "name": "Province of Grosseto",
        "state_code": "GR",
        "type": "province"
      },
      {
        "id": 1788,
        "name": "Province of Imperia",
        "state_code": "IM",
        "type": "province"
      },
      {
        "id": 1789,
        "name": "Province of Isernia",
        "state_code": "IS",
        "type": "province"
      },
      {
        "id": 1781,
        "name": "Province of L'Aquila",
        "state_code": "AQ",
        "type": "province"
      },
      {
        "id": 1791,
        "name": "Province of La Spezia",
        "state_code": "SP",
        "type": "province"
      },
      {
        "id": 1674,
        "name": "Province of Latina",
        "state_code": "LT",
        "type": "province"
      },
      {
        "id": 1675,
        "name": "Province of Lecce",
        "state_code": "LE",
        "type": "province"
      },
      {
        "id": 1677,
        "name": "Province of Lecco",
        "state_code": "LC",
        "type": "province"
      },
      {
        "id": 1745,
        "name": "Province of Livorno",
        "state_code": "LI",
        "type": "province"
      },
      {
        "id": 1747,
        "name": "Province of Lodi",
        "state_code": "LO",
        "type": "province"
      },
      {
        "id": 1749,
        "name": "Province of Lucca",
        "state_code": "LU",
        "type": "province"
      },
      {
        "id": 1750,
        "name": "Province of Macerata",
        "state_code": "MC",
        "type": "province"
      },
      {
        "id": 1758,
        "name": "Province of Mantua",
        "state_code": "MN",
        "type": "province"
      },
      {
        "id": 1759,
        "name": "Province of Massa and Carrara",
        "state_code": "MS",
        "type": "province"
      },
      {
        "id": 1760,
        "name": "Province of Matera",
        "state_code": "MT",
        "type": "province"
      },
      {
        "id": 1761,
        "name": "Province of Medio Campidano",
        "state_code": "VS",
        "type": "province"
      },
      {
        "id": 1757,
        "name": "Province of Modena",
        "state_code": "MO",
        "type": "province"
      },
      {
        "id": 1769,
        "name": "Province of Monza and Brianza",
        "state_code": "MB",
        "type": "province"
      },
      {
        "id": 1774,
        "name": "Province of Novara",
        "state_code": "NO",
        "type": "province"
      },
      {
        "id": 1790,
        "name": "Province of Nuoro",
        "state_code": "NU",
        "type": "province"
      },
      {
        "id": 1782,
        "name": "Province of Ogliastra",
        "state_code": "OG",
        "type": "province"
      },
      {
        "id": 1784,
        "name": "Province of Olbia-Tempio",
        "state_code": "OT",
        "type": "metropolitan city"
      },
      {
        "id": 1786,
        "name": "Province of Oristano",
        "state_code": "OR",
        "type": "province"
      },
      {
        "id": 1665,
        "name": "Province of Padua",
        "state_code": "PD",
        "type": "province"
      },
      {
        "id": 1666,
        "name": "Province of Parma",
        "state_code": "PR",
        "type": "province"
      },
      {
        "id": 1676,
        "name": "Province of Pavia",
        "state_code": "PV",
        "type": "province"
      },
      {
        "id": 1691,
        "name": "Province of Perugia",
        "state_code": "PG",
        "type": "province"
      },
      {
        "id": 1694,
        "name": "Province of Pescara",
        "state_code": "PE",
        "type": "province"
      },
      {
        "id": 1696,
        "name": "Province of Piacenza",
        "state_code": "PC",
        "type": "province"
      },
      {
        "id": 1685,
        "name": "Province of Pisa",
        "state_code": "PI",
        "type": "province"
      },
      {
        "id": 1687,
        "name": "Province of Pistoia",
        "state_code": "PT",
        "type": "province"
      },
      {
        "id": 1690,
        "name": "Province of Pordenone",
        "state_code": "PN",
        "type": "decentralized regional entity"
      },
      {
        "id": 1697,
        "name": "Province of Potenza",
        "state_code": "PZ",
        "type": "province"
      },
      {
        "id": 1700,
        "name": "Province of Prato",
        "state_code": "PO",
        "type": "province"
      },
      {
        "id": 1707,
        "name": "Province of Ravenna",
        "state_code": "RA",
        "type": "province"
      },
      {
        "id": 1708,
        "name": "Province of Reggio Emilia",
        "state_code": "RE",
        "type": "province"
      },
      {
        "id": 1712,
        "name": "Province of Rieti",
        "state_code": "RI",
        "type": "province"
      },
      {
        "id": 1713,
        "name": "Province of Rimini",
        "state_code": "RN",
        "type": "province"
      },
      {
        "id": 1719,
        "name": "Province of Rovigo",
        "state_code": "RO",
        "type": "province"
      },
      {
        "id": 1720,
        "name": "Province of Salerno",
        "state_code": "SA",
        "type": "province"
      },
      {
        "id": 1722,
        "name": "Province of Sassari",
        "state_code": "SS",
        "type": "province"
      },
      {
        "id": 1732,
        "name": "Province of Savona",
        "state_code": "SV",
        "type": "province"
      },
      {
        "id": 1734,
        "name": "Province of Siena",
        "state_code": "SI",
        "type": "province"
      },
      {
        "id": 1741,
        "name": "Province of Sondrio",
        "state_code": "SO",
        "type": "province"
      },
      {
        "id": 1743,
        "name": "Province of Taranto",
        "state_code": "TA",
        "type": "province"
      },
      {
        "id": 1752,
        "name": "Province of Teramo",
        "state_code": "TE",
        "type": "province"
      },
      {
        "id": 1755,
        "name": "Province of Terni",
        "state_code": "TR",
        "type": "province"
      },
      {
        "id": 1762,
        "name": "Province of Treviso",
        "state_code": "TV",
        "type": "province"
      },
      {
        "id": 1763,
        "name": "Province of Trieste",
        "state_code": "TS",
        "type": "decentralized regional entity"
      },
      {
        "id": 1764,
        "name": "Province of Udine",
        "state_code": "UD",
        "type": "decentralized regional entity"
      },
      {
        "id": 1765,
        "name": "Province of Varese",
        "state_code": "VA",
        "type": "province"
      },
      {
        "id": 1726,
        "name": "Province of Verbano-Cusio-Ossola",
        "state_code": "VB",
        "type": "province"
      },
      {
        "id": 1785,
        "name": "Province of Vercelli",
        "state_code": "VC",
        "type": "province"
      },
      {
        "id": 1736,
        "name": "Province of Verona",
        "state_code": "VR",
        "type": "province"
      },
      {
        "id": 1737,
        "name": "Province of Vibo Valentia",
        "state_code": "VV",
        "type": "province"
      },
      {
        "id": 1738,
        "name": "Province of Vicenza",
        "state_code": "VI",
        "type": "province"
      },
      {
        "id": 1735,
        "name": "Province of Viterbo",
        "state_code": "VT",
        "type": "province"
      },
      {
        "id": 1715,
        "name": "Sardinia",
        "state_code": "88",
        "type": "autonomous region"
      },
      {
        "id": 1709,
        "name": "Sicily",
        "state_code": "82",
        "type": "autonomous region"
      },
      {
        "id": 1767,
        "name": "South Tyrol",
        "state_code": "BZ",
        "type": "autonomous province"
      },
      {
        "id": 1748,
        "name": "Trentino",
        "state_code": "TN",
        "type": "autonomous province"
      },
      {
        "id": 1725,
        "name": "Trentino-South Tyrol",
        "state_code": "32",
        "type": "autonomous region"
      },
      {
        "id": 1664,
        "name": "Tuscany",
        "state_code": "52",
        "type": "region"
      },
      {
        "id": 1683,
        "name": "Umbria",
        "state_code": "55",
        "type": "region"
      },
      {
        "id": 1753,
        "name": "Veneto",
        "state_code": "34",
        "type": "region"
      }
    ]
  },
  {
    "name": "Jamaica",
    "iso3": "JAM",
    "iso2": "JM",
    "numeric_code": "388",
    "phone_code": "+1-876",
    "capital": "Kingston",
    "currency": "JMD",
    "currency_name": "Jamaican dollar",
    "currency_symbol": "J$",
    "tld": ".jm",
    "native": "Jamaica",
    "region": "Americas",
    "subregion": "Caribbean",
    "emoji": "🇯🇲",
    "emojiU": "U+1F1EF U+1F1F2",
    "states": [
      {
        "id": 3753,
        "name": "Clarendon Parish",
        "state_code": "13",
        "type": null
      },
      {
        "id": 3749,
        "name": "Hanover Parish",
        "state_code": "09",
        "type": null
      },
      {
        "id": 3748,
        "name": "Kingston Parish",
        "state_code": "01",
        "type": null
      },
      {
        "id": 3754,
        "name": "Manchester Parish",
        "state_code": "12",
        "type": null
      },
      {
        "id": 3752,
        "name": "Portland Parish",
        "state_code": "04",
        "type": null
      },
      {
        "id": 3751,
        "name": "Saint Andrew",
        "state_code": "02",
        "type": null
      },
      {
        "id": 3744,
        "name": "Saint Ann Parish",
        "state_code": "06",
        "type": null
      },
      {
        "id": 3746,
        "name": "Saint Catherine Parish",
        "state_code": "14",
        "type": null
      },
      {
        "id": 3743,
        "name": "Saint Elizabeth Parish",
        "state_code": "11",
        "type": null
      },
      {
        "id": 3745,
        "name": "Saint James Parish",
        "state_code": "08",
        "type": null
      },
      {
        "id": 3747,
        "name": "Saint Mary Parish",
        "state_code": "05",
        "type": null
      },
      {
        "id": 3750,
        "name": "Saint Thomas Parish",
        "state_code": "03",
        "type": null
      },
      {
        "id": 3755,
        "name": "Trelawny Parish",
        "state_code": "07",
        "type": null
      },
      {
        "id": 3742,
        "name": "Westmoreland Parish",
        "state_code": "10",
        "type": null
      }
    ]
  },
  {
    "name": "Japan",
    "iso3": "JPN",
    "iso2": "JP",
    "numeric_code": "392",
    "phone_code": "81",
    "capital": "Tokyo",
    "currency": "JPY",
    "currency_name": "Japanese yen",
    "currency_symbol": "¥",
    "tld": ".jp",
    "native": "日本",
    "region": "Asia",
    "subregion": "Eastern Asia",
    "emoji": "🇯🇵",
    "emojiU": "U+1F1EF U+1F1F5",
    "states": [
      {
        "id": 827,
        "name": "Aichi Prefecture",
        "state_code": "23",
        "type": null
      },
      {
        "id": 829,
        "name": "Akita Prefecture",
        "state_code": "05",
        "type": null
      },
      {
        "id": 839,
        "name": "Aomori Prefecture",
        "state_code": "02",
        "type": null
      },
      {
        "id": 821,
        "name": "Chiba Prefecture",
        "state_code": "12",
        "type": null
      },
      {
        "id": 865,
        "name": "Ehime Prefecture",
        "state_code": "38",
        "type": null
      },
      {
        "id": 848,
        "name": "Fukui Prefecture",
        "state_code": "18",
        "type": null
      },
      {
        "id": 861,
        "name": "Fukuoka Prefecture",
        "state_code": "40",
        "type": null
      },
      {
        "id": 847,
        "name": "Fukushima Prefecture",
        "state_code": "07",
        "type": null
      },
      {
        "id": 858,
        "name": "Gifu Prefecture",
        "state_code": "21",
        "type": null
      },
      {
        "id": 862,
        "name": "Gunma Prefecture",
        "state_code": "10",
        "type": null
      },
      {
        "id": 828,
        "name": "Hiroshima Prefecture",
        "state_code": "34",
        "type": null
      },
      {
        "id": 832,
        "name": "Hokkaidō Prefecture",
        "state_code": "01",
        "type": null
      },
      {
        "id": 831,
        "name": "Hyōgo Prefecture",
        "state_code": "28",
        "type": null
      },
      {
        "id": 851,
        "name": "Ibaraki Prefecture",
        "state_code": "08",
        "type": null
      },
      {
        "id": 830,
        "name": "Ishikawa Prefecture",
        "state_code": "17",
        "type": null
      },
      {
        "id": 856,
        "name": "Iwate Prefecture",
        "state_code": "03",
        "type": null
      },
      {
        "id": 864,
        "name": "Kagawa Prefecture",
        "state_code": "37",
        "type": null
      },
      {
        "id": 840,
        "name": "Kagoshima Prefecture",
        "state_code": "46",
        "type": null
      },
      {
        "id": 842,
        "name": "Kanagawa Prefecture",
        "state_code": "14",
        "type": null
      },
      {
        "id": 4924,
        "name": "Kōchi Prefecture",
        "state_code": "39",
        "type": null
      },
      {
        "id": 846,
        "name": "Kumamoto Prefecture",
        "state_code": "43",
        "type": null
      },
      {
        "id": 834,
        "name": "Kyōto Prefecture",
        "state_code": "26",
        "type": null
      },
      {
        "id": 833,
        "name": "Mie Prefecture",
        "state_code": "24",
        "type": null
      },
      {
        "id": 857,
        "name": "Miyagi Prefecture",
        "state_code": "04",
        "type": null
      },
      {
        "id": 855,
        "name": "Miyazaki Prefecture",
        "state_code": "45",
        "type": null
      },
      {
        "id": 843,
        "name": "Nagano Prefecture",
        "state_code": "20",
        "type": null
      },
      {
        "id": 849,
        "name": "Nagasaki Prefecture",
        "state_code": "42",
        "type": null
      },
      {
        "id": 824,
        "name": "Nara Prefecture",
        "state_code": "29",
        "type": null
      },
      {
        "id": 841,
        "name": "Niigata Prefecture",
        "state_code": "15",
        "type": null
      },
      {
        "id": 822,
        "name": "Ōita Prefecture",
        "state_code": "44",
        "type": null
      },
      {
        "id": 820,
        "name": "Okayama Prefecture",
        "state_code": "33",
        "type": null
      },
      {
        "id": 853,
        "name": "Okinawa Prefecture",
        "state_code": "47",
        "type": null
      },
      {
        "id": 859,
        "name": "Ōsaka Prefecture",
        "state_code": "27",
        "type": null
      },
      {
        "id": 863,
        "name": "Saga Prefecture",
        "state_code": "41",
        "type": null
      },
      {
        "id": 860,
        "name": "Saitama Prefecture",
        "state_code": "11",
        "type": null
      },
      {
        "id": 845,
        "name": "Shiga Prefecture",
        "state_code": "25",
        "type": null
      },
      {
        "id": 826,
        "name": "Shimane Prefecture",
        "state_code": "32",
        "type": null
      },
      {
        "id": 825,
        "name": "Shizuoka Prefecture",
        "state_code": "22",
        "type": null
      },
      {
        "id": 854,
        "name": "Tochigi Prefecture",
        "state_code": "09",
        "type": null
      },
      {
        "id": 836,
        "name": "Tokushima Prefecture",
        "state_code": "36",
        "type": null
      },
      {
        "id": 823,
        "name": "Tokyo",
        "state_code": "13",
        "type": null
      },
      {
        "id": 850,
        "name": "Tottori Prefecture",
        "state_code": "31",
        "type": null
      },
      {
        "id": 838,
        "name": "Toyama Prefecture",
        "state_code": "16",
        "type": null
      },
      {
        "id": 844,
        "name": "Wakayama Prefecture",
        "state_code": "30",
        "type": null
      },
      {
        "id": 837,
        "name": "Yamagata Prefecture",
        "state_code": "06",
        "type": null
      },
      {
        "id": 835,
        "name": "Yamaguchi Prefecture",
        "state_code": "35",
        "type": null
      },
      {
        "id": 852,
        "name": "Yamanashi Prefecture",
        "state_code": "19",
        "type": null
      }
    ]
  },
  {
    "name": "Jersey",
    "iso3": "JEY",
    "iso2": "JE",
    "numeric_code": "832",
    "phone_code": "+44-1534",
    "capital": "Saint Helier",
    "currency": "GBP",
    "currency_name": "British pound",
    "currency_symbol": "£",
    "tld": ".je",
    "native": "Jersey",
    "region": "Europe",
    "subregion": "Northern Europe",
    "emoji": "🇯🇪",
    "emojiU": "U+1F1EF U+1F1EA",
    "states": []
  },
  {
    "name": "Jordan",
    "iso3": "JOR",
    "iso2": "JO",
    "numeric_code": "400",
    "phone_code": "962",
    "capital": "Amman",
    "currency": "JOD",
    "currency_name": "Jordanian dinar",
    "currency_symbol": "ا.د",
    "tld": ".jo",
    "native": "الأردن",
    "region": "Asia",
    "subregion": "Western Asia",
    "emoji": "🇯🇴",
    "emojiU": "U+1F1EF U+1F1F4",
    "states": [
      {
        "id": 963,
        "name": "Ajloun Governorate",
        "state_code": "AJ",
        "type": null
      },
      {
        "id": 965,
        "name": "Amman Governorate",
        "state_code": "AM",
        "type": null
      },
      {
        "id": 959,
        "name": "Aqaba Governorate",
        "state_code": "AQ",
        "type": null
      },
      {
        "id": 961,
        "name": "Balqa Governorate",
        "state_code": "BA",
        "type": null
      },
      {
        "id": 960,
        "name": "Irbid Governorate",
        "state_code": "IR",
        "type": null
      },
      {
        "id": 966,
        "name": "Jerash Governorate",
        "state_code": "JA",
        "type": null
      },
      {
        "id": 956,
        "name": "Karak Governorate",
        "state_code": "KA",
        "type": null
      },
      {
        "id": 964,
        "name": "Ma'an Governorate",
        "state_code": "MN",
        "type": null
      },
      {
        "id": 958,
        "name": "Madaba Governorate",
        "state_code": "MD",
        "type": null
      },
      {
        "id": 962,
        "name": "Mafraq Governorate",
        "state_code": "MA",
        "type": null
      },
      {
        "id": 957,
        "name": "Tafilah Governorate",
        "state_code": "AT",
        "type": null
      },
      {
        "id": 967,
        "name": "Zarqa Governorate",
        "state_code": "AZ",
        "type": null
      }
    ]
  },
  {
    "name": "Kazakhstan",
    "iso3": "KAZ",
    "iso2": "KZ",
    "numeric_code": "398",
    "phone_code": "7",
    "capital": "Astana",
    "currency": "KZT",
    "currency_name": "Kazakhstani tenge",
    "currency_symbol": "лв",
    "tld": ".kz",
    "native": "Қазақстан",
    "region": "Asia",
    "subregion": "Central Asia",
    "emoji": "🇰🇿",
    "emojiU": "U+1F1F0 U+1F1FF",
    "states": [
      {
        "id": 145,
        "name": "Akmola Region",
        "state_code": "AKM",
        "type": null
      },
      {
        "id": 151,
        "name": "Aktobe Region",
        "state_code": "AKT",
        "type": null
      },
      {
        "id": 152,
        "name": "Almaty",
        "state_code": "ALA",
        "type": null
      },
      {
        "id": 143,
        "name": "Almaty Region",
        "state_code": "ALM",
        "type": null
      },
      {
        "id": 153,
        "name": "Atyrau Region",
        "state_code": "ATY",
        "type": null
      },
      {
        "id": 155,
        "name": "Baikonur",
        "state_code": "BAY",
        "type": null
      },
      {
        "id": 154,
        "name": "East Kazakhstan Region",
        "state_code": "VOS",
        "type": null
      },
      {
        "id": 147,
        "name": "Jambyl Region",
        "state_code": "ZHA",
        "type": null
      },
      {
        "id": 150,
        "name": "Karaganda Region",
        "state_code": "KAR",
        "type": null
      },
      {
        "id": 157,
        "name": "Kostanay Region",
        "state_code": "KUS",
        "type": null
      },
      {
        "id": 142,
        "name": "Kyzylorda Region",
        "state_code": "KZY",
        "type": null
      },
      {
        "id": 141,
        "name": "Mangystau Region",
        "state_code": "MAN",
        "type": null
      },
      {
        "id": 144,
        "name": "North Kazakhstan Region",
        "state_code": "SEV",
        "type": null
      },
      {
        "id": 156,
        "name": "Nur-Sultan",
        "state_code": "AST",
        "type": null
      },
      {
        "id": 146,
        "name": "Pavlodar Region",
        "state_code": "PAV",
        "type": null
      },
      {
        "id": 149,
        "name": "Turkestan Region",
        "state_code": "YUZ",
        "type": null
      },
      {
        "id": 148,
        "name": "West Kazakhstan Province",
        "state_code": "ZAP",
        "type": null
      }
    ]
  },
  {
    "name": "Kenya",
    "iso3": "KEN",
    "iso2": "KE",
    "numeric_code": "404",
    "phone_code": "254",
    "capital": "Nairobi",
    "currency": "KES",
    "currency_name": "Kenyan shilling",
    "currency_symbol": "KSh",
    "tld": ".ke",
    "native": "Kenya",
    "region": "Africa",
    "subregion": "Eastern Africa",
    "emoji": "🇰🇪",
    "emojiU": "U+1F1F0 U+1F1EA",
    "states": [
      {
        "id": 181,
        "name": "Baringo",
        "state_code": "01",
        "type": "county"
      },
      {
        "id": 210,
        "name": "Bomet",
        "state_code": "02",
        "type": "county"
      },
      {
        "id": 168,
        "name": "Bungoma",
        "state_code": "03",
        "type": "county"
      },
      {
        "id": 161,
        "name": "Busia",
        "state_code": "04",
        "type": "county"
      },
      {
        "id": 201,
        "name": "Elgeyo-Marakwet",
        "state_code": "05",
        "type": "county"
      },
      {
        "id": 163,
        "name": "Embu",
        "state_code": "06",
        "type": "county"
      },
      {
        "id": 196,
        "name": "Garissa",
        "state_code": "07",
        "type": "county"
      },
      {
        "id": 195,
        "name": "Homa Bay",
        "state_code": "08",
        "type": "county"
      },
      {
        "id": 170,
        "name": "Isiolo",
        "state_code": "09",
        "type": "county"
      },
      {
        "id": 197,
        "name": "Kajiado",
        "state_code": "10",
        "type": "county"
      },
      {
        "id": 158,
        "name": "Kakamega",
        "state_code": "11",
        "type": "county"
      },
      {
        "id": 193,
        "name": "Kericho",
        "state_code": "12",
        "type": "county"
      },
      {
        "id": 199,
        "name": "Kiambu",
        "state_code": "13",
        "type": "county"
      },
      {
        "id": 174,
        "name": "Kilifi",
        "state_code": "14",
        "type": "county"
      },
      {
        "id": 167,
        "name": "Kirinyaga",
        "state_code": "15",
        "type": "county"
      },
      {
        "id": 159,
        "name": "Kisii",
        "state_code": "16",
        "type": "county"
      },
      {
        "id": 171,
        "name": "Kisumu",
        "state_code": "17",
        "type": "county"
      },
      {
        "id": 211,
        "name": "Kitui",
        "state_code": "18",
        "type": "county"
      },
      {
        "id": 173,
        "name": "Kwale",
        "state_code": "19",
        "type": "county"
      },
      {
        "id": 164,
        "name": "Laikipia",
        "state_code": "20",
        "type": "county"
      },
      {
        "id": 166,
        "name": "Lamu",
        "state_code": "21",
        "type": "county"
      },
      {
        "id": 184,
        "name": "Machakos",
        "state_code": "22",
        "type": "county"
      },
      {
        "id": 188,
        "name": "Makueni",
        "state_code": "23",
        "type": "county"
      },
      {
        "id": 187,
        "name": "Mandera",
        "state_code": "24",
        "type": "county"
      },
      {
        "id": 194,
        "name": "Marsabit",
        "state_code": "25",
        "type": "county"
      },
      {
        "id": 198,
        "name": "Meru",
        "state_code": "26",
        "type": "county"
      },
      {
        "id": 190,
        "name": "Migori",
        "state_code": "27",
        "type": "county"
      },
      {
        "id": 200,
        "name": "Mombasa",
        "state_code": "28",
        "type": "county"
      },
      {
        "id": 178,
        "name": "Murang'a",
        "state_code": "29",
        "type": "county"
      },
      {
        "id": 191,
        "name": "Nairobi City",
        "state_code": "30",
        "type": "county"
      },
      {
        "id": 203,
        "name": "Nakuru",
        "state_code": "31",
        "type": "county"
      },
      {
        "id": 165,
        "name": "Nandi",
        "state_code": "32",
        "type": "county"
      },
      {
        "id": 175,
        "name": "Narok",
        "state_code": "33",
        "type": "county"
      },
      {
        "id": 209,
        "name": "Nyamira",
        "state_code": "34",
        "type": "county"
      },
      {
        "id": 192,
        "name": "Nyandarua",
        "state_code": "35",
        "type": "county"
      },
      {
        "id": 180,
        "name": "Nyeri",
        "state_code": "36",
        "type": "county"
      },
      {
        "id": 207,
        "name": "Samburu",
        "state_code": "37",
        "type": "county"
      },
      {
        "id": 186,
        "name": "Siaya",
        "state_code": "38",
        "type": "county"
      },
      {
        "id": 176,
        "name": "Taita–Taveta",
        "state_code": "39",
        "type": "county"
      },
      {
        "id": 205,
        "name": "Tana River",
        "state_code": "40",
        "type": "county"
      },
      {
        "id": 185,
        "name": "Tharaka-Nithi",
        "state_code": "41",
        "type": "county"
      },
      {
        "id": 183,
        "name": "Trans Nzoia",
        "state_code": "42",
        "type": "county"
      },
      {
        "id": 206,
        "name": "Turkana",
        "state_code": "43",
        "type": "county"
      },
      {
        "id": 169,
        "name": "Uasin Gishu",
        "state_code": "44",
        "type": "county"
      },
      {
        "id": 202,
        "name": "Vihiga",
        "state_code": "45",
        "type": "county"
      },
      {
        "id": 182,
        "name": "Wajir",
        "state_code": "46",
        "type": "county"
      },
      {
        "id": 208,
        "name": "West Pokot",
        "state_code": "47",
        "type": "county"
      }
    ]
  },
  {
    "name": "Kiribati",
    "iso3": "KIR",
    "iso2": "KI",
    "numeric_code": "296",
    "phone_code": "686",
    "capital": "Tarawa",
    "currency": "AUD",
    "currency_name": "Australian dollar",
    "currency_symbol": "$",
    "tld": ".ki",
    "native": "Kiribati",
    "region": "Oceania",
    "subregion": "Micronesia",
    "emoji": "🇰🇮",
    "emojiU": "U+1F1F0 U+1F1EE",
    "states": [
      {
        "id": 1831,
        "name": "Gilbert Islands",
        "state_code": "G",
        "type": null
      },
      {
        "id": 1832,
        "name": "Line Islands",
        "state_code": "L",
        "type": null
      },
      {
        "id": 1830,
        "name": "Phoenix Islands",
        "state_code": "P",
        "type": null
      }
    ]
  },
  {
    "name": "Kosovo",
    "iso3": "XKX",
    "iso2": "XK",
    "numeric_code": "926",
    "phone_code": "383",
    "capital": "Pristina",
    "currency": "EUR",
    "currency_name": "Euro",
    "currency_symbol": "€",
    "tld": ".xk",
    "native": "Republika e Kosovës",
    "region": "Europe",
    "subregion": "Eastern Europe",
    "emoji": "🇽🇰",
    "emojiU": "U+1F1FD U+1F1F0",
    "states": [
      {
        "id": 4876,
        "name": "Đakovica District (Gjakove)",
        "state_code": "XDG",
        "type": null
      },
      {
        "id": 4877,
        "name": "Gjilan District",
        "state_code": "XGJ",
        "type": null
      },
      {
        "id": 4878,
        "name": "Kosovska Mitrovica District",
        "state_code": "XKM",
        "type": null
      },
      {
        "id": 3738,
        "name": "Peć District",
        "state_code": "XPE",
        "type": null
      },
      {
        "id": 4879,
        "name": "Pristina (Priştine)",
        "state_code": "XPI",
        "type": null
      },
      {
        "id": 3723,
        "name": "Prizren District",
        "state_code": "XPR",
        "type": null
      },
      {
        "id": 4874,
        "name": "Uroševac District (Ferizaj)",
        "state_code": "XUF",
        "type": null
      }
    ]
  },
  {
    "name": "Kuwait",
    "iso3": "KWT",
    "iso2": "KW",
    "numeric_code": "414",
    "phone_code": "965",
    "capital": "Kuwait City",
    "currency": "KWD",
    "currency_name": "Kuwaiti dinar",
    "currency_symbol": "ك.د",
    "tld": ".kw",
    "native": "الكويت",
    "region": "Asia",
    "subregion": "Western Asia",
    "emoji": "🇰🇼",
    "emojiU": "U+1F1F0 U+1F1FC",
    "states": [
      {
        "id": 977,
        "name": "Al Ahmadi Governorate",
        "state_code": "AH",
        "type": null
      },
      {
        "id": 975,
        "name": "Al Farwaniyah Governorate",
        "state_code": "FA",
        "type": null
      },
      {
        "id": 972,
        "name": "Al Jahra Governorate",
        "state_code": "JA",
        "type": null
      },
      {
        "id": 976,
        "name": "Capital Governorate",
        "state_code": "KU",
        "type": null
      },
      {
        "id": 973,
        "name": "Hawalli Governorate",
        "state_code": "HA",
        "type": null
      },
      {
        "id": 974,
        "name": "Mubarak Al-Kabeer Governorate",
        "state_code": "MU",
        "type": null
      }
    ]
  },
  {
    "name": "Kyrgyzstan",
    "iso3": "KGZ",
    "iso2": "KG",
    "numeric_code": "417",
    "phone_code": "996",
    "capital": "Bishkek",
    "currency": "KGS",
    "currency_name": "Kyrgyzstani som",
    "currency_symbol": "лв",
    "tld": ".kg",
    "native": "Кыргызстан",
    "region": "Asia",
    "subregion": "Central Asia",
    "emoji": "🇰🇬",
    "emojiU": "U+1F1F0 U+1F1EC",
    "states": [
      {
        "id": 998,
        "name": "Batken Region",
        "state_code": "B",
        "type": null
      },
      {
        "id": 1001,
        "name": "Bishkek",
        "state_code": "GB",
        "type": null
      },
      {
        "id": 1004,
        "name": "Chuy Region",
        "state_code": "C",
        "type": null
      },
      {
        "id": 1002,
        "name": "Issyk-Kul Region",
        "state_code": "Y",
        "type": null
      },
      {
        "id": 1000,
        "name": "Jalal-Abad Region",
        "state_code": "J",
        "type": null
      },
      {
        "id": 999,
        "name": "Naryn Region",
        "state_code": "N",
        "type": null
      },
      {
        "id": 1003,
        "name": "Osh",
        "state_code": "GO",
        "type": null
      },
      {
        "id": 1005,
        "name": "Osh Region",
        "state_code": "O",
        "type": null
      },
      {
        "id": 997,
        "name": "Talas Region",
        "state_code": "T",
        "type": null
      }
    ]
  },
  {
    "name": "Laos",
    "iso3": "LAO",
    "iso2": "LA",
    "numeric_code": "418",
    "phone_code": "856",
    "capital": "Vientiane",
    "currency": "LAK",
    "currency_name": "Lao kip",
    "currency_symbol": "₭",
    "tld": ".la",
    "native": "ສປປລາວ",
    "region": "Asia",
    "subregion": "South-Eastern Asia",
    "emoji": "🇱🇦",
    "emojiU": "U+1F1F1 U+1F1E6",
    "states": [
      {
        "id": 982,
        "name": "Attapeu Province",
        "state_code": "AT",
        "type": null
      },
      {
        "id": 991,
        "name": "Bokeo Province",
        "state_code": "BK",
        "type": null
      },
      {
        "id": 985,
        "name": "Bolikhamsai Province",
        "state_code": "BL",
        "type": null
      },
      {
        "id": 996,
        "name": "Champasak Province",
        "state_code": "CH",
        "type": null
      },
      {
        "id": 989,
        "name": "Houaphanh Province",
        "state_code": "HO",
        "type": null
      },
      {
        "id": 986,
        "name": "Khammouane Province",
        "state_code": "KH",
        "type": null
      },
      {
        "id": 992,
        "name": "Luang Namtha Province",
        "state_code": "LM",
        "type": null
      },
      {
        "id": 978,
        "name": "Luang Prabang Province",
        "state_code": "LP",
        "type": null
      },
      {
        "id": 988,
        "name": "Oudomxay Province",
        "state_code": "OU",
        "type": null
      },
      {
        "id": 987,
        "name": "Phongsaly Province",
        "state_code": "PH",
        "type": null
      },
      {
        "id": 993,
        "name": "Sainyabuli Province",
        "state_code": "XA",
        "type": null
      },
      {
        "id": 981,
        "name": "Salavan Province",
        "state_code": "SL",
        "type": null
      },
      {
        "id": 990,
        "name": "Savannakhet Province",
        "state_code": "SV",
        "type": null
      },
      {
        "id": 984,
        "name": "Sekong Province",
        "state_code": "XE",
        "type": null
      },
      {
        "id": 979,
        "name": "Vientiane Prefecture",
        "state_code": "VT",
        "type": null
      },
      {
        "id": 980,
        "name": "Vientiane Province",
        "state_code": "VI",
        "type": null
      },
      {
        "id": 994,
        "name": "Xaisomboun",
        "state_code": "XN",
        "type": null
      },
      {
        "id": 983,
        "name": "Xaisomboun Province",
        "state_code": "XS",
        "type": null
      },
      {
        "id": 995,
        "name": "Xiangkhouang Province",
        "state_code": "XI",
        "type": null
      }
    ]
  },
  {
    "name": "Latvia",
    "iso3": "LVA",
    "iso2": "LV",
    "numeric_code": "428",
    "phone_code": "371",
    "capital": "Riga",
    "currency": "EUR",
    "currency_name": "Euro",
    "currency_symbol": "€",
    "tld": ".lv",
    "native": "Latvija",
    "region": "Europe",
    "subregion": "Northern Europe",
    "emoji": "🇱🇻",
    "emojiU": "U+1F1F1 U+1F1FB",
    "states": [
      {
        "id": 4445,
        "name": "Aglona Municipality",
        "state_code": "001",
        "type": null
      },
      {
        "id": 4472,
        "name": "Aizkraukle Municipality",
        "state_code": "002",
        "type": null
      },
      {
        "id": 4496,
        "name": "Aizpute Municipality",
        "state_code": "003",
        "type": null
      },
      {
        "id": 4499,
        "name": "Aknīste Municipality",
        "state_code": "004",
        "type": null
      },
      {
        "id": 4484,
        "name": "Aloja Municipality",
        "state_code": "005",
        "type": null
      },
      {
        "id": 4485,
        "name": "Alsunga Municipality",
        "state_code": "006",
        "type": null
      },
      {
        "id": 4487,
        "name": "Alūksne Municipality",
        "state_code": "007",
        "type": null
      },
      {
        "id": 4497,
        "name": "Amata Municipality",
        "state_code": "008",
        "type": null
      },
      {
        "id": 4457,
        "name": "Ape Municipality",
        "state_code": "009",
        "type": null
      },
      {
        "id": 4481,
        "name": "Auce Municipality",
        "state_code": "010",
        "type": null
      },
      {
        "id": 4427,
        "name": "Babīte Municipality",
        "state_code": "012",
        "type": null
      },
      {
        "id": 4482,
        "name": "Baldone Municipality",
        "state_code": "013",
        "type": null
      },
      {
        "id": 4498,
        "name": "Baltinava Municipality",
        "state_code": "014",
        "type": null
      },
      {
        "id": 4505,
        "name": "Balvi Municipality",
        "state_code": "015",
        "type": null
      },
      {
        "id": 4465,
        "name": "Bauska Municipality",
        "state_code": "016",
        "type": null
      },
      {
        "id": 4471,
        "name": "Beverīna Municipality",
        "state_code": "017",
        "type": null
      },
      {
        "id": 4468,
        "name": "Brocēni Municipality",
        "state_code": "018",
        "type": null
      },
      {
        "id": 4411,
        "name": "Burtnieki Municipality",
        "state_code": "019",
        "type": null
      },
      {
        "id": 4454,
        "name": "Carnikava Municipality",
        "state_code": "020",
        "type": null
      },
      {
        "id": 4469,
        "name": "Cēsis Municipality",
        "state_code": "022",
        "type": null
      },
      {
        "id": 4414,
        "name": "Cesvaine Municipality",
        "state_code": "021",
        "type": null
      },
      {
        "id": 4410,
        "name": "Cibla Municipality",
        "state_code": "023",
        "type": null
      },
      {
        "id": 4504,
        "name": "Dagda Municipality",
        "state_code": "024",
        "type": null
      },
      {
        "id": 4463,
        "name": "Daugavpils",
        "state_code": "DGV",
        "type": null
      },
      {
        "id": 4492,
        "name": "Daugavpils Municipality",
        "state_code": "025",
        "type": null
      },
      {
        "id": 4437,
        "name": "Dobele Municipality",
        "state_code": "026",
        "type": null
      },
      {
        "id": 4428,
        "name": "Dundaga Municipality",
        "state_code": "027",
        "type": null
      },
      {
        "id": 4458,
        "name": "Durbe Municipality",
        "state_code": "028",
        "type": null
      },
      {
        "id": 4448,
        "name": "Engure Municipality",
        "state_code": "029",
        "type": null
      },
      {
        "id": 4444,
        "name": "Ērgļi Municipality",
        "state_code": "030",
        "type": null
      },
      {
        "id": 4510,
        "name": "Garkalne Municipality",
        "state_code": "031",
        "type": null
      },
      {
        "id": 4470,
        "name": "Grobiņa Municipality",
        "state_code": "032",
        "type": null
      },
      {
        "id": 4400,
        "name": "Gulbene Municipality",
        "state_code": "033",
        "type": null
      },
      {
        "id": 4441,
        "name": "Iecava Municipality",
        "state_code": "034",
        "type": null
      },
      {
        "id": 4511,
        "name": "Ikšķile Municipality",
        "state_code": "035",
        "type": null
      },
      {
        "id": 4399,
        "name": "Ilūkste Municipality",
        "state_code": "036",
        "type": null
      },
      {
        "id": 4449,
        "name": "Inčukalns Municipality",
        "state_code": "037",
        "type": null
      },
      {
        "id": 4475,
        "name": "Jaunjelgava Municipality",
        "state_code": "038",
        "type": null
      },
      {
        "id": 4407,
        "name": "Jaunpiebalga Municipality",
        "state_code": "039",
        "type": null
      },
      {
        "id": 4489,
        "name": "Jaunpils Municipality",
        "state_code": "040",
        "type": null
      },
      {
        "id": 4464,
        "name": "Jēkabpils",
        "state_code": "JKB",
        "type": null
      },
      {
        "id": 4438,
        "name": "Jēkabpils Municipality",
        "state_code": "042",
        "type": null
      },
      {
        "id": 4500,
        "name": "Jelgava",
        "state_code": "JEL",
        "type": null
      },
      {
        "id": 4424,
        "name": "Jelgava Municipality",
        "state_code": "041",
        "type": null
      },
      {
        "id": 4446,
        "name": "Jūrmala",
        "state_code": "JUR",
        "type": null
      },
      {
        "id": 4420,
        "name": "Kandava Municipality",
        "state_code": "043",
        "type": null
      },
      {
        "id": 4453,
        "name": "Kārsava Municipality",
        "state_code": "044",
        "type": null
      },
      {
        "id": 4412,
        "name": "Ķegums Municipality",
        "state_code": "051",
        "type": null
      },
      {
        "id": 4435,
        "name": "Ķekava Municipality",
        "state_code": "052",
        "type": null
      },
      {
        "id": 4495,
        "name": "Kocēni Municipality",
        "state_code": "045",
        "type": null
      },
      {
        "id": 4452,
        "name": "Koknese Municipality",
        "state_code": "046",
        "type": null
      },
      {
        "id": 4474,
        "name": "Krāslava Municipality",
        "state_code": "047",
        "type": null
      },
      {
        "id": 4422,
        "name": "Krimulda Municipality",
        "state_code": "048",
        "type": null
      },
      {
        "id": 4413,
        "name": "Krustpils Municipality",
        "state_code": "049",
        "type": null
      },
      {
        "id": 4490,
        "name": "Kuldīga Municipality",
        "state_code": "050",
        "type": null
      },
      {
        "id": 4512,
        "name": "Lielvārde Municipality",
        "state_code": "053",
        "type": null
      },
      {
        "id": 4460,
        "name": "Liepāja",
        "state_code": "LPX",
        "type": null
      },
      {
        "id": 4488,
        "name": "Līgatne Municipality",
        "state_code": "055",
        "type": null
      },
      {
        "id": 4418,
        "name": "Limbaži Municipality",
        "state_code": "054",
        "type": null
      },
      {
        "id": 4401,
        "name": "Līvāni Municipality",
        "state_code": "056",
        "type": null
      },
      {
        "id": 4419,
        "name": "Lubāna Municipality",
        "state_code": "057",
        "type": null
      },
      {
        "id": 4501,
        "name": "Ludza Municipality",
        "state_code": "058",
        "type": null
      },
      {
        "id": 4433,
        "name": "Madona Municipality",
        "state_code": "059",
        "type": null
      },
      {
        "id": 4461,
        "name": "Mālpils Municipality",
        "state_code": "061",
        "type": null
      },
      {
        "id": 4450,
        "name": "Mārupe Municipality",
        "state_code": "062",
        "type": null
      },
      {
        "id": 4513,
        "name": "Mazsalaca Municipality",
        "state_code": "060",
        "type": null
      },
      {
        "id": 4451,
        "name": "Mērsrags Municipality",
        "state_code": "063",
        "type": null
      },
      {
        "id": 4398,
        "name": "Naukšēni Municipality",
        "state_code": "064",
        "type": null
      },
      {
        "id": 4432,
        "name": "Nereta Municipality",
        "state_code": "065",
        "type": null
      },
      {
        "id": 4436,
        "name": "Nīca Municipality",
        "state_code": "066",
        "type": null
      },
      {
        "id": 4416,
        "name": "Ogre Municipality",
        "state_code": "067",
        "type": null
      },
      {
        "id": 4417,
        "name": "Olaine Municipality",
        "state_code": "068",
        "type": null
      },
      {
        "id": 4442,
        "name": "Ozolnieki Municipality",
        "state_code": "069",
        "type": null
      },
      {
        "id": 4507,
        "name": "Pārgauja Municipality",
        "state_code": "070",
        "type": null
      },
      {
        "id": 4467,
        "name": "Pāvilosta Municipality",
        "state_code": "071",
        "type": null
      },
      {
        "id": 4405,
        "name": "Pļaviņas Municipality",
        "state_code": "072",
        "type": null
      },
      {
        "id": 4483,
        "name": "Preiļi Municipality",
        "state_code": "073",
        "type": null
      },
      {
        "id": 4429,
        "name": "Priekule Municipality",
        "state_code": "074",
        "type": null
      },
      {
        "id": 4506,
        "name": "Priekuļi Municipality",
        "state_code": "075",
        "type": null
      },
      {
        "id": 4479,
        "name": "Rauna Municipality",
        "state_code": "076",
        "type": null
      },
      {
        "id": 4509,
        "name": "Rēzekne",
        "state_code": "REZ",
        "type": null
      },
      {
        "id": 4455,
        "name": "Rēzekne Municipality",
        "state_code": "077",
        "type": null
      },
      {
        "id": 4502,
        "name": "Riebiņi Municipality",
        "state_code": "078",
        "type": null
      },
      {
        "id": 4491,
        "name": "Riga",
        "state_code": "RIX",
        "type": null
      },
      {
        "id": 4440,
        "name": "Roja Municipality",
        "state_code": "079",
        "type": null
      },
      {
        "id": 4493,
        "name": "Ropaži Municipality",
        "state_code": "080",
        "type": null
      },
      {
        "id": 4503,
        "name": "Rucava Municipality",
        "state_code": "081",
        "type": null
      },
      {
        "id": 4423,
        "name": "Rugāji Municipality",
        "state_code": "082",
        "type": null
      },
      {
        "id": 4426,
        "name": "Rūjiena Municipality",
        "state_code": "084",
        "type": null
      },
      {
        "id": 4404,
        "name": "Rundāle Municipality",
        "state_code": "083",
        "type": null
      },
      {
        "id": 4434,
        "name": "Sala Municipality",
        "state_code": "085",
        "type": null
      },
      {
        "id": 4396,
        "name": "Salacgrīva Municipality",
        "state_code": "086",
        "type": null
      },
      {
        "id": 4402,
        "name": "Salaspils Municipality",
        "state_code": "087",
        "type": null
      },
      {
        "id": 4439,
        "name": "Saldus Municipality",
        "state_code": "088",
        "type": null
      },
      {
        "id": 4443,
        "name": "Saulkrasti Municipality",
        "state_code": "089",
        "type": null
      },
      {
        "id": 4408,
        "name": "Sēja Municipality",
        "state_code": "090",
        "type": null
      },
      {
        "id": 4476,
        "name": "Sigulda Municipality",
        "state_code": "091",
        "type": null
      },
      {
        "id": 4415,
        "name": "Skrīveri Municipality",
        "state_code": "092",
        "type": null
      },
      {
        "id": 4447,
        "name": "Skrunda Municipality",
        "state_code": "093",
        "type": null
      },
      {
        "id": 4462,
        "name": "Smiltene Municipality",
        "state_code": "094",
        "type": null
      },
      {
        "id": 4478,
        "name": "Stopiņi Municipality",
        "state_code": "095",
        "type": null
      },
      {
        "id": 4494,
        "name": "Strenči Municipality",
        "state_code": "096",
        "type": null
      },
      {
        "id": 4459,
        "name": "Talsi Municipality",
        "state_code": "097",
        "type": null
      },
      {
        "id": 4480,
        "name": "Tērvete Municipality",
        "state_code": "098",
        "type": null
      },
      {
        "id": 4409,
        "name": "Tukums Municipality",
        "state_code": "099",
        "type": null
      },
      {
        "id": 4508,
        "name": "Vaiņode Municipality",
        "state_code": "100",
        "type": null
      },
      {
        "id": 4425,
        "name": "Valka Municipality",
        "state_code": "101",
        "type": null
      },
      {
        "id": 4473,
        "name": "Valmiera",
        "state_code": "VMR",
        "type": null
      },
      {
        "id": 4431,
        "name": "Varakļāni Municipality",
        "state_code": "102",
        "type": null
      },
      {
        "id": 4406,
        "name": "Vārkava Municipality",
        "state_code": "103",
        "type": null
      },
      {
        "id": 4466,
        "name": "Vecpiebalga Municipality",
        "state_code": "104",
        "type": null
      },
      {
        "id": 4397,
        "name": "Vecumnieki Municipality",
        "state_code": "105",
        "type": null
      },
      {
        "id": 4421,
        "name": "Ventspils",
        "state_code": "VEN",
        "type": null
      },
      {
        "id": 4403,
        "name": "Ventspils Municipality",
        "state_code": "106",
        "type": null
      },
      {
        "id": 4456,
        "name": "Viesīte Municipality",
        "state_code": "107",
        "type": null
      },
      {
        "id": 4477,
        "name": "Viļaka Municipality",
        "state_code": "108",
        "type": null
      },
      {
        "id": 4486,
        "name": "Viļāni Municipality",
        "state_code": "109",
        "type": null
      },
      {
        "id": 4430,
        "name": "Zilupe Municipality",
        "state_code": "110",
        "type": null
      }
    ]
  },
  {
    "name": "Lebanon",
    "iso3": "LBN",
    "iso2": "LB",
    "numeric_code": "422",
    "phone_code": "961",
    "capital": "Beirut",
    "currency": "LBP",
    "currency_name": "Lebanese pound",
    "currency_symbol": "£",
    "tld": ".lb",
    "native": "لبنان",
    "region": "Asia",
    "subregion": "Western Asia",
    "emoji": "🇱🇧",
    "emojiU": "U+1F1F1 U+1F1E7",
    "states": [
      {
        "id": 2285,
        "name": "Akkar Governorate",
        "state_code": "AK",
        "type": null
      },
      {
        "id": 2283,
        "name": "Baalbek-Hermel Governorate",
        "state_code": "BH",
        "type": null
      },
      {
        "id": 2286,
        "name": "Beirut Governorate",
        "state_code": "BA",
        "type": null
      },
      {
        "id": 2287,
        "name": "Beqaa Governorate",
        "state_code": "BI",
        "type": null
      },
      {
        "id": 2282,
        "name": "Mount Lebanon Governorate",
        "state_code": "JL",
        "type": null
      },
      {
        "id": 2288,
        "name": "Nabatieh Governorate",
        "state_code": "NA",
        "type": null
      },
      {
        "id": 2284,
        "name": "North Governorate",
        "state_code": "AS",
        "type": null
      },
      {
        "id": 2281,
        "name": "South Governorate",
        "state_code": "JA",
        "type": null
      }
    ]
  },
  {
    "name": "Lesotho",
    "iso3": "LSO",
    "iso2": "LS",
    "numeric_code": "426",
    "phone_code": "266",
    "capital": "Maseru",
    "currency": "LSL",
    "currency_name": "Lesotho loti",
    "currency_symbol": "L",
    "tld": ".ls",
    "native": "Lesotho",
    "region": "Africa",
    "subregion": "Southern Africa",
    "emoji": "🇱🇸",
    "emojiU": "U+1F1F1 U+1F1F8",
    "states": [
      {
        "id": 3030,
        "name": "Berea District",
        "state_code": "D",
        "type": null
      },
      {
        "id": 3029,
        "name": "Butha-Buthe District",
        "state_code": "B",
        "type": null
      },
      {
        "id": 3026,
        "name": "Leribe District",
        "state_code": "C",
        "type": null
      },
      {
        "id": 3022,
        "name": "Mafeteng District",
        "state_code": "E",
        "type": null
      },
      {
        "id": 3028,
        "name": "Maseru District",
        "state_code": "A",
        "type": null
      },
      {
        "id": 3023,
        "name": "Mohale's Hoek District",
        "state_code": "F",
        "type": null
      },
      {
        "id": 3024,
        "name": "Mokhotlong District",
        "state_code": "J",
        "type": null
      },
      {
        "id": 3025,
        "name": "Qacha's Nek District",
        "state_code": "H",
        "type": null
      },
      {
        "id": 3027,
        "name": "Quthing District",
        "state_code": "G",
        "type": null
      },
      {
        "id": 3031,
        "name": "Thaba-Tseka District",
        "state_code": "K",
        "type": null
      }
    ]
  },
  {
    "name": "Liberia",
    "iso3": "LBR",
    "iso2": "LR",
    "numeric_code": "430",
    "phone_code": "231",
    "capital": "Monrovia",
    "currency": "LRD",
    "currency_name": "Liberian dollar",
    "currency_symbol": "$",
    "tld": ".lr",
    "native": "Liberia",
    "region": "Africa",
    "subregion": "Western Africa",
    "emoji": "🇱🇷",
    "emojiU": "U+1F1F1 U+1F1F7",
    "states": [
      {
        "id": 3041,
        "name": "Bomi County",
        "state_code": "BM",
        "type": null
      },
      {
        "id": 3034,
        "name": "Bong County",
        "state_code": "BG",
        "type": null
      },
      {
        "id": 3044,
        "name": "Gbarpolu County",
        "state_code": "GP",
        "type": null
      },
      {
        "id": 3040,
        "name": "Grand Bassa County",
        "state_code": "GB",
        "type": null
      },
      {
        "id": 3036,
        "name": "Grand Cape Mount County",
        "state_code": "CM",
        "type": null
      },
      {
        "id": 3039,
        "name": "Grand Gedeh County",
        "state_code": "GG",
        "type": null
      },
      {
        "id": 3045,
        "name": "Grand Kru County",
        "state_code": "GK",
        "type": null
      },
      {
        "id": 3037,
        "name": "Lofa County",
        "state_code": "LO",
        "type": null
      },
      {
        "id": 3043,
        "name": "Margibi County",
        "state_code": "MG",
        "type": null
      },
      {
        "id": 3042,
        "name": "Maryland County",
        "state_code": "MY",
        "type": null
      },
      {
        "id": 3032,
        "name": "Montserrado County",
        "state_code": "MO",
        "type": null
      },
      {
        "id": 3046,
        "name": "Nimba",
        "state_code": "NI",
        "type": null
      },
      {
        "id": 3033,
        "name": "River Cess County",
        "state_code": "RI",
        "type": null
      },
      {
        "id": 3038,
        "name": "River Gee County",
        "state_code": "RG",
        "type": null
      },
      {
        "id": 3035,
        "name": "Sinoe County",
        "state_code": "SI",
        "type": null
      }
    ]
  },
  {
    "name": "Libya",
    "iso3": "LBY",
    "iso2": "LY",
    "numeric_code": "434",
    "phone_code": "218",
    "capital": "Tripolis",
    "currency": "LYD",
    "currency_name": "Libyan dinar",
    "currency_symbol": "د.ل",
    "tld": ".ly",
    "native": "‏ليبيا",
    "region": "Africa",
    "subregion": "Northern Africa",
    "emoji": "🇱🇾",
    "emojiU": "U+1F1F1 U+1F1FE",
    "states": [
      {
        "id": 2964,
        "name": "Al Wahat District",
        "state_code": "WA",
        "type": null
      },
      {
        "id": 2981,
        "name": "Benghazi",
        "state_code": "BA",
        "type": null
      },
      {
        "id": 2966,
        "name": "Derna District",
        "state_code": "DR",
        "type": null
      },
      {
        "id": 2969,
        "name": "Ghat District",
        "state_code": "GT",
        "type": null
      },
      {
        "id": 2980,
        "name": "Jabal al Akhdar",
        "state_code": "JA",
        "type": null
      },
      {
        "id": 2974,
        "name": "Jabal al Gharbi District",
        "state_code": "JG",
        "type": null
      },
      {
        "id": 2979,
        "name": "Jafara",
        "state_code": "JI",
        "type": null
      },
      {
        "id": 2970,
        "name": "Jufra",
        "state_code": "JU",
        "type": null
      },
      {
        "id": 2972,
        "name": "Kufra District",
        "state_code": "KF",
        "type": null
      },
      {
        "id": 2968,
        "name": "Marj District",
        "state_code": "MJ",
        "type": null
      },
      {
        "id": 2978,
        "name": "Misrata District",
        "state_code": "MI",
        "type": null
      },
      {
        "id": 2961,
        "name": "Murqub",
        "state_code": "MB",
        "type": null
      },
      {
        "id": 2967,
        "name": "Murzuq District",
        "state_code": "MQ",
        "type": null
      },
      {
        "id": 2976,
        "name": "Nalut District",
        "state_code": "NL",
        "type": null
      },
      {
        "id": 2962,
        "name": "Nuqat al Khams",
        "state_code": "NQ",
        "type": null
      },
      {
        "id": 2965,
        "name": "Sabha District",
        "state_code": "SB",
        "type": null
      },
      {
        "id": 2977,
        "name": "Sirte District",
        "state_code": "SR",
        "type": null
      },
      {
        "id": 2971,
        "name": "Tripoli District",
        "state_code": "TB",
        "type": null
      },
      {
        "id": 2973,
        "name": "Wadi al Hayaa District",
        "state_code": "WD",
        "type": null
      },
      {
        "id": 2975,
        "name": "Wadi al Shatii District",
        "state_code": "WS",
        "type": null
      },
      {
        "id": 2963,
        "name": "Zawiya District",
        "state_code": "ZA",
        "type": null
      }
    ]
  },
  {
    "name": "Liechtenstein",
    "iso3": "LIE",
    "iso2": "LI",
    "numeric_code": "438",
    "phone_code": "423",
    "capital": "Vaduz",
    "currency": "CHF",
    "currency_name": "Swiss franc",
    "currency_symbol": "CHf",
    "tld": ".li",
    "native": "Liechtenstein",
    "region": "Europe",
    "subregion": "Western Europe",
    "emoji": "🇱🇮",
    "emojiU": "U+1F1F1 U+1F1EE",
    "states": [
      {
        "id": 458,
        "name": "Balzers",
        "state_code": "01",
        "type": null
      },
      {
        "id": 451,
        "name": "Eschen",
        "state_code": "02",
        "type": null
      },
      {
        "id": 457,
        "name": "Gamprin",
        "state_code": "03",
        "type": null
      },
      {
        "id": 455,
        "name": "Mauren",
        "state_code": "04",
        "type": null
      },
      {
        "id": 454,
        "name": "Planken",
        "state_code": "05",
        "type": null
      },
      {
        "id": 453,
        "name": "Ruggell",
        "state_code": "06",
        "type": null
      },
      {
        "id": 450,
        "name": "Schaan",
        "state_code": "07",
        "type": null
      },
      {
        "id": 449,
        "name": "Schellenberg",
        "state_code": "08",
        "type": null
      },
      {
        "id": 459,
        "name": "Triesen",
        "state_code": "09",
        "type": null
      },
      {
        "id": 456,
        "name": "Triesenberg",
        "state_code": "10",
        "type": null
      },
      {
        "id": 452,
        "name": "Vaduz",
        "state_code": "11",
        "type": null
      }
    ]
  },
  {
    "name": "Lithuania",
    "iso3": "LTU",
    "iso2": "LT",
    "numeric_code": "440",
    "phone_code": "370",
    "capital": "Vilnius",
    "currency": "EUR",
    "currency_name": "Euro",
    "currency_symbol": "€",
    "tld": ".lt",
    "native": "Lietuva",
    "region": "Europe",
    "subregion": "Northern Europe",
    "emoji": "🇱🇹",
    "emojiU": "U+1F1F1 U+1F1F9",
    "states": [
      {
        "id": 1561,
        "name": "Akmenė District Municipality",
        "state_code": "01",
        "type": null
      },
      {
        "id": 1605,
        "name": "Alytus City Municipality",
        "state_code": "02",
        "type": null
      },
      {
        "id": 1574,
        "name": "Alytus County",
        "state_code": "AL",
        "type": null
      },
      {
        "id": 1599,
        "name": "Alytus District Municipality",
        "state_code": "03",
        "type": null
      },
      {
        "id": 1603,
        "name": "Birštonas Municipality",
        "state_code": "05",
        "type": null
      },
      {
        "id": 1566,
        "name": "Biržai District Municipality",
        "state_code": "06",
        "type": null
      },
      {
        "id": 1579,
        "name": "Druskininkai municipality",
        "state_code": "07",
        "type": null
      },
      {
        "id": 1559,
        "name": "Elektrėnai municipality",
        "state_code": "08",
        "type": null
      },
      {
        "id": 1562,
        "name": "Ignalina District Municipality",
        "state_code": "09",
        "type": null
      },
      {
        "id": 1567,
        "name": "Jonava District Municipality",
        "state_code": "10",
        "type": null
      },
      {
        "id": 1581,
        "name": "Joniškis District Municipality",
        "state_code": "11",
        "type": null
      },
      {
        "id": 1555,
        "name": "Jurbarkas District Municipality",
        "state_code": "12",
        "type": null
      },
      {
        "id": 1583,
        "name": "Kaišiadorys District Municipality",
        "state_code": "13",
        "type": null
      },
      {
        "id": 1591,
        "name": "Kalvarija municipality",
        "state_code": "14",
        "type": null
      },
      {
        "id": 1580,
        "name": "Kaunas City Municipality",
        "state_code": "15",
        "type": null
      },
      {
        "id": 1556,
        "name": "Kaunas County",
        "state_code": "KU",
        "type": null
      },
      {
        "id": 1565,
        "name": "Kaunas District Municipality",
        "state_code": "16",
        "type": null
      },
      {
        "id": 1575,
        "name": "Kazlų Rūda municipality",
        "state_code": "17",
        "type": null
      },
      {
        "id": 1584,
        "name": "Kėdainiai District Municipality",
        "state_code": "18",
        "type": null
      },
      {
        "id": 1618,
        "name": "Kelmė District Municipality",
        "state_code": "19",
        "type": null
      },
      {
        "id": 1597,
        "name": "Klaipeda City Municipality",
        "state_code": "20",
        "type": null
      },
      {
        "id": 1600,
        "name": "Klaipėda County",
        "state_code": "KL",
        "type": null
      },
      {
        "id": 1604,
        "name": "Klaipėda District Municipality",
        "state_code": "21",
        "type": null
      },
      {
        "id": 1571,
        "name": "Kretinga District Municipality",
        "state_code": "22",
        "type": null
      },
      {
        "id": 1585,
        "name": "Kupiškis District Municipality",
        "state_code": "23",
        "type": null
      },
      {
        "id": 1611,
        "name": "Lazdijai District Municipality",
        "state_code": "24",
        "type": null
      },
      {
        "id": 1570,
        "name": "Marijampolė County",
        "state_code": "MR",
        "type": null
      },
      {
        "id": 1610,
        "name": "Marijampolė Municipality",
        "state_code": "25",
        "type": null
      },
      {
        "id": 1557,
        "name": "Mažeikiai District Municipality",
        "state_code": "26",
        "type": null
      },
      {
        "id": 1582,
        "name": "Molėtai District Municipality",
        "state_code": "27",
        "type": null
      },
      {
        "id": 1563,
        "name": "Neringa Municipality",
        "state_code": "28",
        "type": null
      },
      {
        "id": 1612,
        "name": "Pagėgiai municipality",
        "state_code": "29",
        "type": null
      },
      {
        "id": 1595,
        "name": "Pakruojis District Municipality",
        "state_code": "30",
        "type": null
      },
      {
        "id": 1588,
        "name": "Palanga City Municipality",
        "state_code": "31",
        "type": null
      },
      {
        "id": 1589,
        "name": "Panevėžys City Municipality",
        "state_code": "32",
        "type": null
      },
      {
        "id": 1558,
        "name": "Panevėžys County",
        "state_code": "PN",
        "type": null
      },
      {
        "id": 1614,
        "name": "Panevėžys District Municipality",
        "state_code": "33",
        "type": null
      },
      {
        "id": 1616,
        "name": "Pasvalys District Municipality",
        "state_code": "34",
        "type": null
      },
      {
        "id": 1553,
        "name": "Plungė District Municipality",
        "state_code": "35",
        "type": null
      },
      {
        "id": 1578,
        "name": "Prienai District Municipality",
        "state_code": "36",
        "type": null
      },
      {
        "id": 1568,
        "name": "Radviliškis District Municipality",
        "state_code": "37",
        "type": null
      },
      {
        "id": 1587,
        "name": "Raseiniai District Municipality",
        "state_code": "38",
        "type": null
      },
      {
        "id": 1590,
        "name": "Rietavas municipality",
        "state_code": "39",
        "type": null
      },
      {
        "id": 1615,
        "name": "Rokiškis District Municipality",
        "state_code": "40",
        "type": null
      },
      {
        "id": 1576,
        "name": "Šakiai District Municipality",
        "state_code": "41",
        "type": null
      },
      {
        "id": 1577,
        "name": "Šalčininkai District Municipality",
        "state_code": "42",
        "type": null
      },
      {
        "id": 1609,
        "name": "Šiauliai City Municipality",
        "state_code": "43",
        "type": null
      },
      {
        "id": 1586,
        "name": "Šiauliai County",
        "state_code": "SA",
        "type": null
      },
      {
        "id": 1554,
        "name": "Šiauliai District Municipality",
        "state_code": "44",
        "type": null
      },
      {
        "id": 1613,
        "name": "Šilalė District Municipality",
        "state_code": "45",
        "type": null
      },
      {
        "id": 1607,
        "name": "Šilutė District Municipality",
        "state_code": "46",
        "type": null
      },
      {
        "id": 1594,
        "name": "Širvintos District Municipality",
        "state_code": "47",
        "type": null
      },
      {
        "id": 1617,
        "name": "Skuodas District Municipality",
        "state_code": "48",
        "type": null
      },
      {
        "id": 1560,
        "name": "Švenčionys District Municipality",
        "state_code": "49",
        "type": null
      },
      {
        "id": 1573,
        "name": "Tauragė County",
        "state_code": "TA",
        "type": null
      },
      {
        "id": 1572,
        "name": "Tauragė District Municipality",
        "state_code": "50",
        "type": null
      },
      {
        "id": 1569,
        "name": "Telšiai County",
        "state_code": "TE",
        "type": null
      },
      {
        "id": 1608,
        "name": "Telšiai District Municipality",
        "state_code": "51",
        "type": null
      },
      {
        "id": 1593,
        "name": "Trakai District Municipality",
        "state_code": "52",
        "type": null
      },
      {
        "id": 1596,
        "name": "Ukmergė District Municipality",
        "state_code": "53",
        "type": null
      },
      {
        "id": 1621,
        "name": "Utena County",
        "state_code": "UT",
        "type": null
      },
      {
        "id": 1598,
        "name": "Utena District Municipality",
        "state_code": "54",
        "type": null
      },
      {
        "id": 1602,
        "name": "Varėna District Municipality",
        "state_code": "55",
        "type": null
      },
      {
        "id": 1620,
        "name": "Vilkaviškis District Municipality",
        "state_code": "56",
        "type": null
      },
      {
        "id": 1606,
        "name": "Vilnius City Municipality",
        "state_code": "57",
        "type": null
      },
      {
        "id": 1601,
        "name": "Vilnius County",
        "state_code": "VL",
        "type": null
      },
      {
        "id": 1592,
        "name": "Vilnius District Municipality",
        "state_code": "58",
        "type": null
      },
      {
        "id": 1564,
        "name": "Visaginas Municipality",
        "state_code": "59",
        "type": null
      },
      {
        "id": 1619,
        "name": "Zarasai District Municipality",
        "state_code": "60",
        "type": null
      }
    ]
  },
  {
    "name": "Luxembourg",
    "iso3": "LUX",
    "iso2": "LU",
    "numeric_code": "442",
    "phone_code": "352",
    "capital": "Luxembourg",
    "currency": "EUR",
    "currency_name": "Euro",
    "currency_symbol": "€",
    "tld": ".lu",
    "native": "Luxembourg",
    "region": "Europe",
    "subregion": "Western Europe",
    "emoji": "🇱🇺",
    "emojiU": "U+1F1F1 U+1F1FA",
    "states": [
      {
        "id": 1518,
        "name": "Canton of Capellen",
        "state_code": "CA",
        "type": null
      },
      {
        "id": 1521,
        "name": "Canton of Clervaux",
        "state_code": "CL",
        "type": null
      },
      {
        "id": 1513,
        "name": "Canton of Diekirch",
        "state_code": "DI",
        "type": null
      },
      {
        "id": 1515,
        "name": "Canton of Echternach",
        "state_code": "EC",
        "type": null
      },
      {
        "id": 1517,
        "name": "Canton of Esch-sur-Alzette",
        "state_code": "ES",
        "type": null
      },
      {
        "id": 1525,
        "name": "Canton of Grevenmacher",
        "state_code": "GR",
        "type": null
      },
      {
        "id": 1527,
        "name": "Canton of Luxembourg",
        "state_code": "LU",
        "type": null
      },
      {
        "id": 1522,
        "name": "Canton of Mersch",
        "state_code": "ME",
        "type": null
      },
      {
        "id": 1516,
        "name": "Canton of Redange",
        "state_code": "RD",
        "type": null
      },
      {
        "id": 1519,
        "name": "Canton of Remich",
        "state_code": "RM",
        "type": null
      },
      {
        "id": 1523,
        "name": "Canton of Vianden",
        "state_code": "VD",
        "type": null
      },
      {
        "id": 1526,
        "name": "Canton of Wiltz",
        "state_code": "WI",
        "type": null
      },
      {
        "id": 1524,
        "name": "Diekirch District",
        "state_code": "D",
        "type": null
      },
      {
        "id": 1520,
        "name": "Grevenmacher District",
        "state_code": "G",
        "type": null
      },
      {
        "id": 1514,
        "name": "Luxembourg District",
        "state_code": "L",
        "type": null
      }
    ]
  },
  {
    "name": "Macau S.A.R.",
    "iso3": "MAC",
    "iso2": "MO",
    "numeric_code": "446",
    "phone_code": "853",
    "capital": "Macao",
    "currency": "MOP",
    "currency_name": "Macanese pataca",
    "currency_symbol": "$",
    "tld": ".mo",
    "native": "澳門",
    "region": "Asia",
    "subregion": "Eastern Asia",
    "emoji": "🇲🇴",
    "emojiU": "U+1F1F2 U+1F1F4",
    "states": []
  },
  {
    "name": "Macedonia",
    "iso3": "MKD",
    "iso2": "MK",
    "numeric_code": "807",
    "phone_code": "389",
    "capital": "Skopje",
    "currency": "MKD",
    "currency_name": "Denar",
    "currency_symbol": "ден",
    "tld": ".mk",
    "native": "Северна Македонија",
    "region": "Europe",
    "subregion": "Southern Europe",
    "emoji": "🇲🇰",
    "emojiU": "U+1F1F2 U+1F1F0",
    "states": [
      {
        "id": 703,
        "name": "Aerodrom Municipality",
        "state_code": "01",
        "type": null
      },
      {
        "id": 656,
        "name": "Aračinovo Municipality",
        "state_code": "02",
        "type": null
      },
      {
        "id": 716,
        "name": "Berovo Municipality",
        "state_code": "03",
        "type": null
      },
      {
        "id": 679,
        "name": "Bitola Municipality",
        "state_code": "04",
        "type": null
      },
      {
        "id": 649,
        "name": "Bogdanci Municipality",
        "state_code": "05",
        "type": null
      },
      {
        "id": 721,
        "name": "Bogovinje Municipality",
        "state_code": "06",
        "type": null
      },
      {
        "id": 652,
        "name": "Bosilovo Municipality",
        "state_code": "07",
        "type": null
      },
      {
        "id": 660,
        "name": "Brvenica Municipality",
        "state_code": "08",
        "type": null
      },
      {
        "id": 694,
        "name": "Butel Municipality",
        "state_code": "09",
        "type": null
      },
      {
        "id": 704,
        "name": "Čair Municipality",
        "state_code": "79",
        "type": null
      },
      {
        "id": 676,
        "name": "Čaška Municipality",
        "state_code": "80",
        "type": null
      },
      {
        "id": 702,
        "name": "Centar Municipality",
        "state_code": "77",
        "type": null
      },
      {
        "id": 720,
        "name": "Centar Župa Municipality",
        "state_code": "78",
        "type": null
      },
      {
        "id": 644,
        "name": "Češinovo-Obleševo Municipality",
        "state_code": "81",
        "type": null
      },
      {
        "id": 715,
        "name": "Čučer-Sandevo Municipality",
        "state_code": "82",
        "type": null
      },
      {
        "id": 645,
        "name": "Debarca Municipality",
        "state_code": "22",
        "type": null
      },
      {
        "id": 695,
        "name": "Delčevo Municipality",
        "state_code": "23",
        "type": null
      },
      {
        "id": 687,
        "name": "Demir Hisar Municipality",
        "state_code": "25",
        "type": null
      },
      {
        "id": 655,
        "name": "Demir Kapija Municipality",
        "state_code": "24",
        "type": null
      },
      {
        "id": 697,
        "name": "Dojran Municipality",
        "state_code": "26",
        "type": null
      },
      {
        "id": 675,
        "name": "Dolneni Municipality",
        "state_code": "27",
        "type": null
      },
      {
        "id": 657,
        "name": "Drugovo Municipality",
        "state_code": "28",
        "type": null
      },
      {
        "id": 707,
        "name": "Gazi Baba Municipality",
        "state_code": "17",
        "type": null
      },
      {
        "id": 648,
        "name": "Gevgelija Municipality",
        "state_code": "18",
        "type": null
      },
      {
        "id": 722,
        "name": "Gjorče Petrov Municipality",
        "state_code": "29",
        "type": null
      },
      {
        "id": 693,
        "name": "Gostivar Municipality",
        "state_code": "19",
        "type": null
      },
      {
        "id": 708,
        "name": "Gradsko Municipality",
        "state_code": "20",
        "type": null
      },
      {
        "id": 684,
        "name": "Greater Skopje",
        "state_code": "85",
        "type": null
      },
      {
        "id": 690,
        "name": "Ilinden Municipality",
        "state_code": "34",
        "type": null
      },
      {
        "id": 678,
        "name": "Jegunovce Municipality",
        "state_code": "35",
        "type": null
      },
      {
        "id": 674,
        "name": "Karbinci",
        "state_code": "37",
        "type": null
      },
      {
        "id": 681,
        "name": "Karpoš Municipality",
        "state_code": "38",
        "type": null
      },
      {
        "id": 713,
        "name": "Kavadarci Municipality",
        "state_code": "36",
        "type": null
      },
      {
        "id": 688,
        "name": "Kičevo Municipality",
        "state_code": "40",
        "type": null
      },
      {
        "id": 686,
        "name": "Kisela Voda Municipality",
        "state_code": "39",
        "type": null
      },
      {
        "id": 723,
        "name": "Kočani Municipality",
        "state_code": "42",
        "type": null
      },
      {
        "id": 665,
        "name": "Konče Municipality",
        "state_code": "41",
        "type": null
      },
      {
        "id": 641,
        "name": "Kratovo Municipality",
        "state_code": "43",
        "type": null
      },
      {
        "id": 677,
        "name": "Kriva Palanka Municipality",
        "state_code": "44",
        "type": null
      },
      {
        "id": 647,
        "name": "Krivogaštani Municipality",
        "state_code": "45",
        "type": null
      },
      {
        "id": 714,
        "name": "Kruševo Municipality",
        "state_code": "46",
        "type": null
      },
      {
        "id": 683,
        "name": "Kumanovo Municipality",
        "state_code": "47",
        "type": null
      },
      {
        "id": 659,
        "name": "Lipkovo Municipality",
        "state_code": "48",
        "type": null
      },
      {
        "id": 705,
        "name": "Lozovo Municipality",
        "state_code": "49",
        "type": null
      },
      {
        "id": 701,
        "name": "Makedonska Kamenica Municipality",
        "state_code": "51",
        "type": null
      },
      {
        "id": 692,
        "name": "Makedonski Brod Municipality",
        "state_code": "52",
        "type": null
      },
      {
        "id": 669,
        "name": "Mavrovo and Rostuša Municipality",
        "state_code": "50",
        "type": null
      },
      {
        "id": 653,
        "name": "Mogila Municipality",
        "state_code": "53",
        "type": null
      },
      {
        "id": 664,
        "name": "Negotino Municipality",
        "state_code": "54",
        "type": null
      },
      {
        "id": 696,
        "name": "Novaci Municipality",
        "state_code": "55",
        "type": null
      },
      {
        "id": 718,
        "name": "Novo Selo Municipality",
        "state_code": "56",
        "type": null
      },
      {
        "id": 699,
        "name": "Ohrid Municipality",
        "state_code": "58",
        "type": null
      },
      {
        "id": 682,
        "name": "Oslomej Municipality",
        "state_code": "57",
        "type": null
      },
      {
        "id": 685,
        "name": "Pehčevo Municipality",
        "state_code": "60",
        "type": null
      },
      {
        "id": 698,
        "name": "Petrovec Municipality",
        "state_code": "59",
        "type": null
      },
      {
        "id": 670,
        "name": "Plasnica Municipality",
        "state_code": "61",
        "type": null
      },
      {
        "id": 666,
        "name": "Prilep Municipality",
        "state_code": "62",
        "type": null
      },
      {
        "id": 646,
        "name": "Probištip Municipality",
        "state_code": "63",
        "type": null
      },
      {
        "id": 709,
        "name": "Radoviš Municipality",
        "state_code": "64",
        "type": null
      },
      {
        "id": 717,
        "name": "Rankovce Municipality",
        "state_code": "65",
        "type": null
      },
      {
        "id": 712,
        "name": "Resen Municipality",
        "state_code": "66",
        "type": null
      },
      {
        "id": 691,
        "name": "Rosoman Municipality",
        "state_code": "67",
        "type": null
      },
      {
        "id": 667,
        "name": "Saraj Municipality",
        "state_code": "68",
        "type": null
      },
      {
        "id": 719,
        "name": "Sopište Municipality",
        "state_code": "70",
        "type": null
      },
      {
        "id": 643,
        "name": "Staro Nagoričane Municipality",
        "state_code": "71",
        "type": null
      },
      {
        "id": 661,
        "name": "Štip Municipality",
        "state_code": "83",
        "type": null
      },
      {
        "id": 700,
        "name": "Struga Municipality",
        "state_code": "72",
        "type": null
      },
      {
        "id": 710,
        "name": "Strumica Municipality",
        "state_code": "73",
        "type": null
      },
      {
        "id": 711,
        "name": "Studeničani Municipality",
        "state_code": "74",
        "type": null
      },
      {
        "id": 680,
        "name": "Šuto Orizari Municipality",
        "state_code": "84",
        "type": null
      },
      {
        "id": 640,
        "name": "Sveti Nikole Municipality",
        "state_code": "69",
        "type": null
      },
      {
        "id": 654,
        "name": "Tearce Municipality",
        "state_code": "75",
        "type": null
      },
      {
        "id": 663,
        "name": "Tetovo Municipality",
        "state_code": "76",
        "type": null
      },
      {
        "id": 671,
        "name": "Valandovo Municipality",
        "state_code": "10",
        "type": null
      },
      {
        "id": 658,
        "name": "Vasilevo Municipality",
        "state_code": "11",
        "type": null
      },
      {
        "id": 651,
        "name": "Veles Municipality",
        "state_code": "13",
        "type": null
      },
      {
        "id": 662,
        "name": "Vevčani Municipality",
        "state_code": "12",
        "type": null
      },
      {
        "id": 672,
        "name": "Vinica Municipality",
        "state_code": "14",
        "type": null
      },
      {
        "id": 650,
        "name": "Vraneštica Municipality",
        "state_code": "15",
        "type": null
      },
      {
        "id": 689,
        "name": "Vrapčište Municipality",
        "state_code": "16",
        "type": null
      },
      {
        "id": 642,
        "name": "Zajas Municipality",
        "state_code": "31",
        "type": null
      },
      {
        "id": 706,
        "name": "Zelenikovo Municipality",
        "state_code": "32",
        "type": null
      },
      {
        "id": 668,
        "name": "Želino Municipality",
        "state_code": "30",
        "type": null
      },
      {
        "id": 673,
        "name": "Zrnovci Municipality",
        "state_code": "33",
        "type": null
      }
    ]
  },
  {
    "name": "Madagascar",
    "iso3": "MDG",
    "iso2": "MG",
    "numeric_code": "450",
    "phone_code": "261",
    "capital": "Antananarivo",
    "currency": "MGA",
    "currency_name": "Malagasy ariary",
    "currency_symbol": "Ar",
    "tld": ".mg",
    "native": "Madagasikara",
    "region": "Africa",
    "subregion": "Eastern Africa",
    "emoji": "🇲🇬",
    "emojiU": "U+1F1F2 U+1F1EC",
    "states": [
      {
        "id": 2951,
        "name": "Antananarivo Province",
        "state_code": "T",
        "type": null
      },
      {
        "id": 2950,
        "name": "Antsiranana Province",
        "state_code": "D",
        "type": null
      },
      {
        "id": 2948,
        "name": "Fianarantsoa Province",
        "state_code": "F",
        "type": null
      },
      {
        "id": 2953,
        "name": "Mahajanga Province",
        "state_code": "M",
        "type": null
      },
      {
        "id": 2952,
        "name": "Toamasina Province",
        "state_code": "A",
        "type": null
      },
      {
        "id": 2949,
        "name": "Toliara Province",
        "state_code": "U",
        "type": null
      }
    ]
  },
  {
    "name": "Malawi",
    "iso3": "MWI",
    "iso2": "MW",
    "numeric_code": "454",
    "phone_code": "265",
    "capital": "Lilongwe",
    "currency": "MWK",
    "currency_name": "Malawian kwacha",
    "currency_symbol": "MK",
    "tld": ".mw",
    "native": "Malawi",
    "region": "Africa",
    "subregion": "Eastern Africa",
    "emoji": "🇲🇼",
    "emojiU": "U+1F1F2 U+1F1FC",
    "states": [
      {
        "id": 3096,
        "name": "Balaka District",
        "state_code": "BA",
        "type": null
      },
      {
        "id": 3102,
        "name": "Blantyre District",
        "state_code": "BL",
        "type": null
      },
      {
        "id": 3092,
        "name": "Central Region",
        "state_code": "C",
        "type": null
      },
      {
        "id": 3107,
        "name": "Chikwawa District",
        "state_code": "CK",
        "type": null
      },
      {
        "id": 3109,
        "name": "Chiradzulu District",
        "state_code": "CR",
        "type": null
      },
      {
        "id": 3087,
        "name": "Chitipa district",
        "state_code": "CT",
        "type": null
      },
      {
        "id": 3097,
        "name": "Dedza District",
        "state_code": "DE",
        "type": null
      },
      {
        "id": 3090,
        "name": "Dowa District",
        "state_code": "DO",
        "type": null
      },
      {
        "id": 3091,
        "name": "Karonga District",
        "state_code": "KR",
        "type": null
      },
      {
        "id": 3094,
        "name": "Kasungu District",
        "state_code": "KS",
        "type": null
      },
      {
        "id": 3093,
        "name": "Likoma District",
        "state_code": "LK",
        "type": null
      },
      {
        "id": 3101,
        "name": "Lilongwe District",
        "state_code": "LI",
        "type": null
      },
      {
        "id": 3082,
        "name": "Machinga District",
        "state_code": "MH",
        "type": null
      },
      {
        "id": 3110,
        "name": "Mangochi District",
        "state_code": "MG",
        "type": null
      },
      {
        "id": 3099,
        "name": "Mchinji District",
        "state_code": "MC",
        "type": null
      },
      {
        "id": 3103,
        "name": "Mulanje District",
        "state_code": "MU",
        "type": null
      },
      {
        "id": 3084,
        "name": "Mwanza District",
        "state_code": "MW",
        "type": null
      },
      {
        "id": 3104,
        "name": "Mzimba District",
        "state_code": "MZ",
        "type": null
      },
      {
        "id": 3095,
        "name": "Nkhata Bay District",
        "state_code": "NB",
        "type": null
      },
      {
        "id": 3100,
        "name": "Nkhotakota District",
        "state_code": "NK",
        "type": null
      },
      {
        "id": 3105,
        "name": "Northern Region",
        "state_code": "N",
        "type": null
      },
      {
        "id": 3085,
        "name": "Nsanje District",
        "state_code": "NS",
        "type": null
      },
      {
        "id": 3088,
        "name": "Ntcheu District",
        "state_code": "NU",
        "type": null
      },
      {
        "id": 3111,
        "name": "Ntchisi District",
        "state_code": "NI",
        "type": null
      },
      {
        "id": 3108,
        "name": "Phalombe District",
        "state_code": "PH",
        "type": null
      },
      {
        "id": 3089,
        "name": "Rumphi District",
        "state_code": "RU",
        "type": null
      },
      {
        "id": 3086,
        "name": "Salima District",
        "state_code": "SA",
        "type": null
      },
      {
        "id": 3106,
        "name": "Southern Region",
        "state_code": "S",
        "type": null
      },
      {
        "id": 3098,
        "name": "Thyolo District",
        "state_code": "TH",
        "type": null
      },
      {
        "id": 3083,
        "name": "Zomba District",
        "state_code": "ZO",
        "type": null
      }
    ]
  },
  {
    "name": "Malaysia",
    "iso3": "MYS",
    "iso2": "MY",
    "numeric_code": "458",
    "phone_code": "60",
    "capital": "Kuala Lumpur",
    "currency": "MYR",
    "currency_name": "Malaysian ringgit",
    "currency_symbol": "RM",
    "tld": ".my",
    "native": "Malaysia",
    "region": "Asia",
    "subregion": "South-Eastern Asia",
    "emoji": "🇲🇾",
    "emojiU": "U+1F1F2 U+1F1FE",
    "states": [
      {
        "id": 1950,
        "name": "Johor",
        "state_code": "01",
        "type": null
      },
      {
        "id": 1947,
        "name": "Kedah",
        "state_code": "02",
        "type": null
      },
      {
        "id": 1946,
        "name": "Kelantan",
        "state_code": "03",
        "type": null
      },
      {
        "id": 1949,
        "name": "Kuala Lumpur",
        "state_code": "14",
        "type": null
      },
      {
        "id": 1935,
        "name": "Labuan",
        "state_code": "15",
        "type": null
      },
      {
        "id": 1941,
        "name": "Malacca",
        "state_code": "04",
        "type": null
      },
      {
        "id": 1948,
        "name": "Negeri Sembilan",
        "state_code": "05",
        "type": null
      },
      {
        "id": 1940,
        "name": "Pahang",
        "state_code": "06",
        "type": null
      },
      {
        "id": 1939,
        "name": "Penang",
        "state_code": "07",
        "type": null
      },
      {
        "id": 1943,
        "name": "Perak",
        "state_code": "08",
        "type": null
      },
      {
        "id": 1938,
        "name": "Perlis",
        "state_code": "09",
        "type": null
      },
      {
        "id": 1945,
        "name": "Putrajaya",
        "state_code": "16",
        "type": null
      },
      {
        "id": 1936,
        "name": "Sabah",
        "state_code": "12",
        "type": null
      },
      {
        "id": 1937,
        "name": "Sarawak",
        "state_code": "13",
        "type": null
      },
      {
        "id": 1944,
        "name": "Selangor",
        "state_code": "10",
        "type": null
      },
      {
        "id": 1942,
        "name": "Terengganu",
        "state_code": "11",
        "type": null
      }
    ]
  },
  {
    "name": "Maldives",
    "iso3": "MDV",
    "iso2": "MV",
    "numeric_code": "462",
    "phone_code": "960",
    "capital": "Male",
    "currency": "MVR",
    "currency_name": "Maldivian rufiyaa",
    "currency_symbol": "Rf",
    "tld": ".mv",
    "native": "Maldives",
    "region": "Asia",
    "subregion": "Southern Asia",
    "emoji": "🇲🇻",
    "emojiU": "U+1F1F2 U+1F1FB",
    "states": [
      {
        "id": 2594,
        "name": "Addu Atoll",
        "state_code": "01",
        "type": null
      },
      {
        "id": 2587,
        "name": "Alif Alif Atoll",
        "state_code": "02",
        "type": null
      },
      {
        "id": 2600,
        "name": "Alif Dhaal Atoll",
        "state_code": "00",
        "type": null
      },
      {
        "id": 2604,
        "name": "Central Province",
        "state_code": "CE",
        "type": null
      },
      {
        "id": 2590,
        "name": "Dhaalu Atoll",
        "state_code": "17",
        "type": null
      },
      {
        "id": 2599,
        "name": "Faafu Atoll",
        "state_code": "14",
        "type": null
      },
      {
        "id": 2598,
        "name": "Gaafu Alif Atoll",
        "state_code": "27",
        "type": null
      },
      {
        "id": 2603,
        "name": "Gaafu Dhaalu Atoll",
        "state_code": "28",
        "type": null
      },
      {
        "id": 2595,
        "name": "Gnaviyani Atoll",
        "state_code": "29",
        "type": null
      },
      {
        "id": 2586,
        "name": "Haa Alif Atoll",
        "state_code": "07",
        "type": null
      },
      {
        "id": 2597,
        "name": "Haa Dhaalu Atoll",
        "state_code": "23",
        "type": null
      },
      {
        "id": 2596,
        "name": "Kaafu Atoll",
        "state_code": "26",
        "type": null
      },
      {
        "id": 2601,
        "name": "Laamu Atoll",
        "state_code": "05",
        "type": null
      },
      {
        "id": 2607,
        "name": "Lhaviyani Atoll",
        "state_code": "03",
        "type": null
      },
      {
        "id": 2609,
        "name": "Malé",
        "state_code": "MLE",
        "type": null
      },
      {
        "id": 2608,
        "name": "Meemu Atoll",
        "state_code": "12",
        "type": null
      },
      {
        "id": 2592,
        "name": "Noonu Atoll",
        "state_code": "25",
        "type": null
      },
      {
        "id": 2589,
        "name": "North Central Province",
        "state_code": "NC",
        "type": null
      },
      {
        "id": 2588,
        "name": "North Province",
        "state_code": "NO",
        "type": null
      },
      {
        "id": 2602,
        "name": "Raa Atoll",
        "state_code": "13",
        "type": null
      },
      {
        "id": 2585,
        "name": "Shaviyani Atoll",
        "state_code": "24",
        "type": null
      },
      {
        "id": 2606,
        "name": "South Central Province",
        "state_code": "SC",
        "type": null
      },
      {
        "id": 2605,
        "name": "South Province",
        "state_code": "SU",
        "type": null
      },
      {
        "id": 2591,
        "name": "Thaa Atoll",
        "state_code": "08",
        "type": null
      },
      {
        "id": 2593,
        "name": "Upper South Province",
        "state_code": "US",
        "type": null
      },
      {
        "id": 2584,
        "name": "Vaavu Atoll",
        "state_code": "04",
        "type": null
      }
    ]
  },
  {
    "name": "Mali",
    "iso3": "MLI",
    "iso2": "ML",
    "numeric_code": "466",
    "phone_code": "223",
    "capital": "Bamako",
    "currency": "XOF",
    "currency_name": "West African CFA franc",
    "currency_symbol": "CFA",
    "tld": ".ml",
    "native": "Mali",
    "region": "Africa",
    "subregion": "Western Africa",
    "emoji": "🇲🇱",
    "emojiU": "U+1F1F2 U+1F1F1",
    "states": [
      {
        "id": 253,
        "name": "Bamako",
        "state_code": "BKO",
        "type": null
      },
      {
        "id": 258,
        "name": "Gao Region",
        "state_code": "7",
        "type": null
      },
      {
        "id": 252,
        "name": "Kayes Region",
        "state_code": "1",
        "type": null
      },
      {
        "id": 257,
        "name": "Kidal Region",
        "state_code": "8",
        "type": null
      },
      {
        "id": 250,
        "name": "Koulikoro Region",
        "state_code": "2",
        "type": null
      },
      {
        "id": 251,
        "name": "Ménaka Region",
        "state_code": "9",
        "type": null
      },
      {
        "id": 255,
        "name": "Mopti Region",
        "state_code": "5",
        "type": null
      },
      {
        "id": 249,
        "name": "Ségou Region",
        "state_code": "4",
        "type": null
      },
      {
        "id": 254,
        "name": "Sikasso Region",
        "state_code": "3",
        "type": null
      },
      {
        "id": 256,
        "name": "Taoudénit Region",
        "state_code": "10",
        "type": null
      },
      {
        "id": 248,
        "name": "Tombouctou Region",
        "state_code": "6",
        "type": null
      }
    ]
  },
  {
    "name": "Malta",
    "iso3": "MLT",
    "iso2": "MT",
    "numeric_code": "470",
    "phone_code": "356",
    "capital": "Valletta",
    "currency": "EUR",
    "currency_name": "Euro",
    "currency_symbol": "€",
    "tld": ".mt",
    "native": "Malta",
    "region": "Europe",
    "subregion": "Southern Europe",
    "emoji": "🇲🇹",
    "emojiU": "U+1F1F2 U+1F1F9",
    "states": [
      {
        "id": 110,
        "name": "Attard",
        "state_code": "01",
        "type": null
      },
      {
        "id": 108,
        "name": "Balzan",
        "state_code": "02",
        "type": null
      },
      {
        "id": 107,
        "name": "Birgu",
        "state_code": "03",
        "type": null
      },
      {
        "id": 97,
        "name": "Birkirkara",
        "state_code": "04",
        "type": null
      },
      {
        "id": 88,
        "name": "Birżebbuġa",
        "state_code": "05",
        "type": null
      },
      {
        "id": 138,
        "name": "Cospicua",
        "state_code": "06",
        "type": null
      },
      {
        "id": 117,
        "name": "Dingli",
        "state_code": "07",
        "type": null
      },
      {
        "id": 129,
        "name": "Fgura",
        "state_code": "08",
        "type": null
      },
      {
        "id": 84,
        "name": "Floriana",
        "state_code": "09",
        "type": null
      },
      {
        "id": 134,
        "name": "Fontana",
        "state_code": "10",
        "type": null
      },
      {
        "id": 130,
        "name": "Għajnsielem",
        "state_code": "13",
        "type": null
      },
      {
        "id": 92,
        "name": "Għarb",
        "state_code": "14",
        "type": null
      },
      {
        "id": 120,
        "name": "Għargħur",
        "state_code": "15",
        "type": null
      },
      {
        "id": 106,
        "name": "Għasri",
        "state_code": "16",
        "type": null
      },
      {
        "id": 124,
        "name": "Għaxaq",
        "state_code": "17",
        "type": null
      },
      {
        "id": 118,
        "name": "Gudja",
        "state_code": "11",
        "type": null
      },
      {
        "id": 113,
        "name": "Gżira",
        "state_code": "12",
        "type": null
      },
      {
        "id": 105,
        "name": "Ħamrun",
        "state_code": "18",
        "type": null
      },
      {
        "id": 93,
        "name": "Iklin",
        "state_code": "19",
        "type": null
      },
      {
        "id": 99,
        "name": "Kalkara",
        "state_code": "21",
        "type": null
      },
      {
        "id": 91,
        "name": "Kerċem",
        "state_code": "22",
        "type": null
      },
      {
        "id": 82,
        "name": "Kirkop",
        "state_code": "23",
        "type": null
      },
      {
        "id": 126,
        "name": "Lija",
        "state_code": "24",
        "type": null
      },
      {
        "id": 77,
        "name": "Luqa",
        "state_code": "25",
        "type": null
      },
      {
        "id": 128,
        "name": "Marsa",
        "state_code": "26",
        "type": null
      },
      {
        "id": 137,
        "name": "Marsaskala",
        "state_code": "27",
        "type": null
      },
      {
        "id": 78,
        "name": "Marsaxlokk",
        "state_code": "28",
        "type": null
      },
      {
        "id": 89,
        "name": "Mdina",
        "state_code": "29",
        "type": null
      },
      {
        "id": 102,
        "name": "Mellieħa",
        "state_code": "30",
        "type": null
      },
      {
        "id": 109,
        "name": "Mġarr",
        "state_code": "31",
        "type": null
      },
      {
        "id": 140,
        "name": "Mosta",
        "state_code": "32",
        "type": null
      },
      {
        "id": 74,
        "name": "Mqabba",
        "state_code": "33",
        "type": null
      },
      {
        "id": 96,
        "name": "Msida",
        "state_code": "34",
        "type": null
      },
      {
        "id": 131,
        "name": "Mtarfa",
        "state_code": "35",
        "type": null
      },
      {
        "id": 132,
        "name": "Munxar",
        "state_code": "36",
        "type": null
      },
      {
        "id": 133,
        "name": "Nadur",
        "state_code": "37",
        "type": null
      },
      {
        "id": 112,
        "name": "Naxxar",
        "state_code": "38",
        "type": null
      },
      {
        "id": 115,
        "name": "Paola",
        "state_code": "39",
        "type": null
      },
      {
        "id": 125,
        "name": "Pembroke",
        "state_code": "40",
        "type": null
      },
      {
        "id": 127,
        "name": "Pietà",
        "state_code": "41",
        "type": null
      },
      {
        "id": 79,
        "name": "Qala",
        "state_code": "42",
        "type": null
      },
      {
        "id": 119,
        "name": "Qormi",
        "state_code": "43",
        "type": null
      },
      {
        "id": 111,
        "name": "Qrendi",
        "state_code": "44",
        "type": null
      },
      {
        "id": 83,
        "name": "Rabat",
        "state_code": "46",
        "type": null
      },
      {
        "id": 87,
        "name": "Saint Lawrence",
        "state_code": "50",
        "type": null
      },
      {
        "id": 75,
        "name": "San Ġwann",
        "state_code": "49",
        "type": null
      },
      {
        "id": 116,
        "name": "Sannat",
        "state_code": "52",
        "type": null
      },
      {
        "id": 94,
        "name": "Santa Luċija",
        "state_code": "53",
        "type": null
      },
      {
        "id": 90,
        "name": "Santa Venera",
        "state_code": "54",
        "type": null
      },
      {
        "id": 136,
        "name": "Senglea",
        "state_code": "20",
        "type": null
      },
      {
        "id": 98,
        "name": "Siġġiewi",
        "state_code": "55",
        "type": null
      },
      {
        "id": 104,
        "name": "Sliema",
        "state_code": "56",
        "type": null
      },
      {
        "id": 100,
        "name": "St. Julian's",
        "state_code": "48",
        "type": null
      },
      {
        "id": 139,
        "name": "St. Paul's Bay",
        "state_code": "51",
        "type": null
      },
      {
        "id": 86,
        "name": "Swieqi",
        "state_code": "57",
        "type": null
      },
      {
        "id": 122,
        "name": "Ta' Xbiex",
        "state_code": "58",
        "type": null
      },
      {
        "id": 103,
        "name": "Tarxien",
        "state_code": "59",
        "type": null
      },
      {
        "id": 95,
        "name": "Valletta",
        "state_code": "60",
        "type": null
      },
      {
        "id": 101,
        "name": "Victoria",
        "state_code": "45",
        "type": null
      },
      {
        "id": 114,
        "name": "Xagħra",
        "state_code": "61",
        "type": null
      },
      {
        "id": 121,
        "name": "Xewkija",
        "state_code": "62",
        "type": null
      },
      {
        "id": 81,
        "name": "Xgħajra",
        "state_code": "63",
        "type": null
      },
      {
        "id": 123,
        "name": "Żabbar",
        "state_code": "64",
        "type": null
      },
      {
        "id": 85,
        "name": "Żebbuġ Gozo",
        "state_code": "65",
        "type": null
      },
      {
        "id": 80,
        "name": "Żebbuġ Malta",
        "state_code": "66",
        "type": null
      },
      {
        "id": 135,
        "name": "Żejtun",
        "state_code": "67",
        "type": null
      },
      {
        "id": 76,
        "name": "Żurrieq",
        "state_code": "68",
        "type": null
      }
    ]
  },
  {
    "name": "Man (Isle of)",
    "iso3": "IMN",
    "iso2": "IM",
    "numeric_code": "833",
    "phone_code": "+44-1624",
    "capital": "Douglas, Isle of Man",
    "currency": "GBP",
    "currency_name": "British pound",
    "currency_symbol": "£",
    "tld": ".im",
    "native": "Isle of Man",
    "region": "Europe",
    "subregion": "Northern Europe",
    "emoji": "🇮🇲",
    "emojiU": "U+1F1EE U+1F1F2",
    "states": []
  },
  {
    "name": "Marshall Islands",
    "iso3": "MHL",
    "iso2": "MH",
    "numeric_code": "584",
    "phone_code": "692",
    "capital": "Majuro",
    "currency": "USD",
    "currency_name": "United States dollar",
    "currency_symbol": "$",
    "tld": ".mh",
    "native": "M̧ajeļ",
    "region": "Oceania",
    "subregion": "Micronesia",
    "emoji": "🇲🇭",
    "emojiU": "U+1F1F2 U+1F1ED",
    "states": [
      {
        "id": 2574,
        "name": "Ralik Chain",
        "state_code": "L",
        "type": null
      },
      {
        "id": 2573,
        "name": "Ratak Chain",
        "state_code": "T",
        "type": null
      }
    ]
  },
  {
    "name": "Martinique",
    "iso3": "MTQ",
    "iso2": "MQ",
    "numeric_code": "474",
    "phone_code": "596",
    "capital": "Fort-de-France",
    "currency": "EUR",
    "currency_name": "Euro",
    "currency_symbol": "€",
    "tld": ".mq",
    "native": "Martinique",
    "region": "Americas",
    "subregion": "Caribbean",
    "emoji": "🇲🇶",
    "emojiU": "U+1F1F2 U+1F1F6",
    "states": []
  },
  {
    "name": "Mauritania",
    "iso3": "MRT",
    "iso2": "MR",
    "numeric_code": "478",
    "phone_code": "222",
    "capital": "Nouakchott",
    "currency": "MRO",
    "currency_name": "Mauritanian ouguiya",
    "currency_symbol": "MRU",
    "tld": ".mr",
    "native": "موريتانيا",
    "region": "Africa",
    "subregion": "Western Africa",
    "emoji": "🇲🇷",
    "emojiU": "U+1F1F2 U+1F1F7",
    "states": [
      {
        "id": 3344,
        "name": "Adrar Region",
        "state_code": "07",
        "type": null
      },
      {
        "id": 3349,
        "name": "Assaba Region",
        "state_code": "03",
        "type": null
      },
      {
        "id": 3339,
        "name": "Brakna Region",
        "state_code": "05",
        "type": null
      },
      {
        "id": 3346,
        "name": "Dakhlet Nouadhibou",
        "state_code": "08",
        "type": null
      },
      {
        "id": 3341,
        "name": "Gorgol Region",
        "state_code": "04",
        "type": null
      },
      {
        "id": 3350,
        "name": "Guidimaka Region",
        "state_code": "10",
        "type": null
      },
      {
        "id": 3338,
        "name": "Hodh Ech Chargui Region",
        "state_code": "01",
        "type": null
      },
      {
        "id": 3351,
        "name": "Hodh El Gharbi Region",
        "state_code": "02",
        "type": null
      },
      {
        "id": 3342,
        "name": "Inchiri Region",
        "state_code": "12",
        "type": null
      },
      {
        "id": 3343,
        "name": "Nouakchott-Nord Region",
        "state_code": "14",
        "type": null
      },
      {
        "id": 3352,
        "name": "Nouakchott-Ouest Region",
        "state_code": "13",
        "type": null
      },
      {
        "id": 3347,
        "name": "Nouakchott-Sud Region",
        "state_code": "15",
        "type": null
      },
      {
        "id": 3345,
        "name": "Tagant Region",
        "state_code": "09",
        "type": null
      },
      {
        "id": 3340,
        "name": "Tiris Zemmour Region",
        "state_code": "11",
        "type": null
      },
      {
        "id": 3348,
        "name": "Trarza Region",
        "state_code": "06",
        "type": null
      }
    ]
  },
  {
    "name": "Mauritius",
    "iso3": "MUS",
    "iso2": "MU",
    "numeric_code": "480",
    "phone_code": "230",
    "capital": "Port Louis",
    "currency": "MUR",
    "currency_name": "Mauritian rupee",
    "currency_symbol": "₨",
    "tld": ".mu",
    "native": "Maurice",
    "region": "Africa",
    "subregion": "Eastern Africa",
    "emoji": "🇲🇺",
    "emojiU": "U+1F1F2 U+1F1FA",
    "states": [
      {
        "id": 3248,
        "name": "Agaléga",
        "state_code": "AG",
        "type": null
      },
      {
        "id": 3262,
        "name": "Beau Bassin-Rose Hill",
        "state_code": "BR",
        "type": null
      },
      {
        "id": 3251,
        "name": "Cargados Carajos",
        "state_code": "CC",
        "type": null
      },
      {
        "id": 3255,
        "name": "Curepipe",
        "state_code": "CU",
        "type": null
      },
      {
        "id": 3254,
        "name": "Flacq District",
        "state_code": "FL",
        "type": null
      },
      {
        "id": 3264,
        "name": "Grand Port District",
        "state_code": "GP",
        "type": null
      },
      {
        "id": 3253,
        "name": "Moka District",
        "state_code": "MO",
        "type": null
      },
      {
        "id": 3250,
        "name": "Pamplemousses District",
        "state_code": "PA",
        "type": null
      },
      {
        "id": 3263,
        "name": "Plaines Wilhems District",
        "state_code": "PW",
        "type": null
      },
      {
        "id": 3256,
        "name": "Port Louis",
        "state_code": "PU",
        "type": null
      },
      {
        "id": 3260,
        "name": "Port Louis District",
        "state_code": "PL",
        "type": null
      },
      {
        "id": 3258,
        "name": "Quatre Bornes",
        "state_code": "QB",
        "type": null
      },
      {
        "id": 3261,
        "name": "Rivière du Rempart District",
        "state_code": "RR",
        "type": null
      },
      {
        "id": 3259,
        "name": "Rivière Noire District",
        "state_code": "BL",
        "type": null
      },
      {
        "id": 3249,
        "name": "Rodrigues",
        "state_code": "RO",
        "type": null
      },
      {
        "id": 3257,
        "name": "Savanne District",
        "state_code": "SA",
        "type": null
      },
      {
        "id": 3252,
        "name": "Vacoas-Phoenix",
        "state_code": "VP",
        "type": null
      }
    ]
  },
  {
    "name": "Mayotte",
    "iso3": "MYT",
    "iso2": "YT",
    "numeric_code": "175",
    "phone_code": "262",
    "capital": "Mamoudzou",
    "currency": "EUR",
    "currency_name": "Euro",
    "currency_symbol": "€",
    "tld": ".yt",
    "native": "Mayotte",
    "region": "Africa",
    "subregion": "Eastern Africa",
    "emoji": "🇾🇹",
    "emojiU": "U+1F1FE U+1F1F9",
    "states": []
  },
  {
    "name": "Mexico",
    "iso3": "MEX",
    "iso2": "MX",
    "numeric_code": "484",
    "phone_code": "52",
    "capital": "Mexico City",
    "currency": "MXN",
    "currency_name": "Mexican peso",
    "currency_symbol": "$",
    "tld": ".mx",
    "native": "México",
    "region": "Americas",
    "subregion": "Central America",
    "emoji": "🇲🇽",
    "emojiU": "U+1F1F2 U+1F1FD",
    "states": [
      {
        "id": 3456,
        "name": "Aguascalientes",
        "state_code": "AGU",
        "type": null
      },
      {
        "id": 3457,
        "name": "Baja California",
        "state_code": "BCN",
        "type": null
      },
      {
        "id": 3460,
        "name": "Baja California Sur",
        "state_code": "BCS",
        "type": null
      },
      {
        "id": 3475,
        "name": "Campeche",
        "state_code": "CAM",
        "type": null
      },
      {
        "id": 3451,
        "name": "Chiapas",
        "state_code": "CHP",
        "type": null
      },
      {
        "id": 3447,
        "name": "Chihuahua",
        "state_code": "CHH",
        "type": null
      },
      {
        "id": 3471,
        "name": "Coahuila",
        "state_code": "COA",
        "type": null
      },
      {
        "id": 3472,
        "name": "Colima",
        "state_code": "COL",
        "type": null
      },
      {
        "id": 3453,
        "name": "Durango",
        "state_code": "DUR",
        "type": null
      },
      {
        "id": 3469,
        "name": "Guanajuato",
        "state_code": "GUA",
        "type": null
      },
      {
        "id": 3459,
        "name": "Guerrero",
        "state_code": "GRO",
        "type": null
      },
      {
        "id": 3470,
        "name": "Hidalgo",
        "state_code": "HID",
        "type": null
      },
      {
        "id": 4857,
        "name": "Jalisco",
        "state_code": "JAL",
        "type": null
      },
      {
        "id": 3450,
        "name": "México",
        "state_code": "MEX",
        "type": null
      },
      {
        "id": 3473,
        "name": "Mexico City",
        "state_code": "CMX",
        "type": null
      },
      {
        "id": 3474,
        "name": "Michoacán",
        "state_code": "MIC",
        "type": null
      },
      {
        "id": 3465,
        "name": "Morelos",
        "state_code": "MOR",
        "type": null
      },
      {
        "id": 3477,
        "name": "Nayarit",
        "state_code": "NAY",
        "type": null
      },
      {
        "id": 3452,
        "name": "Nuevo León",
        "state_code": "NLE",
        "type": null
      },
      {
        "id": 3448,
        "name": "Oaxaca",
        "state_code": "OAX",
        "type": null
      },
      {
        "id": 3476,
        "name": "Puebla",
        "state_code": "PUE",
        "type": null
      },
      {
        "id": 3455,
        "name": "Querétaro",
        "state_code": "QUE",
        "type": null
      },
      {
        "id": 3467,
        "name": "Quintana Roo",
        "state_code": "ROO",
        "type": null
      },
      {
        "id": 3461,
        "name": "San Luis Potosí",
        "state_code": "SLP",
        "type": null
      },
      {
        "id": 3449,
        "name": "Sinaloa",
        "state_code": "SIN",
        "type": null
      },
      {
        "id": 3468,
        "name": "Sonora",
        "state_code": "SON",
        "type": null
      },
      {
        "id": 3454,
        "name": "Tabasco",
        "state_code": "TAB",
        "type": null
      },
      {
        "id": 3463,
        "name": "Tamaulipas",
        "state_code": "TAM",
        "type": null
      },
      {
        "id": 3458,
        "name": "Tlaxcala",
        "state_code": "TLA",
        "type": null
      },
      {
        "id": 3464,
        "name": "Veracruz",
        "state_code": "VER",
        "type": null
      },
      {
        "id": 3466,
        "name": "Yucatán",
        "state_code": "YUC",
        "type": null
      },
      {
        "id": 3462,
        "name": "Zacatecas",
        "state_code": "ZAC",
        "type": null
      }
    ]
  },
  {
    "name": "Micronesia",
    "iso3": "FSM",
    "iso2": "FM",
    "numeric_code": "583",
    "phone_code": "691",
    "capital": "Palikir",
    "currency": "USD",
    "currency_name": "United States dollar",
    "currency_symbol": "$",
    "tld": ".fm",
    "native": "Micronesia",
    "region": "Oceania",
    "subregion": "Micronesia",
    "emoji": "🇫🇲",
    "emojiU": "U+1F1EB U+1F1F2",
    "states": [
      {
        "id": 2580,
        "name": "Chuuk State",
        "state_code": "TRK",
        "type": null
      },
      {
        "id": 2583,
        "name": "Kosrae State",
        "state_code": "KSA",
        "type": null
      },
      {
        "id": 2581,
        "name": "Pohnpei State",
        "state_code": "PNI",
        "type": null
      },
      {
        "id": 2582,
        "name": "Yap State",
        "state_code": "YAP",
        "type": null
      }
    ]
  },
  {
    "name": "Moldova",
    "iso3": "MDA",
    "iso2": "MD",
    "numeric_code": "498",
    "phone_code": "373",
    "capital": "Chisinau",
    "currency": "MDL",
    "currency_name": "Moldovan leu",
    "currency_symbol": "L",
    "tld": ".md",
    "native": "Moldova",
    "region": "Europe",
    "subregion": "Eastern Europe",
    "emoji": "🇲🇩",
    "emojiU": "U+1F1F2 U+1F1E9",
    "states": [
      {
        "id": 4368,
        "name": "Anenii Noi District",
        "state_code": "AN",
        "type": null
      },
      {
        "id": 4393,
        "name": "Bălți Municipality",
        "state_code": "BA",
        "type": null
      },
      {
        "id": 4379,
        "name": "Basarabeasca District",
        "state_code": "BS",
        "type": null
      },
      {
        "id": 4362,
        "name": "Bender Municipality",
        "state_code": "BD",
        "type": null
      },
      {
        "id": 4375,
        "name": "Briceni District",
        "state_code": "BR",
        "type": null
      },
      {
        "id": 4391,
        "name": "Cahul District",
        "state_code": "CA",
        "type": null
      },
      {
        "id": 4366,
        "name": "Călărași District",
        "state_code": "CL",
        "type": null
      },
      {
        "id": 4380,
        "name": "Cantemir District",
        "state_code": "CT",
        "type": null
      },
      {
        "id": 4365,
        "name": "Căușeni District",
        "state_code": "CS",
        "type": null
      },
      {
        "id": 4373,
        "name": "Chișinău Municipality",
        "state_code": "CU",
        "type": null
      },
      {
        "id": 4360,
        "name": "Cimișlia District",
        "state_code": "CM",
        "type": null
      },
      {
        "id": 4390,
        "name": "Criuleni District",
        "state_code": "CR",
        "type": null
      },
      {
        "id": 4384,
        "name": "Dondușeni District",
        "state_code": "DO",
        "type": null
      },
      {
        "id": 4392,
        "name": "Drochia District",
        "state_code": "DR",
        "type": null
      },
      {
        "id": 4383,
        "name": "Dubăsari District",
        "state_code": "DU",
        "type": null
      },
      {
        "id": 4387,
        "name": "Edineț District",
        "state_code": "ED",
        "type": null
      },
      {
        "id": 4381,
        "name": "Fălești District",
        "state_code": "FA",
        "type": null
      },
      {
        "id": 4370,
        "name": "Florești District",
        "state_code": "FL",
        "type": null
      },
      {
        "id": 4385,
        "name": "Gagauzia",
        "state_code": "GA",
        "type": null
      },
      {
        "id": 4367,
        "name": "Glodeni District",
        "state_code": "GL",
        "type": null
      },
      {
        "id": 4382,
        "name": "Hîncești District",
        "state_code": "HI",
        "type": null
      },
      {
        "id": 4369,
        "name": "Ialoveni District",
        "state_code": "IA",
        "type": null
      },
      {
        "id": 4363,
        "name": "Nisporeni District",
        "state_code": "NI",
        "type": null
      },
      {
        "id": 4389,
        "name": "Ocnița District",
        "state_code": "OC",
        "type": null
      },
      {
        "id": 4361,
        "name": "Orhei District",
        "state_code": "OR",
        "type": null
      },
      {
        "id": 4394,
        "name": "Rezina District",
        "state_code": "RE",
        "type": null
      },
      {
        "id": 4376,
        "name": "Rîșcani District",
        "state_code": "RI",
        "type": null
      },
      {
        "id": 4364,
        "name": "Sîngerei District",
        "state_code": "SI",
        "type": null
      },
      {
        "id": 4388,
        "name": "Șoldănești District",
        "state_code": "SD",
        "type": null
      },
      {
        "id": 4374,
        "name": "Soroca District",
        "state_code": "SO",
        "type": null
      },
      {
        "id": 4378,
        "name": "Ștefan Vodă District",
        "state_code": "SV",
        "type": null
      },
      {
        "id": 4377,
        "name": "Strășeni District",
        "state_code": "ST",
        "type": null
      },
      {
        "id": 4372,
        "name": "Taraclia District",
        "state_code": "TA",
        "type": null
      },
      {
        "id": 4371,
        "name": "Telenești District",
        "state_code": "TE",
        "type": null
      },
      {
        "id": 4395,
        "name": "Transnistria autonomous territorial unit",
        "state_code": "SN",
        "type": null
      },
      {
        "id": 4386,
        "name": "Ungheni District",
        "state_code": "UN",
        "type": null
      }
    ]
  },
  {
    "name": "Monaco",
    "iso3": "MCO",
    "iso2": "MC",
    "numeric_code": "492",
    "phone_code": "377",
    "capital": "Monaco",
    "currency": "EUR",
    "currency_name": "Euro",
    "currency_symbol": "€",
    "tld": ".mc",
    "native": "Monaco",
    "region": "Europe",
    "subregion": "Western Europe",
    "emoji": "🇲🇨",
    "emojiU": "U+1F1F2 U+1F1E8",
    "states": [
      {
        "id": 4917,
        "name": "La Colle",
        "state_code": "CL",
        "type": null
      },
      {
        "id": 4918,
        "name": "La Condamine",
        "state_code": "CO",
        "type": null
      },
      {
        "id": 4919,
        "name": "Moneghetti",
        "state_code": "MG",
        "type": null
      }
    ]
  },
  {
    "name": "Mongolia",
    "iso3": "MNG",
    "iso2": "MN",
    "numeric_code": "496",
    "phone_code": "976",
    "capital": "Ulan Bator",
    "currency": "MNT",
    "currency_name": "Mongolian tögrög",
    "currency_symbol": "₮",
    "tld": ".mn",
    "native": "Монгол улс",
    "region": "Asia",
    "subregion": "Eastern Asia",
    "emoji": "🇲🇳",
    "emojiU": "U+1F1F2 U+1F1F3",
    "states": [
      {
        "id": 1973,
        "name": "Arkhangai Province",
        "state_code": "073",
        "type": null
      },
      {
        "id": 1969,
        "name": "Bayan-Ölgii Province",
        "state_code": "071",
        "type": null
      },
      {
        "id": 1976,
        "name": "Bayankhongor Province",
        "state_code": "069",
        "type": null
      },
      {
        "id": 1961,
        "name": "Bulgan Province",
        "state_code": "067",
        "type": null
      },
      {
        "id": 1962,
        "name": "Darkhan-Uul Province",
        "state_code": "037",
        "type": null
      },
      {
        "id": 1963,
        "name": "Dornod Province",
        "state_code": "061",
        "type": null
      },
      {
        "id": 1981,
        "name": "Dornogovi Province",
        "state_code": "063",
        "type": null
      },
      {
        "id": 1970,
        "name": "Dundgovi Province",
        "state_code": "059",
        "type": null
      },
      {
        "id": 1972,
        "name": "Govi-Altai Province",
        "state_code": "065",
        "type": null
      },
      {
        "id": 1978,
        "name": "Govisümber Province",
        "state_code": "064",
        "type": null
      },
      {
        "id": 1974,
        "name": "Khentii Province",
        "state_code": "039",
        "type": null
      },
      {
        "id": 1964,
        "name": "Khovd Province",
        "state_code": "043",
        "type": null
      },
      {
        "id": 1975,
        "name": "Khövsgöl Province",
        "state_code": "041",
        "type": null
      },
      {
        "id": 1967,
        "name": "Ömnögovi Province",
        "state_code": "053",
        "type": null
      },
      {
        "id": 1966,
        "name": "Orkhon Province",
        "state_code": "035",
        "type": null
      },
      {
        "id": 1965,
        "name": "Övörkhangai Province",
        "state_code": "055",
        "type": null
      },
      {
        "id": 1980,
        "name": "Selenge Province",
        "state_code": "049",
        "type": null
      },
      {
        "id": 1977,
        "name": "Sükhbaatar Province",
        "state_code": "051",
        "type": null
      },
      {
        "id": 1968,
        "name": "Töv Province",
        "state_code": "047",
        "type": null
      },
      {
        "id": 1971,
        "name": "Uvs Province",
        "state_code": "046",
        "type": null
      },
      {
        "id": 1979,
        "name": "Zavkhan Province",
        "state_code": "057",
        "type": null
      }
    ]
  },
  {
    "name": "Montenegro",
    "iso3": "MNE",
    "iso2": "ME",
    "numeric_code": "499",
    "phone_code": "382",
    "capital": "Podgorica",
    "currency": "EUR",
    "currency_name": "Euro",
    "currency_symbol": "€",
    "tld": ".me",
    "native": "Црна Гора",
    "region": "Europe",
    "subregion": "Southern Europe",
    "emoji": "🇲🇪",
    "emojiU": "U+1F1F2 U+1F1EA",
    "states": [
      {
        "id": 23,
        "name": "Andrijevica Municipality",
        "state_code": "01",
        "type": null
      },
      {
        "id": 13,
        "name": "Bar Municipality",
        "state_code": "02",
        "type": null
      },
      {
        "id": 21,
        "name": "Berane Municipality",
        "state_code": "03",
        "type": null
      },
      {
        "id": 25,
        "name": "Bijelo Polje Municipality",
        "state_code": "04",
        "type": null
      },
      {
        "id": 30,
        "name": "Budva Municipality",
        "state_code": "05",
        "type": null
      },
      {
        "id": 14,
        "name": "Danilovgrad Municipality",
        "state_code": "07",
        "type": null
      },
      {
        "id": 24,
        "name": "Gusinje Municipality",
        "state_code": "22",
        "type": null
      },
      {
        "id": 31,
        "name": "Kolašin Municipality",
        "state_code": "09",
        "type": null
      },
      {
        "id": 26,
        "name": "Kotor Municipality",
        "state_code": "10",
        "type": null
      },
      {
        "id": 22,
        "name": "Mojkovac Municipality",
        "state_code": "11",
        "type": null
      },
      {
        "id": 17,
        "name": "Nikšić Municipality",
        "state_code": "12",
        "type": null
      },
      {
        "id": 28,
        "name": "Old Royal Capital Cetinje",
        "state_code": "06",
        "type": null
      },
      {
        "id": 12,
        "name": "Petnjica Municipality",
        "state_code": "23",
        "type": null
      },
      {
        "id": 19,
        "name": "Plav Municipality",
        "state_code": "13",
        "type": null
      },
      {
        "id": 20,
        "name": "Pljevlja Municipality",
        "state_code": "14",
        "type": null
      },
      {
        "id": 16,
        "name": "Plužine Municipality",
        "state_code": "15",
        "type": null
      },
      {
        "id": 27,
        "name": "Podgorica Municipality",
        "state_code": "16",
        "type": null
      },
      {
        "id": 15,
        "name": "Rožaje Municipality",
        "state_code": "17",
        "type": null
      },
      {
        "id": 18,
        "name": "Šavnik Municipality",
        "state_code": "18",
        "type": null
      },
      {
        "id": 29,
        "name": "Tivat Municipality",
        "state_code": "19",
        "type": null
      },
      {
        "id": 33,
        "name": "Ulcinj Municipality",
        "state_code": "20",
        "type": null
      },
      {
        "id": 32,
        "name": "Žabljak Municipality",
        "state_code": "21",
        "type": null
      }
    ]
  },
  {
    "name": "Montserrat",
    "iso3": "MSR",
    "iso2": "MS",
    "numeric_code": "500",
    "phone_code": "+1-664",
    "capital": "Plymouth",
    "currency": "XCD",
    "currency_name": "Eastern Caribbean dollar",
    "currency_symbol": "$",
    "tld": ".ms",
    "native": "Montserrat",
    "region": "Americas",
    "subregion": "Caribbean",
    "emoji": "🇲🇸",
    "emojiU": "U+1F1F2 U+1F1F8",
    "states": []
  },
  {
    "name": "Morocco",
    "iso3": "MAR",
    "iso2": "MA",
    "numeric_code": "504",
    "phone_code": "212",
    "capital": "Rabat",
    "currency": "MAD",
    "currency_name": "Moroccan dirham",
    "currency_symbol": "DH",
    "tld": ".ma",
    "native": "المغرب",
    "region": "Africa",
    "subregion": "Northern Africa",
    "emoji": "🇲🇦",
    "emojiU": "U+1F1F2 U+1F1E6",
    "states": [
      {
        "id": 4928,
        "name": "Agadir-Ida-Ou-Tanane",
        "state_code": "AGD",
        "type": null
      },
      {
        "id": 3320,
        "name": "Al Haouz",
        "state_code": "HAO",
        "type": null
      },
      {
        "id": 3267,
        "name": "Al Hoceïma",
        "state_code": "HOC",
        "type": null
      },
      {
        "id": 3266,
        "name": "Aousserd (EH)",
        "state_code": "AOU",
        "type": null
      },
      {
        "id": 3297,
        "name": "Assa-Zag (EH-partial)",
        "state_code": "ASZ",
        "type": null
      },
      {
        "id": 3321,
        "name": "Azilal",
        "state_code": "AZI",
        "type": null
      },
      {
        "id": 3272,
        "name": "Béni Mellal",
        "state_code": "BEM",
        "type": null
      },
      {
        "id": 3278,
        "name": "Béni Mellal-Khénifra",
        "state_code": "05",
        "type": null
      },
      {
        "id": 3304,
        "name": "Benslimane",
        "state_code": "BES",
        "type": null
      },
      {
        "id": 3285,
        "name": "Berkane",
        "state_code": "BER",
        "type": null
      },
      {
        "id": 4929,
        "name": "Berrechid",
        "state_code": "BRR",
        "type": null
      },
      {
        "id": 3275,
        "name": "Boujdour (EH)",
        "state_code": "BOD",
        "type": null
      },
      {
        "id": 3270,
        "name": "Boulemane",
        "state_code": "BOM",
        "type": null
      },
      {
        "id": 4930,
        "name": "Casablanca",
        "state_code": "CAS",
        "type": null
      },
      {
        "id": 3303,
        "name": "Casablanca-Settat",
        "state_code": "06",
        "type": null
      },
      {
        "id": 3310,
        "name": "Chefchaouen",
        "state_code": "CHE",
        "type": null
      },
      {
        "id": 3274,
        "name": "Chichaoua",
        "state_code": "CHI",
        "type": null
      },
      {
        "id": 3302,
        "name": "Chtouka-Ait Baha",
        "state_code": "CHT",
        "type": null
      },
      {
        "id": 3306,
        "name": "Dakhla-Oued Ed-Dahab (EH)",
        "state_code": "12",
        "type": null
      },
      {
        "id": 3290,
        "name": "Drâa-Tafilalet",
        "state_code": "08",
        "type": null
      },
      {
        "id": 4931,
        "name": "Driouch",
        "state_code": "DRI",
        "type": null
      },
      {
        "id": 3291,
        "name": "El Hajeb",
        "state_code": "HAJ",
        "type": null
      },
      {
        "id": 3280,
        "name": "El Jadida",
        "state_code": "JDI",
        "type": null
      },
      {
        "id": 3309,
        "name": "El Kelâa des Sraghna",
        "state_code": "KES",
        "type": null
      },
      {
        "id": 3299,
        "name": "Errachidia",
        "state_code": "ERR",
        "type": null
      },
      {
        "id": 3292,
        "name": "Es-Semara (EH-partial)",
        "state_code": "ESM",
        "type": null
      },
      {
        "id": 3316,
        "name": "Essaouira",
        "state_code": "ESI",
        "type": null
      },
      {
        "id": 3300,
        "name": "Fahs-Anjra",
        "state_code": "FAH",
        "type": null
      },
      {
        "id": 4932,
        "name": "Fès",
        "state_code": "FES",
        "type": null
      },
      {
        "id": 3313,
        "name": "Fès-Meknès",
        "state_code": "03",
        "type": null
      },
      {
        "id": 3301,
        "name": "Figuig",
        "state_code": "FIG",
        "type": null
      },
      {
        "id": 4933,
        "name": "Fquih Ben Salah",
        "state_code": "FQH",
        "type": null
      },
      {
        "id": 3265,
        "name": "Guelmim",
        "state_code": "GUE",
        "type": null
      },
      {
        "id": 3305,
        "name": "Guelmim-Oued Noun (EH-partial)",
        "state_code": "10",
        "type": null
      },
      {
        "id": 4934,
        "name": "Guercif",
        "state_code": "GUF",
        "type": null
      },
      {
        "id": 3325,
        "name": "Ifrane",
        "state_code": "IFR",
        "type": null
      },
      {
        "id": 3294,
        "name": "Inezgane-Ait Melloul",
        "state_code": "INE",
        "type": null
      },
      {
        "id": 3307,
        "name": "Jerada",
        "state_code": "JRA",
        "type": null
      },
      {
        "id": 3308,
        "name": "Kénitra",
        "state_code": "KEN",
        "type": null
      },
      {
        "id": 3276,
        "name": "Khémisset",
        "state_code": "KHE",
        "type": null
      },
      {
        "id": 3317,
        "name": "Khénifra",
        "state_code": "KHN",
        "type": null
      },
      {
        "id": 3326,
        "name": "Khouribga",
        "state_code": "KHO",
        "type": null
      },
      {
        "id": 3271,
        "name": "L'Oriental",
        "state_code": "02",
        "type": null
      },
      {
        "id": 3293,
        "name": "Laâyoune (EH)",
        "state_code": "LAA",
        "type": null
      },
      {
        "id": 3298,
        "name": "Laâyoune-Sakia El Hamra (EH-partial)",
        "state_code": "11",
        "type": null
      },
      {
        "id": 3268,
        "name": "Larache",
        "state_code": "LAR",
        "type": null
      },
      {
        "id": 4936,
        "name": "M’diq-Fnideq",
        "state_code": "MDF",
        "type": null
      },
      {
        "id": 4935,
        "name": "Marrakech",
        "state_code": "MAR",
        "type": null
      },
      {
        "id": 3288,
        "name": "Marrakesh-Safi",
        "state_code": "07",
        "type": null
      },
      {
        "id": 3284,
        "name": "Médiouna",
        "state_code": "MED",
        "type": null
      },
      {
        "id": 4937,
        "name": "Meknès",
        "state_code": "MEK",
        "type": null
      },
      {
        "id": 4938,
        "name": "Midelt",
        "state_code": "MID",
        "type": null
      },
      {
        "id": 4939,
        "name": "Mohammadia",
        "state_code": "MOH",
        "type": null
      },
      {
        "id": 3315,
        "name": "Moulay Yacoub",
        "state_code": "MOU",
        "type": null
      },
      {
        "id": 3281,
        "name": "Nador",
        "state_code": "NAD",
        "type": null
      },
      {
        "id": 3287,
        "name": "Nouaceur",
        "state_code": "NOU",
        "type": null
      },
      {
        "id": 3269,
        "name": "Ouarzazate",
        "state_code": "OUA",
        "type": null
      },
      {
        "id": 3319,
        "name": "Oued Ed-Dahab (EH)",
        "state_code": "OUD",
        "type": null
      },
      {
        "id": 4941,
        "name": "Ouezzane",
        "state_code": "OUZ",
        "type": null
      },
      {
        "id": 4940,
        "name": "Oujda-Angad",
        "state_code": "OUJ",
        "type": null
      },
      {
        "id": 4942,
        "name": "Rabat",
        "state_code": "RAB",
        "type": null
      },
      {
        "id": 4927,
        "name": "Rabat-Salé-Kénitra",
        "state_code": "04",
        "type": null
      },
      {
        "id": 4943,
        "name": "Rehamna",
        "state_code": "REH",
        "type": null
      },
      {
        "id": 3311,
        "name": "Safi",
        "state_code": "SAF",
        "type": null
      },
      {
        "id": 4944,
        "name": "Salé",
        "state_code": "SAL",
        "type": null
      },
      {
        "id": 3289,
        "name": "Sefrou",
        "state_code": "SEF",
        "type": null
      },
      {
        "id": 3282,
        "name": "Settat",
        "state_code": "SET",
        "type": null
      },
      {
        "id": 4945,
        "name": "Sidi Bennour",
        "state_code": "SIB",
        "type": null
      },
      {
        "id": 4946,
        "name": "Sidi Ifni",
        "state_code": "SIF",
        "type": null
      },
      {
        "id": 3279,
        "name": "Sidi Kacem",
        "state_code": "SIK",
        "type": null
      },
      {
        "id": 4952,
        "name": "Sidi Slimane",
        "state_code": "SIL",
        "type": null
      },
      {
        "id": 4947,
        "name": "Skhirate-Témara",
        "state_code": "SKH",
        "type": null
      },
      {
        "id": 3295,
        "name": "Souss-Massa",
        "state_code": "09",
        "type": null
      },
      {
        "id": 3286,
        "name": "Tan-Tan (EH-partial)",
        "state_code": "TNT",
        "type": null
      },
      {
        "id": 4950,
        "name": "Tanger-Assilah",
        "state_code": "TNG",
        "type": null
      },
      {
        "id": 3324,
        "name": "Tanger-Tétouan-Al Hoceïma",
        "state_code": "01",
        "type": null
      },
      {
        "id": 3323,
        "name": "Taounate",
        "state_code": "TAO",
        "type": null
      },
      {
        "id": 3322,
        "name": "Taourirt",
        "state_code": "TAI",
        "type": null
      },
      {
        "id": 4948,
        "name": "Tarfaya (EH-partial)",
        "state_code": "TAF",
        "type": null
      },
      {
        "id": 3314,
        "name": "Taroudannt",
        "state_code": "TAR",
        "type": null
      },
      {
        "id": 3312,
        "name": "Tata",
        "state_code": "TAT",
        "type": null
      },
      {
        "id": 3296,
        "name": "Taza",
        "state_code": "TAZ",
        "type": null
      },
      {
        "id": 3318,
        "name": "Tétouan",
        "state_code": "TET",
        "type": null
      },
      {
        "id": 4949,
        "name": "Tinghir",
        "state_code": "TIN",
        "type": null
      },
      {
        "id": 3277,
        "name": "Tiznit",
        "state_code": "TIZ",
        "type": null
      },
      {
        "id": 4951,
        "name": "Youssoufia",
        "state_code": "YUS",
        "type": null
      },
      {
        "id": 3283,
        "name": "Zagora",
        "state_code": "ZAG",
        "type": null
      }
    ]
  },
  {
    "name": "Mozambique",
    "iso3": "MOZ",
    "iso2": "MZ",
    "numeric_code": "508",
    "phone_code": "258",
    "capital": "Maputo",
    "currency": "MZN",
    "currency_name": "Mozambican metical",
    "currency_symbol": "MT",
    "tld": ".mz",
    "native": "Moçambique",
    "region": "Africa",
    "subregion": "Eastern Africa",
    "emoji": "🇲🇿",
    "emojiU": "U+1F1F2 U+1F1FF",
    "states": [
      {
        "id": 3327,
        "name": "Cabo Delgado Province",
        "state_code": "P",
        "type": null
      },
      {
        "id": 3329,
        "name": "Gaza Province",
        "state_code": "G",
        "type": null
      },
      {
        "id": 3330,
        "name": "Inhambane Province",
        "state_code": "I",
        "type": null
      },
      {
        "id": 3337,
        "name": "Manica Province",
        "state_code": "B",
        "type": null
      },
      {
        "id": 3335,
        "name": "Maputo",
        "state_code": "MPM",
        "type": null
      },
      {
        "id": 3332,
        "name": "Maputo Province",
        "state_code": "L",
        "type": null
      },
      {
        "id": 3336,
        "name": "Nampula Province",
        "state_code": "N",
        "type": null
      },
      {
        "id": 3333,
        "name": "Niassa Province",
        "state_code": "A",
        "type": null
      },
      {
        "id": 3331,
        "name": "Sofala Province",
        "state_code": "S",
        "type": null
      },
      {
        "id": 3334,
        "name": "Tete Province",
        "state_code": "T",
        "type": null
      },
      {
        "id": 3328,
        "name": "Zambezia Province",
        "state_code": "Q",
        "type": null
      }
    ]
  },
  {
    "name": "Myanmar",
    "iso3": "MMR",
    "iso2": "MM",
    "numeric_code": "104",
    "phone_code": "95",
    "capital": "Nay Pyi Taw",
    "currency": "MMK",
    "currency_name": "Burmese kyat",
    "currency_symbol": "K",
    "tld": ".mm",
    "native": "မြန်မာ",
    "region": "Asia",
    "subregion": "South-Eastern Asia",
    "emoji": "🇲🇲",
    "emojiU": "U+1F1F2 U+1F1F2",
    "states": [
      {
        "id": 2142,
        "name": "Ayeyarwady Region",
        "state_code": "07",
        "type": null
      },
      {
        "id": 2141,
        "name": "Bago",
        "state_code": "02",
        "type": null
      },
      {
        "id": 2137,
        "name": "Chin State",
        "state_code": "14",
        "type": null
      },
      {
        "id": 2143,
        "name": "Kachin State",
        "state_code": "11",
        "type": null
      },
      {
        "id": 2144,
        "name": "Kayah State",
        "state_code": "12",
        "type": null
      },
      {
        "id": 2133,
        "name": "Kayin State",
        "state_code": "13",
        "type": null
      },
      {
        "id": 2136,
        "name": "Magway Region",
        "state_code": "03",
        "type": null
      },
      {
        "id": 2134,
        "name": "Mandalay Region",
        "state_code": "04",
        "type": null
      },
      {
        "id": 2147,
        "name": "Mon State",
        "state_code": "15",
        "type": null
      },
      {
        "id": 2146,
        "name": "Naypyidaw Union Territory",
        "state_code": "18",
        "type": null
      },
      {
        "id": 2138,
        "name": "Rakhine State",
        "state_code": "16",
        "type": null
      },
      {
        "id": 2145,
        "name": "Sagaing Region",
        "state_code": "01",
        "type": null
      },
      {
        "id": 2139,
        "name": "Shan State",
        "state_code": "17",
        "type": null
      },
      {
        "id": 2140,
        "name": "Tanintharyi Region",
        "state_code": "05",
        "type": null
      },
      {
        "id": 2135,
        "name": "Yangon Region",
        "state_code": "06",
        "type": null
      }
    ]
  },
  {
    "name": "Namibia",
    "iso3": "NAM",
    "iso2": "NA",
    "numeric_code": "516",
    "phone_code": "264",
    "capital": "Windhoek",
    "currency": "NAD",
    "currency_name": "Namibian dollar",
    "currency_symbol": "$",
    "tld": ".na",
    "native": "Namibia",
    "region": "Africa",
    "subregion": "Southern Africa",
    "emoji": "🇳🇦",
    "emojiU": "U+1F1F3 U+1F1E6",
    "states": [
      {
        "id": 43,
        "name": "Erongo Region",
        "state_code": "ER",
        "type": null
      },
      {
        "id": 38,
        "name": "Hardap Region",
        "state_code": "HA",
        "type": null
      },
      {
        "id": 45,
        "name": "Karas Region",
        "state_code": "KA",
        "type": null
      },
      {
        "id": 36,
        "name": "Kavango East Region",
        "state_code": "KE",
        "type": null
      },
      {
        "id": 35,
        "name": "Kavango West Region",
        "state_code": "KW",
        "type": null
      },
      {
        "id": 44,
        "name": "Khomas Region",
        "state_code": "KH",
        "type": null
      },
      {
        "id": 34,
        "name": "Kunene Region",
        "state_code": "KU",
        "type": null
      },
      {
        "id": 40,
        "name": "Ohangwena Region",
        "state_code": "OW",
        "type": null
      },
      {
        "id": 41,
        "name": "Omaheke Region",
        "state_code": "OH",
        "type": null
      },
      {
        "id": 39,
        "name": "Omusati Region",
        "state_code": "OS",
        "type": null
      },
      {
        "id": 37,
        "name": "Oshana Region",
        "state_code": "ON",
        "type": null
      },
      {
        "id": 42,
        "name": "Oshikoto Region",
        "state_code": "OT",
        "type": null
      },
      {
        "id": 46,
        "name": "Otjozondjupa Region",
        "state_code": "OD",
        "type": null
      },
      {
        "id": 47,
        "name": "Zambezi Region",
        "state_code": "CA",
        "type": null
      }
    ]
  },
  {
    "name": "Nauru",
    "iso3": "NRU",
    "iso2": "NR",
    "numeric_code": "520",
    "phone_code": "674",
    "capital": "Yaren",
    "currency": "AUD",
    "currency_name": "Australian dollar",
    "currency_symbol": "$",
    "tld": ".nr",
    "native": "Nauru",
    "region": "Oceania",
    "subregion": "Micronesia",
    "emoji": "🇳🇷",
    "emojiU": "U+1F1F3 U+1F1F7",
    "states": [
      {
        "id": 4656,
        "name": "Aiwo District",
        "state_code": "01",
        "type": null
      },
      {
        "id": 4658,
        "name": "Anabar District",
        "state_code": "02",
        "type": null
      },
      {
        "id": 4667,
        "name": "Anetan District",
        "state_code": "03",
        "type": null
      },
      {
        "id": 4663,
        "name": "Anibare District",
        "state_code": "04",
        "type": null
      },
      {
        "id": 4660,
        "name": "Baiti District",
        "state_code": "05",
        "type": null
      },
      {
        "id": 4665,
        "name": "Boe District",
        "state_code": "06",
        "type": null
      },
      {
        "id": 4662,
        "name": "Buada District",
        "state_code": "07",
        "type": null
      },
      {
        "id": 4666,
        "name": "Denigomodu District",
        "state_code": "08",
        "type": null
      },
      {
        "id": 4654,
        "name": "Ewa District",
        "state_code": "09",
        "type": null
      },
      {
        "id": 4661,
        "name": "Ijuw District",
        "state_code": "10",
        "type": null
      },
      {
        "id": 4657,
        "name": "Meneng District",
        "state_code": "11",
        "type": null
      },
      {
        "id": 4659,
        "name": "Nibok District",
        "state_code": "12",
        "type": null
      },
      {
        "id": 4655,
        "name": "Uaboe District",
        "state_code": "13",
        "type": null
      },
      {
        "id": 4664,
        "name": "Yaren District",
        "state_code": "14",
        "type": null
      }
    ]
  },
  {
    "name": "Nepal",
    "iso3": "NPL",
    "iso2": "NP",
    "numeric_code": "524",
    "phone_code": "977",
    "capital": "Kathmandu",
    "currency": "NPR",
    "currency_name": "Nepalese rupee",
    "currency_symbol": "₨",
    "tld": ".np",
    "native": "नपल",
    "region": "Asia",
    "subregion": "Southern Asia",
    "emoji": "🇳🇵",
    "emojiU": "U+1F1F3 U+1F1F5",
    "states": [
      {
        "id": 2082,
        "name": "Bagmati Zone",
        "state_code": "BA",
        "type": null
      },
      {
        "id": 2071,
        "name": "Bheri Zone",
        "state_code": "BH",
        "type": null
      },
      {
        "id": 2073,
        "name": "Central Region",
        "state_code": "1",
        "type": null
      },
      {
        "id": 2080,
        "name": "Dhaulagiri Zone",
        "state_code": "DH",
        "type": null
      },
      {
        "id": 2069,
        "name": "Eastern Development Region",
        "state_code": "4",
        "type": null
      },
      {
        "id": 2068,
        "name": "Far-Western Development Region",
        "state_code": "5",
        "type": null
      },
      {
        "id": 2081,
        "name": "Gandaki Zone",
        "state_code": "GA",
        "type": null
      },
      {
        "id": 2076,
        "name": "Janakpur Zone",
        "state_code": "JA",
        "type": null
      },
      {
        "id": 2079,
        "name": "Karnali Zone",
        "state_code": "KA",
        "type": null
      },
      {
        "id": 2072,
        "name": "Kosi Zone",
        "state_code": "KO",
        "type": null
      },
      {
        "id": 2074,
        "name": "Lumbini Zone",
        "state_code": "LU",
        "type": null
      },
      {
        "id": 2083,
        "name": "Mahakali Zone",
        "state_code": "MA",
        "type": null
      },
      {
        "id": 2070,
        "name": "Mechi Zone",
        "state_code": "ME",
        "type": null
      },
      {
        "id": 2066,
        "name": "Mid-Western Region",
        "state_code": "2",
        "type": null
      },
      {
        "id": 2075,
        "name": "Narayani Zone",
        "state_code": "NA",
        "type": null
      },
      {
        "id": 2077,
        "name": "Rapti Zone",
        "state_code": "RA",
        "type": null
      },
      {
        "id": 2084,
        "name": "Sagarmatha Zone",
        "state_code": "SA",
        "type": null
      },
      {
        "id": 2078,
        "name": "Seti Zone",
        "state_code": "SE",
        "type": null
      },
      {
        "id": 2067,
        "name": "Western Region",
        "state_code": "3",
        "type": null
      }
    ]
  },
  {
    "name": "Netherlands",
    "iso3": "NLD",
    "iso2": "NL",
    "numeric_code": "528",
    "phone_code": "31",
    "capital": "Amsterdam",
    "currency": "EUR",
    "currency_name": "Euro",
    "currency_symbol": "€",
    "tld": ".nl",
    "native": "Nederland",
    "region": "Europe",
    "subregion": "Western Europe",
    "emoji": "🇳🇱",
    "emojiU": "U+1F1F3 U+1F1F1",
    "states": [
      {
        "id": 2624,
        "name": "Bonaire",
        "state_code": "BQ1",
        "type": null
      },
      {
        "id": 2613,
        "name": "Drenthe",
        "state_code": "DR",
        "type": null
      },
      {
        "id": 2619,
        "name": "Flevoland",
        "state_code": "FL",
        "type": null
      },
      {
        "id": 2622,
        "name": "Friesland",
        "state_code": "FR",
        "type": null
      },
      {
        "id": 2611,
        "name": "Gelderland",
        "state_code": "GE",
        "type": null
      },
      {
        "id": 2617,
        "name": "Groningen",
        "state_code": "GR",
        "type": null
      },
      {
        "id": 2615,
        "name": "Limburg",
        "state_code": "LI",
        "type": null
      },
      {
        "id": 2623,
        "name": "North Brabant",
        "state_code": "NB",
        "type": null
      },
      {
        "id": 2612,
        "name": "North Holland",
        "state_code": "NH",
        "type": null
      },
      {
        "id": 2618,
        "name": "Overijssel",
        "state_code": "OV",
        "type": null
      },
      {
        "id": 2621,
        "name": "Saba",
        "state_code": "BQ2",
        "type": null
      },
      {
        "id": 2616,
        "name": "Sint Eustatius",
        "state_code": "BQ3",
        "type": null
      },
      {
        "id": 2614,
        "name": "South Holland",
        "state_code": "ZH",
        "type": null
      },
      {
        "id": 2610,
        "name": "Utrecht",
        "state_code": "UT",
        "type": null
      },
      {
        "id": 2620,
        "name": "Zeeland",
        "state_code": "ZE",
        "type": null
      }
    ]
  },
  {
    "name": "New Caledonia",
    "iso3": "NCL",
    "iso2": "NC",
    "numeric_code": "540",
    "phone_code": "687",
    "capital": "Noumea",
    "currency": "XPF",
    "currency_name": "CFP franc",
    "currency_symbol": "₣",
    "tld": ".nc",
    "native": "Nouvelle-Calédonie",
    "region": "Oceania",
    "subregion": "Melanesia",
    "emoji": "🇳🇨",
    "emojiU": "U+1F1F3 U+1F1E8",
    "states": []
  },
  {
    "name": "New Zealand",
    "iso3": "NZL",
    "iso2": "NZ",
    "numeric_code": "554",
    "phone_code": "64",
    "capital": "Wellington",
    "currency": "NZD",
    "currency_name": "New Zealand dollar",
    "currency_symbol": "$",
    "tld": ".nz",
    "native": "New Zealand",
    "region": "Oceania",
    "subregion": "Australia and New Zealand",
    "emoji": "🇳🇿",
    "emojiU": "U+1F1F3 U+1F1FF",
    "states": [
      {
        "id": 4072,
        "name": "Auckland Region",
        "state_code": "AUK",
        "type": null
      },
      {
        "id": 4074,
        "name": "Bay of Plenty Region",
        "state_code": "BOP",
        "type": null
      },
      {
        "id": 4066,
        "name": "Canterbury Region",
        "state_code": "CAN",
        "type": null
      },
      {
        "id": 4067,
        "name": "Chatham Islands",
        "state_code": "CIT",
        "type": null
      },
      {
        "id": 4068,
        "name": "Gisborne District",
        "state_code": "GIS",
        "type": null
      },
      {
        "id": 4075,
        "name": "Hawke's Bay Region",
        "state_code": "HKB",
        "type": null
      },
      {
        "id": 4060,
        "name": "Manawatu-Wanganui Region",
        "state_code": "MWT",
        "type": null
      },
      {
        "id": 4063,
        "name": "Marlborough Region",
        "state_code": "MBH",
        "type": null
      },
      {
        "id": 4070,
        "name": "Nelson Region",
        "state_code": "NSN",
        "type": null
      },
      {
        "id": 4059,
        "name": "Northland Region",
        "state_code": "NTL",
        "type": null
      },
      {
        "id": 4062,
        "name": "Otago Region",
        "state_code": "OTA",
        "type": null
      },
      {
        "id": 4071,
        "name": "Southland Region",
        "state_code": "STL",
        "type": null
      },
      {
        "id": 4069,
        "name": "Taranaki Region",
        "state_code": "TKI",
        "type": null
      },
      {
        "id": 4073,
        "name": "Tasman District",
        "state_code": "TAS",
        "type": null
      },
      {
        "id": 4061,
        "name": "Waikato Region",
        "state_code": "WKO",
        "type": null
      },
      {
        "id": 4065,
        "name": "Wellington Region",
        "state_code": "WGN",
        "type": null
      },
      {
        "id": 4064,
        "name": "West Coast Region",
        "state_code": "WTC",
        "type": null
      }
    ]
  },
  {
    "name": "Nicaragua",
    "iso3": "NIC",
    "iso2": "NI",
    "numeric_code": "558",
    "phone_code": "505",
    "capital": "Managua",
    "currency": "NIO",
    "currency_name": "Nicaraguan córdoba",
    "currency_symbol": "C$",
    "tld": ".ni",
    "native": "Nicaragua",
    "region": "Americas",
    "subregion": "Central America",
    "emoji": "🇳🇮",
    "emojiU": "U+1F1F3 U+1F1EE",
    "states": [
      {
        "id": 946,
        "name": "Boaco",
        "state_code": "BO",
        "type": "department"
      },
      {
        "id": 950,
        "name": "Carazo",
        "state_code": "CA",
        "type": "department"
      },
      {
        "id": 954,
        "name": "Chinandega",
        "state_code": "CI",
        "type": "department"
      },
      {
        "id": 940,
        "name": "Chontales",
        "state_code": "CO",
        "type": "department"
      },
      {
        "id": 945,
        "name": "Estelí",
        "state_code": "ES",
        "type": "department"
      },
      {
        "id": 943,
        "name": "Granada",
        "state_code": "GR",
        "type": "department"
      },
      {
        "id": 955,
        "name": "Jinotega",
        "state_code": "JI",
        "type": "department"
      },
      {
        "id": 944,
        "name": "León",
        "state_code": "LE",
        "type": "department"
      },
      {
        "id": 948,
        "name": "Madriz",
        "state_code": "MD",
        "type": "department"
      },
      {
        "id": 941,
        "name": "Managua",
        "state_code": "MN",
        "type": "department"
      },
      {
        "id": 953,
        "name": "Masaya",
        "state_code": "MS",
        "type": "department"
      },
      {
        "id": 947,
        "name": "Matagalpa",
        "state_code": "MT",
        "type": "department"
      },
      {
        "id": 951,
        "name": "North Caribbean Coast",
        "state_code": "AN",
        "type": "autonomous region\t"
      },
      {
        "id": 4964,
        "name": "Nueva Segovia\t",
        "state_code": "NS",
        "type": "department"
      },
      {
        "id": 949,
        "name": "Río San Juan",
        "state_code": "SJ",
        "type": "department"
      },
      {
        "id": 942,
        "name": "Rivas",
        "state_code": "RI",
        "type": "department"
      },
      {
        "id": 952,
        "name": "South Caribbean Coast",
        "state_code": "AS",
        "type": "autonomous region\t"
      }
    ]
  },
  {
    "name": "Niger",
    "iso3": "NER",
    "iso2": "NE",
    "numeric_code": "562",
    "phone_code": "227",
    "capital": "Niamey",
    "currency": "XOF",
    "currency_name": "West African CFA franc",
    "currency_symbol": "CFA",
    "tld": ".ne",
    "native": "Niger",
    "region": "Africa",
    "subregion": "Western Africa",
    "emoji": "🇳🇪",
    "emojiU": "U+1F1F3 U+1F1EA",
    "states": [
      {
        "id": 71,
        "name": "Agadez Region",
        "state_code": "1",
        "type": null
      },
      {
        "id": 72,
        "name": "Diffa Region",
        "state_code": "2",
        "type": null
      },
      {
        "id": 68,
        "name": "Dosso Region",
        "state_code": "3",
        "type": null
      },
      {
        "id": 70,
        "name": "Maradi Region",
        "state_code": "4",
        "type": null
      },
      {
        "id": 73,
        "name": "Tahoua Region",
        "state_code": "5",
        "type": null
      },
      {
        "id": 67,
        "name": "Tillabéri Region",
        "state_code": "6",
        "type": null
      },
      {
        "id": 69,
        "name": "Zinder Region",
        "state_code": "7",
        "type": null
      }
    ]
  },
  {
    "name": "Nigeria",
    "iso3": "NGA",
    "iso2": "NG",
    "numeric_code": "566",
    "phone_code": "234",
    "capital": "Abuja",
    "currency": "NGN",
    "currency_name": "Nigerian naira",
    "currency_symbol": "₦",
    "tld": ".ng",
    "native": "Nigeria",
    "region": "Africa",
    "subregion": "Western Africa",
    "emoji": "🇳🇬",
    "emojiU": "U+1F1F3 U+1F1EC",
    "states": [
      {
        "id": 303,
        "name": "Abia",
        "state_code": "AB",
        "type": "state"
      },
      {
        "id": 293,
        "name": "Abuja Federal Capital Territory",
        "state_code": "FC",
        "type": "capital territory"
      },
      {
        "id": 320,
        "name": "Adamawa",
        "state_code": "AD",
        "type": "state"
      },
      {
        "id": 304,
        "name": "Akwa Ibom",
        "state_code": "AK",
        "type": "state"
      },
      {
        "id": 315,
        "name": "Anambra",
        "state_code": "AN",
        "type": "state"
      },
      {
        "id": 312,
        "name": "Bauchi",
        "state_code": "BA",
        "type": "state"
      },
      {
        "id": 305,
        "name": "Bayelsa",
        "state_code": "BY",
        "type": "state"
      },
      {
        "id": 291,
        "name": "Benue",
        "state_code": "BE",
        "type": "state"
      },
      {
        "id": 307,
        "name": "Borno",
        "state_code": "BO",
        "type": "state"
      },
      {
        "id": 314,
        "name": "Cross River",
        "state_code": "CR",
        "type": "state"
      },
      {
        "id": 316,
        "name": "Delta",
        "state_code": "DE",
        "type": "state"
      },
      {
        "id": 311,
        "name": "Ebonyi",
        "state_code": "EB",
        "type": "state"
      },
      {
        "id": 318,
        "name": "Edo",
        "state_code": "ED",
        "type": "state"
      },
      {
        "id": 309,
        "name": "Ekiti",
        "state_code": "EK",
        "type": "state"
      },
      {
        "id": 289,
        "name": "Enugu",
        "state_code": "EN",
        "type": "state"
      },
      {
        "id": 310,
        "name": "Gombe",
        "state_code": "GO",
        "type": "state"
      },
      {
        "id": 308,
        "name": "Imo",
        "state_code": "IM",
        "type": "state"
      },
      {
        "id": 288,
        "name": "Jigawa",
        "state_code": "JI",
        "type": "state"
      },
      {
        "id": 294,
        "name": "Kaduna",
        "state_code": "KD",
        "type": "state"
      },
      {
        "id": 300,
        "name": "Kano",
        "state_code": "KN",
        "type": "state"
      },
      {
        "id": 313,
        "name": "Katsina",
        "state_code": "KT",
        "type": "state"
      },
      {
        "id": 290,
        "name": "Kebbi",
        "state_code": "KE",
        "type": "state"
      },
      {
        "id": 298,
        "name": "Kogi",
        "state_code": "KO",
        "type": "state"
      },
      {
        "id": 295,
        "name": "Kwara",
        "state_code": "KW",
        "type": "state"
      },
      {
        "id": 306,
        "name": "Lagos",
        "state_code": "LA",
        "type": "state"
      },
      {
        "id": 301,
        "name": "Nasarawa",
        "state_code": "NA",
        "type": "state"
      },
      {
        "id": 317,
        "name": "Niger",
        "state_code": "NI",
        "type": "state"
      },
      {
        "id": 323,
        "name": "Ogun",
        "state_code": "OG",
        "type": "state"
      },
      {
        "id": 321,
        "name": "Ondo",
        "state_code": "ON",
        "type": "state"
      },
      {
        "id": 322,
        "name": "Osun",
        "state_code": "OS",
        "type": "state"
      },
      {
        "id": 296,
        "name": "Oyo",
        "state_code": "OY",
        "type": "state"
      },
      {
        "id": 302,
        "name": "Plateau",
        "state_code": "PL",
        "type": "state"
      },
      {
        "id": 4926,
        "name": "Rivers",
        "state_code": "RI",
        "type": "state"
      },
      {
        "id": 292,
        "name": "Sokoto",
        "state_code": "SO",
        "type": "state"
      },
      {
        "id": 319,
        "name": "Taraba",
        "state_code": "TA",
        "type": "state"
      },
      {
        "id": 297,
        "name": "Yobe",
        "state_code": "YO",
        "type": "state"
      },
      {
        "id": 299,
        "name": "Zamfara",
        "state_code": "ZA",
        "type": "state"
      }
    ]
  },
  {
    "name": "Niue",
    "iso3": "NIU",
    "iso2": "NU",
    "numeric_code": "570",
    "phone_code": "683",
    "capital": "Alofi",
    "currency": "NZD",
    "currency_name": "New Zealand dollar",
    "currency_symbol": "$",
    "tld": ".nu",
    "native": "Niuē",
    "region": "Oceania",
    "subregion": "Polynesia",
    "emoji": "🇳🇺",
    "emojiU": "U+1F1F3 U+1F1FA",
    "states": []
  },
  {
    "name": "Norfolk Island",
    "iso3": "NFK",
    "iso2": "NF",
    "numeric_code": "574",
    "phone_code": "672",
    "capital": "Kingston",
    "currency": "AUD",
    "currency_name": "Australian dollar",
    "currency_symbol": "$",
    "tld": ".nf",
    "native": "Norfolk Island",
    "region": "Oceania",
    "subregion": "Australia and New Zealand",
    "emoji": "🇳🇫",
    "emojiU": "U+1F1F3 U+1F1EB",
    "states": []
  },
  {
    "name": "North Korea",
    "iso3": "PRK",
    "iso2": "KP",
    "numeric_code": "408",
    "phone_code": "850",
    "capital": "Pyongyang",
    "currency": "KPW",
    "currency_name": "North Korean Won",
    "currency_symbol": "₩",
    "tld": ".kp",
    "native": "북한",
    "region": "Asia",
    "subregion": "Eastern Asia",
    "emoji": "🇰🇵",
    "emojiU": "U+1F1F0 U+1F1F5",
    "states": [
      {
        "id": 3998,
        "name": "Chagang Province",
        "state_code": "04",
        "type": null
      },
      {
        "id": 3999,
        "name": "Kangwon Province",
        "state_code": "07",
        "type": null
      },
      {
        "id": 3995,
        "name": "North Hamgyong Province",
        "state_code": "09",
        "type": null
      },
      {
        "id": 4004,
        "name": "North Hwanghae Province",
        "state_code": "06",
        "type": null
      },
      {
        "id": 4002,
        "name": "North Pyongan Province",
        "state_code": "03",
        "type": null
      },
      {
        "id": 4005,
        "name": "Pyongyang",
        "state_code": "01",
        "type": null
      },
      {
        "id": 4001,
        "name": "Rason",
        "state_code": "13",
        "type": null
      },
      {
        "id": 3996,
        "name": "Ryanggang Province",
        "state_code": "10",
        "type": null
      },
      {
        "id": 4000,
        "name": "South Hamgyong Province",
        "state_code": "08",
        "type": null
      },
      {
        "id": 4003,
        "name": "South Hwanghae Province",
        "state_code": "05",
        "type": null
      },
      {
        "id": 3997,
        "name": "South Pyongan Province",
        "state_code": "02",
        "type": null
      }
    ]
  },
  {
    "name": "Northern Mariana Islands",
    "iso3": "MNP",
    "iso2": "MP",
    "numeric_code": "580",
    "phone_code": "+1-670",
    "capital": "Saipan",
    "currency": "USD",
    "currency_name": "United States dollar",
    "currency_symbol": "$",
    "tld": ".mp",
    "native": "Northern Mariana Islands",
    "region": "Oceania",
    "subregion": "Micronesia",
    "emoji": "🇲🇵",
    "emojiU": "U+1F1F2 U+1F1F5",
    "states": []
  },
  {
    "name": "Norway",
    "iso3": "NOR",
    "iso2": "NO",
    "numeric_code": "578",
    "phone_code": "47",
    "capital": "Oslo",
    "currency": "NOK",
    "currency_name": "Norwegian krone",
    "currency_symbol": "kr",
    "tld": ".no",
    "native": "Norge",
    "region": "Europe",
    "subregion": "Northern Europe",
    "emoji": "🇳🇴",
    "emojiU": "U+1F1F3 U+1F1F4",
    "states": [
      {
        "id": 1017,
        "name": "Akershus",
        "state_code": "02",
        "type": null
      },
      {
        "id": 1011,
        "name": "Buskerud",
        "state_code": "06",
        "type": null
      },
      {
        "id": 1016,
        "name": "Finnmark",
        "state_code": "20",
        "type": null
      },
      {
        "id": 1019,
        "name": "Hedmark",
        "state_code": "04",
        "type": null
      },
      {
        "id": 1023,
        "name": "Hordaland",
        "state_code": "12",
        "type": null
      },
      {
        "id": 1026,
        "name": "Jan Mayen",
        "state_code": "22",
        "type": null
      },
      {
        "id": 1020,
        "name": "Møre og Romsdal",
        "state_code": "15",
        "type": null
      },
      {
        "id": 1012,
        "name": "Nord-Trøndelag",
        "state_code": "17",
        "type": null
      },
      {
        "id": 1025,
        "name": "Nordland",
        "state_code": "18",
        "type": null
      },
      {
        "id": 1009,
        "name": "Oppland",
        "state_code": "05",
        "type": null
      },
      {
        "id": 1007,
        "name": "Oslo",
        "state_code": "03",
        "type": null
      },
      {
        "id": 1022,
        "name": "Østfold",
        "state_code": "01",
        "type": null
      },
      {
        "id": 1021,
        "name": "Rogaland",
        "state_code": "11",
        "type": null
      },
      {
        "id": 1018,
        "name": "Sogn og Fjordane",
        "state_code": "14",
        "type": null
      },
      {
        "id": 1010,
        "name": "Sør-Trøndelag",
        "state_code": "16",
        "type": null
      },
      {
        "id": 1013,
        "name": "Svalbard",
        "state_code": "21",
        "type": null
      },
      {
        "id": 1024,
        "name": "Telemark",
        "state_code": "08",
        "type": null
      },
      {
        "id": 1015,
        "name": "Troms",
        "state_code": "19",
        "type": null
      },
      {
        "id": 1006,
        "name": "Trøndelag",
        "state_code": "50",
        "type": null
      },
      {
        "id": 1014,
        "name": "Vest-Agder",
        "state_code": "10",
        "type": null
      },
      {
        "id": 1008,
        "name": "Vestfold",
        "state_code": "07",
        "type": null
      }
    ]
  },
  {
    "name": "Oman",
    "iso3": "OMN",
    "iso2": "OM",
    "numeric_code": "512",
    "phone_code": "968",
    "capital": "Muscat",
    "currency": "OMR",
    "currency_name": "Omani rial",
    "currency_symbol": ".ع.ر",
    "tld": ".om",
    "native": "عمان",
    "region": "Asia",
    "subregion": "Western Asia",
    "emoji": "🇴🇲",
    "emojiU": "U+1F1F4 U+1F1F2",
    "states": [
      {
        "id": 3058,
        "name": "Ad Dakhiliyah Governorate",
        "state_code": "DA",
        "type": null
      },
      {
        "id": 3047,
        "name": "Ad Dhahirah Governorate",
        "state_code": "ZA",
        "type": null
      },
      {
        "id": 3048,
        "name": "Al Batinah North Governorate",
        "state_code": "BS",
        "type": null
      },
      {
        "id": 3050,
        "name": "Al Batinah Region",
        "state_code": "BA",
        "type": null
      },
      {
        "id": 3049,
        "name": "Al Batinah South Governorate",
        "state_code": "BJ",
        "type": null
      },
      {
        "id": 3059,
        "name": "Al Buraimi Governorate",
        "state_code": "BU",
        "type": null
      },
      {
        "id": 3056,
        "name": "Al Wusta Governorate",
        "state_code": "WU",
        "type": null
      },
      {
        "id": 3053,
        "name": "Ash Sharqiyah North Governorate",
        "state_code": "SS",
        "type": null
      },
      {
        "id": 3051,
        "name": "Ash Sharqiyah Region",
        "state_code": "SH",
        "type": null
      },
      {
        "id": 3054,
        "name": "Ash Sharqiyah South Governorate",
        "state_code": "SJ",
        "type": null
      },
      {
        "id": 3057,
        "name": "Dhofar Governorate",
        "state_code": "ZU",
        "type": null
      },
      {
        "id": 3052,
        "name": "Musandam Governorate",
        "state_code": "MU",
        "type": null
      },
      {
        "id": 3055,
        "name": "Muscat Governorate",
        "state_code": "MA",
        "type": null
      }
    ]
  },
  {
    "name": "Pakistan",
    "iso3": "PAK",
    "iso2": "PK",
    "numeric_code": "586",
    "phone_code": "92",
    "capital": "Islamabad",
    "currency": "PKR",
    "currency_name": "Pakistani rupee",
    "currency_symbol": "₨",
    "tld": ".pk",
    "native": "Pakistan",
    "region": "Asia",
    "subregion": "Southern Asia",
    "emoji": "🇵🇰",
    "emojiU": "U+1F1F5 U+1F1F0",
    "states": [
      {
        "id": 3172,
        "name": "Azad Kashmir",
        "state_code": "JK",
        "type": null
      },
      {
        "id": 3174,
        "name": "Balochistan",
        "state_code": "BA",
        "type": null
      },
      {
        "id": 3173,
        "name": "Federally Administered Tribal Areas",
        "state_code": "TA",
        "type": null
      },
      {
        "id": 3170,
        "name": "Gilgit-Baltistan",
        "state_code": "GB",
        "type": null
      },
      {
        "id": 3169,
        "name": "Islamabad Capital Territory",
        "state_code": "IS",
        "type": null
      },
      {
        "id": 3171,
        "name": "Khyber Pakhtunkhwa",
        "state_code": "KP",
        "type": null
      },
      {
        "id": 3176,
        "name": "Punjab",
        "state_code": "PB",
        "type": null
      },
      {
        "id": 3175,
        "name": "Sindh",
        "state_code": "SD",
        "type": null
      }
    ]
  },
  {
    "name": "Palau",
    "iso3": "PLW",
    "iso2": "PW",
    "numeric_code": "585",
    "phone_code": "680",
    "capital": "Melekeok",
    "currency": "USD",
    "currency_name": "United States dollar",
    "currency_symbol": "$",
    "tld": ".pw",
    "native": "Palau",
    "region": "Oceania",
    "subregion": "Micronesia",
    "emoji": "🇵🇼",
    "emojiU": "U+1F1F5 U+1F1FC",
    "states": [
      {
        "id": 4540,
        "name": "Aimeliik",
        "state_code": "002",
        "type": null
      },
      {
        "id": 4528,
        "name": "Airai",
        "state_code": "004",
        "type": null
      },
      {
        "id": 4538,
        "name": "Angaur",
        "state_code": "010",
        "type": null
      },
      {
        "id": 4529,
        "name": "Hatohobei",
        "state_code": "050",
        "type": null
      },
      {
        "id": 4539,
        "name": "Kayangel",
        "state_code": "100",
        "type": null
      },
      {
        "id": 4532,
        "name": "Koror",
        "state_code": "150",
        "type": null
      },
      {
        "id": 4530,
        "name": "Melekeok",
        "state_code": "212",
        "type": null
      },
      {
        "id": 4537,
        "name": "Ngaraard",
        "state_code": "214",
        "type": null
      },
      {
        "id": 4533,
        "name": "Ngarchelong",
        "state_code": "218",
        "type": null
      },
      {
        "id": 4527,
        "name": "Ngardmau",
        "state_code": "222",
        "type": null
      },
      {
        "id": 4531,
        "name": "Ngatpang",
        "state_code": "224",
        "type": null
      },
      {
        "id": 4536,
        "name": "Ngchesar",
        "state_code": "226",
        "type": null
      },
      {
        "id": 4541,
        "name": "Ngeremlengui",
        "state_code": "227",
        "type": null
      },
      {
        "id": 4534,
        "name": "Ngiwal",
        "state_code": "228",
        "type": null
      },
      {
        "id": 4526,
        "name": "Peleliu",
        "state_code": "350",
        "type": null
      },
      {
        "id": 4535,
        "name": "Sonsorol",
        "state_code": "370",
        "type": null
      }
    ]
  },
  {
    "name": "Palestinian Territory Occupied",
    "iso3": "PSE",
    "iso2": "PS",
    "numeric_code": "275",
    "phone_code": "970",
    "capital": "East Jerusalem",
    "currency": "ILS",
    "currency_name": "Israeli new shekel",
    "currency_symbol": "₪",
    "tld": ".ps",
    "native": "فلسطين",
    "region": "Asia",
    "subregion": "Western Asia",
    "emoji": "🇵🇸",
    "emojiU": "U+1F1F5 U+1F1F8",
    "states": []
  },
  {
    "name": "Panama",
    "iso3": "PAN",
    "iso2": "PA",
    "numeric_code": "591",
    "phone_code": "507",
    "capital": "Panama City",
    "currency": "PAB",
    "currency_name": "Panamanian balboa",
    "currency_symbol": "B/.",
    "tld": ".pa",
    "native": "Panamá",
    "region": "Americas",
    "subregion": "Central America",
    "emoji": "🇵🇦",
    "emojiU": "U+1F1F5 U+1F1E6",
    "states": [
      {
        "id": 1393,
        "name": "Bocas del Toro Province",
        "state_code": "1",
        "type": null
      },
      {
        "id": 1397,
        "name": "Chiriquí Province",
        "state_code": "4",
        "type": null
      },
      {
        "id": 1387,
        "name": "Coclé Province",
        "state_code": "2",
        "type": null
      },
      {
        "id": 1386,
        "name": "Colón Province",
        "state_code": "3",
        "type": null
      },
      {
        "id": 1385,
        "name": "Darién Province",
        "state_code": "5",
        "type": null
      },
      {
        "id": 1396,
        "name": "Emberá-Wounaan Comarca",
        "state_code": "EM",
        "type": null
      },
      {
        "id": 1388,
        "name": "Guna Yala",
        "state_code": "KY",
        "type": null
      },
      {
        "id": 1389,
        "name": "Herrera Province",
        "state_code": "6",
        "type": null
      },
      {
        "id": 1390,
        "name": "Los Santos Province",
        "state_code": "7",
        "type": null
      },
      {
        "id": 1391,
        "name": "Ngöbe-Buglé Comarca",
        "state_code": "NB",
        "type": null
      },
      {
        "id": 1394,
        "name": "Panamá Oeste Province",
        "state_code": "10",
        "type": null
      },
      {
        "id": 1395,
        "name": "Panamá Province",
        "state_code": "8",
        "type": null
      },
      {
        "id": 1392,
        "name": "Veraguas Province",
        "state_code": "9",
        "type": null
      }
    ]
  },
  {
    "name": "Papua new Guinea",
    "iso3": "PNG",
    "iso2": "PG",
    "numeric_code": "598",
    "phone_code": "675",
    "capital": "Port Moresby",
    "currency": "PGK",
    "currency_name": "Papua New Guinean kina",
    "currency_symbol": "K",
    "tld": ".pg",
    "native": "Papua Niugini",
    "region": "Oceania",
    "subregion": "Melanesia",
    "emoji": "🇵🇬",
    "emojiU": "U+1F1F5 U+1F1EC",
    "states": [
      {
        "id": 4831,
        "name": "Bougainville",
        "state_code": "NSB",
        "type": null
      },
      {
        "id": 4847,
        "name": "Central Province",
        "state_code": "CPM",
        "type": null
      },
      {
        "id": 4846,
        "name": "Chimbu Province",
        "state_code": "CPK",
        "type": null
      },
      {
        "id": 4834,
        "name": "East New Britain",
        "state_code": "EBR",
        "type": null
      },
      {
        "id": 4845,
        "name": "Eastern Highlands Province",
        "state_code": "EHG",
        "type": null
      },
      {
        "id": 4848,
        "name": "Enga Province",
        "state_code": "EPW",
        "type": null
      },
      {
        "id": 4839,
        "name": "Gulf",
        "state_code": "GPK",
        "type": null
      },
      {
        "id": 4833,
        "name": "Hela",
        "state_code": "HLA",
        "type": null
      },
      {
        "id": 4832,
        "name": "Jiwaka Province",
        "state_code": "JWK",
        "type": null
      },
      {
        "id": 4843,
        "name": "Madang Province",
        "state_code": "MPM",
        "type": null
      },
      {
        "id": 4842,
        "name": "Manus Province",
        "state_code": "MRL",
        "type": null
      },
      {
        "id": 4849,
        "name": "Milne Bay Province",
        "state_code": "MBA",
        "type": null
      },
      {
        "id": 4835,
        "name": "Morobe Province",
        "state_code": "MPL",
        "type": null
      },
      {
        "id": 4841,
        "name": "New Ireland Province",
        "state_code": "NIK",
        "type": null
      },
      {
        "id": 4838,
        "name": "Oro Province",
        "state_code": "NPP",
        "type": null
      },
      {
        "id": 4837,
        "name": "Port Moresby",
        "state_code": "NCD",
        "type": null
      },
      {
        "id": 4836,
        "name": "Sandaun Province",
        "state_code": "SAN",
        "type": null
      },
      {
        "id": 4844,
        "name": "Southern Highlands Province",
        "state_code": "SHM",
        "type": null
      },
      {
        "id": 4830,
        "name": "West New Britain Province",
        "state_code": "WBK",
        "type": null
      },
      {
        "id": 4840,
        "name": "Western Highlands Province",
        "state_code": "WHM",
        "type": null
      },
      {
        "id": 4850,
        "name": "Western Province",
        "state_code": "WPD",
        "type": null
      }
    ]
  },
  {
    "name": "Paraguay",
    "iso3": "PRY",
    "iso2": "PY",
    "numeric_code": "600",
    "phone_code": "595",
    "capital": "Asuncion",
    "currency": "PYG",
    "currency_name": "Paraguayan guarani",
    "currency_symbol": "₲",
    "tld": ".py",
    "native": "Paraguay",
    "region": "Americas",
    "subregion": "South America",
    "emoji": "🇵🇾",
    "emojiU": "U+1F1F5 U+1F1FE",
    "states": [
      {
        "id": 2785,
        "name": "Alto Paraguay Department",
        "state_code": "16",
        "type": null
      },
      {
        "id": 2784,
        "name": "Alto Paraná Department",
        "state_code": "10",
        "type": null
      },
      {
        "id": 2782,
        "name": "Amambay Department",
        "state_code": "13",
        "type": null
      },
      {
        "id": 2780,
        "name": "Boquerón Department",
        "state_code": "19",
        "type": null
      },
      {
        "id": 2773,
        "name": "Caaguazú",
        "state_code": "5",
        "type": null
      },
      {
        "id": 2775,
        "name": "Caazapá",
        "state_code": "6",
        "type": null
      },
      {
        "id": 2771,
        "name": "Canindeyú",
        "state_code": "14",
        "type": null
      },
      {
        "id": 2777,
        "name": "Central Department",
        "state_code": "11",
        "type": null
      },
      {
        "id": 2779,
        "name": "Concepción Department",
        "state_code": "1",
        "type": null
      },
      {
        "id": 2783,
        "name": "Cordillera Department",
        "state_code": "3",
        "type": null
      },
      {
        "id": 2772,
        "name": "Guairá Department",
        "state_code": "4",
        "type": null
      },
      {
        "id": 2778,
        "name": "Itapúa",
        "state_code": "7",
        "type": null
      },
      {
        "id": 2786,
        "name": "Misiones Department",
        "state_code": "8",
        "type": null
      },
      {
        "id": 2781,
        "name": "Ñeembucú Department",
        "state_code": "12",
        "type": null
      },
      {
        "id": 2774,
        "name": "Paraguarí Department",
        "state_code": "9",
        "type": null
      },
      {
        "id": 2770,
        "name": "Presidente Hayes Department",
        "state_code": "15",
        "type": null
      },
      {
        "id": 2776,
        "name": "San Pedro Department",
        "state_code": "2",
        "type": null
      }
    ]
  },
  {
    "name": "Peru",
    "iso3": "PER",
    "iso2": "PE",
    "numeric_code": "604",
    "phone_code": "51",
    "capital": "Lima",
    "currency": "PEN",
    "currency_name": "Peruvian sol",
    "currency_symbol": "S/.",
    "tld": ".pe",
    "native": "Perú",
    "region": "Americas",
    "subregion": "South America",
    "emoji": "🇵🇪",
    "emojiU": "U+1F1F5 U+1F1EA",
    "states": [
      {
        "id": 3685,
        "name": "Amazonas",
        "state_code": "AMA",
        "type": null
      },
      {
        "id": 3680,
        "name": "Áncash",
        "state_code": "ANC",
        "type": null
      },
      {
        "id": 3699,
        "name": "Apurímac",
        "state_code": "APU",
        "type": null
      },
      {
        "id": 3681,
        "name": "Arequipa",
        "state_code": "ARE",
        "type": null
      },
      {
        "id": 3692,
        "name": "Ayacucho",
        "state_code": "AYA",
        "type": null
      },
      {
        "id": 3688,
        "name": "Cajamarca",
        "state_code": "CAJ",
        "type": null
      },
      {
        "id": 3701,
        "name": "Callao",
        "state_code": "CAL",
        "type": null
      },
      {
        "id": 3691,
        "name": "Cusco",
        "state_code": "CUS",
        "type": null
      },
      {
        "id": 3679,
        "name": "Huancavelica",
        "state_code": "HUV",
        "type": null
      },
      {
        "id": 3687,
        "name": "Huanuco",
        "state_code": "HUC",
        "type": null
      },
      {
        "id": 3700,
        "name": "Ica",
        "state_code": "ICA",
        "type": null
      },
      {
        "id": 3693,
        "name": "Junín",
        "state_code": "JUN",
        "type": null
      },
      {
        "id": 3683,
        "name": "La Libertad",
        "state_code": "LAL",
        "type": null
      },
      {
        "id": 3702,
        "name": "Lambayeque",
        "state_code": "LAM",
        "type": null
      },
      {
        "id": 3695,
        "name": "Lima",
        "state_code": "LIM",
        "type": null
      },
      {
        "id": 4922,
        "name": "Loreto",
        "state_code": "LOR",
        "type": null
      },
      {
        "id": 3678,
        "name": "Madre de Dios",
        "state_code": "MDD",
        "type": null
      },
      {
        "id": 3698,
        "name": "Moquegua",
        "state_code": "MOQ",
        "type": null
      },
      {
        "id": 3686,
        "name": "Pasco",
        "state_code": "PAS",
        "type": null
      },
      {
        "id": 3697,
        "name": "Piura",
        "state_code": "PIU",
        "type": null
      },
      {
        "id": 3682,
        "name": "Puno",
        "state_code": "PUN",
        "type": null
      },
      {
        "id": 3694,
        "name": "San Martín",
        "state_code": "SAM",
        "type": null
      },
      {
        "id": 3696,
        "name": "Tacna",
        "state_code": "TAC",
        "type": null
      },
      {
        "id": 3689,
        "name": "Tumbes",
        "state_code": "TUM",
        "type": null
      },
      {
        "id": 3684,
        "name": "Ucayali",
        "state_code": "UCA",
        "type": null
      }
    ]
  },
  {
    "name": "Philippines",
    "iso3": "PHL",
    "iso2": "PH",
    "numeric_code": "608",
    "phone_code": "63",
    "capital": "Manila",
    "currency": "PHP",
    "currency_name": "Philippine peso",
    "currency_symbol": "₱",
    "tld": ".ph",
    "native": "Pilipinas",
    "region": "Asia",
    "subregion": "South-Eastern Asia",
    "emoji": "🇵🇭",
    "emojiU": "U+1F1F5 U+1F1ED",
    "states": [
      {
        "id": 1324,
        "name": "Abra",
        "state_code": "ABR",
        "type": null
      },
      {
        "id": 1323,
        "name": "Agusan del Norte",
        "state_code": "AGN",
        "type": null
      },
      {
        "id": 1326,
        "name": "Agusan del Sur",
        "state_code": "AGS",
        "type": null
      },
      {
        "id": 1331,
        "name": "Aklan",
        "state_code": "AKL",
        "type": null
      },
      {
        "id": 1337,
        "name": "Albay",
        "state_code": "ALB",
        "type": null
      },
      {
        "id": 1336,
        "name": "Antique",
        "state_code": "ANT",
        "type": null
      },
      {
        "id": 1334,
        "name": "Apayao",
        "state_code": "APA",
        "type": null
      },
      {
        "id": 1341,
        "name": "Aurora",
        "state_code": "AUR",
        "type": null
      },
      {
        "id": 1316,
        "name": "Autonomous Region in Muslim Mindanao",
        "state_code": "14",
        "type": null
      },
      {
        "id": 1346,
        "name": "Basilan",
        "state_code": "BAS",
        "type": null
      },
      {
        "id": 1344,
        "name": "Bataan",
        "state_code": "BAN",
        "type": null
      },
      {
        "id": 1352,
        "name": "Batanes",
        "state_code": "BTN",
        "type": null
      },
      {
        "id": 1359,
        "name": "Batangas",
        "state_code": "BTG",
        "type": null
      },
      {
        "id": 1363,
        "name": "Benguet",
        "state_code": "BEN",
        "type": null
      },
      {
        "id": 1304,
        "name": "Bicol Region",
        "state_code": "05",
        "type": null
      },
      {
        "id": 1274,
        "name": "Biliran",
        "state_code": "BIL",
        "type": null
      },
      {
        "id": 1272,
        "name": "Bohol",
        "state_code": "BOH",
        "type": null
      },
      {
        "id": 1270,
        "name": "Bukidnon",
        "state_code": "BUK",
        "type": null
      },
      {
        "id": 1278,
        "name": "Bulacan",
        "state_code": "BUL",
        "type": null
      },
      {
        "id": 1279,
        "name": "Cagayan",
        "state_code": "CAG",
        "type": null
      },
      {
        "id": 1342,
        "name": "Cagayan Valley",
        "state_code": "02",
        "type": null
      },
      {
        "id": 1294,
        "name": "Calabarzon",
        "state_code": "40",
        "type": null
      },
      {
        "id": 1283,
        "name": "Camarines Norte",
        "state_code": "CAN",
        "type": null
      },
      {
        "id": 1287,
        "name": "Camarines Sur",
        "state_code": "CAS",
        "type": null
      },
      {
        "id": 1285,
        "name": "Camiguin",
        "state_code": "CAM",
        "type": null
      },
      {
        "id": 1292,
        "name": "Capiz",
        "state_code": "CAP",
        "type": null
      },
      {
        "id": 1314,
        "name": "Caraga",
        "state_code": "13",
        "type": null
      },
      {
        "id": 1301,
        "name": "Catanduanes",
        "state_code": "CAT",
        "type": null
      },
      {
        "id": 1307,
        "name": "Cavite",
        "state_code": "CAV",
        "type": null
      },
      {
        "id": 1306,
        "name": "Cebu",
        "state_code": "CEB",
        "type": null
      },
      {
        "id": 1345,
        "name": "Central Luzon",
        "state_code": "03",
        "type": null
      },
      {
        "id": 1308,
        "name": "Central Visayas",
        "state_code": "07",
        "type": null
      },
      {
        "id": 1311,
        "name": "Compostela Valley",
        "state_code": "COM",
        "type": null
      },
      {
        "id": 1335,
        "name": "Cordillera Administrative Region",
        "state_code": "15",
        "type": null
      },
      {
        "id": 1320,
        "name": "Cotabato",
        "state_code": "NCO",
        "type": null
      },
      {
        "id": 1319,
        "name": "Davao del Norte",
        "state_code": "DAV",
        "type": null
      },
      {
        "id": 1318,
        "name": "Davao del Sur",
        "state_code": "DAS",
        "type": null
      },
      {
        "id": 1309,
        "name": "Davao Occidental",
        "state_code": "DVO",
        "type": null
      },
      {
        "id": 1289,
        "name": "Davao Oriental",
        "state_code": "DAO",
        "type": null
      },
      {
        "id": 1340,
        "name": "Davao Region",
        "state_code": "11",
        "type": null
      },
      {
        "id": 1291,
        "name": "Dinagat Islands",
        "state_code": "DIN",
        "type": null
      },
      {
        "id": 1290,
        "name": "Eastern Samar",
        "state_code": "EAS",
        "type": null
      },
      {
        "id": 1322,
        "name": "Eastern Visayas",
        "state_code": "08",
        "type": null
      },
      {
        "id": 1303,
        "name": "Guimaras",
        "state_code": "GUI",
        "type": null
      },
      {
        "id": 1300,
        "name": "Ifugao",
        "state_code": "IFU",
        "type": null
      },
      {
        "id": 1298,
        "name": "Ilocos Norte",
        "state_code": "ILN",
        "type": null
      },
      {
        "id": 1355,
        "name": "Ilocos Region",
        "state_code": "01",
        "type": null
      },
      {
        "id": 1321,
        "name": "Ilocos Sur",
        "state_code": "ILS",
        "type": null
      },
      {
        "id": 1315,
        "name": "Iloilo",
        "state_code": "ILI",
        "type": null
      },
      {
        "id": 1313,
        "name": "Isabela",
        "state_code": "ISA",
        "type": null
      },
      {
        "id": 1312,
        "name": "Kalinga",
        "state_code": "KAL",
        "type": null
      },
      {
        "id": 1317,
        "name": "La Union",
        "state_code": "LUN",
        "type": null
      },
      {
        "id": 1328,
        "name": "Laguna",
        "state_code": "LAG",
        "type": null
      },
      {
        "id": 1327,
        "name": "Lanao del Norte",
        "state_code": "LAN",
        "type": null
      },
      {
        "id": 1333,
        "name": "Lanao del Sur",
        "state_code": "LAS",
        "type": null
      },
      {
        "id": 1332,
        "name": "Leyte",
        "state_code": "LEY",
        "type": null
      },
      {
        "id": 1330,
        "name": "Maguindanao",
        "state_code": "MAG",
        "type": null
      },
      {
        "id": 1329,
        "name": "Marinduque",
        "state_code": "MAD",
        "type": null
      },
      {
        "id": 1338,
        "name": "Masbate",
        "state_code": "MAS",
        "type": null
      },
      {
        "id": 1347,
        "name": "Metro Manila",
        "state_code": "NCR",
        "type": null
      },
      {
        "id": 1299,
        "name": "Mimaropa",
        "state_code": "41",
        "type": null
      },
      {
        "id": 1343,
        "name": "Misamis Occidental",
        "state_code": "MSC",
        "type": null
      },
      {
        "id": 1348,
        "name": "Misamis Oriental",
        "state_code": "MSR",
        "type": null
      },
      {
        "id": 1353,
        "name": "Mountain Province",
        "state_code": "MOU",
        "type": null
      },
      {
        "id": 1351,
        "name": "Negros Occidental",
        "state_code": "NEC",
        "type": null
      },
      {
        "id": 1350,
        "name": "Negros Oriental",
        "state_code": "NER",
        "type": null
      },
      {
        "id": 1339,
        "name": "Northern Mindanao",
        "state_code": "10",
        "type": null
      },
      {
        "id": 1349,
        "name": "Northern Samar",
        "state_code": "NSA",
        "type": null
      },
      {
        "id": 1360,
        "name": "Nueva Ecija",
        "state_code": "NUE",
        "type": null
      },
      {
        "id": 1358,
        "name": "Nueva Vizcaya",
        "state_code": "NUV",
        "type": null
      },
      {
        "id": 1356,
        "name": "Occidental Mindoro",
        "state_code": "MDC",
        "type": null
      },
      {
        "id": 1354,
        "name": "Oriental Mindoro",
        "state_code": "MDR",
        "type": null
      },
      {
        "id": 1361,
        "name": "Palawan",
        "state_code": "PLW",
        "type": null
      },
      {
        "id": 1365,
        "name": "Pampanga",
        "state_code": "PAM",
        "type": null
      },
      {
        "id": 1364,
        "name": "Pangasinan",
        "state_code": "PAN",
        "type": null
      },
      {
        "id": 1275,
        "name": "Quezon",
        "state_code": "QUE",
        "type": null
      },
      {
        "id": 1273,
        "name": "Quirino",
        "state_code": "QUI",
        "type": null
      },
      {
        "id": 1271,
        "name": "Rizal",
        "state_code": "RIZ",
        "type": null
      },
      {
        "id": 1269,
        "name": "Romblon",
        "state_code": "ROM",
        "type": null
      },
      {
        "id": 1277,
        "name": "Sarangani",
        "state_code": "SAR",
        "type": null
      },
      {
        "id": 1276,
        "name": "Siquijor",
        "state_code": "SIG",
        "type": null
      },
      {
        "id": 1310,
        "name": "Soccsksargen",
        "state_code": "12",
        "type": null
      },
      {
        "id": 1281,
        "name": "Sorsogon",
        "state_code": "SOR",
        "type": null
      },
      {
        "id": 1280,
        "name": "South Cotabato",
        "state_code": "SCO",
        "type": null
      },
      {
        "id": 1284,
        "name": "Southern Leyte",
        "state_code": "SLE",
        "type": null
      },
      {
        "id": 1282,
        "name": "Sultan Kudarat",
        "state_code": "SUK",
        "type": null
      },
      {
        "id": 1288,
        "name": "Sulu",
        "state_code": "SLU",
        "type": null
      },
      {
        "id": 1286,
        "name": "Surigao del Norte",
        "state_code": "SUN",
        "type": null
      },
      {
        "id": 1296,
        "name": "Surigao del Sur",
        "state_code": "SUR",
        "type": null
      },
      {
        "id": 1295,
        "name": "Tarlac",
        "state_code": "TAR",
        "type": null
      },
      {
        "id": 1293,
        "name": "Tawi-Tawi",
        "state_code": "TAW",
        "type": null
      },
      {
        "id": 1305,
        "name": "Western Visayas",
        "state_code": "06",
        "type": null
      },
      {
        "id": 1297,
        "name": "Zambales",
        "state_code": "ZMB",
        "type": null
      },
      {
        "id": 1302,
        "name": "Zamboanga del Norte",
        "state_code": "ZAN",
        "type": null
      },
      {
        "id": 1357,
        "name": "Zamboanga del Sur",
        "state_code": "ZAS",
        "type": null
      },
      {
        "id": 1325,
        "name": "Zamboanga Peninsula",
        "state_code": "09",
        "type": null
      },
      {
        "id": 1362,
        "name": "Zamboanga Sibugay",
        "state_code": "ZSI",
        "type": null
      }
    ]
  },
  {
    "name": "Pitcairn Island",
    "iso3": "PCN",
    "iso2": "PN",
    "numeric_code": "612",
    "phone_code": "870",
    "capital": "Adamstown",
    "currency": "NZD",
    "currency_name": "New Zealand dollar",
    "currency_symbol": "$",
    "tld": ".pn",
    "native": "Pitcairn Islands",
    "region": "Oceania",
    "subregion": "Polynesia",
    "emoji": "🇵🇳",
    "emojiU": "U+1F1F5 U+1F1F3",
    "states": []
  },
  {
    "name": "Poland",
    "iso3": "POL",
    "iso2": "PL",
    "numeric_code": "616",
    "phone_code": "48",
    "capital": "Warsaw",
    "currency": "PLN",
    "currency_name": "Polish złoty",
    "currency_symbol": "zł",
    "tld": ".pl",
    "native": "Polska",
    "region": "Europe",
    "subregion": "Eastern Europe",
    "emoji": "🇵🇱",
    "emojiU": "U+1F1F5 U+1F1F1",
    "states": [
      {
        "id": 1634,
        "name": "Greater Poland Voivodeship",
        "state_code": "WP",
        "type": null
      },
      {
        "id": 1625,
        "name": "Kuyavian-Pomeranian Voivodeship",
        "state_code": "KP",
        "type": null
      },
      {
        "id": 1635,
        "name": "Lesser Poland Voivodeship",
        "state_code": "MA",
        "type": null
      },
      {
        "id": 1629,
        "name": "Lower Silesian Voivodeship",
        "state_code": "DS",
        "type": null
      },
      {
        "id": 1638,
        "name": "Lublin Voivodeship",
        "state_code": "LU",
        "type": null
      },
      {
        "id": 1631,
        "name": "Lubusz Voivodeship",
        "state_code": "LB",
        "type": null
      },
      {
        "id": 1636,
        "name": "Łódź Voivodeship",
        "state_code": "LD",
        "type": null
      },
      {
        "id": 1637,
        "name": "Masovian Voivodeship",
        "state_code": "MZ",
        "type": null
      },
      {
        "id": 1622,
        "name": "Opole Voivodeship",
        "state_code": "OP",
        "type": null
      },
      {
        "id": 1626,
        "name": "Podkarpackie Voivodeship",
        "state_code": "PK",
        "type": null
      },
      {
        "id": 1632,
        "name": "Podlaskie Voivodeship",
        "state_code": "PD",
        "type": null
      },
      {
        "id": 1624,
        "name": "Pomeranian Voivodeship",
        "state_code": "PM",
        "type": null
      },
      {
        "id": 1623,
        "name": "Silesian Voivodeship",
        "state_code": "SL",
        "type": null
      },
      {
        "id": 1630,
        "name": "Świętokrzyskie Voivodeship",
        "state_code": "SK",
        "type": null
      },
      {
        "id": 1628,
        "name": "Warmian-Masurian Voivodeship",
        "state_code": "WN",
        "type": null
      },
      {
        "id": 1633,
        "name": "West Pomeranian Voivodeship",
        "state_code": "ZP",
        "type": null
      }
    ]
  },
  {
    "name": "Portugal",
    "iso3": "PRT",
    "iso2": "PT",
    "numeric_code": "620",
    "phone_code": "351",
    "capital": "Lisbon",
    "currency": "EUR",
    "currency_name": "Euro",
    "currency_symbol": "€",
    "tld": ".pt",
    "native": "Portugal",
    "region": "Europe",
    "subregion": "Southern Europe",
    "emoji": "🇵🇹",
    "emojiU": "U+1F1F5 U+1F1F9",
    "states": [
      {
        "id": 2233,
        "name": "Açores",
        "state_code": "20",
        "type": null
      },
      {
        "id": 2235,
        "name": "Aveiro",
        "state_code": "01",
        "type": null
      },
      {
        "id": 2230,
        "name": "Beja",
        "state_code": "02",
        "type": null
      },
      {
        "id": 2244,
        "name": "Braga",
        "state_code": "03",
        "type": null
      },
      {
        "id": 2229,
        "name": "Bragança",
        "state_code": "04",
        "type": null
      },
      {
        "id": 2241,
        "name": "Castelo Branco",
        "state_code": "05",
        "type": null
      },
      {
        "id": 2246,
        "name": "Coimbra",
        "state_code": "06",
        "type": null
      },
      {
        "id": 2236,
        "name": "Évora",
        "state_code": "07",
        "type": null
      },
      {
        "id": 2239,
        "name": "Faro",
        "state_code": "08",
        "type": null
      },
      {
        "id": 4859,
        "name": "Guarda",
        "state_code": "09",
        "type": null
      },
      {
        "id": 2240,
        "name": "Leiria",
        "state_code": "10",
        "type": null
      },
      {
        "id": 2228,
        "name": "Lisbon",
        "state_code": "11",
        "type": null
      },
      {
        "id": 2231,
        "name": "Madeira",
        "state_code": "30",
        "type": null
      },
      {
        "id": 2232,
        "name": "Portalegre",
        "state_code": "12",
        "type": null
      },
      {
        "id": 2243,
        "name": "Porto",
        "state_code": "13",
        "type": null
      },
      {
        "id": 2238,
        "name": "Santarém",
        "state_code": "14",
        "type": null
      },
      {
        "id": 2242,
        "name": "Setúbal",
        "state_code": "15",
        "type": null
      },
      {
        "id": 2245,
        "name": "Viana do Castelo",
        "state_code": "16",
        "type": null
      },
      {
        "id": 2234,
        "name": "Vila Real",
        "state_code": "17",
        "type": null
      },
      {
        "id": 2237,
        "name": "Viseu",
        "state_code": "18",
        "type": null
      }
    ]
  },
  {
    "name": "Puerto Rico",
    "iso3": "PRI",
    "iso2": "PR",
    "numeric_code": "630",
    "phone_code": "+1-787 and 1-939",
    "capital": "San Juan",
    "currency": "USD",
    "currency_name": "United States dollar",
    "currency_symbol": "$",
    "tld": ".pr",
    "native": "Puerto Rico",
    "region": "Americas",
    "subregion": "Caribbean",
    "emoji": "🇵🇷",
    "emojiU": "U+1F1F5 U+1F1F7",
    "states": []
  },
  {
    "name": "Qatar",
    "iso3": "QAT",
    "iso2": "QA",
    "numeric_code": "634",
    "phone_code": "974",
    "capital": "Doha",
    "currency": "QAR",
    "currency_name": "Qatari riyal",
    "currency_symbol": "ق.ر",
    "tld": ".qa",
    "native": "قطر",
    "region": "Asia",
    "subregion": "Western Asia",
    "emoji": "🇶🇦",
    "emojiU": "U+1F1F6 U+1F1E6",
    "states": [
      {
        "id": 3182,
        "name": "Al Daayen",
        "state_code": "ZA",
        "type": null
      },
      {
        "id": 3183,
        "name": "Al Khor",
        "state_code": "KH",
        "type": null
      },
      {
        "id": 3177,
        "name": "Al Rayyan Municipality",
        "state_code": "RA",
        "type": null
      },
      {
        "id": 3179,
        "name": "Al Wakrah",
        "state_code": "WA",
        "type": null
      },
      {
        "id": 3178,
        "name": "Al-Shahaniya",
        "state_code": "SH",
        "type": null
      },
      {
        "id": 3181,
        "name": "Doha",
        "state_code": "DA",
        "type": null
      },
      {
        "id": 3180,
        "name": "Madinat ash Shamal",
        "state_code": "MS",
        "type": null
      },
      {
        "id": 3184,
        "name": "Umm Salal Municipality",
        "state_code": "US",
        "type": null
      }
    ]
  },
  {
    "name": "Reunion",
    "iso3": "REU",
    "iso2": "RE",
    "numeric_code": "638",
    "phone_code": "262",
    "capital": "Saint-Denis",
    "currency": "EUR",
    "currency_name": "Euro",
    "currency_symbol": "€",
    "tld": ".re",
    "native": "La Réunion",
    "region": "Africa",
    "subregion": "Eastern Africa",
    "emoji": "🇷🇪",
    "emojiU": "U+1F1F7 U+1F1EA",
    "states": []
  },
  {
    "name": "Romania",
    "iso3": "ROU",
    "iso2": "RO",
    "numeric_code": "642",
    "phone_code": "40",
    "capital": "Bucharest",
    "currency": "RON",
    "currency_name": "Romanian leu",
    "currency_symbol": "lei",
    "tld": ".ro",
    "native": "România",
    "region": "Europe",
    "subregion": "Eastern Europe",
    "emoji": "🇷🇴",
    "emojiU": "U+1F1F7 U+1F1F4",
    "states": [
      {
        "id": 4724,
        "name": "Alba",
        "state_code": "AB",
        "type": null
      },
      {
        "id": 4739,
        "name": "Arad County",
        "state_code": "AR",
        "type": null
      },
      {
        "id": 4722,
        "name": "Arges",
        "state_code": "AG",
        "type": null
      },
      {
        "id": 4744,
        "name": "Bacău County",
        "state_code": "BC",
        "type": null
      },
      {
        "id": 4723,
        "name": "Bihor County",
        "state_code": "BH",
        "type": null
      },
      {
        "id": 4733,
        "name": "Bistrița-Năsăud County",
        "state_code": "BN",
        "type": null
      },
      {
        "id": 4740,
        "name": "Botoșani County",
        "state_code": "BT",
        "type": null
      },
      {
        "id": 4736,
        "name": "Braila",
        "state_code": "BR",
        "type": null
      },
      {
        "id": 4759,
        "name": "Brașov County",
        "state_code": "BV",
        "type": null
      },
      {
        "id": 4730,
        "name": "Bucharest",
        "state_code": "B",
        "type": null
      },
      {
        "id": 4756,
        "name": "Buzău County",
        "state_code": "BZ",
        "type": null
      },
      {
        "id": 4732,
        "name": "Călărași County",
        "state_code": "CL",
        "type": null
      },
      {
        "id": 4753,
        "name": "Caraș-Severin County",
        "state_code": "CS",
        "type": null
      },
      {
        "id": 4734,
        "name": "Cluj County",
        "state_code": "CJ",
        "type": null
      },
      {
        "id": 4737,
        "name": "Constanța County",
        "state_code": "CT",
        "type": null
      },
      {
        "id": 4754,
        "name": "Covasna County",
        "state_code": "CV",
        "type": null
      },
      {
        "id": 4745,
        "name": "Dâmbovița County",
        "state_code": "DB",
        "type": null
      },
      {
        "id": 4742,
        "name": "Dolj County",
        "state_code": "DJ",
        "type": null
      },
      {
        "id": 4747,
        "name": "Galați County",
        "state_code": "GL",
        "type": null
      },
      {
        "id": 4726,
        "name": "Giurgiu County",
        "state_code": "GR",
        "type": null
      },
      {
        "id": 4750,
        "name": "Gorj County",
        "state_code": "GJ",
        "type": null
      },
      {
        "id": 4749,
        "name": "Harghita County",
        "state_code": "HR",
        "type": null
      },
      {
        "id": 4721,
        "name": "Hunedoara County",
        "state_code": "HD",
        "type": null
      },
      {
        "id": 4743,
        "name": "Ialomița County",
        "state_code": "IL",
        "type": null
      },
      {
        "id": 4735,
        "name": "Iași County",
        "state_code": "IS",
        "type": null
      },
      {
        "id": 4725,
        "name": "Ilfov County",
        "state_code": "IF",
        "type": null
      },
      {
        "id": 4760,
        "name": "Maramureș County",
        "state_code": "MM",
        "type": null
      },
      {
        "id": 4751,
        "name": "Mehedinți County",
        "state_code": "MH",
        "type": null
      },
      {
        "id": 4915,
        "name": "Mureș County",
        "state_code": "MS",
        "type": null
      },
      {
        "id": 4731,
        "name": "Neamț County",
        "state_code": "NT",
        "type": null
      },
      {
        "id": 4738,
        "name": "Olt County",
        "state_code": "OT",
        "type": null
      },
      {
        "id": 4729,
        "name": "Prahova County",
        "state_code": "PH",
        "type": null
      },
      {
        "id": 4741,
        "name": "Sălaj County",
        "state_code": "SJ",
        "type": null
      },
      {
        "id": 4746,
        "name": "Satu Mare County",
        "state_code": "SM",
        "type": null
      },
      {
        "id": 4755,
        "name": "Sibiu County",
        "state_code": "SB",
        "type": null
      },
      {
        "id": 4720,
        "name": "Suceava County",
        "state_code": "SV",
        "type": null
      },
      {
        "id": 4728,
        "name": "Teleorman County",
        "state_code": "TR",
        "type": null
      },
      {
        "id": 4748,
        "name": "Timiș County",
        "state_code": "TM",
        "type": null
      },
      {
        "id": 4727,
        "name": "Tulcea County",
        "state_code": "TL",
        "type": null
      },
      {
        "id": 4757,
        "name": "Vâlcea County",
        "state_code": "VL",
        "type": null
      },
      {
        "id": 4752,
        "name": "Vaslui County",
        "state_code": "VS",
        "type": null
      },
      {
        "id": 4758,
        "name": "Vrancea County",
        "state_code": "VN",
        "type": null
      }
    ]
  },
  {
    "name": "Russia",
    "iso3": "RUS",
    "iso2": "RU",
    "numeric_code": "643",
    "phone_code": "7",
    "capital": "Moscow",
    "currency": "RUB",
    "currency_name": "Russian ruble",
    "currency_symbol": "₽",
    "tld": ".ru",
    "native": "Россия",
    "region": "Europe",
    "subregion": "Eastern Europe",
    "emoji": "🇷🇺",
    "emojiU": "U+1F1F7 U+1F1FA",
    "states": [
      {
        "id": 1911,
        "name": "Altai Krai",
        "state_code": "ALT",
        "type": null
      },
      {
        "id": 1876,
        "name": "Altai Republic",
        "state_code": "AL",
        "type": null
      },
      {
        "id": 1858,
        "name": "Amur Oblast",
        "state_code": "AMU",
        "type": null
      },
      {
        "id": 1849,
        "name": "Arkhangelsk",
        "state_code": "ARK",
        "type": null
      },
      {
        "id": 1866,
        "name": "Astrakhan Oblast",
        "state_code": "AST",
        "type": null
      },
      {
        "id": 1903,
        "name": "Belgorod Oblast",
        "state_code": "BEL",
        "type": null
      },
      {
        "id": 1867,
        "name": "Bryansk Oblast",
        "state_code": "BRY",
        "type": null
      },
      {
        "id": 1893,
        "name": "Chechen Republic",
        "state_code": "CE",
        "type": null
      },
      {
        "id": 1845,
        "name": "Chelyabinsk Oblast",
        "state_code": "CHE",
        "type": null
      },
      {
        "id": 1859,
        "name": "Chukotka Autonomous Okrug",
        "state_code": "CHU",
        "type": null
      },
      {
        "id": 1914,
        "name": "Chuvash Republic",
        "state_code": "CU",
        "type": null
      },
      {
        "id": 1880,
        "name": "Irkutsk",
        "state_code": "IRK",
        "type": null
      },
      {
        "id": 1864,
        "name": "Ivanovo Oblast",
        "state_code": "IVA",
        "type": null
      },
      {
        "id": 1835,
        "name": "Jewish Autonomous Oblast",
        "state_code": "YEV",
        "type": null
      },
      {
        "id": 1892,
        "name": "Kabardino-Balkar Republic",
        "state_code": "KB",
        "type": null
      },
      {
        "id": 1902,
        "name": "Kaliningrad",
        "state_code": "KGD",
        "type": null
      },
      {
        "id": 1844,
        "name": "Kaluga Oblast",
        "state_code": "KLU",
        "type": null
      },
      {
        "id": 1865,
        "name": "Kamchatka Krai",
        "state_code": "KAM",
        "type": null
      },
      {
        "id": 1869,
        "name": "Karachay-Cherkess Republic",
        "state_code": "KC",
        "type": null
      },
      {
        "id": 1897,
        "name": "Kemerovo Oblast",
        "state_code": "KEM",
        "type": null
      },
      {
        "id": 1873,
        "name": "Khabarovsk Krai",
        "state_code": "KHA",
        "type": null
      },
      {
        "id": 1838,
        "name": "Khanty-Mansi Autonomous Okrug",
        "state_code": "KHM",
        "type": null
      },
      {
        "id": 1890,
        "name": "Kirov Oblast",
        "state_code": "KIR",
        "type": null
      },
      {
        "id": 1899,
        "name": "Komi Republic",
        "state_code": "KO",
        "type": null
      },
      {
        "id": 1910,
        "name": "Kostroma Oblast",
        "state_code": "KOS",
        "type": null
      },
      {
        "id": 1891,
        "name": "Krasnodar Krai",
        "state_code": "KDA",
        "type": null
      },
      {
        "id": 1840,
        "name": "Krasnoyarsk Krai",
        "state_code": "KYA",
        "type": null
      },
      {
        "id": 1915,
        "name": "Kurgan Oblast",
        "state_code": "KGN",
        "type": null
      },
      {
        "id": 1855,
        "name": "Kursk Oblast",
        "state_code": "KRS",
        "type": null
      },
      {
        "id": 1896,
        "name": "Leningrad Oblast",
        "state_code": "LEN",
        "type": null
      },
      {
        "id": 1889,
        "name": "Lipetsk Oblast",
        "state_code": "LIP",
        "type": null
      },
      {
        "id": 1839,
        "name": "Magadan Oblast",
        "state_code": "MAG",
        "type": null
      },
      {
        "id": 1870,
        "name": "Mari El Republic",
        "state_code": "ME",
        "type": null
      },
      {
        "id": 1901,
        "name": "Moscow",
        "state_code": "MOW",
        "type": null
      },
      {
        "id": 1882,
        "name": "Moscow Oblast",
        "state_code": "MOS",
        "type": null
      },
      {
        "id": 1843,
        "name": "Murmansk Oblast",
        "state_code": "MUR",
        "type": null
      },
      {
        "id": 1836,
        "name": "Nenets Autonomous Okrug",
        "state_code": "NEN",
        "type": null
      },
      {
        "id": 1857,
        "name": "Nizhny Novgorod Oblast",
        "state_code": "NIZ",
        "type": null
      },
      {
        "id": 1834,
        "name": "Novgorod Oblast",
        "state_code": "NGR",
        "type": null
      },
      {
        "id": 1888,
        "name": "Novosibirsk",
        "state_code": "NVS",
        "type": null
      },
      {
        "id": 1846,
        "name": "Omsk Oblast",
        "state_code": "OMS",
        "type": null
      },
      {
        "id": 1886,
        "name": "Orenburg Oblast",
        "state_code": "ORE",
        "type": null
      },
      {
        "id": 1908,
        "name": "Oryol Oblast",
        "state_code": "ORL",
        "type": null
      },
      {
        "id": 1909,
        "name": "Penza Oblast",
        "state_code": "PNZ",
        "type": null
      },
      {
        "id": 1871,
        "name": "Perm Krai",
        "state_code": "PER",
        "type": null
      },
      {
        "id": 1833,
        "name": "Primorsky Krai",
        "state_code": "PRI",
        "type": null
      },
      {
        "id": 1863,
        "name": "Pskov Oblast",
        "state_code": "PSK",
        "type": null
      },
      {
        "id": 1852,
        "name": "Republic of Adygea",
        "state_code": "AD",
        "type": null
      },
      {
        "id": 1854,
        "name": "Republic of Bashkortostan",
        "state_code": "BA",
        "type": null
      },
      {
        "id": 1842,
        "name": "Republic of Buryatia",
        "state_code": "BU",
        "type": null
      },
      {
        "id": 1850,
        "name": "Republic of Dagestan",
        "state_code": "DA",
        "type": null
      },
      {
        "id": 1884,
        "name": "Republic of Ingushetia",
        "state_code": "IN",
        "type": null
      },
      {
        "id": 1883,
        "name": "Republic of Kalmykia",
        "state_code": "KL",
        "type": null
      },
      {
        "id": 1841,
        "name": "Republic of Karelia",
        "state_code": "KR",
        "type": null
      },
      {
        "id": 1877,
        "name": "Republic of Khakassia",
        "state_code": "KK",
        "type": null
      },
      {
        "id": 1898,
        "name": "Republic of Mordovia",
        "state_code": "MO",
        "type": null
      },
      {
        "id": 1853,
        "name": "Republic of North Ossetia-Alania",
        "state_code": "SE",
        "type": null
      },
      {
        "id": 1861,
        "name": "Republic of Tatarstan",
        "state_code": "TA",
        "type": null
      },
      {
        "id": 1837,
        "name": "Rostov Oblast",
        "state_code": "ROS",
        "type": null
      },
      {
        "id": 1905,
        "name": "Ryazan Oblast",
        "state_code": "RYA",
        "type": null
      },
      {
        "id": 1879,
        "name": "Saint Petersburg",
        "state_code": "SPE",
        "type": null
      },
      {
        "id": 1848,
        "name": "Sakha Republic",
        "state_code": "SA",
        "type": null
      },
      {
        "id": 1875,
        "name": "Sakhalin",
        "state_code": "SAK",
        "type": null
      },
      {
        "id": 1862,
        "name": "Samara Oblast",
        "state_code": "SAM",
        "type": null
      },
      {
        "id": 1887,
        "name": "Saratov Oblast",
        "state_code": "SAR",
        "type": null
      },
      {
        "id": 1912,
        "name": "Sevastopol",
        "state_code": "UA-40",
        "type": null
      },
      {
        "id": 1885,
        "name": "Smolensk Oblast",
        "state_code": "SMO",
        "type": null
      },
      {
        "id": 1868,
        "name": "Stavropol Krai",
        "state_code": "STA",
        "type": null
      },
      {
        "id": 1894,
        "name": "Sverdlovsk",
        "state_code": "SVE",
        "type": null
      },
      {
        "id": 1878,
        "name": "Tambov Oblast",
        "state_code": "TAM",
        "type": null
      },
      {
        "id": 1872,
        "name": "Tomsk Oblast",
        "state_code": "TOM",
        "type": null
      },
      {
        "id": 1895,
        "name": "Tula Oblast",
        "state_code": "TUL",
        "type": null
      },
      {
        "id": 1900,
        "name": "Tuva Republic",
        "state_code": "TY",
        "type": null
      },
      {
        "id": 1860,
        "name": "Tver Oblast",
        "state_code": "TVE",
        "type": null
      },
      {
        "id": 1907,
        "name": "Tyumen Oblast",
        "state_code": "TYU",
        "type": null
      },
      {
        "id": 1913,
        "name": "Udmurt Republic",
        "state_code": "UD",
        "type": null
      },
      {
        "id": 1856,
        "name": "Ulyanovsk Oblast",
        "state_code": "ULY",
        "type": null
      },
      {
        "id": 1881,
        "name": "Vladimir Oblast",
        "state_code": "VLA",
        "type": null
      },
      {
        "id": 4916,
        "name": "Volgograd Oblast",
        "state_code": "VGG",
        "type": null
      },
      {
        "id": 1874,
        "name": "Vologda Oblast",
        "state_code": "VLG",
        "type": null
      },
      {
        "id": 1906,
        "name": "Voronezh Oblast",
        "state_code": "VOR",
        "type": null
      },
      {
        "id": 1847,
        "name": "Yamalo-Nenets Autonomous Okrug",
        "state_code": "YAN",
        "type": null
      },
      {
        "id": 1851,
        "name": "Yaroslavl Oblast",
        "state_code": "YAR",
        "type": null
      },
      {
        "id": 1904,
        "name": "Zabaykalsky Krai",
        "state_code": "ZAB",
        "type": null
      }
    ]
  },
  {
    "name": "Rwanda",
    "iso3": "RWA",
    "iso2": "RW",
    "numeric_code": "646",
    "phone_code": "250",
    "capital": "Kigali",
    "currency": "RWF",
    "currency_name": "Rwandan franc",
    "currency_symbol": "FRw",
    "tld": ".rw",
    "native": "Rwanda",
    "region": "Africa",
    "subregion": "Eastern Africa",
    "emoji": "🇷🇼",
    "emojiU": "U+1F1F7 U+1F1FC",
    "states": [
      {
        "id": 261,
        "name": "Eastern Province",
        "state_code": "02",
        "type": null
      },
      {
        "id": 262,
        "name": "Kigali district",
        "state_code": "01",
        "type": null
      },
      {
        "id": 263,
        "name": "Northern Province",
        "state_code": "03",
        "type": null
      },
      {
        "id": 259,
        "name": "Southern Province",
        "state_code": "05",
        "type": null
      },
      {
        "id": 260,
        "name": "Western Province",
        "state_code": "04",
        "type": null
      }
    ]
  },
  {
    "name": "Saint Helena",
    "iso3": "SHN",
    "iso2": "SH",
    "numeric_code": "654",
    "phone_code": "290",
    "capital": "Jamestown",
    "currency": "SHP",
    "currency_name": "Saint Helena pound",
    "currency_symbol": "£",
    "tld": ".sh",
    "native": "Saint Helena",
    "region": "Africa",
    "subregion": "Western Africa",
    "emoji": "🇸🇭",
    "emojiU": "U+1F1F8 U+1F1ED",
    "states": []
  },
  {
    "name": "Saint Kitts And Nevis",
    "iso3": "KNA",
    "iso2": "KN",
    "numeric_code": "659",
    "phone_code": "+1-869",
    "capital": "Basseterre",
    "currency": "XCD",
    "currency_name": "Eastern Caribbean dollar",
    "currency_symbol": "$",
    "tld": ".kn",
    "native": "Saint Kitts and Nevis",
    "region": "Americas",
    "subregion": "Caribbean",
    "emoji": "🇰🇳",
    "emojiU": "U+1F1F0 U+1F1F3",
    "states": [
      {
        "id": 3833,
        "name": "Christ Church Nichola Town Parish",
        "state_code": "01",
        "type": null
      },
      {
        "id": 3832,
        "name": "Nevis",
        "state_code": "N",
        "type": null
      },
      {
        "id": 3836,
        "name": "Saint Anne Sandy Point Parish",
        "state_code": "02",
        "type": null
      },
      {
        "id": 3837,
        "name": "Saint George Gingerland Parish",
        "state_code": "04",
        "type": null
      },
      {
        "id": 3835,
        "name": "Saint James Windward Parish",
        "state_code": "05",
        "type": null
      },
      {
        "id": 3845,
        "name": "Saint John Capisterre Parish",
        "state_code": "06",
        "type": null
      },
      {
        "id": 3840,
        "name": "Saint John Figtree Parish",
        "state_code": "07",
        "type": null
      },
      {
        "id": 3841,
        "name": "Saint Kitts",
        "state_code": "K",
        "type": null
      },
      {
        "id": 3844,
        "name": "Saint Mary Cayon Parish",
        "state_code": "08",
        "type": null
      },
      {
        "id": 3834,
        "name": "Saint Paul Capisterre Parish",
        "state_code": "09",
        "type": null
      },
      {
        "id": 3838,
        "name": "Saint Paul Charlestown Parish",
        "state_code": "10",
        "type": null
      },
      {
        "id": 3831,
        "name": "Saint Peter Basseterre Parish",
        "state_code": "11",
        "type": null
      },
      {
        "id": 3839,
        "name": "Saint Thomas Lowland Parish",
        "state_code": "12",
        "type": null
      },
      {
        "id": 3842,
        "name": "Saint Thomas Middle Island Parish",
        "state_code": "13",
        "type": null
      },
      {
        "id": 3843,
        "name": "Trinity Palmetto Point Parish",
        "state_code": "15",
        "type": null
      }
    ]
  },
  {
    "name": "Saint Lucia",
    "iso3": "LCA",
    "iso2": "LC",
    "numeric_code": "662",
    "phone_code": "+1-758",
    "capital": "Castries",
    "currency": "XCD",
    "currency_name": "Eastern Caribbean dollar",
    "currency_symbol": "$",
    "tld": ".lc",
    "native": "Saint Lucia",
    "region": "Americas",
    "subregion": "Caribbean",
    "emoji": "🇱🇨",
    "emojiU": "U+1F1F1 U+1F1E8",
    "states": [
      {
        "id": 3757,
        "name": "Anse la Raye Quarter",
        "state_code": "01",
        "type": null
      },
      {
        "id": 3761,
        "name": "Canaries",
        "state_code": "12",
        "type": null
      },
      {
        "id": 3758,
        "name": "Castries Quarter",
        "state_code": "02",
        "type": null
      },
      {
        "id": 3760,
        "name": "Choiseul Quarter",
        "state_code": "03",
        "type": null
      },
      {
        "id": 3767,
        "name": "Dauphin Quarter",
        "state_code": "04",
        "type": null
      },
      {
        "id": 3756,
        "name": "Dennery Quarter",
        "state_code": "05",
        "type": null
      },
      {
        "id": 3766,
        "name": "Gros Islet Quarter",
        "state_code": "06",
        "type": null
      },
      {
        "id": 3759,
        "name": "Laborie Quarter",
        "state_code": "07",
        "type": null
      },
      {
        "id": 3762,
        "name": "Micoud Quarter",
        "state_code": "08",
        "type": null
      },
      {
        "id": 3765,
        "name": "Praslin Quarter",
        "state_code": "09",
        "type": null
      },
      {
        "id": 3764,
        "name": "Soufrière Quarter",
        "state_code": "10",
        "type": null
      },
      {
        "id": 3763,
        "name": "Vieux Fort Quarter",
        "state_code": "11",
        "type": null
      }
    ]
  },
  {
    "name": "Saint Pierre and Miquelon",
    "iso3": "SPM",
    "iso2": "PM",
    "numeric_code": "666",
    "phone_code": "508",
    "capital": "Saint-Pierre",
    "currency": "EUR",
    "currency_name": "Euro",
    "currency_symbol": "€",
    "tld": ".pm",
    "native": "Saint-Pierre-et-Miquelon",
    "region": "Americas",
    "subregion": "Northern America",
    "emoji": "🇵🇲",
    "emojiU": "U+1F1F5 U+1F1F2",
    "states": []
  },
  {
    "name": "Saint Vincent And The Grenadines",
    "iso3": "VCT",
    "iso2": "VC",
    "numeric_code": "670",
    "phone_code": "+1-784",
    "capital": "Kingstown",
    "currency": "XCD",
    "currency_name": "Eastern Caribbean dollar",
    "currency_symbol": "$",
    "tld": ".vc",
    "native": "Saint Vincent and the Grenadines",
    "region": "Americas",
    "subregion": "Caribbean",
    "emoji": "🇻🇨",
    "emojiU": "U+1F1FB U+1F1E8",
    "states": [
      {
        "id": 3389,
        "name": "Charlotte Parish",
        "state_code": "01",
        "type": null
      },
      {
        "id": 3388,
        "name": "Grenadines Parish",
        "state_code": "06",
        "type": null
      },
      {
        "id": 3386,
        "name": "Saint Andrew Parish",
        "state_code": "02",
        "type": null
      },
      {
        "id": 3387,
        "name": "Saint David Parish",
        "state_code": "03",
        "type": null
      },
      {
        "id": 3384,
        "name": "Saint George Parish",
        "state_code": "04",
        "type": null
      },
      {
        "id": 3385,
        "name": "Saint Patrick Parish",
        "state_code": "05",
        "type": null
      }
    ]
  },
  {
    "name": "Saint-Barthelemy",
    "iso3": "BLM",
    "iso2": "BL",
    "numeric_code": "652",
    "phone_code": "590",
    "capital": "Gustavia",
    "currency": "EUR",
    "currency_name": "Euro",
    "currency_symbol": "€",
    "tld": ".bl",
    "native": "Saint-Barthélemy",
    "region": "Americas",
    "subregion": "Caribbean",
    "emoji": "🇧🇱",
    "emojiU": "U+1F1E7 U+1F1F1",
    "states": []
  },
  {
    "name": "Saint-Martin (French part)",
    "iso3": "MAF",
    "iso2": "MF",
    "numeric_code": "663",
    "phone_code": "590",
    "capital": "Marigot",
    "currency": "EUR",
    "currency_name": "Euro",
    "currency_symbol": "€",
    "tld": ".mf",
    "native": "Saint-Martin",
    "region": "Americas",
    "subregion": "Caribbean",
    "emoji": "🇲🇫",
    "emojiU": "U+1F1F2 U+1F1EB",
    "states": []
  },
  {
    "name": "Samoa",
    "iso3": "WSM",
    "iso2": "WS",
    "numeric_code": "882",
    "phone_code": "685",
    "capital": "Apia",
    "currency": "WST",
    "currency_name": "Samoan tālā",
    "currency_symbol": "SAT",
    "tld": ".ws",
    "native": "Samoa",
    "region": "Oceania",
    "subregion": "Polynesia",
    "emoji": "🇼🇸",
    "emojiU": "U+1F1FC U+1F1F8",
    "states": [
      {
        "id": 4763,
        "name": "A'ana",
        "state_code": "AA",
        "type": null
      },
      {
        "id": 4761,
        "name": "Aiga-i-le-Tai",
        "state_code": "AL",
        "type": null
      },
      {
        "id": 4765,
        "name": "Atua",
        "state_code": "AT",
        "type": null
      },
      {
        "id": 4764,
        "name": "Fa'asaleleaga",
        "state_code": "FA",
        "type": null
      },
      {
        "id": 4769,
        "name": "Gaga'emauga",
        "state_code": "GE",
        "type": null
      },
      {
        "id": 4771,
        "name": "Gaga'ifomauga",
        "state_code": "GI",
        "type": null
      },
      {
        "id": 4767,
        "name": "Palauli",
        "state_code": "PA",
        "type": null
      },
      {
        "id": 4762,
        "name": "Satupa'itea",
        "state_code": "SA",
        "type": null
      },
      {
        "id": 4770,
        "name": "Tuamasaga",
        "state_code": "TU",
        "type": null
      },
      {
        "id": 4768,
        "name": "Va'a-o-Fonoti",
        "state_code": "VF",
        "type": null
      },
      {
        "id": 4766,
        "name": "Vaisigano",
        "state_code": "VS",
        "type": null
      }
    ]
  },
  {
    "name": "San Marino",
    "iso3": "SMR",
    "iso2": "SM",
    "numeric_code": "674",
    "phone_code": "378",
    "capital": "San Marino",
    "currency": "EUR",
    "currency_name": "Euro",
    "currency_symbol": "€",
    "tld": ".sm",
    "native": "San Marino",
    "region": "Europe",
    "subregion": "Southern Europe",
    "emoji": "🇸🇲",
    "emojiU": "U+1F1F8 U+1F1F2",
    "states": [
      {
        "id": 59,
        "name": "Acquaviva",
        "state_code": "01",
        "type": null
      },
      {
        "id": 61,
        "name": "Borgo Maggiore",
        "state_code": "06",
        "type": null
      },
      {
        "id": 60,
        "name": "Chiesanuova",
        "state_code": "02",
        "type": null
      },
      {
        "id": 64,
        "name": "Domagnano",
        "state_code": "03",
        "type": null
      },
      {
        "id": 62,
        "name": "Faetano",
        "state_code": "04",
        "type": null
      },
      {
        "id": 66,
        "name": "Fiorentino",
        "state_code": "05",
        "type": null
      },
      {
        "id": 63,
        "name": "Montegiardino",
        "state_code": "08",
        "type": null
      },
      {
        "id": 58,
        "name": "San Marino",
        "state_code": "07",
        "type": null
      },
      {
        "id": 65,
        "name": "Serravalle",
        "state_code": "09",
        "type": null
      }
    ]
  },
  {
    "name": "Sao Tome and Principe",
    "iso3": "STP",
    "iso2": "ST",
    "numeric_code": "678",
    "phone_code": "239",
    "capital": "Sao Tome",
    "currency": "STD",
    "currency_name": "Dobra",
    "currency_symbol": "Db",
    "tld": ".st",
    "native": "São Tomé e Príncipe",
    "region": "Africa",
    "subregion": "Middle Africa",
    "emoji": "🇸🇹",
    "emojiU": "U+1F1F8 U+1F1F9",
    "states": [
      {
        "id": 270,
        "name": "Príncipe Province",
        "state_code": "P",
        "type": null
      },
      {
        "id": 271,
        "name": "São Tomé Province",
        "state_code": "S",
        "type": null
      }
    ]
  },
  {
    "name": "Saudi Arabia",
    "iso3": "SAU",
    "iso2": "SA",
    "numeric_code": "682",
    "phone_code": "966",
    "capital": "Riyadh",
    "currency": "SAR",
    "currency_name": "Saudi riyal",
    "currency_symbol": "﷼",
    "tld": ".sa",
    "native": "المملكة العربية السعودية",
    "region": "Asia",
    "subregion": "Western Asia",
    "emoji": "🇸🇦",
    "emojiU": "U+1F1F8 U+1F1E6",
    "states": [
      {
        "id": 2853,
        "name": "'Asir",
        "state_code": "14",
        "type": null
      },
      {
        "id": 2859,
        "name": "Al Bahah",
        "state_code": "11",
        "type": null
      },
      {
        "id": 2857,
        "name": "Al Jawf",
        "state_code": "12",
        "type": null
      },
      {
        "id": 2851,
        "name": "Al Madinah",
        "state_code": "03",
        "type": null
      },
      {
        "id": 2861,
        "name": "Al-Qassim",
        "state_code": "05",
        "type": null
      },
      {
        "id": 2856,
        "name": "Eastern Province",
        "state_code": "04",
        "type": null
      },
      {
        "id": 2855,
        "name": "Ha'il",
        "state_code": "06",
        "type": null
      },
      {
        "id": 2858,
        "name": "Jizan",
        "state_code": "09",
        "type": null
      },
      {
        "id": 2850,
        "name": "Makkah",
        "state_code": "02",
        "type": null
      },
      {
        "id": 2860,
        "name": "Najran",
        "state_code": "10",
        "type": null
      },
      {
        "id": 2854,
        "name": "Northern Borders",
        "state_code": "08",
        "type": null
      },
      {
        "id": 2849,
        "name": "Riyadh",
        "state_code": "01",
        "type": null
      },
      {
        "id": 2852,
        "name": "Tabuk",
        "state_code": "07",
        "type": null
      }
    ]
  },
  {
    "name": "Senegal",
    "iso3": "SEN",
    "iso2": "SN",
    "numeric_code": "686",
    "phone_code": "221",
    "capital": "Dakar",
    "currency": "XOF",
    "currency_name": "West African CFA franc",
    "currency_symbol": "CFA",
    "tld": ".sn",
    "native": "Sénégal",
    "region": "Africa",
    "subregion": "Western Africa",
    "emoji": "🇸🇳",
    "emojiU": "U+1F1F8 U+1F1F3",
    "states": [
      {
        "id": 473,
        "name": "Dakar",
        "state_code": "DK",
        "type": null
      },
      {
        "id": 480,
        "name": "Diourbel Region",
        "state_code": "DB",
        "type": null
      },
      {
        "id": 479,
        "name": "Fatick",
        "state_code": "FK",
        "type": null
      },
      {
        "id": 475,
        "name": "Kaffrine",
        "state_code": "KA",
        "type": null
      },
      {
        "id": 483,
        "name": "Kaolack",
        "state_code": "KL",
        "type": null
      },
      {
        "id": 481,
        "name": "Kédougou",
        "state_code": "KE",
        "type": null
      },
      {
        "id": 474,
        "name": "Kolda",
        "state_code": "KD",
        "type": null
      },
      {
        "id": 485,
        "name": "Louga",
        "state_code": "LG",
        "type": null
      },
      {
        "id": 476,
        "name": "Matam",
        "state_code": "MT",
        "type": null
      },
      {
        "id": 477,
        "name": "Saint-Louis",
        "state_code": "SL",
        "type": null
      },
      {
        "id": 482,
        "name": "Sédhiou",
        "state_code": "SE",
        "type": null
      },
      {
        "id": 486,
        "name": "Tambacounda Region",
        "state_code": "TC",
        "type": null
      },
      {
        "id": 484,
        "name": "Thiès Region",
        "state_code": "TH",
        "type": null
      },
      {
        "id": 478,
        "name": "Ziguinchor",
        "state_code": "ZG",
        "type": null
      }
    ]
  },
  {
    "name": "Serbia",
    "iso3": "SRB",
    "iso2": "RS",
    "numeric_code": "688",
    "phone_code": "381",
    "capital": "Belgrade",
    "currency": "RSD",
    "currency_name": "Serbian dinar",
    "currency_symbol": "din",
    "tld": ".rs",
    "native": "Србија",
    "region": "Europe",
    "subregion": "Southern Europe",
    "emoji": "🇷🇸",
    "emojiU": "U+1F1F7 U+1F1F8",
    "states": [
      {
        "id": 3728,
        "name": "Belgrade",
        "state_code": "00",
        "type": null
      },
      {
        "id": 3717,
        "name": "Bor District",
        "state_code": "14",
        "type": null
      },
      {
        "id": 3732,
        "name": "Braničevo District",
        "state_code": "11",
        "type": null
      },
      {
        "id": 3716,
        "name": "Central Banat District",
        "state_code": "02",
        "type": null
      },
      {
        "id": 3715,
        "name": "Jablanica District",
        "state_code": "23",
        "type": null
      },
      {
        "id": 3724,
        "name": "Kolubara District",
        "state_code": "09",
        "type": null
      },
      {
        "id": 3719,
        "name": "Mačva District",
        "state_code": "08",
        "type": null
      },
      {
        "id": 3727,
        "name": "Moravica District",
        "state_code": "17",
        "type": null
      },
      {
        "id": 3722,
        "name": "Nišava District",
        "state_code": "20",
        "type": null
      },
      {
        "id": 3714,
        "name": "North Bačka District",
        "state_code": "01",
        "type": null
      },
      {
        "id": 3736,
        "name": "North Banat District",
        "state_code": "03",
        "type": null
      },
      {
        "id": 3721,
        "name": "Pčinja District",
        "state_code": "24",
        "type": null
      },
      {
        "id": 3712,
        "name": "Pirot District",
        "state_code": "22",
        "type": null
      },
      {
        "id": 3741,
        "name": "Podunavlje District",
        "state_code": "10",
        "type": null
      },
      {
        "id": 3737,
        "name": "Pomoravlje District",
        "state_code": "13",
        "type": null
      },
      {
        "id": 3720,
        "name": "Rasina District",
        "state_code": "19",
        "type": null
      },
      {
        "id": 3725,
        "name": "Raška District",
        "state_code": "18",
        "type": null
      },
      {
        "id": 3711,
        "name": "South Bačka District",
        "state_code": "06",
        "type": null
      },
      {
        "id": 3713,
        "name": "South Banat District",
        "state_code": "04",
        "type": null
      },
      {
        "id": 3740,
        "name": "Srem District",
        "state_code": "07",
        "type": null
      },
      {
        "id": 3734,
        "name": "Šumadija District",
        "state_code": "12",
        "type": null
      },
      {
        "id": 3718,
        "name": "Toplica District",
        "state_code": "21",
        "type": null
      },
      {
        "id": 3733,
        "name": "Vojvodina",
        "state_code": "VO",
        "type": null
      },
      {
        "id": 3726,
        "name": "West Bačka District",
        "state_code": "05",
        "type": null
      },
      {
        "id": 3731,
        "name": "Zaječar District",
        "state_code": "15",
        "type": null
      },
      {
        "id": 3729,
        "name": "Zlatibor District",
        "state_code": "16",
        "type": null
      }
    ]
  },
  {
    "name": "Seychelles",
    "iso3": "SYC",
    "iso2": "SC",
    "numeric_code": "690",
    "phone_code": "248",
    "capital": "Victoria",
    "currency": "SCR",
    "currency_name": "Seychellois rupee",
    "currency_symbol": "SRe",
    "tld": ".sc",
    "native": "Seychelles",
    "region": "Africa",
    "subregion": "Eastern Africa",
    "emoji": "🇸🇨",
    "emojiU": "U+1F1F8 U+1F1E8",
    "states": [
      {
        "id": 513,
        "name": "Anse Boileau",
        "state_code": "02",
        "type": null
      },
      {
        "id": 502,
        "name": "Anse Royale",
        "state_code": "05",
        "type": null
      },
      {
        "id": 506,
        "name": "Anse-aux-Pins",
        "state_code": "01",
        "type": null
      },
      {
        "id": 508,
        "name": "Au Cap",
        "state_code": "04",
        "type": null
      },
      {
        "id": 497,
        "name": "Baie Lazare",
        "state_code": "06",
        "type": null
      },
      {
        "id": 514,
        "name": "Baie Sainte Anne",
        "state_code": "07",
        "type": null
      },
      {
        "id": 512,
        "name": "Beau Vallon",
        "state_code": "08",
        "type": null
      },
      {
        "id": 515,
        "name": "Bel Air",
        "state_code": "09",
        "type": null
      },
      {
        "id": 505,
        "name": "Bel Ombre",
        "state_code": "10",
        "type": null
      },
      {
        "id": 517,
        "name": "Cascade",
        "state_code": "11",
        "type": null
      },
      {
        "id": 503,
        "name": "Glacis",
        "state_code": "12",
        "type": null
      },
      {
        "id": 500,
        "name": "Grand'Anse Mahé",
        "state_code": "13",
        "type": null
      },
      {
        "id": 504,
        "name": "Grand'Anse Praslin",
        "state_code": "14",
        "type": null
      },
      {
        "id": 495,
        "name": "La Digue",
        "state_code": "15",
        "type": null
      },
      {
        "id": 516,
        "name": "La Rivière Anglaise",
        "state_code": "16",
        "type": null
      },
      {
        "id": 499,
        "name": "Les Mamelles",
        "state_code": "24",
        "type": null
      },
      {
        "id": 494,
        "name": "Mont Buxton",
        "state_code": "17",
        "type": null
      },
      {
        "id": 498,
        "name": "Mont Fleuri",
        "state_code": "18",
        "type": null
      },
      {
        "id": 511,
        "name": "Plaisance",
        "state_code": "19",
        "type": null
      },
      {
        "id": 510,
        "name": "Pointe La Rue",
        "state_code": "20",
        "type": null
      },
      {
        "id": 507,
        "name": "Port Glaud",
        "state_code": "21",
        "type": null
      },
      {
        "id": 501,
        "name": "Roche Caiman",
        "state_code": "25",
        "type": null
      },
      {
        "id": 496,
        "name": "Saint Louis",
        "state_code": "22",
        "type": null
      },
      {
        "id": 509,
        "name": "Takamaka",
        "state_code": "23",
        "type": null
      }
    ]
  },
  {
    "name": "Sierra Leone",
    "iso3": "SLE",
    "iso2": "SL",
    "numeric_code": "694",
    "phone_code": "232",
    "capital": "Freetown",
    "currency": "SLL",
    "currency_name": "Sierra Leonean leone",
    "currency_symbol": "Le",
    "tld": ".sl",
    "native": "Sierra Leone",
    "region": "Africa",
    "subregion": "Western Africa",
    "emoji": "🇸🇱",
    "emojiU": "U+1F1F8 U+1F1F1",
    "states": [
      {
        "id": 914,
        "name": "Eastern Province",
        "state_code": "E",
        "type": null
      },
      {
        "id": 911,
        "name": "Northern Province",
        "state_code": "N",
        "type": null
      },
      {
        "id": 912,
        "name": "Southern Province",
        "state_code": "S",
        "type": null
      },
      {
        "id": 913,
        "name": "Western Area",
        "state_code": "W",
        "type": null
      }
    ]
  },
  {
    "name": "Singapore",
    "iso3": "SGP",
    "iso2": "SG",
    "numeric_code": "702",
    "phone_code": "65",
    "capital": "Singapur",
    "currency": "SGD",
    "currency_name": "Singapore dollar",
    "currency_symbol": "$",
    "tld": ".sg",
    "native": "Singapore",
    "region": "Asia",
    "subregion": "South-Eastern Asia",
    "emoji": "🇸🇬",
    "emojiU": "U+1F1F8 U+1F1EC",
    "states": [
      {
        "id": 4651,
        "name": "Central Singapore Community Development Council",
        "state_code": "01",
        "type": null
      },
      {
        "id": 4649,
        "name": "North East Community Development Council",
        "state_code": "02",
        "type": null
      },
      {
        "id": 4653,
        "name": "North West Community Development Council",
        "state_code": "03",
        "type": null
      },
      {
        "id": 4650,
        "name": "South East Community Development Council",
        "state_code": "04",
        "type": null
      },
      {
        "id": 4652,
        "name": "South West Community Development Council",
        "state_code": "05",
        "type": null
      }
    ]
  },
  {
    "name": "Sint Maarten (Dutch part)",
    "iso3": "SXM",
    "iso2": "SX",
    "numeric_code": "534",
    "phone_code": "1721",
    "capital": "Philipsburg",
    "currency": "ANG",
    "currency_name": "Netherlands Antillean guilder",
    "currency_symbol": "ƒ",
    "tld": ".sx",
    "native": "Sint Maarten",
    "region": "Americas",
    "subregion": "Caribbean",
    "emoji": "🇸🇽",
    "emojiU": "U+1F1F8 U+1F1FD",
    "states": []
  },
  {
    "name": "Slovakia",
    "iso3": "SVK",
    "iso2": "SK",
    "numeric_code": "703",
    "phone_code": "421",
    "capital": "Bratislava",
    "currency": "EUR",
    "currency_name": "Euro",
    "currency_symbol": "€",
    "tld": ".sk",
    "native": "Slovensko",
    "region": "Europe",
    "subregion": "Eastern Europe",
    "emoji": "🇸🇰",
    "emojiU": "U+1F1F8 U+1F1F0",
    "states": [
      {
        "id": 4352,
        "name": "Banská Bystrica Region",
        "state_code": "BC",
        "type": null
      },
      {
        "id": 4356,
        "name": "Bratislava Region",
        "state_code": "BL",
        "type": null
      },
      {
        "id": 4353,
        "name": "Košice Region",
        "state_code": "KI",
        "type": null
      },
      {
        "id": 4357,
        "name": "Nitra Region",
        "state_code": "NI",
        "type": null
      },
      {
        "id": 4354,
        "name": "Prešov Region",
        "state_code": "PV",
        "type": null
      },
      {
        "id": 4358,
        "name": "Trenčín Region",
        "state_code": "TC",
        "type": null
      },
      {
        "id": 4355,
        "name": "Trnava Region",
        "state_code": "TA",
        "type": null
      },
      {
        "id": 4359,
        "name": "Žilina Region",
        "state_code": "ZI",
        "type": null
      }
    ]
  },
  {
    "name": "Slovenia",
    "iso3": "SVN",
    "iso2": "SI",
    "numeric_code": "705",
    "phone_code": "386",
    "capital": "Ljubljana",
    "currency": "EUR",
    "currency_name": "Euro",
    "currency_symbol": "€",
    "tld": ".si",
    "native": "Slovenija",
    "region": "Europe",
    "subregion": "Southern Europe",
    "emoji": "🇸🇮",
    "emojiU": "U+1F1F8 U+1F1EE",
    "states": [
      {
        "id": 4183,
        "name": "Ajdovščina Municipality",
        "state_code": "001",
        "type": null
      },
      {
        "id": 4326,
        "name": "Ankaran Municipality",
        "state_code": "213",
        "type": null
      },
      {
        "id": 4301,
        "name": "Beltinci Municipality",
        "state_code": "002",
        "type": null
      },
      {
        "id": 4166,
        "name": "Benedikt Municipality",
        "state_code": "148",
        "type": null
      },
      {
        "id": 4179,
        "name": "Bistrica ob Sotli Municipality",
        "state_code": "149",
        "type": null
      },
      {
        "id": 4202,
        "name": "Bled Municipality",
        "state_code": "003",
        "type": null
      },
      {
        "id": 4278,
        "name": "Bloke Municipality",
        "state_code": "150",
        "type": null
      },
      {
        "id": 4282,
        "name": "Bohinj Municipality",
        "state_code": "004",
        "type": null
      },
      {
        "id": 4200,
        "name": "Borovnica Municipality",
        "state_code": "005",
        "type": null
      },
      {
        "id": 4181,
        "name": "Bovec Municipality",
        "state_code": "006",
        "type": null
      },
      {
        "id": 4141,
        "name": "Braslovče Municipality",
        "state_code": "151",
        "type": null
      },
      {
        "id": 4240,
        "name": "Brda Municipality",
        "state_code": "007",
        "type": null
      },
      {
        "id": 4215,
        "name": "Brežice Municipality",
        "state_code": "009",
        "type": null
      },
      {
        "id": 4165,
        "name": "Brezovica Municipality",
        "state_code": "008",
        "type": null
      },
      {
        "id": 4147,
        "name": "Cankova Municipality",
        "state_code": "152",
        "type": null
      },
      {
        "id": 4310,
        "name": "Cerklje na Gorenjskem Municipality",
        "state_code": "012",
        "type": null
      },
      {
        "id": 4162,
        "name": "Cerknica Municipality",
        "state_code": "013",
        "type": null
      },
      {
        "id": 4178,
        "name": "Cerkno Municipality",
        "state_code": "014",
        "type": null
      },
      {
        "id": 4176,
        "name": "Cerkvenjak Municipality",
        "state_code": "153",
        "type": null
      },
      {
        "id": 4191,
        "name": "City Municipality of Celje",
        "state_code": "011",
        "type": null
      },
      {
        "id": 4236,
        "name": "City Municipality of Novo Mesto",
        "state_code": "085",
        "type": null
      },
      {
        "id": 4151,
        "name": "Črenšovci Municipality",
        "state_code": "015",
        "type": null
      },
      {
        "id": 4232,
        "name": "Črna na Koroškem Municipality",
        "state_code": "016",
        "type": null
      },
      {
        "id": 4291,
        "name": "Črnomelj Municipality",
        "state_code": "017",
        "type": null
      },
      {
        "id": 4304,
        "name": "Destrnik Municipality",
        "state_code": "018",
        "type": null
      },
      {
        "id": 4167,
        "name": "Divača Municipality",
        "state_code": "019",
        "type": null
      },
      {
        "id": 4295,
        "name": "Dobje Municipality",
        "state_code": "154",
        "type": null
      },
      {
        "id": 4216,
        "name": "Dobrepolje Municipality",
        "state_code": "020",
        "type": null
      },
      {
        "id": 4252,
        "name": "Dobrna Municipality",
        "state_code": "155",
        "type": null
      },
      {
        "id": 4308,
        "name": "Dobrova–Polhov Gradec Municipality",
        "state_code": "021",
        "type": null
      },
      {
        "id": 4189,
        "name": "Dobrovnik Municipality",
        "state_code": "156",
        "type": null
      },
      {
        "id": 4173,
        "name": "Dol pri Ljubljani Municipality",
        "state_code": "022",
        "type": null
      },
      {
        "id": 4281,
        "name": "Dolenjske Toplice Municipality",
        "state_code": "157",
        "type": null
      },
      {
        "id": 4159,
        "name": "Domžale Municipality",
        "state_code": "023",
        "type": null
      },
      {
        "id": 4290,
        "name": "Dornava Municipality",
        "state_code": "024",
        "type": null
      },
      {
        "id": 4345,
        "name": "Dravograd Municipality",
        "state_code": "025",
        "type": null
      },
      {
        "id": 4213,
        "name": "Duplek Municipality",
        "state_code": "026",
        "type": null
      },
      {
        "id": 4293,
        "name": "Gorenja Vas–Poljane Municipality",
        "state_code": "027",
        "type": null
      },
      {
        "id": 4210,
        "name": "Gorišnica Municipality",
        "state_code": "028",
        "type": null
      },
      {
        "id": 4284,
        "name": "Gorje Municipality",
        "state_code": "207",
        "type": null
      },
      {
        "id": 4343,
        "name": "Gornja Radgona Municipality",
        "state_code": "029",
        "type": null
      },
      {
        "id": 4339,
        "name": "Gornji Grad Municipality",
        "state_code": "030",
        "type": null
      },
      {
        "id": 4271,
        "name": "Gornji Petrovci Municipality",
        "state_code": "031",
        "type": null
      },
      {
        "id": 4217,
        "name": "Grad Municipality",
        "state_code": "158",
        "type": null
      },
      {
        "id": 4336,
        "name": "Grosuplje Municipality",
        "state_code": "032",
        "type": null
      },
      {
        "id": 4145,
        "name": "Hajdina Municipality",
        "state_code": "159",
        "type": null
      },
      {
        "id": 4175,
        "name": "Hoče–Slivnica Municipality",
        "state_code": "160",
        "type": null
      },
      {
        "id": 4327,
        "name": "Hodoš Municipality",
        "state_code": "161",
        "type": null
      },
      {
        "id": 4193,
        "name": "Horjul Municipality",
        "state_code": "162",
        "type": null
      },
      {
        "id": 4341,
        "name": "Hrastnik Municipality",
        "state_code": "034",
        "type": null
      },
      {
        "id": 4321,
        "name": "Hrpelje–Kozina Municipality",
        "state_code": "035",
        "type": null
      },
      {
        "id": 4152,
        "name": "Idrija Municipality",
        "state_code": "036",
        "type": null
      },
      {
        "id": 4286,
        "name": "Ig Municipality",
        "state_code": "037",
        "type": null
      },
      {
        "id": 4305,
        "name": "Ivančna Gorica Municipality",
        "state_code": "039",
        "type": null
      },
      {
        "id": 4322,
        "name": "Izola Municipality",
        "state_code": "040",
        "type": null
      },
      {
        "id": 4337,
        "name": "Jesenice Municipality",
        "state_code": "041",
        "type": null
      },
      {
        "id": 4203,
        "name": "Jezersko Municipality",
        "state_code": "163",
        "type": null
      },
      {
        "id": 4266,
        "name": "Juršinci Municipality",
        "state_code": "042",
        "type": null
      },
      {
        "id": 4180,
        "name": "Kamnik Municipality",
        "state_code": "043",
        "type": null
      },
      {
        "id": 4227,
        "name": "Kanal ob Soči Municipality",
        "state_code": "044",
        "type": null
      },
      {
        "id": 4150,
        "name": "Kidričevo Municipality",
        "state_code": "045",
        "type": null
      },
      {
        "id": 4243,
        "name": "Kobarid Municipality",
        "state_code": "046",
        "type": null
      },
      {
        "id": 4325,
        "name": "Kobilje Municipality",
        "state_code": "047",
        "type": null
      },
      {
        "id": 4335,
        "name": "Kočevje Municipality",
        "state_code": "048",
        "type": null
      },
      {
        "id": 4315,
        "name": "Komen Municipality",
        "state_code": "049",
        "type": null
      },
      {
        "id": 4283,
        "name": "Komenda Municipality",
        "state_code": "164",
        "type": null
      },
      {
        "id": 4319,
        "name": "Koper City Municipality",
        "state_code": "050",
        "type": null
      },
      {
        "id": 4254,
        "name": "Kostanjevica na Krki Municipality",
        "state_code": "197",
        "type": null
      },
      {
        "id": 4331,
        "name": "Kostel Municipality",
        "state_code": "165",
        "type": null
      },
      {
        "id": 4186,
        "name": "Kozje Municipality",
        "state_code": "051",
        "type": null
      },
      {
        "id": 4287,
        "name": "Kranj City Municipality",
        "state_code": "052",
        "type": null
      },
      {
        "id": 4340,
        "name": "Kranjska Gora Municipality",
        "state_code": "053",
        "type": null
      },
      {
        "id": 4238,
        "name": "Križevci Municipality",
        "state_code": "166",
        "type": null
      },
      {
        "id": 4197,
        "name": "Kungota",
        "state_code": "055",
        "type": null
      },
      {
        "id": 4211,
        "name": "Kuzma Municipality",
        "state_code": "056",
        "type": null
      },
      {
        "id": 4338,
        "name": "Laško Municipality",
        "state_code": "057",
        "type": null
      },
      {
        "id": 4142,
        "name": "Lenart Municipality",
        "state_code": "058",
        "type": null
      },
      {
        "id": 4225,
        "name": "Lendava Municipality",
        "state_code": "059",
        "type": null
      },
      {
        "id": 4347,
        "name": "Litija Municipality",
        "state_code": "060",
        "type": null
      },
      {
        "id": 4270,
        "name": "Ljubljana City Municipality",
        "state_code": "061",
        "type": null
      },
      {
        "id": 4294,
        "name": "Ljubno Municipality",
        "state_code": "062",
        "type": null
      },
      {
        "id": 4351,
        "name": "Ljutomer Municipality",
        "state_code": "063",
        "type": null
      },
      {
        "id": 4306,
        "name": "Log–Dragomer Municipality",
        "state_code": "208",
        "type": null
      },
      {
        "id": 4350,
        "name": "Logatec Municipality",
        "state_code": "064",
        "type": null
      },
      {
        "id": 4174,
        "name": "Loška Dolina Municipality",
        "state_code": "065",
        "type": null
      },
      {
        "id": 4158,
        "name": "Loški Potok Municipality",
        "state_code": "066",
        "type": null
      },
      {
        "id": 4156,
        "name": "Lovrenc na Pohorju Municipality",
        "state_code": "167",
        "type": null
      },
      {
        "id": 4219,
        "name": "Luče Municipality",
        "state_code": "067",
        "type": null
      },
      {
        "id": 4302,
        "name": "Lukovica Municipality",
        "state_code": "068",
        "type": null
      },
      {
        "id": 4157,
        "name": "Majšperk Municipality",
        "state_code": "069",
        "type": null
      },
      {
        "id": 4224,
        "name": "Makole Municipality",
        "state_code": "198",
        "type": null
      },
      {
        "id": 4242,
        "name": "Maribor City Municipality",
        "state_code": "070",
        "type": null
      },
      {
        "id": 4244,
        "name": "Markovci Municipality",
        "state_code": "168",
        "type": null
      },
      {
        "id": 4349,
        "name": "Medvode Municipality",
        "state_code": "071",
        "type": null
      },
      {
        "id": 4348,
        "name": "Mengeš Municipality",
        "state_code": "072",
        "type": null
      },
      {
        "id": 4323,
        "name": "Metlika Municipality",
        "state_code": "073",
        "type": null
      },
      {
        "id": 4265,
        "name": "Mežica Municipality",
        "state_code": "074",
        "type": null
      },
      {
        "id": 4223,
        "name": "Miklavž na Dravskem Polju Municipality",
        "state_code": "169",
        "type": null
      },
      {
        "id": 4220,
        "name": "Miren–Kostanjevica Municipality",
        "state_code": "075",
        "type": null
      },
      {
        "id": 4298,
        "name": "Mirna Municipality",
        "state_code": "212",
        "type": null
      },
      {
        "id": 4237,
        "name": "Mirna Peč Municipality",
        "state_code": "170",
        "type": null
      },
      {
        "id": 4212,
        "name": "Mislinja Municipality",
        "state_code": "076",
        "type": null
      },
      {
        "id": 4297,
        "name": "Mokronog–Trebelno Municipality",
        "state_code": "199",
        "type": null
      },
      {
        "id": 4168,
        "name": "Moravče Municipality",
        "state_code": "077",
        "type": null
      },
      {
        "id": 4218,
        "name": "Moravske Toplice Municipality",
        "state_code": "078",
        "type": null
      },
      {
        "id": 4190,
        "name": "Mozirje Municipality",
        "state_code": "079",
        "type": null
      },
      {
        "id": 4318,
        "name": "Municipality of Apače",
        "state_code": "195",
        "type": null
      },
      {
        "id": 4309,
        "name": "Municipality of Cirkulane",
        "state_code": "196",
        "type": null
      },
      {
        "id": 4344,
        "name": "Municipality of Ilirska Bistrica",
        "state_code": "038",
        "type": null
      },
      {
        "id": 4314,
        "name": "Municipality of Krško",
        "state_code": "054",
        "type": null
      },
      {
        "id": 4187,
        "name": "Municipality of Škofljica",
        "state_code": "123",
        "type": null
      },
      {
        "id": 4313,
        "name": "Murska Sobota City Municipality",
        "state_code": "080",
        "type": null
      },
      {
        "id": 4208,
        "name": "Muta Municipality",
        "state_code": "081",
        "type": null
      },
      {
        "id": 4177,
        "name": "Naklo Municipality",
        "state_code": "082",
        "type": null
      },
      {
        "id": 4329,
        "name": "Nazarje Municipality",
        "state_code": "083",
        "type": null
      },
      {
        "id": 4205,
        "name": "Nova Gorica City Municipality",
        "state_code": "084",
        "type": null
      },
      {
        "id": 4320,
        "name": "Odranci Municipality",
        "state_code": "086",
        "type": null
      },
      {
        "id": 4143,
        "name": "Oplotnica",
        "state_code": "171",
        "type": null
      },
      {
        "id": 4221,
        "name": "Ormož Municipality",
        "state_code": "087",
        "type": null
      },
      {
        "id": 4199,
        "name": "Osilnica Municipality",
        "state_code": "088",
        "type": null
      },
      {
        "id": 4172,
        "name": "Pesnica Municipality",
        "state_code": "089",
        "type": null
      },
      {
        "id": 4201,
        "name": "Piran Municipality",
        "state_code": "090",
        "type": null
      },
      {
        "id": 4184,
        "name": "Pivka Municipality",
        "state_code": "091",
        "type": null
      },
      {
        "id": 4146,
        "name": "Podčetrtek Municipality",
        "state_code": "092",
        "type": null
      },
      {
        "id": 4161,
        "name": "Podlehnik Municipality",
        "state_code": "172",
        "type": null
      },
      {
        "id": 4234,
        "name": "Podvelka Municipality",
        "state_code": "093",
        "type": null
      },
      {
        "id": 4239,
        "name": "Poljčane Municipality",
        "state_code": "200",
        "type": null
      },
      {
        "id": 4272,
        "name": "Polzela Municipality",
        "state_code": "173",
        "type": null
      },
      {
        "id": 4330,
        "name": "Postojna Municipality",
        "state_code": "094",
        "type": null
      },
      {
        "id": 4188,
        "name": "Prebold Municipality",
        "state_code": "174",
        "type": null
      },
      {
        "id": 4303,
        "name": "Preddvor Municipality",
        "state_code": "095",
        "type": null
      },
      {
        "id": 4274,
        "name": "Prevalje Municipality",
        "state_code": "175",
        "type": null
      },
      {
        "id": 4228,
        "name": "Ptuj City Municipality",
        "state_code": "096",
        "type": null
      },
      {
        "id": 4288,
        "name": "Puconci Municipality",
        "state_code": "097",
        "type": null
      },
      {
        "id": 4204,
        "name": "Rače–Fram Municipality",
        "state_code": "098",
        "type": null
      },
      {
        "id": 4195,
        "name": "Radeče Municipality",
        "state_code": "099",
        "type": null
      },
      {
        "id": 4292,
        "name": "Radenci Municipality",
        "state_code": "100",
        "type": null
      },
      {
        "id": 4275,
        "name": "Radlje ob Dravi Municipality",
        "state_code": "101",
        "type": null
      },
      {
        "id": 4231,
        "name": "Radovljica Municipality",
        "state_code": "102",
        "type": null
      },
      {
        "id": 4155,
        "name": "Ravne na Koroškem Municipality",
        "state_code": "103",
        "type": null
      },
      {
        "id": 4206,
        "name": "Razkrižje Municipality",
        "state_code": "176",
        "type": null
      },
      {
        "id": 4160,
        "name": "Rečica ob Savinji Municipality",
        "state_code": "209",
        "type": null
      },
      {
        "id": 4253,
        "name": "Renče–Vogrsko Municipality",
        "state_code": "201",
        "type": null
      },
      {
        "id": 4235,
        "name": "Ribnica Municipality",
        "state_code": "104",
        "type": null
      },
      {
        "id": 4207,
        "name": "Ribnica na Pohorju Municipality",
        "state_code": "177",
        "type": null
      },
      {
        "id": 4233,
        "name": "Rogaška Slatina Municipality",
        "state_code": "106",
        "type": null
      },
      {
        "id": 4264,
        "name": "Rogašovci Municipality",
        "state_code": "105",
        "type": null
      },
      {
        "id": 4209,
        "name": "Rogatec Municipality",
        "state_code": "107",
        "type": null
      },
      {
        "id": 4280,
        "name": "Ruše Municipality",
        "state_code": "108",
        "type": null
      },
      {
        "id": 4222,
        "name": "Šalovci Municipality",
        "state_code": "033",
        "type": null
      },
      {
        "id": 4230,
        "name": "Selnica ob Dravi Municipality",
        "state_code": "178",
        "type": null
      },
      {
        "id": 4346,
        "name": "Semič Municipality",
        "state_code": "109",
        "type": null
      },
      {
        "id": 4317,
        "name": "Šempeter–Vrtojba Municipality",
        "state_code": "183",
        "type": null
      },
      {
        "id": 4299,
        "name": "Šenčur Municipality",
        "state_code": "117",
        "type": null
      },
      {
        "id": 4324,
        "name": "Šentilj Municipality",
        "state_code": "118",
        "type": null
      },
      {
        "id": 4241,
        "name": "Šentjernej Municipality",
        "state_code": "119",
        "type": null
      },
      {
        "id": 4171,
        "name": "Šentjur Municipality",
        "state_code": "120",
        "type": null
      },
      {
        "id": 4311,
        "name": "Šentrupert Municipality",
        "state_code": "211",
        "type": null
      },
      {
        "id": 4268,
        "name": "Sevnica Municipality",
        "state_code": "110",
        "type": null
      },
      {
        "id": 4149,
        "name": "Sežana Municipality",
        "state_code": "111",
        "type": null
      },
      {
        "id": 4170,
        "name": "Škocjan Municipality",
        "state_code": "121",
        "type": null
      },
      {
        "id": 4316,
        "name": "Škofja Loka Municipality",
        "state_code": "122",
        "type": null
      },
      {
        "id": 4169,
        "name": "Slovenj Gradec City Municipality",
        "state_code": "112",
        "type": null
      },
      {
        "id": 4332,
        "name": "Slovenska Bistrica Municipality",
        "state_code": "113",
        "type": null
      },
      {
        "id": 4198,
        "name": "Slovenske Konjice Municipality",
        "state_code": "114",
        "type": null
      },
      {
        "id": 4285,
        "name": "Šmarje pri Jelšah Municipality",
        "state_code": "124",
        "type": null
      },
      {
        "id": 4289,
        "name": "Šmarješke Toplice Municipality",
        "state_code": "206",
        "type": null
      },
      {
        "id": 4296,
        "name": "Šmartno ob Paki Municipality",
        "state_code": "125",
        "type": null
      },
      {
        "id": 4279,
        "name": "Šmartno pri Litiji Municipality",
        "state_code": "194",
        "type": null
      },
      {
        "id": 4277,
        "name": "Sodražica Municipality",
        "state_code": "179",
        "type": null
      },
      {
        "id": 4261,
        "name": "Solčava Municipality",
        "state_code": "180",
        "type": null
      },
      {
        "id": 4248,
        "name": "Šoštanj Municipality",
        "state_code": "126",
        "type": null
      },
      {
        "id": 4263,
        "name": "Središče ob Dravi",
        "state_code": "202",
        "type": null
      },
      {
        "id": 4259,
        "name": "Starše Municipality",
        "state_code": "115",
        "type": null
      },
      {
        "id": 4185,
        "name": "Štore Municipality",
        "state_code": "127",
        "type": null
      },
      {
        "id": 4333,
        "name": "Straža Municipality",
        "state_code": "203",
        "type": null
      },
      {
        "id": 4164,
        "name": "Sveta Ana Municipality",
        "state_code": "181",
        "type": null
      },
      {
        "id": 4260,
        "name": "Sveta Trojica v Slovenskih Goricah Municipality",
        "state_code": "204",
        "type": null
      },
      {
        "id": 4229,
        "name": "Sveti Andraž v Slovenskih Goricah Municipality",
        "state_code": "182",
        "type": null
      },
      {
        "id": 4255,
        "name": "Sveti Jurij ob Ščavnici Municipality",
        "state_code": "116",
        "type": null
      },
      {
        "id": 4328,
        "name": "Sveti Jurij v Slovenskih Goricah Municipality",
        "state_code": "210",
        "type": null
      },
      {
        "id": 4273,
        "name": "Sveti Tomaž Municipality",
        "state_code": "205",
        "type": null
      },
      {
        "id": 4194,
        "name": "Tabor Municipality",
        "state_code": "184",
        "type": null
      },
      {
        "id": 4312,
        "name": "Tišina Municipality",
        "state_code": "010",
        "type": null
      },
      {
        "id": 4247,
        "name": "Tolmin Municipality",
        "state_code": "128",
        "type": null
      },
      {
        "id": 4246,
        "name": "Trbovlje Municipality",
        "state_code": "129",
        "type": null
      },
      {
        "id": 4214,
        "name": "Trebnje Municipality",
        "state_code": "130",
        "type": null
      },
      {
        "id": 4153,
        "name": "Trnovska Vas Municipality",
        "state_code": "185",
        "type": null
      },
      {
        "id": 4250,
        "name": "Tržič Municipality",
        "state_code": "131",
        "type": null
      },
      {
        "id": 4334,
        "name": "Trzin Municipality",
        "state_code": "186",
        "type": null
      },
      {
        "id": 4251,
        "name": "Turnišče Municipality",
        "state_code": "132",
        "type": null
      },
      {
        "id": 4267,
        "name": "Velika Polana Municipality",
        "state_code": "187",
        "type": null
      },
      {
        "id": 4144,
        "name": "Velike Lašče Municipality",
        "state_code": "134",
        "type": null
      },
      {
        "id": 4257,
        "name": "Veržej Municipality",
        "state_code": "188",
        "type": null
      },
      {
        "id": 4300,
        "name": "Videm Municipality",
        "state_code": "135",
        "type": null
      },
      {
        "id": 4196,
        "name": "Vipava Municipality",
        "state_code": "136",
        "type": null
      },
      {
        "id": 4148,
        "name": "Vitanje Municipality",
        "state_code": "137",
        "type": null
      },
      {
        "id": 4154,
        "name": "Vodice Municipality",
        "state_code": "138",
        "type": null
      },
      {
        "id": 4245,
        "name": "Vojnik Municipality",
        "state_code": "139",
        "type": null
      },
      {
        "id": 4163,
        "name": "Vransko Municipality",
        "state_code": "189",
        "type": null
      },
      {
        "id": 4262,
        "name": "Vrhnika Municipality",
        "state_code": "140",
        "type": null
      },
      {
        "id": 4226,
        "name": "Vuzenica Municipality",
        "state_code": "141",
        "type": null
      },
      {
        "id": 4269,
        "name": "Zagorje ob Savi Municipality",
        "state_code": "142",
        "type": null
      },
      {
        "id": 4258,
        "name": "Žalec Municipality",
        "state_code": "190",
        "type": null
      },
      {
        "id": 4182,
        "name": "Zavrč Municipality",
        "state_code": "143",
        "type": null
      },
      {
        "id": 4256,
        "name": "Železniki Municipality",
        "state_code": "146",
        "type": null
      },
      {
        "id": 4249,
        "name": "Žetale Municipality",
        "state_code": "191",
        "type": null
      },
      {
        "id": 4192,
        "name": "Žiri Municipality",
        "state_code": "147",
        "type": null
      },
      {
        "id": 4276,
        "name": "Žirovnica Municipality",
        "state_code": "192",
        "type": null
      },
      {
        "id": 4342,
        "name": "Zreče Municipality",
        "state_code": "144",
        "type": null
      },
      {
        "id": 4307,
        "name": "Žužemberk Municipality",
        "state_code": "193",
        "type": null
      }
    ]
  },
  {
    "name": "Solomon Islands",
    "iso3": "SLB",
    "iso2": "SB",
    "numeric_code": "090",
    "phone_code": "677",
    "capital": "Honiara",
    "currency": "SBD",
    "currency_name": "Solomon Islands dollar",
    "currency_symbol": "Si$",
    "tld": ".sb",
    "native": "Solomon Islands",
    "region": "Oceania",
    "subregion": "Melanesia",
    "emoji": "🇸🇧",
    "emojiU": "U+1F1F8 U+1F1E7",
    "states": [
      {
        "id": 4784,
        "name": "Central Province",
        "state_code": "CE",
        "type": null
      },
      {
        "id": 4781,
        "name": "Choiseul Province",
        "state_code": "CH",
        "type": null
      },
      {
        "id": 4785,
        "name": "Guadalcanal Province",
        "state_code": "GU",
        "type": null
      },
      {
        "id": 4778,
        "name": "Honiara",
        "state_code": "CT",
        "type": null
      },
      {
        "id": 4780,
        "name": "Isabel Province",
        "state_code": "IS",
        "type": null
      },
      {
        "id": 4782,
        "name": "Makira-Ulawa Province",
        "state_code": "MK",
        "type": null
      },
      {
        "id": 4783,
        "name": "Malaita Province",
        "state_code": "ML",
        "type": null
      },
      {
        "id": 4787,
        "name": "Rennell and Bellona Province",
        "state_code": "RB",
        "type": null
      },
      {
        "id": 4779,
        "name": "Temotu Province",
        "state_code": "TE",
        "type": null
      },
      {
        "id": 4786,
        "name": "Western Province",
        "state_code": "WE",
        "type": null
      }
    ]
  },
  {
    "name": "Somalia",
    "iso3": "SOM",
    "iso2": "SO",
    "numeric_code": "706",
    "phone_code": "252",
    "capital": "Mogadishu",
    "currency": "SOS",
    "currency_name": "Somali shilling",
    "currency_symbol": "Sh.so.",
    "tld": ".so",
    "native": "Soomaaliya",
    "region": "Africa",
    "subregion": "Eastern Africa",
    "emoji": "🇸🇴",
    "emojiU": "U+1F1F8 U+1F1F4",
    "states": [
      {
        "id": 925,
        "name": "Awdal Region",
        "state_code": "AW",
        "type": null
      },
      {
        "id": 917,
        "name": "Bakool",
        "state_code": "BK",
        "type": null
      },
      {
        "id": 927,
        "name": "Banaadir",
        "state_code": "BN",
        "type": null
      },
      {
        "id": 930,
        "name": "Bari",
        "state_code": "BR",
        "type": null
      },
      {
        "id": 926,
        "name": "Bay",
        "state_code": "BY",
        "type": null
      },
      {
        "id": 918,
        "name": "Galguduud",
        "state_code": "GA",
        "type": null
      },
      {
        "id": 928,
        "name": "Gedo",
        "state_code": "GE",
        "type": null
      },
      {
        "id": 915,
        "name": "Hiran",
        "state_code": "HI",
        "type": null
      },
      {
        "id": 924,
        "name": "Lower Juba",
        "state_code": "JH",
        "type": null
      },
      {
        "id": 921,
        "name": "Lower Shebelle",
        "state_code": "SH",
        "type": null
      },
      {
        "id": 922,
        "name": "Middle Juba",
        "state_code": "JD",
        "type": null
      },
      {
        "id": 923,
        "name": "Middle Shebelle",
        "state_code": "SD",
        "type": null
      },
      {
        "id": 916,
        "name": "Mudug",
        "state_code": "MU",
        "type": null
      },
      {
        "id": 920,
        "name": "Nugal",
        "state_code": "NU",
        "type": null
      },
      {
        "id": 919,
        "name": "Sanaag Region",
        "state_code": "SA",
        "type": null
      },
      {
        "id": 929,
        "name": "Togdheer Region",
        "state_code": "TO",
        "type": null
      }
    ]
  },
  {
    "name": "South Africa",
    "iso3": "ZAF",
    "iso2": "ZA",
    "numeric_code": "710",
    "phone_code": "27",
    "capital": "Pretoria",
    "currency": "ZAR",
    "currency_name": "South African rand",
    "currency_symbol": "R",
    "tld": ".za",
    "native": "South Africa",
    "region": "Africa",
    "subregion": "Southern Africa",
    "emoji": "🇿🇦",
    "emojiU": "U+1F1FF U+1F1E6",
    "states": [
      {
        "id": 938,
        "name": "Eastern Cape",
        "state_code": "EC",
        "type": null
      },
      {
        "id": 932,
        "name": "Free State",
        "state_code": "FS",
        "type": null
      },
      {
        "id": 936,
        "name": "Gauteng",
        "state_code": "GP",
        "type": null
      },
      {
        "id": 935,
        "name": "KwaZulu-Natal",
        "state_code": "KZN",
        "type": null
      },
      {
        "id": 933,
        "name": "Limpopo",
        "state_code": "LP",
        "type": null
      },
      {
        "id": 937,
        "name": "Mpumalanga",
        "state_code": "MP",
        "type": null
      },
      {
        "id": 934,
        "name": "North West",
        "state_code": "NW",
        "type": null
      },
      {
        "id": 931,
        "name": "Northern Cape",
        "state_code": "NC",
        "type": null
      },
      {
        "id": 939,
        "name": "Western Cape",
        "state_code": "WC",
        "type": null
      }
    ]
  },
  {
    "name": "South Georgia",
    "iso3": "SGS",
    "iso2": "GS",
    "numeric_code": "239",
    "phone_code": "500",
    "capital": "Grytviken",
    "currency": "GBP",
    "currency_name": "British pound",
    "currency_symbol": "£",
    "tld": ".gs",
    "native": "South Georgia",
    "region": "Americas",
    "subregion": "South America",
    "emoji": "🇬🇸",
    "emojiU": "U+1F1EC U+1F1F8",
    "states": []
  },
  {
    "name": "South Korea",
    "iso3": "KOR",
    "iso2": "KR",
    "numeric_code": "410",
    "phone_code": "82",
    "capital": "Seoul",
    "currency": "KRW",
    "currency_name": "Won",
    "currency_symbol": "₩",
    "tld": ".kr",
    "native": "대한민국",
    "region": "Asia",
    "subregion": "Eastern Asia",
    "emoji": "🇰🇷",
    "emojiU": "U+1F1F0 U+1F1F7",
    "states": [
      {
        "id": 3860,
        "name": "Busan",
        "state_code": "26",
        "type": null
      },
      {
        "id": 3846,
        "name": "Daegu",
        "state_code": "27",
        "type": null
      },
      {
        "id": 3850,
        "name": "Daejeon",
        "state_code": "30",
        "type": null
      },
      {
        "id": 3862,
        "name": "Gangwon Province",
        "state_code": "42",
        "type": null
      },
      {
        "id": 3858,
        "name": "Gwangju",
        "state_code": "29",
        "type": null
      },
      {
        "id": 3847,
        "name": "Gyeonggi Province",
        "state_code": "41",
        "type": null
      },
      {
        "id": 3848,
        "name": "Incheon",
        "state_code": "28",
        "type": null
      },
      {
        "id": 3853,
        "name": "Jeju",
        "state_code": "49",
        "type": null
      },
      {
        "id": 3854,
        "name": "North Chungcheong Province",
        "state_code": "43",
        "type": null
      },
      {
        "id": 3855,
        "name": "North Gyeongsang Province",
        "state_code": "47",
        "type": null
      },
      {
        "id": 3851,
        "name": "North Jeolla Province",
        "state_code": "45",
        "type": null
      },
      {
        "id": 3861,
        "name": "Sejong City",
        "state_code": "50",
        "type": null
      },
      {
        "id": 3849,
        "name": "Seoul",
        "state_code": "11",
        "type": null
      },
      {
        "id": 3859,
        "name": "South Chungcheong Province",
        "state_code": "44",
        "type": null
      },
      {
        "id": 3857,
        "name": "South Gyeongsang Province",
        "state_code": "48",
        "type": null
      },
      {
        "id": 3856,
        "name": "South Jeolla Province",
        "state_code": "46",
        "type": null
      },
      {
        "id": 3852,
        "name": "Ulsan",
        "state_code": "31",
        "type": null
      }
    ]
  },
  {
    "name": "South Sudan",
    "iso3": "SSD",
    "iso2": "SS",
    "numeric_code": "728",
    "phone_code": "211",
    "capital": "Juba",
    "currency": "SSP",
    "currency_name": "South Sudanese pound",
    "currency_symbol": "£",
    "tld": ".ss",
    "native": "South Sudan",
    "region": "Africa",
    "subregion": "Middle Africa",
    "emoji": "🇸🇸",
    "emojiU": "U+1F1F8 U+1F1F8",
    "states": [
      {
        "id": 2092,
        "name": "Central Equatoria",
        "state_code": "EC",
        "type": null
      },
      {
        "id": 2093,
        "name": "Eastern Equatoria",
        "state_code": "EE",
        "type": null
      },
      {
        "id": 2094,
        "name": "Jonglei State",
        "state_code": "JG",
        "type": null
      },
      {
        "id": 2090,
        "name": "Lakes",
        "state_code": "LK",
        "type": null
      },
      {
        "id": 2088,
        "name": "Northern Bahr el Ghazal",
        "state_code": "BN",
        "type": null
      },
      {
        "id": 2085,
        "name": "Unity",
        "state_code": "UY",
        "type": null
      },
      {
        "id": 2086,
        "name": "Upper Nile",
        "state_code": "NU",
        "type": null
      },
      {
        "id": 2087,
        "name": "Warrap",
        "state_code": "WR",
        "type": null
      },
      {
        "id": 2091,
        "name": "Western Bahr el Ghazal",
        "state_code": "BW",
        "type": null
      },
      {
        "id": 2089,
        "name": "Western Equatoria",
        "state_code": "EW",
        "type": null
      }
    ]
  },
  {
    "name": "Spain",
    "iso3": "ESP",
    "iso2": "ES",
    "numeric_code": "724",
    "phone_code": "34",
    "capital": "Madrid",
    "currency": "EUR",
    "currency_name": "Euro",
    "currency_symbol": "€",
    "tld": ".es",
    "native": "España",
    "region": "Europe",
    "subregion": "Southern Europe",
    "emoji": "🇪🇸",
    "emojiU": "U+1F1EA U+1F1F8",
    "states": [
      {
        "id": 1193,
        "name": "Andalusia",
        "state_code": "AN",
        "type": null
      },
      {
        "id": 1177,
        "name": "Aragon",
        "state_code": "AR",
        "type": null
      },
      {
        "id": 1160,
        "name": "Asturias",
        "state_code": "AS",
        "type": null
      },
      {
        "id": 1189,
        "name": "Ávila",
        "state_code": "AV",
        "type": null
      },
      {
        "id": 1174,
        "name": "Balearic Islands",
        "state_code": "PM",
        "type": null
      },
      {
        "id": 1191,
        "name": "Basque Country",
        "state_code": "PV",
        "type": null
      },
      {
        "id": 1146,
        "name": "Burgos Province",
        "state_code": "BU",
        "type": null
      },
      {
        "id": 1185,
        "name": "Canary Islands",
        "state_code": "CN",
        "type": null
      },
      {
        "id": 1170,
        "name": "Cantabria",
        "state_code": "CB",
        "type": null
      },
      {
        "id": 1184,
        "name": "Castile and León",
        "state_code": "CL",
        "type": null
      },
      {
        "id": 1205,
        "name": "Castilla La Mancha",
        "state_code": "CM",
        "type": null
      },
      {
        "id": 1203,
        "name": "Catalonia",
        "state_code": "CT",
        "type": null
      },
      {
        "id": 1206,
        "name": "Ceuta",
        "state_code": "CE",
        "type": null
      },
      {
        "id": 1190,
        "name": "Extremadura",
        "state_code": "EX",
        "type": null
      },
      {
        "id": 1167,
        "name": "Galicia",
        "state_code": "GA",
        "type": null
      },
      {
        "id": 1171,
        "name": "La Rioja",
        "state_code": "RI",
        "type": null
      },
      {
        "id": 1200,
        "name": "Léon",
        "state_code": "LE",
        "type": null
      },
      {
        "id": 1158,
        "name": "Madrid",
        "state_code": "MD",
        "type": null
      },
      {
        "id": 1159,
        "name": "Melilla",
        "state_code": "ML",
        "type": null
      },
      {
        "id": 1176,
        "name": "Murcia",
        "state_code": "MC",
        "type": null
      },
      {
        "id": 1204,
        "name": "Navarra",
        "state_code": "NC",
        "type": null
      },
      {
        "id": 1157,
        "name": "Palencia Province",
        "state_code": "P",
        "type": null
      },
      {
        "id": 1147,
        "name": "Salamanca Province",
        "state_code": "SA",
        "type": null
      },
      {
        "id": 1192,
        "name": "Segovia Province",
        "state_code": "SG",
        "type": null
      },
      {
        "id": 1208,
        "name": "Soria Province",
        "state_code": "SO",
        "type": null
      },
      {
        "id": 1175,
        "name": "Valencia",
        "state_code": "VC",
        "type": null
      },
      {
        "id": 1183,
        "name": "Valladolid Province",
        "state_code": "VA",
        "type": null
      },
      {
        "id": 1161,
        "name": "Zamora Province",
        "state_code": "ZA",
        "type": null
      }
    ]
  },
  {
    "name": "Sri Lanka",
    "iso3": "LKA",
    "iso2": "LK",
    "numeric_code": "144",
    "phone_code": "94",
    "capital": "Colombo",
    "currency": "LKR",
    "currency_name": "Sri Lankan rupee",
    "currency_symbol": "Rs",
    "tld": ".lk",
    "native": "śrī laṃkāva",
    "region": "Asia",
    "subregion": "Southern Asia",
    "emoji": "🇱🇰",
    "emojiU": "U+1F1F1 U+1F1F0",
    "states": [
      {
        "id": 2799,
        "name": "Ampara District",
        "state_code": "52",
        "type": null
      },
      {
        "id": 2816,
        "name": "Anuradhapura District",
        "state_code": "71",
        "type": null
      },
      {
        "id": 2790,
        "name": "Badulla District",
        "state_code": "81",
        "type": null
      },
      {
        "id": 2818,
        "name": "Batticaloa District",
        "state_code": "51",
        "type": null
      },
      {
        "id": 2798,
        "name": "Central Province",
        "state_code": "2",
        "type": null
      },
      {
        "id": 2815,
        "name": "Colombo District",
        "state_code": "11",
        "type": null
      },
      {
        "id": 2808,
        "name": "Eastern Province",
        "state_code": "5",
        "type": null
      },
      {
        "id": 2792,
        "name": "Galle District",
        "state_code": "31",
        "type": null
      },
      {
        "id": 2804,
        "name": "Gampaha District",
        "state_code": "12",
        "type": null
      },
      {
        "id": 2791,
        "name": "Hambantota District",
        "state_code": "33",
        "type": null
      },
      {
        "id": 2787,
        "name": "Jaffna District",
        "state_code": "41",
        "type": null
      },
      {
        "id": 2789,
        "name": "Kalutara District",
        "state_code": "13",
        "type": null
      },
      {
        "id": 2788,
        "name": "Kandy District",
        "state_code": "21",
        "type": null
      },
      {
        "id": 2797,
        "name": "Kegalle District",
        "state_code": "92",
        "type": null
      },
      {
        "id": 2793,
        "name": "Kilinochchi District",
        "state_code": "42",
        "type": null
      },
      {
        "id": 2805,
        "name": "Mannar District",
        "state_code": "43",
        "type": null
      },
      {
        "id": 2810,
        "name": "Matale District",
        "state_code": "22",
        "type": null
      },
      {
        "id": 2806,
        "name": "Matara District",
        "state_code": "32",
        "type": null
      },
      {
        "id": 2819,
        "name": "Monaragala District",
        "state_code": "82",
        "type": null
      },
      {
        "id": 2814,
        "name": "Mullaitivu District",
        "state_code": "45",
        "type": null
      },
      {
        "id": 2800,
        "name": "North Central Province",
        "state_code": "7",
        "type": null
      },
      {
        "id": 2817,
        "name": "North Western Province",
        "state_code": "6",
        "type": null
      },
      {
        "id": 2813,
        "name": "Northern Province",
        "state_code": "4",
        "type": null
      },
      {
        "id": 2794,
        "name": "Nuwara Eliya District",
        "state_code": "23",
        "type": null
      },
      {
        "id": 2812,
        "name": "Polonnaruwa District",
        "state_code": "72",
        "type": null
      },
      {
        "id": 2796,
        "name": "Puttalam District",
        "state_code": "62",
        "type": null
      },
      {
        "id": 2807,
        "name": "Ratnapura district",
        "state_code": "91",
        "type": null
      },
      {
        "id": 2803,
        "name": "Sabaragamuwa Province",
        "state_code": "9",
        "type": null
      },
      {
        "id": 2801,
        "name": "Southern Province",
        "state_code": "3",
        "type": null
      },
      {
        "id": 2795,
        "name": "Trincomalee District",
        "state_code": "53",
        "type": null
      },
      {
        "id": 2811,
        "name": "Uva Province",
        "state_code": "8",
        "type": null
      },
      {
        "id": 2809,
        "name": "Vavuniya District",
        "state_code": "44",
        "type": null
      },
      {
        "id": 2802,
        "name": "Western Province",
        "state_code": "1",
        "type": null
      }
    ]
  },
  {
    "name": "Sudan",
    "iso3": "SDN",
    "iso2": "SD",
    "numeric_code": "729",
    "phone_code": "249",
    "capital": "Khartoum",
    "currency": "SDG",
    "currency_name": "Sudanese pound",
    "currency_symbol": ".س.ج",
    "tld": ".sd",
    "native": "السودان",
    "region": "Africa",
    "subregion": "Northern Africa",
    "emoji": "🇸🇩",
    "emojiU": "U+1F1F8 U+1F1E9",
    "states": [
      {
        "id": 885,
        "name": "Al Jazirah",
        "state_code": "GZ",
        "type": null
      },
      {
        "id": 886,
        "name": "Al Qadarif",
        "state_code": "GD",
        "type": null
      },
      {
        "id": 887,
        "name": "Blue Nile",
        "state_code": "NB",
        "type": null
      },
      {
        "id": 896,
        "name": "Central Darfur",
        "state_code": "DC",
        "type": null
      },
      {
        "id": 892,
        "name": "East Darfur",
        "state_code": "DE",
        "type": null
      },
      {
        "id": 884,
        "name": "Kassala",
        "state_code": "KA",
        "type": null
      },
      {
        "id": 881,
        "name": "Khartoum",
        "state_code": "KH",
        "type": null
      },
      {
        "id": 890,
        "name": "North Darfur",
        "state_code": "DN",
        "type": null
      },
      {
        "id": 893,
        "name": "North Kordofan",
        "state_code": "KN",
        "type": null
      },
      {
        "id": 895,
        "name": "Northern",
        "state_code": "NO",
        "type": null
      },
      {
        "id": 880,
        "name": "Red Sea",
        "state_code": "RS",
        "type": null
      },
      {
        "id": 891,
        "name": "River Nile",
        "state_code": "NR",
        "type": null
      },
      {
        "id": 882,
        "name": "Sennar",
        "state_code": "SI",
        "type": null
      },
      {
        "id": 894,
        "name": "South Darfur",
        "state_code": "DS",
        "type": null
      },
      {
        "id": 883,
        "name": "South Kordofan",
        "state_code": "KS",
        "type": null
      },
      {
        "id": 888,
        "name": "West Darfur",
        "state_code": "DW",
        "type": null
      },
      {
        "id": 889,
        "name": "West Kordofan",
        "state_code": "GK",
        "type": null
      },
      {
        "id": 879,
        "name": "White Nile",
        "state_code": "NW",
        "type": null
      }
    ]
  },
  {
    "name": "Suriname",
    "iso3": "SUR",
    "iso2": "SR",
    "numeric_code": "740",
    "phone_code": "597",
    "capital": "Paramaribo",
    "currency": "SRD",
    "currency_name": "Surinamese dollar",
    "currency_symbol": "$",
    "tld": ".sr",
    "native": "Suriname",
    "region": "Americas",
    "subregion": "South America",
    "emoji": "🇸🇷",
    "emojiU": "U+1F1F8 U+1F1F7",
    "states": [
      {
        "id": 2846,
        "name": "Brokopondo District",
        "state_code": "BR",
        "type": null
      },
      {
        "id": 2839,
        "name": "Commewijne District",
        "state_code": "CM",
        "type": null
      },
      {
        "id": 2842,
        "name": "Coronie District",
        "state_code": "CR",
        "type": null
      },
      {
        "id": 2845,
        "name": "Marowijne District",
        "state_code": "MA",
        "type": null
      },
      {
        "id": 2840,
        "name": "Nickerie District",
        "state_code": "NI",
        "type": null
      },
      {
        "id": 2841,
        "name": "Para District",
        "state_code": "PR",
        "type": null
      },
      {
        "id": 2843,
        "name": "Paramaribo District",
        "state_code": "PM",
        "type": null
      },
      {
        "id": 2848,
        "name": "Saramacca District",
        "state_code": "SA",
        "type": null
      },
      {
        "id": 2847,
        "name": "Sipaliwini District",
        "state_code": "SI",
        "type": null
      },
      {
        "id": 2844,
        "name": "Wanica District",
        "state_code": "WA",
        "type": null
      }
    ]
  },
  {
    "name": "Svalbard And Jan Mayen Islands",
    "iso3": "SJM",
    "iso2": "SJ",
    "numeric_code": "744",
    "phone_code": "47",
    "capital": "Longyearbyen",
    "currency": "NOK",
    "currency_name": "Norwegian Krone",
    "currency_symbol": "kr",
    "tld": ".sj",
    "native": "Svalbard og Jan Mayen",
    "region": "Europe",
    "subregion": "Northern Europe",
    "emoji": "🇸🇯",
    "emojiU": "U+1F1F8 U+1F1EF",
    "states": []
  },
  {
    "name": "Swaziland",
    "iso3": "SWZ",
    "iso2": "SZ",
    "numeric_code": "748",
    "phone_code": "268",
    "capital": "Mbabane",
    "currency": "SZL",
    "currency_name": "Lilangeni",
    "currency_symbol": "E",
    "tld": ".sz",
    "native": "Swaziland",
    "region": "Africa",
    "subregion": "Southern Africa",
    "emoji": "🇸🇿",
    "emojiU": "U+1F1F8 U+1F1FF",
    "states": [
      {
        "id": 969,
        "name": "Hhohho District",
        "state_code": "HH",
        "type": null
      },
      {
        "id": 970,
        "name": "Lubombo District",
        "state_code": "LU",
        "type": null
      },
      {
        "id": 968,
        "name": "Manzini District",
        "state_code": "MA",
        "type": null
      },
      {
        "id": 971,
        "name": "Shiselweni District",
        "state_code": "SH",
        "type": null
      }
    ]
  },
  {
    "name": "Sweden",
    "iso3": "SWE",
    "iso2": "SE",
    "numeric_code": "752",
    "phone_code": "46",
    "capital": "Stockholm",
    "currency": "SEK",
    "currency_name": "Swedish krona",
    "currency_symbol": "kr",
    "tld": ".se",
    "native": "Sverige",
    "region": "Europe",
    "subregion": "Northern Europe",
    "emoji": "🇸🇪",
    "emojiU": "U+1F1F8 U+1F1EA",
    "states": [
      {
        "id": 1537,
        "name": "Blekinge",
        "state_code": "K",
        "type": null
      },
      {
        "id": 1534,
        "name": "Dalarna County",
        "state_code": "W",
        "type": null
      },
      {
        "id": 1533,
        "name": "Gävleborg County",
        "state_code": "X",
        "type": null
      },
      {
        "id": 1546,
        "name": "Gotland County",
        "state_code": "I",
        "type": null
      },
      {
        "id": 1548,
        "name": "Halland County",
        "state_code": "N",
        "type": null
      },
      {
        "id": 1550,
        "name": "Jönköping County",
        "state_code": "F",
        "type": null
      },
      {
        "id": 1544,
        "name": "Kalmar County",
        "state_code": "H",
        "type": null
      },
      {
        "id": 1542,
        "name": "Kronoberg County",
        "state_code": "G",
        "type": null
      },
      {
        "id": 1538,
        "name": "Norrbotten County",
        "state_code": "BD",
        "type": null
      },
      {
        "id": 1539,
        "name": "Örebro County",
        "state_code": "T",
        "type": null
      },
      {
        "id": 1536,
        "name": "Östergötland County",
        "state_code": "E",
        "type": null
      },
      {
        "id": 1541,
        "name": "Skåne County",
        "state_code": "M",
        "type": null
      },
      {
        "id": 1540,
        "name": "Södermanland County",
        "state_code": "D",
        "type": null
      },
      {
        "id": 1551,
        "name": "Stockholm County",
        "state_code": "AB",
        "type": null
      },
      {
        "id": 1545,
        "name": "Uppsala County",
        "state_code": "C",
        "type": null
      },
      {
        "id": 1535,
        "name": "Värmland County",
        "state_code": "S",
        "type": null
      },
      {
        "id": 1543,
        "name": "Västerbotten County",
        "state_code": "AC",
        "type": null
      },
      {
        "id": 1552,
        "name": "Västernorrland County",
        "state_code": "Y",
        "type": null
      },
      {
        "id": 1549,
        "name": "Västmanland County",
        "state_code": "U",
        "type": null
      },
      {
        "id": 1547,
        "name": "Västra Götaland County",
        "state_code": "O",
        "type": null
      }
    ]
  },
  {
    "name": "Switzerland",
    "iso3": "CHE",
    "iso2": "CH",
    "numeric_code": "756",
    "phone_code": "41",
    "capital": "Bern",
    "currency": "CHF",
    "currency_name": "Swiss franc",
    "currency_symbol": "CHf",
    "tld": ".ch",
    "native": "Schweiz",
    "region": "Europe",
    "subregion": "Western Europe",
    "emoji": "🇨🇭",
    "emojiU": "U+1F1E8 U+1F1ED",
    "states": [
      {
        "id": 1639,
        "name": "Aargau",
        "state_code": "AG",
        "type": "canton"
      },
      {
        "id": 1655,
        "name": "Appenzell Ausserrhoden",
        "state_code": "AR",
        "type": "canton"
      },
      {
        "id": 1649,
        "name": "Appenzell Innerrhoden",
        "state_code": "AI",
        "type": "canton"
      },
      {
        "id": 1641,
        "name": "Basel-Land",
        "state_code": "BL",
        "type": "canton"
      },
      {
        "id": 4957,
        "name": "Basel-Stadt",
        "state_code": "BS",
        "type": "canton"
      },
      {
        "id": 1645,
        "name": "Bern",
        "state_code": "BE",
        "type": "canton"
      },
      {
        "id": 1640,
        "name": "Fribourg",
        "state_code": "FR",
        "type": "canton"
      },
      {
        "id": 1647,
        "name": "Geneva",
        "state_code": "GE",
        "type": "canton"
      },
      {
        "id": 1661,
        "name": "Glarus",
        "state_code": "GL",
        "type": "canton"
      },
      {
        "id": 1660,
        "name": "Graubünden",
        "state_code": "GR",
        "type": "canton"
      },
      {
        "id": 1658,
        "name": "Jura",
        "state_code": "JU",
        "type": "canton"
      },
      {
        "id": 1663,
        "name": "Lucerne",
        "state_code": "LU",
        "type": "canton"
      },
      {
        "id": 1659,
        "name": "Neuchâtel",
        "state_code": "NE",
        "type": "canton"
      },
      {
        "id": 1652,
        "name": "Nidwalden",
        "state_code": "NW",
        "type": "canton"
      },
      {
        "id": 1650,
        "name": "Obwalden",
        "state_code": "OW",
        "type": "canton"
      },
      {
        "id": 1654,
        "name": "Schaffhausen",
        "state_code": "SH",
        "type": "canton"
      },
      {
        "id": 1653,
        "name": "Schwyz",
        "state_code": "SZ",
        "type": "canton"
      },
      {
        "id": 1662,
        "name": "Solothurn",
        "state_code": "SO",
        "type": "canton"
      },
      {
        "id": 1644,
        "name": "St. Gallen",
        "state_code": "SG",
        "type": "canton"
      },
      {
        "id": 1657,
        "name": "Thurgau",
        "state_code": "TG",
        "type": "canton"
      },
      {
        "id": 1643,
        "name": "Ticino",
        "state_code": "TI",
        "type": "canton"
      },
      {
        "id": 1642,
        "name": "Uri",
        "state_code": "UR",
        "type": "canton"
      },
      {
        "id": 1648,
        "name": "Valais",
        "state_code": "VS",
        "type": "canton"
      },
      {
        "id": 1651,
        "name": "Vaud",
        "state_code": "VD",
        "type": "canton"
      },
      {
        "id": 1646,
        "name": "Zug",
        "state_code": "ZG",
        "type": "canton"
      },
      {
        "id": 1656,
        "name": "Zürich",
        "state_code": "ZH",
        "type": "canton"
      }
    ]
  },
  {
    "name": "Syria",
    "iso3": "SYR",
    "iso2": "SY",
    "numeric_code": "760",
    "phone_code": "963",
    "capital": "Damascus",
    "currency": "SYP",
    "currency_name": "Syrian pound",
    "currency_symbol": "LS",
    "tld": ".sy",
    "native": "سوريا",
    "region": "Asia",
    "subregion": "Western Asia",
    "emoji": "🇸🇾",
    "emojiU": "U+1F1F8 U+1F1FE",
    "states": [
      {
        "id": 2941,
        "name": "Al-Hasakah Governorate",
        "state_code": "HA",
        "type": null
      },
      {
        "id": 2944,
        "name": "Al-Raqqah Governorate",
        "state_code": "RA",
        "type": null
      },
      {
        "id": 2946,
        "name": "Aleppo Governorate",
        "state_code": "HL",
        "type": null
      },
      {
        "id": 2936,
        "name": "As-Suwayda Governorate",
        "state_code": "SU",
        "type": null
      },
      {
        "id": 2939,
        "name": "Damascus Governorate",
        "state_code": "DI",
        "type": null
      },
      {
        "id": 2945,
        "name": "Daraa Governorate",
        "state_code": "DR",
        "type": null
      },
      {
        "id": 2937,
        "name": "Deir ez-Zor Governorate",
        "state_code": "DY",
        "type": null
      },
      {
        "id": 2934,
        "name": "Hama Governorate",
        "state_code": "HM",
        "type": null
      },
      {
        "id": 2942,
        "name": "Homs Governorate",
        "state_code": "HI",
        "type": null
      },
      {
        "id": 2940,
        "name": "Idlib Governorate",
        "state_code": "ID",
        "type": null
      },
      {
        "id": 2938,
        "name": "Latakia Governorate",
        "state_code": "LA",
        "type": null
      },
      {
        "id": 2943,
        "name": "Quneitra Governorate",
        "state_code": "QU",
        "type": null
      },
      {
        "id": 2935,
        "name": "Rif Dimashq Governorate",
        "state_code": "RD",
        "type": null
      },
      {
        "id": 2947,
        "name": "Tartus Governorate",
        "state_code": "TA",
        "type": null
      }
    ]
  },
  {
    "name": "Taiwan",
    "iso3": "TWN",
    "iso2": "TW",
    "numeric_code": "158",
    "phone_code": "886",
    "capital": "Taipei",
    "currency": "TWD",
    "currency_name": "New Taiwan dollar",
    "currency_symbol": "$",
    "tld": ".tw",
    "native": "臺灣",
    "region": "Asia",
    "subregion": "Eastern Asia",
    "emoji": "🇹🇼",
    "emojiU": "U+1F1F9 U+1F1FC",
    "states": [
      {
        "id": 3404,
        "name": "Changhua",
        "state_code": "CHA",
        "type": "county"
      },
      {
        "id": 3408,
        "name": "Chiayi",
        "state_code": "CYI",
        "type": "city"
      },
      {
        "id": 3418,
        "name": "Chiayi",
        "state_code": "CYQ",
        "type": "county"
      },
      {
        "id": 3423,
        "name": "Hsinchu",
        "state_code": "HSQ",
        "type": "county"
      },
      {
        "id": 3417,
        "name": "Hsinchu",
        "state_code": "HSZ",
        "type": "city"
      },
      {
        "id": 3411,
        "name": "Hualien",
        "state_code": "HUA",
        "type": "county"
      },
      {
        "id": 3412,
        "name": "Kaohsiung",
        "state_code": "KHH",
        "type": "special municipality\t"
      },
      {
        "id": 4965,
        "name": "Keelung",
        "state_code": "KEE",
        "type": "city"
      },
      {
        "id": 3415,
        "name": "Kinmen",
        "state_code": "KIN",
        "type": "county"
      },
      {
        "id": 3420,
        "name": "Lienchiang",
        "state_code": "LIE",
        "type": "county"
      },
      {
        "id": 3413,
        "name": "Miaoli",
        "state_code": "MIA",
        "type": "county"
      },
      {
        "id": 3407,
        "name": "Nantou",
        "state_code": "NAN",
        "type": "county"
      },
      {
        "id": 4966,
        "name": "New Taipei",
        "state_code": "NWT",
        "type": "special municipality\t"
      },
      {
        "id": 3403,
        "name": "Penghu",
        "state_code": "PEN",
        "type": "county"
      },
      {
        "id": 3405,
        "name": "Pingtung",
        "state_code": "PIF",
        "type": "county"
      },
      {
        "id": 3406,
        "name": "Taichung",
        "state_code": "TXG",
        "type": "special municipality\t"
      },
      {
        "id": 3421,
        "name": "Tainan",
        "state_code": "TNN",
        "type": "special municipality\t"
      },
      {
        "id": 3422,
        "name": "Taipei",
        "state_code": "TPE",
        "type": "special municipality\t"
      },
      {
        "id": 3410,
        "name": "Taitung",
        "state_code": "TTT",
        "type": "county"
      },
      {
        "id": 3419,
        "name": "Taoyuan",
        "state_code": "TAO",
        "type": "special municipality\t"
      },
      {
        "id": 3402,
        "name": "Yilan",
        "state_code": "ILA",
        "type": "county"
      },
      {
        "id": 3416,
        "name": "Yunlin",
        "state_code": "YUN",
        "type": "county"
      }
    ]
  },
  {
    "name": "Tajikistan",
    "iso3": "TJK",
    "iso2": "TJ",
    "numeric_code": "762",
    "phone_code": "992",
    "capital": "Dushanbe",
    "currency": "TJS",
    "currency_name": "Tajikistani somoni",
    "currency_symbol": "SM",
    "tld": ".tj",
    "native": "Тоҷикистон",
    "region": "Asia",
    "subregion": "Central Asia",
    "emoji": "🇹🇯",
    "emojiU": "U+1F1F9 U+1F1EF",
    "states": [
      {
        "id": 3397,
        "name": "districts of Republican Subordination",
        "state_code": "RA",
        "type": null
      },
      {
        "id": 3399,
        "name": "Gorno-Badakhshan Autonomous Province",
        "state_code": "GB",
        "type": null
      },
      {
        "id": 3398,
        "name": "Khatlon Province",
        "state_code": "KT",
        "type": null
      },
      {
        "id": 3400,
        "name": "Sughd Province",
        "state_code": "SU",
        "type": null
      }
    ]
  },
  {
    "name": "Tanzania",
    "iso3": "TZA",
    "iso2": "TZ",
    "numeric_code": "834",
    "phone_code": "255",
    "capital": "Dodoma",
    "currency": "TZS",
    "currency_name": "Tanzanian shilling",
    "currency_symbol": "TSh",
    "tld": ".tz",
    "native": "Tanzania",
    "region": "Africa",
    "subregion": "Eastern Africa",
    "emoji": "🇹🇿",
    "emojiU": "U+1F1F9 U+1F1FF",
    "states": [
      {
        "id": 1491,
        "name": "Arusha",
        "state_code": "01",
        "type": "Region"
      },
      {
        "id": 1490,
        "name": "Dar es Salaam",
        "state_code": "02",
        "type": "Region"
      },
      {
        "id": 1466,
        "name": "Dodoma",
        "state_code": "03",
        "type": "Region"
      },
      {
        "id": 1481,
        "name": "Geita",
        "state_code": "27",
        "type": "Region"
      },
      {
        "id": 1489,
        "name": "Iringa",
        "state_code": "04",
        "type": "Region"
      },
      {
        "id": 1465,
        "name": "Kagera",
        "state_code": "05",
        "type": "Region"
      },
      {
        "id": 1482,
        "name": "Katavi",
        "state_code": "28",
        "type": "Region"
      },
      {
        "id": 1478,
        "name": "Kigoma",
        "state_code": "08",
        "type": "Region"
      },
      {
        "id": 1467,
        "name": "Kilimanjaro",
        "state_code": "09",
        "type": "Region"
      },
      {
        "id": 1483,
        "name": "Lindi",
        "state_code": "12",
        "type": "Region"
      },
      {
        "id": 1484,
        "name": "Manyara",
        "state_code": "26",
        "type": "Region"
      },
      {
        "id": 1468,
        "name": "Mara",
        "state_code": "13",
        "type": "Region"
      },
      {
        "id": 4955,
        "name": "Mbeya",
        "state_code": "14",
        "type": "Region"
      },
      {
        "id": 1470,
        "name": "Morogoro",
        "state_code": "16",
        "type": "Region"
      },
      {
        "id": 1476,
        "name": "Mtwara",
        "state_code": "17",
        "type": "Region"
      },
      {
        "id": 1479,
        "name": "Mwanza",
        "state_code": "18",
        "type": "Region"
      },
      {
        "id": 1480,
        "name": "Njombe",
        "state_code": "29",
        "type": "Region"
      },
      {
        "id": 1488,
        "name": "Pemba North",
        "state_code": "06",
        "type": "Region"
      },
      {
        "id": 1472,
        "name": "Pemba South",
        "state_code": "10",
        "type": "Region"
      },
      {
        "id": 1485,
        "name": "Pwani",
        "state_code": "19",
        "type": "Region"
      },
      {
        "id": 1477,
        "name": "Rukwa",
        "state_code": "20",
        "type": "Region"
      },
      {
        "id": 1486,
        "name": "Ruvuma",
        "state_code": "21",
        "type": "Region"
      },
      {
        "id": 1463,
        "name": "Shinyanga",
        "state_code": "22",
        "type": "Region"
      },
      {
        "id": 1464,
        "name": "Simiyu",
        "state_code": "30",
        "type": "Region"
      },
      {
        "id": 1474,
        "name": "Singida",
        "state_code": "23",
        "type": "Region"
      },
      {
        "id": 4956,
        "name": "Songwe",
        "state_code": "31",
        "type": "Region"
      },
      {
        "id": 1469,
        "name": "Tabora",
        "state_code": "24",
        "type": "Region"
      },
      {
        "id": 1487,
        "name": "Tanga",
        "state_code": "25",
        "type": "Region"
      },
      {
        "id": 1473,
        "name": "Zanzibar North",
        "state_code": "07",
        "type": "Region"
      },
      {
        "id": 1471,
        "name": "Zanzibar South",
        "state_code": "11",
        "type": "Region"
      },
      {
        "id": 1475,
        "name": "Zanzibar West",
        "state_code": "15",
        "type": "Region"
      }
    ]
  },
  {
    "name": "Thailand",
    "iso3": "THA",
    "iso2": "TH",
    "numeric_code": "764",
    "phone_code": "66",
    "capital": "Bangkok",
    "currency": "THB",
    "currency_name": "Thai baht",
    "currency_symbol": "฿",
    "tld": ".th",
    "native": "ประเทศไทย",
    "region": "Asia",
    "subregion": "South-Eastern Asia",
    "emoji": "🇹🇭",
    "emojiU": "U+1F1F9 U+1F1ED",
    "states": [
      {
        "id": 3523,
        "name": "Amnat Charoen",
        "state_code": "37",
        "type": null
      },
      {
        "id": 3519,
        "name": "Ang Thong",
        "state_code": "15",
        "type": null
      },
      {
        "id": 3554,
        "name": "Bangkok",
        "state_code": "10",
        "type": null
      },
      {
        "id": 3533,
        "name": "Bueng Kan",
        "state_code": "38",
        "type": null
      },
      {
        "id": 3534,
        "name": "Buri Ram",
        "state_code": "31",
        "type": null
      },
      {
        "id": 3552,
        "name": "Chachoengsao",
        "state_code": "24",
        "type": null
      },
      {
        "id": 3522,
        "name": "Chai Nat",
        "state_code": "18",
        "type": null
      },
      {
        "id": 4954,
        "name": "Chaiyaphum",
        "state_code": "36",
        "type": null
      },
      {
        "id": 3486,
        "name": "Chanthaburi",
        "state_code": "22",
        "type": null
      },
      {
        "id": 3491,
        "name": "Chiang Mai",
        "state_code": "50",
        "type": null
      },
      {
        "id": 3498,
        "name": "Chiang Rai",
        "state_code": "57",
        "type": null
      },
      {
        "id": 3513,
        "name": "Chon Buri",
        "state_code": "20",
        "type": null
      },
      {
        "id": 3526,
        "name": "Chumphon",
        "state_code": "86",
        "type": null
      },
      {
        "id": 3550,
        "name": "Kalasin",
        "state_code": "46",
        "type": null
      },
      {
        "id": 3516,
        "name": "Kamphaeng Phet",
        "state_code": "62",
        "type": null
      },
      {
        "id": 3511,
        "name": "Kanchanaburi",
        "state_code": "71",
        "type": null
      },
      {
        "id": 3485,
        "name": "Khon Kaen",
        "state_code": "40",
        "type": null
      },
      {
        "id": 3478,
        "name": "Krabi",
        "state_code": "81",
        "type": null
      },
      {
        "id": 3544,
        "name": "Lampang",
        "state_code": "52",
        "type": null
      },
      {
        "id": 3483,
        "name": "Lamphun",
        "state_code": "51",
        "type": null
      },
      {
        "id": 3509,
        "name": "Loei",
        "state_code": "42",
        "type": null
      },
      {
        "id": 3543,
        "name": "Lop Buri",
        "state_code": "16",
        "type": null
      },
      {
        "id": 3505,
        "name": "Mae Hong Son",
        "state_code": "58",
        "type": null
      },
      {
        "id": 3517,
        "name": "Maha Sarakham",
        "state_code": "44",
        "type": null
      },
      {
        "id": 3546,
        "name": "Mukdahan",
        "state_code": "49",
        "type": null
      },
      {
        "id": 3535,
        "name": "Nakhon Nayok",
        "state_code": "26",
        "type": null
      },
      {
        "id": 3503,
        "name": "Nakhon Pathom",
        "state_code": "73",
        "type": null
      },
      {
        "id": 3548,
        "name": "Nakhon Phanom",
        "state_code": "48",
        "type": null
      },
      {
        "id": 3497,
        "name": "Nakhon Ratchasima",
        "state_code": "30",
        "type": null
      },
      {
        "id": 3492,
        "name": "Nakhon Sawan",
        "state_code": "60",
        "type": null
      },
      {
        "id": 3520,
        "name": "Nakhon Si Thammarat",
        "state_code": "80",
        "type": null
      },
      {
        "id": 3530,
        "name": "Nan",
        "state_code": "55",
        "type": null
      },
      {
        "id": 3553,
        "name": "Narathiwat",
        "state_code": "96",
        "type": null
      },
      {
        "id": 3480,
        "name": "Nong Bua Lam Phu",
        "state_code": "39",
        "type": null
      },
      {
        "id": 3484,
        "name": "Nong Khai",
        "state_code": "43",
        "type": null
      },
      {
        "id": 3495,
        "name": "Nonthaburi",
        "state_code": "12",
        "type": null
      },
      {
        "id": 3500,
        "name": "Pathum Thani",
        "state_code": "13",
        "type": null
      },
      {
        "id": 3540,
        "name": "Pattani",
        "state_code": "94",
        "type": null
      },
      {
        "id": 3507,
        "name": "Pattaya",
        "state_code": "S",
        "type": null
      },
      {
        "id": 3549,
        "name": "Phangnga",
        "state_code": "82",
        "type": null
      },
      {
        "id": 3488,
        "name": "Phatthalung",
        "state_code": "93",
        "type": null
      },
      {
        "id": 3538,
        "name": "Phayao",
        "state_code": "56",
        "type": null
      },
      {
        "id": 3515,
        "name": "Phetchabun",
        "state_code": "67",
        "type": null
      },
      {
        "id": 3532,
        "name": "Phetchaburi",
        "state_code": "76",
        "type": null
      },
      {
        "id": 3514,
        "name": "Phichit",
        "state_code": "66",
        "type": null
      },
      {
        "id": 3506,
        "name": "Phitsanulok",
        "state_code": "65",
        "type": null
      },
      {
        "id": 3494,
        "name": "Phra Nakhon Si Ayutthaya",
        "state_code": "14",
        "type": null
      },
      {
        "id": 3528,
        "name": "Phrae",
        "state_code": "54",
        "type": null
      },
      {
        "id": 3536,
        "name": "Phuket",
        "state_code": "83",
        "type": null
      },
      {
        "id": 3542,
        "name": "Prachin Buri",
        "state_code": "25",
        "type": null
      },
      {
        "id": 3508,
        "name": "Prachuap Khiri Khan",
        "state_code": "77",
        "type": null
      },
      {
        "id": 3479,
        "name": "Ranong",
        "state_code": "85",
        "type": null
      },
      {
        "id": 3499,
        "name": "Ratchaburi",
        "state_code": "70",
        "type": null
      },
      {
        "id": 3518,
        "name": "Rayong",
        "state_code": "21",
        "type": null
      },
      {
        "id": 3510,
        "name": "Roi Et",
        "state_code": "45",
        "type": null
      },
      {
        "id": 3529,
        "name": "Sa Kaeo",
        "state_code": "27",
        "type": null
      },
      {
        "id": 3501,
        "name": "Sakon Nakhon",
        "state_code": "47",
        "type": null
      },
      {
        "id": 3481,
        "name": "Samut Prakan",
        "state_code": "11",
        "type": null
      },
      {
        "id": 3504,
        "name": "Samut Sakhon",
        "state_code": "74",
        "type": null
      },
      {
        "id": 3502,
        "name": "Samut Songkhram",
        "state_code": "75",
        "type": null
      },
      {
        "id": 3487,
        "name": "Saraburi",
        "state_code": "19",
        "type": null
      },
      {
        "id": 3537,
        "name": "Satun",
        "state_code": "91",
        "type": null
      },
      {
        "id": 3547,
        "name": "Si Sa Ket",
        "state_code": "33",
        "type": null
      },
      {
        "id": 3490,
        "name": "Sing Buri",
        "state_code": "17",
        "type": null
      },
      {
        "id": 3539,
        "name": "Songkhla",
        "state_code": "90",
        "type": null
      },
      {
        "id": 3545,
        "name": "Sukhothai",
        "state_code": "64",
        "type": null
      },
      {
        "id": 3524,
        "name": "Suphan Buri",
        "state_code": "72",
        "type": null
      },
      {
        "id": 3482,
        "name": "Surat Thani",
        "state_code": "84",
        "type": null
      },
      {
        "id": 3531,
        "name": "Surin",
        "state_code": "32",
        "type": null
      },
      {
        "id": 3525,
        "name": "Tak",
        "state_code": "63",
        "type": null
      },
      {
        "id": 3541,
        "name": "Trang",
        "state_code": "92",
        "type": null
      },
      {
        "id": 3496,
        "name": "Trat",
        "state_code": "23",
        "type": null
      },
      {
        "id": 3512,
        "name": "Ubon Ratchathani",
        "state_code": "34",
        "type": null
      },
      {
        "id": 3527,
        "name": "Udon Thani",
        "state_code": "41",
        "type": null
      },
      {
        "id": 3551,
        "name": "Uthai Thani",
        "state_code": "61",
        "type": null
      },
      {
        "id": 3489,
        "name": "Uttaradit",
        "state_code": "53",
        "type": null
      },
      {
        "id": 3493,
        "name": "Yala",
        "state_code": "95",
        "type": null
      },
      {
        "id": 3521,
        "name": "Yasothon",
        "state_code": "35",
        "type": null
      }
    ]
  },
  {
    "name": "Togo",
    "iso3": "TGO",
    "iso2": "TG",
    "numeric_code": "768",
    "phone_code": "228",
    "capital": "Lome",
    "currency": "XOF",
    "currency_name": "West African CFA franc",
    "currency_symbol": "CFA",
    "tld": ".tg",
    "native": "Togo",
    "region": "Africa",
    "subregion": "Western Africa",
    "emoji": "🇹🇬",
    "emojiU": "U+1F1F9 U+1F1EC",
    "states": [
      {
        "id": 2575,
        "name": "Centrale Region",
        "state_code": "C",
        "type": null
      },
      {
        "id": 2579,
        "name": "Kara Region",
        "state_code": "K",
        "type": null
      },
      {
        "id": 2576,
        "name": "Maritime",
        "state_code": "M",
        "type": null
      },
      {
        "id": 2577,
        "name": "Plateaux Region",
        "state_code": "P",
        "type": null
      },
      {
        "id": 2578,
        "name": "Savanes Region",
        "state_code": "S",
        "type": null
      }
    ]
  },
  {
    "name": "Tokelau",
    "iso3": "TKL",
    "iso2": "TK",
    "numeric_code": "772",
    "phone_code": "690",
    "capital": "",
    "currency": "NZD",
    "currency_name": "New Zealand dollar",
    "currency_symbol": "$",
    "tld": ".tk",
    "native": "Tokelau",
    "region": "Oceania",
    "subregion": "Polynesia",
    "emoji": "🇹🇰",
    "emojiU": "U+1F1F9 U+1F1F0",
    "states": []
  },
  {
    "name": "Tonga",
    "iso3": "TON",
    "iso2": "TO",
    "numeric_code": "776",
    "phone_code": "676",
    "capital": "Nuku'alofa",
    "currency": "TOP",
    "currency_name": "Tongan paʻanga",
    "currency_symbol": "$",
    "tld": ".to",
    "native": "Tonga",
    "region": "Oceania",
    "subregion": "Polynesia",
    "emoji": "🇹🇴",
    "emojiU": "U+1F1F9 U+1F1F4",
    "states": [
      {
        "id": 3913,
        "name": "Haʻapai",
        "state_code": "02",
        "type": null
      },
      {
        "id": 3915,
        "name": "ʻEua",
        "state_code": "01",
        "type": null
      },
      {
        "id": 3914,
        "name": "Niuas",
        "state_code": "03",
        "type": null
      },
      {
        "id": 3912,
        "name": "Tongatapu",
        "state_code": "04",
        "type": null
      },
      {
        "id": 3911,
        "name": "Vavaʻu",
        "state_code": "05",
        "type": null
      }
    ]
  },
  {
    "name": "Trinidad And Tobago",
    "iso3": "TTO",
    "iso2": "TT",
    "numeric_code": "780",
    "phone_code": "+1-868",
    "capital": "Port of Spain",
    "currency": "TTD",
    "currency_name": "Trinidad and Tobago dollar",
    "currency_symbol": "$",
    "tld": ".tt",
    "native": "Trinidad and Tobago",
    "region": "Americas",
    "subregion": "Caribbean",
    "emoji": "🇹🇹",
    "emojiU": "U+1F1F9 U+1F1F9",
    "states": [
      {
        "id": 3362,
        "name": "Arima",
        "state_code": "ARI",
        "type": null
      },
      {
        "id": 3366,
        "name": "Chaguanas",
        "state_code": "CHA",
        "type": null
      },
      {
        "id": 3354,
        "name": "Couva-Tabaquite-Talparo Regional Corporation",
        "state_code": "CTT",
        "type": null
      },
      {
        "id": 3367,
        "name": "Diego Martin Regional Corporation",
        "state_code": "DMN",
        "type": null
      },
      {
        "id": 3355,
        "name": "Eastern Tobago",
        "state_code": "ETO",
        "type": null
      },
      {
        "id": 3365,
        "name": "Penal-Debe Regional Corporation",
        "state_code": "PED",
        "type": null
      },
      {
        "id": 3360,
        "name": "Point Fortin",
        "state_code": "PTF",
        "type": null
      },
      {
        "id": 3363,
        "name": "Port of Spain",
        "state_code": "POS",
        "type": null
      },
      {
        "id": 3368,
        "name": "Princes Town Regional Corporation",
        "state_code": "PRT",
        "type": null
      },
      {
        "id": 3356,
        "name": "Rio Claro-Mayaro Regional Corporation",
        "state_code": "MRC",
        "type": null
      },
      {
        "id": 3359,
        "name": "San Fernando",
        "state_code": "SFO",
        "type": null
      },
      {
        "id": 3357,
        "name": "San Juan-Laventille Regional Corporation",
        "state_code": "SJL",
        "type": null
      },
      {
        "id": 3361,
        "name": "Sangre Grande Regional Corporation",
        "state_code": "SGE",
        "type": null
      },
      {
        "id": 3364,
        "name": "Siparia Regional Corporation",
        "state_code": "SIP",
        "type": null
      },
      {
        "id": 3358,
        "name": "Tunapuna-Piarco Regional Corporation",
        "state_code": "TUP",
        "type": null
      },
      {
        "id": 3353,
        "name": "Western Tobago",
        "state_code": "WTO",
        "type": null
      }
    ]
  },
  {
    "name": "Tunisia",
    "iso3": "TUN",
    "iso2": "TN",
    "numeric_code": "788",
    "phone_code": "216",
    "capital": "Tunis",
    "currency": "TND",
    "currency_name": "Tunisian dinar",
    "currency_symbol": "ت.د",
    "tld": ".tn",
    "native": "تونس",
    "region": "Africa",
    "subregion": "Northern Africa",
    "emoji": "🇹🇳",
    "emojiU": "U+1F1F9 U+1F1F3",
    "states": [
      {
        "id": 2550,
        "name": "Ariana Governorate",
        "state_code": "12",
        "type": null
      },
      {
        "id": 2566,
        "name": "Ben Arous Governorate",
        "state_code": "13",
        "type": null
      },
      {
        "id": 2551,
        "name": "Bizerte Governorate",
        "state_code": "23",
        "type": null
      },
      {
        "id": 2558,
        "name": "Gabès Governorate",
        "state_code": "81",
        "type": null
      },
      {
        "id": 2556,
        "name": "Gafsa Governorate",
        "state_code": "71",
        "type": null
      },
      {
        "id": 2552,
        "name": "Jendouba Governorate",
        "state_code": "32",
        "type": null
      },
      {
        "id": 2564,
        "name": "Kairouan Governorate",
        "state_code": "41",
        "type": null
      },
      {
        "id": 2570,
        "name": "Kasserine Governorate",
        "state_code": "42",
        "type": null
      },
      {
        "id": 2572,
        "name": "Kassrine",
        "state_code": "31",
        "type": null
      },
      {
        "id": 2562,
        "name": "Kebili Governorate",
        "state_code": "73",
        "type": null
      },
      {
        "id": 2561,
        "name": "Kef Governorate",
        "state_code": "33",
        "type": null
      },
      {
        "id": 2568,
        "name": "Mahdia Governorate",
        "state_code": "53",
        "type": null
      },
      {
        "id": 2555,
        "name": "Manouba Governorate",
        "state_code": "14",
        "type": null
      },
      {
        "id": 2560,
        "name": "Medenine Governorate",
        "state_code": "82",
        "type": null
      },
      {
        "id": 2553,
        "name": "Monastir Governorate",
        "state_code": "52",
        "type": null
      },
      {
        "id": 2557,
        "name": "Sfax Governorate",
        "state_code": "61",
        "type": null
      },
      {
        "id": 2567,
        "name": "Sidi Bouzid Governorate",
        "state_code": "43",
        "type": null
      },
      {
        "id": 2563,
        "name": "Siliana Governorate",
        "state_code": "34",
        "type": null
      },
      {
        "id": 2571,
        "name": "Sousse Governorate",
        "state_code": "51",
        "type": null
      },
      {
        "id": 2559,
        "name": "Tataouine Governorate",
        "state_code": "83",
        "type": null
      },
      {
        "id": 2569,
        "name": "Tozeur Governorate",
        "state_code": "72",
        "type": null
      },
      {
        "id": 2554,
        "name": "Tunis Governorate",
        "state_code": "11",
        "type": null
      },
      {
        "id": 2565,
        "name": "Zaghouan Governorate",
        "state_code": "22",
        "type": null
      }
    ]
  },
  {
    "name": "Turkey",
    "iso3": "TUR",
    "iso2": "TR",
    "numeric_code": "792",
    "phone_code": "90",
    "capital": "Ankara",
    "currency": "TRY",
    "currency_name": "Turkish lira",
    "currency_symbol": "₺",
    "tld": ".tr",
    "native": "Türkiye",
    "region": "Asia",
    "subregion": "Western Asia",
    "emoji": "🇹🇷",
    "emojiU": "U+1F1F9 U+1F1F7",
    "states": [
      {
        "id": 2212,
        "name": "Adana",
        "state_code": "01",
        "type": "province"
      },
      {
        "id": 2155,
        "name": "Adıyaman",
        "state_code": "02",
        "type": "province"
      },
      {
        "id": 2179,
        "name": "Afyonkarahisar",
        "state_code": "03",
        "type": "province"
      },
      {
        "id": 2193,
        "name": "Ağrı",
        "state_code": "04",
        "type": "province"
      },
      {
        "id": 2210,
        "name": "Aksaray",
        "state_code": "68",
        "type": "province"
      },
      {
        "id": 2161,
        "name": "Amasya",
        "state_code": "05",
        "type": "province"
      },
      {
        "id": 2217,
        "name": "Ankara",
        "state_code": "06",
        "type": "province"
      },
      {
        "id": 2169,
        "name": "Antalya",
        "state_code": "07",
        "type": "province"
      },
      {
        "id": 2185,
        "name": "Ardahan",
        "state_code": "75",
        "type": "province"
      },
      {
        "id": 2191,
        "name": "Artvin",
        "state_code": "08",
        "type": "province"
      },
      {
        "id": 2187,
        "name": "Aydın",
        "state_code": "09",
        "type": "province"
      },
      {
        "id": 2175,
        "name": "Balıkesir",
        "state_code": "10",
        "type": "province"
      },
      {
        "id": 2148,
        "name": "Bartın",
        "state_code": "74",
        "type": "province"
      },
      {
        "id": 2194,
        "name": "Batman",
        "state_code": "72",
        "type": "province"
      },
      {
        "id": 2177,
        "name": "Bayburt",
        "state_code": "69",
        "type": "province"
      },
      {
        "id": 2221,
        "name": "Bilecik",
        "state_code": "11",
        "type": "province"
      },
      {
        "id": 2153,
        "name": "Bingöl",
        "state_code": "12",
        "type": "province"
      },
      {
        "id": 2215,
        "name": "Bitlis",
        "state_code": "13",
        "type": "province"
      },
      {
        "id": 2172,
        "name": "Bolu",
        "state_code": "14",
        "type": "province"
      },
      {
        "id": 2209,
        "name": "Burdur",
        "state_code": "15",
        "type": "province"
      },
      {
        "id": 2163,
        "name": "Bursa",
        "state_code": "16",
        "type": "province"
      },
      {
        "id": 2216,
        "name": "Çanakkale",
        "state_code": "17",
        "type": "province"
      },
      {
        "id": 2168,
        "name": "Çankırı",
        "state_code": "18",
        "type": "province"
      },
      {
        "id": 2173,
        "name": "Çorum",
        "state_code": "19",
        "type": "province"
      },
      {
        "id": 2157,
        "name": "Denizli",
        "state_code": "20",
        "type": "province"
      },
      {
        "id": 2226,
        "name": "Diyarbakır",
        "state_code": "21",
        "type": "province"
      },
      {
        "id": 2202,
        "name": "Düzce",
        "state_code": "81",
        "type": "province"
      },
      {
        "id": 2151,
        "name": "Edirne",
        "state_code": "22",
        "type": "province"
      },
      {
        "id": 2159,
        "name": "Elazığ",
        "state_code": "23",
        "type": "province"
      },
      {
        "id": 2160,
        "name": "Erzincan",
        "state_code": "24",
        "type": "province"
      },
      {
        "id": 2165,
        "name": "Erzurum",
        "state_code": "25",
        "type": "province"
      },
      {
        "id": 2164,
        "name": "Eskişehir",
        "state_code": "26",
        "type": "province"
      },
      {
        "id": 2203,
        "name": "Gaziantep",
        "state_code": "27",
        "type": "province"
      },
      {
        "id": 2186,
        "name": "Giresun",
        "state_code": "28",
        "type": "province"
      },
      {
        "id": 2204,
        "name": "Gümüşhane",
        "state_code": "29",
        "type": "province"
      },
      {
        "id": 2190,
        "name": "Hakkâri",
        "state_code": "30",
        "type": "province"
      },
      {
        "id": 2211,
        "name": "Hatay",
        "state_code": "31",
        "type": "province"
      },
      {
        "id": 2166,
        "name": "Iğdır",
        "state_code": "76",
        "type": "province"
      },
      {
        "id": 2222,
        "name": "Isparta",
        "state_code": "32",
        "type": "province"
      },
      {
        "id": 2170,
        "name": "Istanbul",
        "state_code": "34",
        "type": "province"
      },
      {
        "id": 2205,
        "name": "İzmir",
        "state_code": "35",
        "type": "province"
      },
      {
        "id": 2227,
        "name": "Kahramanmaraş",
        "state_code": "46",
        "type": "province"
      },
      {
        "id": 2223,
        "name": "Karabük",
        "state_code": "78",
        "type": "province"
      },
      {
        "id": 2184,
        "name": "Karaman",
        "state_code": "70",
        "type": "province"
      },
      {
        "id": 2208,
        "name": "Kars",
        "state_code": "36",
        "type": "province"
      },
      {
        "id": 2197,
        "name": "Kastamonu",
        "state_code": "37",
        "type": "province"
      },
      {
        "id": 2200,
        "name": "Kayseri",
        "state_code": "38",
        "type": "province"
      },
      {
        "id": 2154,
        "name": "Kilis",
        "state_code": "79",
        "type": "province"
      },
      {
        "id": 2178,
        "name": "Kırıkkale",
        "state_code": "71",
        "type": "province"
      },
      {
        "id": 2176,
        "name": "Kırklareli",
        "state_code": "39",
        "type": "province"
      },
      {
        "id": 2180,
        "name": "Kırşehir",
        "state_code": "40",
        "type": "province"
      },
      {
        "id": 2195,
        "name": "Kocaeli",
        "state_code": "41",
        "type": "province"
      },
      {
        "id": 2171,
        "name": "Konya",
        "state_code": "42",
        "type": "province"
      },
      {
        "id": 2149,
        "name": "Kütahya",
        "state_code": "43",
        "type": "province"
      },
      {
        "id": 2158,
        "name": "Malatya",
        "state_code": "44",
        "type": "province"
      },
      {
        "id": 2198,
        "name": "Manisa",
        "state_code": "45",
        "type": "province"
      },
      {
        "id": 2224,
        "name": "Mardin",
        "state_code": "47",
        "type": "province"
      },
      {
        "id": 2156,
        "name": "Mersin",
        "state_code": "33",
        "type": "province"
      },
      {
        "id": 2182,
        "name": "Muğla",
        "state_code": "48",
        "type": "province"
      },
      {
        "id": 2162,
        "name": "Muş",
        "state_code": "49",
        "type": "province"
      },
      {
        "id": 2196,
        "name": "Nevşehir",
        "state_code": "50",
        "type": "province"
      },
      {
        "id": 2189,
        "name": "Niğde",
        "state_code": "51",
        "type": "province"
      },
      {
        "id": 2174,
        "name": "Ordu",
        "state_code": "52",
        "type": "province"
      },
      {
        "id": 2214,
        "name": "Osmaniye",
        "state_code": "80",
        "type": "province"
      },
      {
        "id": 2219,
        "name": "Rize",
        "state_code": "53",
        "type": "province"
      },
      {
        "id": 2150,
        "name": "Sakarya",
        "state_code": "54",
        "type": "province"
      },
      {
        "id": 2220,
        "name": "Samsun",
        "state_code": "55",
        "type": "province"
      },
      {
        "id": 2183,
        "name": "Şanlıurfa",
        "state_code": "63",
        "type": "province"
      },
      {
        "id": 2207,
        "name": "Siirt",
        "state_code": "56",
        "type": "province"
      },
      {
        "id": 4854,
        "name": "Sinop",
        "state_code": "57",
        "type": "province"
      },
      {
        "id": 2181,
        "name": "Sivas",
        "state_code": "58",
        "type": "province"
      },
      {
        "id": 2225,
        "name": "Şırnak",
        "state_code": "73",
        "type": "province"
      },
      {
        "id": 2167,
        "name": "Tekirdağ",
        "state_code": "59",
        "type": "province"
      },
      {
        "id": 2199,
        "name": "Tokat",
        "state_code": "60",
        "type": "province"
      },
      {
        "id": 2206,
        "name": "Trabzon",
        "state_code": "61",
        "type": "province"
      },
      {
        "id": 2192,
        "name": "Tunceli",
        "state_code": "62",
        "type": "province"
      },
      {
        "id": 2201,
        "name": "Uşak",
        "state_code": "64",
        "type": "province"
      },
      {
        "id": 2152,
        "name": "Van",
        "state_code": "65",
        "type": "province"
      },
      {
        "id": 2218,
        "name": "Yalova",
        "state_code": "77",
        "type": "province"
      },
      {
        "id": 2188,
        "name": "Yozgat",
        "state_code": "66",
        "type": "province"
      },
      {
        "id": 2213,
        "name": "Zonguldak",
        "state_code": "67",
        "type": "province"
      }
    ]
  },
  {
    "name": "Turkmenistan",
    "iso3": "TKM",
    "iso2": "TM",
    "numeric_code": "795",
    "phone_code": "993",
    "capital": "Ashgabat",
    "currency": "TMT",
    "currency_name": "Turkmenistan manat",
    "currency_symbol": "T",
    "tld": ".tm",
    "native": "Türkmenistan",
    "region": "Asia",
    "subregion": "Central Asia",
    "emoji": "🇹🇲",
    "emojiU": "U+1F1F9 U+1F1F2",
    "states": [
      {
        "id": 3374,
        "name": "Ahal Region",
        "state_code": "A",
        "type": null
      },
      {
        "id": 3371,
        "name": "Ashgabat",
        "state_code": "S",
        "type": null
      },
      {
        "id": 3372,
        "name": "Balkan Region",
        "state_code": "B",
        "type": null
      },
      {
        "id": 3373,
        "name": "Daşoguz Region",
        "state_code": "D",
        "type": null
      },
      {
        "id": 3370,
        "name": "Lebap Region",
        "state_code": "L",
        "type": null
      },
      {
        "id": 3369,
        "name": "Mary Region",
        "state_code": "M",
        "type": null
      }
    ]
  },
  {
    "name": "Turks And Caicos Islands",
    "iso3": "TCA",
    "iso2": "TC",
    "numeric_code": "796",
    "phone_code": "+1-649",
    "capital": "Cockburn Town",
    "currency": "USD",
    "currency_name": "United States dollar",
    "currency_symbol": "$",
    "tld": ".tc",
    "native": "Turks and Caicos Islands",
    "region": "Americas",
    "subregion": "Caribbean",
    "emoji": "🇹🇨",
    "emojiU": "U+1F1F9 U+1F1E8",
    "states": []
  },
  {
    "name": "Tuvalu",
    "iso3": "TUV",
    "iso2": "TV",
    "numeric_code": "798",
    "phone_code": "688",
    "capital": "Funafuti",
    "currency": "AUD",
    "currency_name": "Australian dollar",
    "currency_symbol": "$",
    "tld": ".tv",
    "native": "Tuvalu",
    "region": "Oceania",
    "subregion": "Polynesia",
    "emoji": "🇹🇻",
    "emojiU": "U+1F1F9 U+1F1FB",
    "states": [
      {
        "id": 3951,
        "name": "Funafuti",
        "state_code": "FUN",
        "type": null
      },
      {
        "id": 3947,
        "name": "Nanumanga",
        "state_code": "NMG",
        "type": null
      },
      {
        "id": 3949,
        "name": "Nanumea",
        "state_code": "NMA",
        "type": null
      },
      {
        "id": 3946,
        "name": "Niutao Island Council",
        "state_code": "NIT",
        "type": null
      },
      {
        "id": 3948,
        "name": "Nui",
        "state_code": "NUI",
        "type": null
      },
      {
        "id": 3952,
        "name": "Nukufetau",
        "state_code": "NKF",
        "type": null
      },
      {
        "id": 3953,
        "name": "Nukulaelae",
        "state_code": "NKL",
        "type": null
      },
      {
        "id": 3950,
        "name": "Vaitupu",
        "state_code": "VAI",
        "type": null
      }
    ]
  },
  {
    "name": "Uganda",
    "iso3": "UGA",
    "iso2": "UG",
    "numeric_code": "800",
    "phone_code": "256",
    "capital": "Kampala",
    "currency": "UGX",
    "currency_name": "Ugandan shilling",
    "currency_symbol": "USh",
    "tld": ".ug",
    "native": "Uganda",
    "region": "Africa",
    "subregion": "Eastern Africa",
    "emoji": "🇺🇬",
    "emojiU": "U+1F1FA U+1F1EC",
    "states": [
      {
        "id": 329,
        "name": "Abim District",
        "state_code": "314",
        "type": null
      },
      {
        "id": 361,
        "name": "Adjumani District",
        "state_code": "301",
        "type": null
      },
      {
        "id": 392,
        "name": "Agago District",
        "state_code": "322",
        "type": null
      },
      {
        "id": 344,
        "name": "Alebtong District",
        "state_code": "323",
        "type": null
      },
      {
        "id": 416,
        "name": "Amolatar District",
        "state_code": "315",
        "type": null
      },
      {
        "id": 353,
        "name": "Amudat District",
        "state_code": "324",
        "type": null
      },
      {
        "id": 352,
        "name": "Amuria District",
        "state_code": "216",
        "type": null
      },
      {
        "id": 335,
        "name": "Amuru District",
        "state_code": "316",
        "type": null
      },
      {
        "id": 328,
        "name": "Apac District",
        "state_code": "302",
        "type": null
      },
      {
        "id": 447,
        "name": "Arua District",
        "state_code": "303",
        "type": null
      },
      {
        "id": 441,
        "name": "Budaka District",
        "state_code": "217",
        "type": null
      },
      {
        "id": 349,
        "name": "Bududa District",
        "state_code": "218",
        "type": null
      },
      {
        "id": 387,
        "name": "Bugiri District",
        "state_code": "201",
        "type": null
      },
      {
        "id": 391,
        "name": "Buhweju District",
        "state_code": "420",
        "type": null
      },
      {
        "id": 377,
        "name": "Buikwe District",
        "state_code": "117",
        "type": null
      },
      {
        "id": 343,
        "name": "Bukedea District",
        "state_code": "219",
        "type": null
      },
      {
        "id": 375,
        "name": "Bukomansimbi District",
        "state_code": "118",
        "type": null
      },
      {
        "id": 385,
        "name": "Bukwo District",
        "state_code": "220",
        "type": null
      },
      {
        "id": 428,
        "name": "Bulambuli District",
        "state_code": "225",
        "type": null
      },
      {
        "id": 389,
        "name": "Buliisa District",
        "state_code": "416",
        "type": null
      },
      {
        "id": 419,
        "name": "Bundibugyo District",
        "state_code": "401",
        "type": null
      },
      {
        "id": 381,
        "name": "Bunyangabu District",
        "state_code": "430",
        "type": null
      },
      {
        "id": 386,
        "name": "Bushenyi District",
        "state_code": "402",
        "type": null
      },
      {
        "id": 431,
        "name": "Busia District",
        "state_code": "202",
        "type": null
      },
      {
        "id": 365,
        "name": "Butaleja District",
        "state_code": "221",
        "type": null
      },
      {
        "id": 384,
        "name": "Butambala District",
        "state_code": "119",
        "type": null
      },
      {
        "id": 388,
        "name": "Butebo District",
        "state_code": "233",
        "type": null
      },
      {
        "id": 414,
        "name": "Buvuma District",
        "state_code": "120",
        "type": null
      },
      {
        "id": 380,
        "name": "Buyende District",
        "state_code": "226",
        "type": null
      },
      {
        "id": 396,
        "name": "Central Region",
        "state_code": "C",
        "type": null
      },
      {
        "id": 341,
        "name": "Dokolo District",
        "state_code": "317",
        "type": null
      },
      {
        "id": 372,
        "name": "Eastern Region",
        "state_code": "E",
        "type": null
      },
      {
        "id": 366,
        "name": "Gomba District",
        "state_code": "121",
        "type": null
      },
      {
        "id": 413,
        "name": "Gulu District",
        "state_code": "304",
        "type": null
      },
      {
        "id": 339,
        "name": "Ibanda District",
        "state_code": "417",
        "type": null
      },
      {
        "id": 340,
        "name": "Iganga District",
        "state_code": "203",
        "type": null
      },
      {
        "id": 383,
        "name": "Isingiro District",
        "state_code": "418",
        "type": null
      },
      {
        "id": 367,
        "name": "Jinja District",
        "state_code": "204",
        "type": null
      },
      {
        "id": 434,
        "name": "Kaabong District",
        "state_code": "318",
        "type": null
      },
      {
        "id": 426,
        "name": "Kabale District",
        "state_code": "404",
        "type": null
      },
      {
        "id": 326,
        "name": "Kabarole District",
        "state_code": "405",
        "type": null
      },
      {
        "id": 336,
        "name": "Kaberamaido District",
        "state_code": "213",
        "type": null
      },
      {
        "id": 403,
        "name": "Kagadi District",
        "state_code": "427",
        "type": null
      },
      {
        "id": 399,
        "name": "Kakumiro District",
        "state_code": "428",
        "type": null
      },
      {
        "id": 405,
        "name": "Kalangala District",
        "state_code": "101",
        "type": null
      },
      {
        "id": 398,
        "name": "Kaliro District",
        "state_code": "222",
        "type": null
      },
      {
        "id": 394,
        "name": "Kalungu District",
        "state_code": "122",
        "type": null
      },
      {
        "id": 382,
        "name": "Kampala District",
        "state_code": "102",
        "type": null
      },
      {
        "id": 334,
        "name": "Kamuli District",
        "state_code": "205",
        "type": null
      },
      {
        "id": 360,
        "name": "Kamwenge District",
        "state_code": "413",
        "type": null
      },
      {
        "id": 373,
        "name": "Kanungu District",
        "state_code": "414",
        "type": null
      },
      {
        "id": 432,
        "name": "Kapchorwa District",
        "state_code": "206",
        "type": null
      },
      {
        "id": 440,
        "name": "Kasese District",
        "state_code": "406",
        "type": null
      },
      {
        "id": 420,
        "name": "Katakwi District",
        "state_code": "207",
        "type": null
      },
      {
        "id": 368,
        "name": "Kayunga District",
        "state_code": "112",
        "type": null
      },
      {
        "id": 436,
        "name": "Kibaale District",
        "state_code": "407",
        "type": null
      },
      {
        "id": 347,
        "name": "Kiboga District",
        "state_code": "103",
        "type": null
      },
      {
        "id": 338,
        "name": "Kibuku District",
        "state_code": "227",
        "type": null
      },
      {
        "id": 355,
        "name": "Kiruhura District",
        "state_code": "419",
        "type": null
      },
      {
        "id": 346,
        "name": "Kiryandongo District",
        "state_code": "421",
        "type": null
      },
      {
        "id": 409,
        "name": "Kisoro District",
        "state_code": "408",
        "type": null
      },
      {
        "id": 348,
        "name": "Kitgum District",
        "state_code": "305",
        "type": null
      },
      {
        "id": 345,
        "name": "Koboko District",
        "state_code": "319",
        "type": null
      },
      {
        "id": 401,
        "name": "Kole District",
        "state_code": "325",
        "type": null
      },
      {
        "id": 443,
        "name": "Kotido District",
        "state_code": "306",
        "type": null
      },
      {
        "id": 425,
        "name": "Kumi District",
        "state_code": "208",
        "type": null
      },
      {
        "id": 369,
        "name": "Kween District",
        "state_code": "228",
        "type": null
      },
      {
        "id": 325,
        "name": "Kyankwanzi District",
        "state_code": "123",
        "type": null
      },
      {
        "id": 437,
        "name": "Kyegegwa District",
        "state_code": "422",
        "type": null
      },
      {
        "id": 402,
        "name": "Kyenjojo District",
        "state_code": "415",
        "type": null
      },
      {
        "id": 448,
        "name": "Kyotera District",
        "state_code": "125",
        "type": null
      },
      {
        "id": 411,
        "name": "Lamwo District",
        "state_code": "326",
        "type": null
      },
      {
        "id": 342,
        "name": "Lira District",
        "state_code": "307",
        "type": null
      },
      {
        "id": 445,
        "name": "Luuka District",
        "state_code": "229",
        "type": null
      },
      {
        "id": 433,
        "name": "Luwero District",
        "state_code": "104",
        "type": null
      },
      {
        "id": 417,
        "name": "Lwengo District",
        "state_code": "124",
        "type": null
      },
      {
        "id": 376,
        "name": "Lyantonde District",
        "state_code": "114",
        "type": null
      },
      {
        "id": 438,
        "name": "Manafwa District",
        "state_code": "223",
        "type": null
      },
      {
        "id": 421,
        "name": "Maracha District",
        "state_code": "320",
        "type": null
      },
      {
        "id": 356,
        "name": "Masaka District",
        "state_code": "105",
        "type": null
      },
      {
        "id": 354,
        "name": "Masindi District",
        "state_code": "409",
        "type": null
      },
      {
        "id": 418,
        "name": "Mayuge District",
        "state_code": "214",
        "type": null
      },
      {
        "id": 350,
        "name": "Mbale District",
        "state_code": "209",
        "type": null
      },
      {
        "id": 415,
        "name": "Mbarara District",
        "state_code": "410",
        "type": null
      },
      {
        "id": 435,
        "name": "Mitooma District",
        "state_code": "423",
        "type": null
      },
      {
        "id": 364,
        "name": "Mityana District",
        "state_code": "115",
        "type": null
      },
      {
        "id": 395,
        "name": "Moroto District",
        "state_code": "308",
        "type": null
      },
      {
        "id": 363,
        "name": "Moyo District",
        "state_code": "309",
        "type": null
      },
      {
        "id": 327,
        "name": "Mpigi District",
        "state_code": "106",
        "type": null
      },
      {
        "id": 371,
        "name": "Mubende District",
        "state_code": "107",
        "type": null
      },
      {
        "id": 410,
        "name": "Mukono District",
        "state_code": "108",
        "type": null
      },
      {
        "id": 393,
        "name": "Nakapiripirit District",
        "state_code": "311",
        "type": null
      },
      {
        "id": 423,
        "name": "Nakaseke District",
        "state_code": "116",
        "type": null
      },
      {
        "id": 406,
        "name": "Nakasongola District",
        "state_code": "109",
        "type": null
      },
      {
        "id": 351,
        "name": "Namayingo District",
        "state_code": "230",
        "type": null
      },
      {
        "id": 400,
        "name": "Namisindwa District",
        "state_code": "234",
        "type": null
      },
      {
        "id": 337,
        "name": "Namutumba District",
        "state_code": "224",
        "type": null
      },
      {
        "id": 430,
        "name": "Napak District",
        "state_code": "327",
        "type": null
      },
      {
        "id": 446,
        "name": "Nebbi District",
        "state_code": "310",
        "type": null
      },
      {
        "id": 424,
        "name": "Ngora District",
        "state_code": "231",
        "type": null
      },
      {
        "id": 332,
        "name": "Northern Region",
        "state_code": "N",
        "type": null
      },
      {
        "id": 422,
        "name": "Ntoroko District",
        "state_code": "424",
        "type": null
      },
      {
        "id": 404,
        "name": "Ntungamo District",
        "state_code": "411",
        "type": null
      },
      {
        "id": 378,
        "name": "Nwoya District",
        "state_code": "328",
        "type": null
      },
      {
        "id": 374,
        "name": "Omoro District",
        "state_code": "331",
        "type": null
      },
      {
        "id": 390,
        "name": "Otuke District",
        "state_code": "329",
        "type": null
      },
      {
        "id": 397,
        "name": "Oyam District",
        "state_code": "321",
        "type": null
      },
      {
        "id": 408,
        "name": "Pader District",
        "state_code": "312",
        "type": null
      },
      {
        "id": 357,
        "name": "Pakwach District",
        "state_code": "332",
        "type": null
      },
      {
        "id": 412,
        "name": "Pallisa District",
        "state_code": "210",
        "type": null
      },
      {
        "id": 439,
        "name": "Rakai District",
        "state_code": "110",
        "type": null
      },
      {
        "id": 358,
        "name": "Rubanda District",
        "state_code": "429",
        "type": null
      },
      {
        "id": 442,
        "name": "Rubirizi District",
        "state_code": "425",
        "type": null
      },
      {
        "id": 331,
        "name": "Rukiga District",
        "state_code": "431",
        "type": null
      },
      {
        "id": 324,
        "name": "Rukungiri District",
        "state_code": "412",
        "type": null
      },
      {
        "id": 427,
        "name": "Sembabule District",
        "state_code": "111",
        "type": null
      },
      {
        "id": 333,
        "name": "Serere District",
        "state_code": "232",
        "type": null
      },
      {
        "id": 407,
        "name": "Sheema District",
        "state_code": "426",
        "type": null
      },
      {
        "id": 429,
        "name": "Sironko District",
        "state_code": "215",
        "type": null
      },
      {
        "id": 444,
        "name": "Soroti District",
        "state_code": "211",
        "type": null
      },
      {
        "id": 359,
        "name": "Tororo District",
        "state_code": "212",
        "type": null
      },
      {
        "id": 362,
        "name": "Wakiso District",
        "state_code": "113",
        "type": null
      },
      {
        "id": 370,
        "name": "Western Region",
        "state_code": "W",
        "type": null
      },
      {
        "id": 330,
        "name": "Yumbe District",
        "state_code": "313",
        "type": null
      },
      {
        "id": 379,
        "name": "Zombo District",
        "state_code": "330",
        "type": null
      }
    ]
  },
  {
    "name": "Ukraine",
    "iso3": "UKR",
    "iso2": "UA",
    "numeric_code": "804",
    "phone_code": "380",
    "capital": "Kiev",
    "currency": "UAH",
    "currency_name": "Ukrainian hryvnia",
    "currency_symbol": "₴",
    "tld": ".ua",
    "native": "Україна",
    "region": "Europe",
    "subregion": "Eastern Europe",
    "emoji": "🇺🇦",
    "emojiU": "U+1F1FA U+1F1E6",
    "states": [
      {
        "id": 4689,
        "name": "Autonomous Republic of Crimea",
        "state_code": "43",
        "type": null
      },
      {
        "id": 4680,
        "name": "Cherkasy Oblast",
        "state_code": "71",
        "type": null
      },
      {
        "id": 4692,
        "name": "Chernihiv Oblast",
        "state_code": "74",
        "type": null
      },
      {
        "id": 4678,
        "name": "Chernivtsi Oblast",
        "state_code": "77",
        "type": null
      },
      {
        "id": 4675,
        "name": "Dnipropetrovsk Oblast",
        "state_code": "12",
        "type": null
      },
      {
        "id": 4691,
        "name": "Donetsk Oblast",
        "state_code": "14",
        "type": null
      },
      {
        "id": 4682,
        "name": "Ivano-Frankivsk Oblast",
        "state_code": "26",
        "type": null
      },
      {
        "id": 4686,
        "name": "Kharkiv Oblast",
        "state_code": "63",
        "type": null
      },
      {
        "id": 4684,
        "name": "Kherson Oblast",
        "state_code": "65",
        "type": null
      },
      {
        "id": 4681,
        "name": "Khmelnytsky Oblast",
        "state_code": "68",
        "type": null
      },
      {
        "id": 4676,
        "name": "Kiev",
        "state_code": "30",
        "type": null
      },
      {
        "id": 4677,
        "name": "Kirovohrad Oblast",
        "state_code": "35",
        "type": null
      },
      {
        "id": 4671,
        "name": "Kyiv Oblast",
        "state_code": "32",
        "type": null
      },
      {
        "id": 4673,
        "name": "Luhansk Oblast",
        "state_code": "09",
        "type": null
      },
      {
        "id": 4672,
        "name": "Lviv Oblast",
        "state_code": "46",
        "type": null
      },
      {
        "id": 4679,
        "name": "Mykolaiv Oblast",
        "state_code": "48",
        "type": null
      },
      {
        "id": 4688,
        "name": "Odessa Oblast",
        "state_code": "51",
        "type": null
      },
      {
        "id": 4683,
        "name": "Rivne Oblast",
        "state_code": "56",
        "type": null
      },
      {
        "id": 4685,
        "name": "Sumy Oblast",
        "state_code": "59",
        "type": null
      },
      {
        "id": 4674,
        "name": "Ternopil Oblast",
        "state_code": "61",
        "type": null
      },
      {
        "id": 4669,
        "name": "Vinnytsia Oblast",
        "state_code": "05",
        "type": null
      },
      {
        "id": 4690,
        "name": "Volyn Oblast",
        "state_code": "07",
        "type": null
      },
      {
        "id": 4670,
        "name": "Zakarpattia Oblast",
        "state_code": "21",
        "type": null
      },
      {
        "id": 4687,
        "name": "Zaporizhzhya Oblast",
        "state_code": "23",
        "type": null
      },
      {
        "id": 4668,
        "name": "Zhytomyr Oblast",
        "state_code": "18",
        "type": null
      }
    ]
  },
  {
    "name": "United Arab Emirates",
    "iso3": "ARE",
    "iso2": "AE",
    "numeric_code": "784",
    "phone_code": "971",
    "capital": "Abu Dhabi",
    "currency": "AED",
    "currency_name": "United Arab Emirates dirham",
    "currency_symbol": "إ.د",
    "tld": ".ae",
    "native": "دولة الإمارات العربية المتحدة",
    "region": "Asia",
    "subregion": "Western Asia",
    "emoji": "🇦🇪",
    "emojiU": "U+1F1E6 U+1F1EA",
    "states": [
      {
        "id": 3396,
        "name": "Abu Dhabi Emirate",
        "state_code": "AZ",
        "type": null
      },
      {
        "id": 3395,
        "name": "Ajman Emirate",
        "state_code": "AJ",
        "type": null
      },
      {
        "id": 3391,
        "name": "Dubai",
        "state_code": "DU",
        "type": null
      },
      {
        "id": 3393,
        "name": "Fujairah",
        "state_code": "FU",
        "type": null
      },
      {
        "id": 3394,
        "name": "Ras al-Khaimah",
        "state_code": "RK",
        "type": null
      },
      {
        "id": 3390,
        "name": "Sharjah Emirate",
        "state_code": "SH",
        "type": null
      },
      {
        "id": 3392,
        "name": "Umm al-Quwain",
        "state_code": "UQ",
        "type": null
      }
    ]
  },
  {
    "name": "United Kingdom",
    "iso3": "GBR",
    "iso2": "GB",
    "numeric_code": "826",
    "phone_code": "44",
    "capital": "London",
    "currency": "GBP",
    "currency_name": "British pound",
    "currency_symbol": "£",
    "tld": ".uk",
    "native": "United Kingdom",
    "region": "Europe",
    "subregion": "Northern Europe",
    "emoji": "🇬🇧",
    "emojiU": "U+1F1EC U+1F1E7",
    "states": [
      {
        "id": 2463,
        "name": "Aberdeen",
        "state_code": "ABE",
        "type": null
      },
      {
        "id": 2401,
        "name": "Aberdeenshire",
        "state_code": "ABD",
        "type": null
      },
      {
        "id": 2387,
        "name": "Angus",
        "state_code": "ANS",
        "type": null
      },
      {
        "id": 2533,
        "name": "Antrim",
        "state_code": "ANT",
        "type": null
      },
      {
        "id": 2412,
        "name": "Antrim and Newtownabbey",
        "state_code": "ANN",
        "type": null
      },
      {
        "id": 2498,
        "name": "Ards",
        "state_code": "ARD",
        "type": null
      },
      {
        "id": 2523,
        "name": "Ards and North Down",
        "state_code": "AND",
        "type": null
      },
      {
        "id": 2392,
        "name": "Argyll and Bute",
        "state_code": "AGB",
        "type": null
      },
      {
        "id": 2331,
        "name": "Armagh City and District Council",
        "state_code": "ARM",
        "type": null
      },
      {
        "id": 2324,
        "name": "Armagh, Banbridge and Craigavon",
        "state_code": "ABC",
        "type": null
      },
      {
        "id": 2378,
        "name": "Ascension Island",
        "state_code": "SH-AC",
        "type": null
      },
      {
        "id": 2363,
        "name": "Ballymena Borough",
        "state_code": "BLA",
        "type": null
      },
      {
        "id": 2361,
        "name": "Ballymoney",
        "state_code": "BLY",
        "type": null
      },
      {
        "id": 2315,
        "name": "Banbridge",
        "state_code": "BNB",
        "type": null
      },
      {
        "id": 2499,
        "name": "Barnsley",
        "state_code": "BNS",
        "type": null
      },
      {
        "id": 2339,
        "name": "Bath and North East Somerset",
        "state_code": "BAS",
        "type": null
      },
      {
        "id": 2507,
        "name": "Bedford",
        "state_code": "BDF",
        "type": null
      },
      {
        "id": 2311,
        "name": "Belfast district",
        "state_code": "BFS",
        "type": null
      },
      {
        "id": 2425,
        "name": "Birmingham",
        "state_code": "BIR",
        "type": null
      },
      {
        "id": 2329,
        "name": "Blackburn with Darwen",
        "state_code": "BBD",
        "type": null
      },
      {
        "id": 2451,
        "name": "Blackpool",
        "state_code": "BPL",
        "type": null
      },
      {
        "id": 2530,
        "name": "Blaenau Gwent County Borough",
        "state_code": "BGW",
        "type": null
      },
      {
        "id": 2504,
        "name": "Bolton",
        "state_code": "BOL",
        "type": null
      },
      {
        "id": 2342,
        "name": "Bournemouth",
        "state_code": "BMH",
        "type": null
      },
      {
        "id": 2470,
        "name": "Bracknell Forest",
        "state_code": "BRC",
        "type": null
      },
      {
        "id": 2529,
        "name": "Bradford",
        "state_code": "BRD",
        "type": null
      },
      {
        "id": 2452,
        "name": "Bridgend County Borough",
        "state_code": "BGE",
        "type": null
      },
      {
        "id": 2395,
        "name": "Brighton and Hove",
        "state_code": "BNH",
        "type": null
      },
      {
        "id": 2405,
        "name": "Buckinghamshire",
        "state_code": "BKM",
        "type": null
      },
      {
        "id": 2459,
        "name": "Bury",
        "state_code": "BUR",
        "type": null
      },
      {
        "id": 2298,
        "name": "Caerphilly County Borough",
        "state_code": "CAY",
        "type": null
      },
      {
        "id": 2517,
        "name": "Calderdale",
        "state_code": "CLD",
        "type": null
      },
      {
        "id": 2423,
        "name": "Cambridgeshire",
        "state_code": "CAM",
        "type": null
      },
      {
        "id": 2484,
        "name": "Carmarthenshire",
        "state_code": "CMN",
        "type": null
      },
      {
        "id": 2439,
        "name": "Carrickfergus Borough Council",
        "state_code": "CKF",
        "type": null
      },
      {
        "id": 2525,
        "name": "Castlereagh",
        "state_code": "CSR",
        "type": null
      },
      {
        "id": 2316,
        "name": "Causeway Coast and Glens",
        "state_code": "CCG",
        "type": null
      },
      {
        "id": 2303,
        "name": "Central Bedfordshire",
        "state_code": "CBF",
        "type": null
      },
      {
        "id": 2509,
        "name": "Ceredigion",
        "state_code": "CGN",
        "type": null
      },
      {
        "id": 2444,
        "name": "Cheshire East",
        "state_code": "CHE",
        "type": null
      },
      {
        "id": 2442,
        "name": "Cheshire West and Chester",
        "state_code": "CHW",
        "type": null
      },
      {
        "id": 2528,
        "name": "City and County of Cardiff",
        "state_code": "CRF",
        "type": null
      },
      {
        "id": 2433,
        "name": "City and County of Swansea",
        "state_code": "SWA",
        "type": null
      },
      {
        "id": 2413,
        "name": "City of Bristol",
        "state_code": "BST",
        "type": null
      },
      {
        "id": 2485,
        "name": "City of Derby",
        "state_code": "DER",
        "type": null
      },
      {
        "id": 2475,
        "name": "City of Kingston upon Hull",
        "state_code": "KHL",
        "type": null
      },
      {
        "id": 2318,
        "name": "City of Leicester",
        "state_code": "LCE",
        "type": null
      },
      {
        "id": 2424,
        "name": "City of London",
        "state_code": "LND",
        "type": null
      },
      {
        "id": 2359,
        "name": "City of Nottingham",
        "state_code": "NGM",
        "type": null
      },
      {
        "id": 2297,
        "name": "City of Peterborough",
        "state_code": "PTE",
        "type": null
      },
      {
        "id": 2514,
        "name": "City of Plymouth",
        "state_code": "PLY",
        "type": null
      },
      {
        "id": 2305,
        "name": "City of Portsmouth",
        "state_code": "POR",
        "type": null
      },
      {
        "id": 2294,
        "name": "City of Southampton",
        "state_code": "STH",
        "type": null
      },
      {
        "id": 2506,
        "name": "City of Stoke-on-Trent",
        "state_code": "STE",
        "type": null
      },
      {
        "id": 2372,
        "name": "City of Sunderland",
        "state_code": "SND",
        "type": null
      },
      {
        "id": 2357,
        "name": "City of Westminster",
        "state_code": "WSM",
        "type": null
      },
      {
        "id": 2489,
        "name": "City of Wolverhampton",
        "state_code": "WLV",
        "type": null
      },
      {
        "id": 2426,
        "name": "City of York",
        "state_code": "YOR",
        "type": null
      },
      {
        "id": 2450,
        "name": "Clackmannanshire",
        "state_code": "CLK",
        "type": null
      },
      {
        "id": 2461,
        "name": "Coleraine Borough Council",
        "state_code": "CLR",
        "type": null
      },
      {
        "id": 2352,
        "name": "Conwy County Borough",
        "state_code": "CWY",
        "type": null
      },
      {
        "id": 2445,
        "name": "Cookstown District Council",
        "state_code": "CKT",
        "type": null
      },
      {
        "id": 2312,
        "name": "Cornwall",
        "state_code": "CON",
        "type": null
      },
      {
        "id": 2406,
        "name": "County Durham",
        "state_code": "DUR",
        "type": null
      },
      {
        "id": 2438,
        "name": "Coventry",
        "state_code": "COV",
        "type": null
      },
      {
        "id": 2449,
        "name": "Craigavon Borough Council",
        "state_code": "CGV",
        "type": null
      },
      {
        "id": 2334,
        "name": "Cumbria",
        "state_code": "CMA",
        "type": null
      },
      {
        "id": 2389,
        "name": "Darlington",
        "state_code": "DAL",
        "type": null
      },
      {
        "id": 2497,
        "name": "Denbighshire",
        "state_code": "DEN",
        "type": null
      },
      {
        "id": 2403,
        "name": "Derbyshire",
        "state_code": "DBY",
        "type": null
      },
      {
        "id": 2446,
        "name": "Derry City and Strabane",
        "state_code": "DRS",
        "type": null
      },
      {
        "id": 2417,
        "name": "Derry City Council",
        "state_code": "DRY",
        "type": null
      },
      {
        "id": 2491,
        "name": "Devon",
        "state_code": "DEV",
        "type": null
      },
      {
        "id": 2364,
        "name": "Doncaster",
        "state_code": "DNC",
        "type": null
      },
      {
        "id": 2345,
        "name": "Dorset",
        "state_code": "DOR",
        "type": null
      },
      {
        "id": 2304,
        "name": "Down District Council",
        "state_code": "DOW",
        "type": null
      },
      {
        "id": 2457,
        "name": "Dudley",
        "state_code": "DUD",
        "type": null
      },
      {
        "id": 2415,
        "name": "Dumfries and Galloway",
        "state_code": "DGY",
        "type": null
      },
      {
        "id": 2511,
        "name": "Dundee",
        "state_code": "DND",
        "type": null
      },
      {
        "id": 2508,
        "name": "Dungannon and South Tyrone Borough Council",
        "state_code": "DGN",
        "type": null
      },
      {
        "id": 2374,
        "name": "East Ayrshire",
        "state_code": "EAY",
        "type": null
      },
      {
        "id": 2454,
        "name": "East Dunbartonshire",
        "state_code": "EDU",
        "type": null
      },
      {
        "id": 2462,
        "name": "East Lothian",
        "state_code": "ELN",
        "type": null
      },
      {
        "id": 2333,
        "name": "East Renfrewshire",
        "state_code": "ERW",
        "type": null
      },
      {
        "id": 2370,
        "name": "East Riding of Yorkshire",
        "state_code": "ERY",
        "type": null
      },
      {
        "id": 2414,
        "name": "East Sussex",
        "state_code": "ESX",
        "type": null
      },
      {
        "id": 2428,
        "name": "Edinburgh",
        "state_code": "EDH",
        "type": null
      },
      {
        "id": 2336,
        "name": "England",
        "state_code": "ENG",
        "type": null
      },
      {
        "id": 2410,
        "name": "Essex",
        "state_code": "ESS",
        "type": null
      },
      {
        "id": 2344,
        "name": "Falkirk",
        "state_code": "FAL",
        "type": null
      },
      {
        "id": 2366,
        "name": "Fermanagh and Omagh",
        "state_code": "FMO",
        "type": null
      },
      {
        "id": 2531,
        "name": "Fermanagh District Council",
        "state_code": "FER",
        "type": null
      },
      {
        "id": 2479,
        "name": "Fife",
        "state_code": "FIF",
        "type": null
      },
      {
        "id": 2437,
        "name": "Flintshire",
        "state_code": "FLN",
        "type": null
      },
      {
        "id": 2431,
        "name": "Gateshead",
        "state_code": "GAT",
        "type": null
      },
      {
        "id": 2404,
        "name": "Glasgow",
        "state_code": "GLG",
        "type": null
      },
      {
        "id": 2373,
        "name": "Gloucestershire",
        "state_code": "GLS",
        "type": null
      },
      {
        "id": 2379,
        "name": "Gwynedd",
        "state_code": "GWN",
        "type": null
      },
      {
        "id": 2466,
        "name": "Halton",
        "state_code": "HAL",
        "type": null
      },
      {
        "id": 2435,
        "name": "Hampshire",
        "state_code": "HAM",
        "type": null
      },
      {
        "id": 2309,
        "name": "Hartlepool",
        "state_code": "HPL",
        "type": null
      },
      {
        "id": 2500,
        "name": "Herefordshire",
        "state_code": "HEF",
        "type": null
      },
      {
        "id": 2369,
        "name": "Hertfordshire",
        "state_code": "HRT",
        "type": null
      },
      {
        "id": 2383,
        "name": "Highland",
        "state_code": "HLD",
        "type": null
      },
      {
        "id": 2388,
        "name": "Inverclyde",
        "state_code": "IVC",
        "type": null
      },
      {
        "id": 2289,
        "name": "Isle of Wight",
        "state_code": "IOW",
        "type": null
      },
      {
        "id": 2343,
        "name": "Isles of Scilly",
        "state_code": "IOS",
        "type": null
      },
      {
        "id": 2464,
        "name": "Kent",
        "state_code": "KEN",
        "type": null
      },
      {
        "id": 2371,
        "name": "Kirklees",
        "state_code": "KIR",
        "type": null
      },
      {
        "id": 2330,
        "name": "Knowsley",
        "state_code": "KWL",
        "type": null
      },
      {
        "id": 2495,
        "name": "Lancashire",
        "state_code": "LAN",
        "type": null
      },
      {
        "id": 2515,
        "name": "Larne Borough Council",
        "state_code": "LRN",
        "type": null
      },
      {
        "id": 2503,
        "name": "Leeds",
        "state_code": "LDS",
        "type": null
      },
      {
        "id": 2516,
        "name": "Leicestershire",
        "state_code": "LEC",
        "type": null
      },
      {
        "id": 2382,
        "name": "Limavady Borough Council",
        "state_code": "LMV",
        "type": null
      },
      {
        "id": 2355,
        "name": "Lincolnshire",
        "state_code": "LIN",
        "type": null
      },
      {
        "id": 2460,
        "name": "Lisburn and Castlereagh",
        "state_code": "LBC",
        "type": null
      },
      {
        "id": 2494,
        "name": "Lisburn City Council",
        "state_code": "LSB",
        "type": null
      },
      {
        "id": 2340,
        "name": "Liverpool",
        "state_code": "LIV",
        "type": null
      },
      {
        "id": 2356,
        "name": "London Borough of Barking and Dagenham",
        "state_code": "BDG",
        "type": null
      },
      {
        "id": 2520,
        "name": "London Borough of Barnet",
        "state_code": "BNE",
        "type": null
      },
      {
        "id": 2307,
        "name": "London Borough of Bexley",
        "state_code": "BEX",
        "type": null
      },
      {
        "id": 2291,
        "name": "London Borough of Brent",
        "state_code": "BEN",
        "type": null
      },
      {
        "id": 2490,
        "name": "London Borough of Bromley",
        "state_code": "BRY",
        "type": null
      },
      {
        "id": 2349,
        "name": "London Borough of Camden",
        "state_code": "CMD",
        "type": null
      },
      {
        "id": 2512,
        "name": "London Borough of Croydon",
        "state_code": "CRY",
        "type": null
      },
      {
        "id": 2532,
        "name": "London Borough of Ealing",
        "state_code": "EAL",
        "type": null
      },
      {
        "id": 2476,
        "name": "London Borough of Enfield",
        "state_code": "ENF",
        "type": null
      },
      {
        "id": 2411,
        "name": "London Borough of Hackney",
        "state_code": "HCK",
        "type": null
      },
      {
        "id": 2448,
        "name": "London Borough of Hammersmith and Fulham",
        "state_code": "HMF",
        "type": null
      },
      {
        "id": 2306,
        "name": "London Borough of Haringey",
        "state_code": "HRY",
        "type": null
      },
      {
        "id": 2385,
        "name": "London Borough of Harrow",
        "state_code": "HRW",
        "type": null
      },
      {
        "id": 2347,
        "name": "London Borough of Havering",
        "state_code": "HAV",
        "type": null
      },
      {
        "id": 2376,
        "name": "London Borough of Hillingdon",
        "state_code": "HIL",
        "type": null
      },
      {
        "id": 2380,
        "name": "London Borough of Hounslow",
        "state_code": "HNS",
        "type": null
      },
      {
        "id": 2319,
        "name": "London Borough of Islington",
        "state_code": "ISL",
        "type": null
      },
      {
        "id": 2396,
        "name": "London Borough of Lambeth",
        "state_code": "LBH",
        "type": null
      },
      {
        "id": 2358,
        "name": "London Borough of Lewisham",
        "state_code": "LEW",
        "type": null
      },
      {
        "id": 2483,
        "name": "London Borough of Merton",
        "state_code": "MRT",
        "type": null
      },
      {
        "id": 2418,
        "name": "London Borough of Newham",
        "state_code": "NWM",
        "type": null
      },
      {
        "id": 2397,
        "name": "London Borough of Redbridge",
        "state_code": "RDB",
        "type": null
      },
      {
        "id": 2501,
        "name": "London Borough of Richmond upon Thames",
        "state_code": "RIC",
        "type": null
      },
      {
        "id": 2432,
        "name": "London Borough of Southwark",
        "state_code": "SWK",
        "type": null
      },
      {
        "id": 2313,
        "name": "London Borough of Sutton",
        "state_code": "STN",
        "type": null
      },
      {
        "id": 2390,
        "name": "London Borough of Tower Hamlets",
        "state_code": "TWH",
        "type": null
      },
      {
        "id": 2326,
        "name": "London Borough of Waltham Forest",
        "state_code": "WFT",
        "type": null
      },
      {
        "id": 2434,
        "name": "London Borough of Wandsworth",
        "state_code": "WND",
        "type": null
      },
      {
        "id": 2322,
        "name": "Magherafelt District Council",
        "state_code": "MFT",
        "type": null
      },
      {
        "id": 2398,
        "name": "Manchester",
        "state_code": "MAN",
        "type": null
      },
      {
        "id": 2381,
        "name": "Medway",
        "state_code": "MDW",
        "type": null
      },
      {
        "id": 2328,
        "name": "Merthyr Tydfil County Borough",
        "state_code": "MTY",
        "type": null
      },
      {
        "id": 2320,
        "name": "Metropolitan Borough of Wigan",
        "state_code": "WGN",
        "type": null
      },
      {
        "id": 2429,
        "name": "Mid and East Antrim",
        "state_code": "MEA",
        "type": null
      },
      {
        "id": 2399,
        "name": "Mid Ulster",
        "state_code": "MUL",
        "type": null
      },
      {
        "id": 2332,
        "name": "Middlesbrough",
        "state_code": "MDB",
        "type": null
      },
      {
        "id": 2519,
        "name": "Midlothian",
        "state_code": "MLN",
        "type": null
      },
      {
        "id": 2416,
        "name": "Milton Keynes",
        "state_code": "MIK",
        "type": null
      },
      {
        "id": 2402,
        "name": "Monmouthshire",
        "state_code": "MON",
        "type": null
      },
      {
        "id": 2360,
        "name": "Moray",
        "state_code": "MRY",
        "type": null
      },
      {
        "id": 2348,
        "name": "Moyle District Council",
        "state_code": "MYL",
        "type": null
      },
      {
        "id": 2351,
        "name": "Neath Port Talbot County Borough",
        "state_code": "NTL",
        "type": null
      },
      {
        "id": 2458,
        "name": "Newcastle upon Tyne",
        "state_code": "NET",
        "type": null
      },
      {
        "id": 2524,
        "name": "Newport",
        "state_code": "NWP",
        "type": null
      },
      {
        "id": 2350,
        "name": "Newry and Mourne District Council",
        "state_code": "NYM",
        "type": null
      },
      {
        "id": 2534,
        "name": "Newry, Mourne and Down",
        "state_code": "NMD",
        "type": null
      },
      {
        "id": 2317,
        "name": "Newtownabbey Borough Council",
        "state_code": "NTA",
        "type": null
      },
      {
        "id": 2473,
        "name": "Norfolk",
        "state_code": "NFK",
        "type": null
      },
      {
        "id": 2535,
        "name": "North Ayrshire",
        "state_code": "NAY",
        "type": null
      },
      {
        "id": 2513,
        "name": "North Down Borough Council",
        "state_code": "NDN",
        "type": null
      },
      {
        "id": 2384,
        "name": "North East Lincolnshire",
        "state_code": "NEL",
        "type": null
      },
      {
        "id": 2487,
        "name": "North Lanarkshire",
        "state_code": "NLK",
        "type": null
      },
      {
        "id": 2453,
        "name": "North Lincolnshire",
        "state_code": "NLN",
        "type": null
      },
      {
        "id": 2430,
        "name": "North Somerset",
        "state_code": "NSM",
        "type": null
      },
      {
        "id": 2521,
        "name": "North Tyneside",
        "state_code": "NTY",
        "type": null
      },
      {
        "id": 2522,
        "name": "North Yorkshire",
        "state_code": "NYK",
        "type": null
      },
      {
        "id": 2480,
        "name": "Northamptonshire",
        "state_code": "NTH",
        "type": null
      },
      {
        "id": 2337,
        "name": "Northern Ireland",
        "state_code": "NIR",
        "type": null
      },
      {
        "id": 2365,
        "name": "Northumberland",
        "state_code": "NBL",
        "type": null
      },
      {
        "id": 2456,
        "name": "Nottinghamshire",
        "state_code": "NTT",
        "type": null
      },
      {
        "id": 2477,
        "name": "Oldham",
        "state_code": "OLD",
        "type": null
      },
      {
        "id": 2314,
        "name": "Omagh District Council",
        "state_code": "OMH",
        "type": null
      },
      {
        "id": 2474,
        "name": "Orkney Islands",
        "state_code": "ORK",
        "type": null
      },
      {
        "id": 2353,
        "name": "Outer Hebrides",
        "state_code": "ELS",
        "type": null
      },
      {
        "id": 2321,
        "name": "Oxfordshire",
        "state_code": "OXF",
        "type": null
      },
      {
        "id": 2486,
        "name": "Pembrokeshire",
        "state_code": "PEM",
        "type": null
      },
      {
        "id": 2325,
        "name": "Perth and Kinross",
        "state_code": "PKN",
        "type": null
      },
      {
        "id": 2302,
        "name": "Poole",
        "state_code": "POL",
        "type": null
      },
      {
        "id": 2441,
        "name": "Powys",
        "state_code": "POW",
        "type": null
      },
      {
        "id": 2455,
        "name": "Reading",
        "state_code": "RDG",
        "type": null
      },
      {
        "id": 2527,
        "name": "Redcar and Cleveland",
        "state_code": "RCC",
        "type": null
      },
      {
        "id": 2443,
        "name": "Renfrewshire",
        "state_code": "RFW",
        "type": null
      },
      {
        "id": 2301,
        "name": "Rhondda Cynon Taf",
        "state_code": "RCT",
        "type": null
      },
      {
        "id": 2327,
        "name": "Rochdale",
        "state_code": "RCH",
        "type": null
      },
      {
        "id": 2308,
        "name": "Rotherham",
        "state_code": "ROT",
        "type": null
      },
      {
        "id": 2492,
        "name": "Royal Borough of Greenwich",
        "state_code": "GRE",
        "type": null
      },
      {
        "id": 2368,
        "name": "Royal Borough of Kensington and Chelsea",
        "state_code": "KEC",
        "type": null
      },
      {
        "id": 2481,
        "name": "Royal Borough of Kingston upon Thames",
        "state_code": "KTT",
        "type": null
      },
      {
        "id": 2472,
        "name": "Rutland",
        "state_code": "RUT",
        "type": null
      },
      {
        "id": 2502,
        "name": "Saint Helena",
        "state_code": "SH-HL",
        "type": null
      },
      {
        "id": 2493,
        "name": "Salford",
        "state_code": "SLF",
        "type": null
      },
      {
        "id": 2341,
        "name": "Sandwell",
        "state_code": "SAW",
        "type": null
      },
      {
        "id": 2335,
        "name": "Scotland",
        "state_code": "SCT",
        "type": null
      },
      {
        "id": 2346,
        "name": "Scottish Borders",
        "state_code": "SCB",
        "type": null
      },
      {
        "id": 2518,
        "name": "Sefton",
        "state_code": "SFT",
        "type": null
      },
      {
        "id": 2295,
        "name": "Sheffield",
        "state_code": "SHF",
        "type": null
      },
      {
        "id": 2300,
        "name": "Shetland Islands",
        "state_code": "ZET",
        "type": null
      },
      {
        "id": 2407,
        "name": "Shropshire",
        "state_code": "SHR",
        "type": null
      },
      {
        "id": 2427,
        "name": "Slough",
        "state_code": "SLG",
        "type": null
      },
      {
        "id": 2469,
        "name": "Solihull",
        "state_code": "SOL",
        "type": null
      },
      {
        "id": 2386,
        "name": "Somerset",
        "state_code": "SOM",
        "type": null
      },
      {
        "id": 2377,
        "name": "South Ayrshire",
        "state_code": "SAY",
        "type": null
      },
      {
        "id": 2400,
        "name": "South Gloucestershire",
        "state_code": "SGC",
        "type": null
      },
      {
        "id": 2362,
        "name": "South Lanarkshire",
        "state_code": "SLK",
        "type": null
      },
      {
        "id": 2409,
        "name": "South Tyneside",
        "state_code": "STY",
        "type": null
      },
      {
        "id": 2323,
        "name": "Southend-on-Sea",
        "state_code": "SOS",
        "type": null
      },
      {
        "id": 2290,
        "name": "St Helens",
        "state_code": "SHN",
        "type": null
      },
      {
        "id": 2447,
        "name": "Staffordshire",
        "state_code": "STS",
        "type": null
      },
      {
        "id": 2488,
        "name": "Stirling",
        "state_code": "STG",
        "type": null
      },
      {
        "id": 2394,
        "name": "Stockport",
        "state_code": "SKP",
        "type": null
      },
      {
        "id": 2421,
        "name": "Stockton-on-Tees",
        "state_code": "STT",
        "type": null
      },
      {
        "id": 2393,
        "name": "Strabane District Council",
        "state_code": "STB",
        "type": null
      },
      {
        "id": 2467,
        "name": "Suffolk",
        "state_code": "SFK",
        "type": null
      },
      {
        "id": 2526,
        "name": "Surrey",
        "state_code": "SRY",
        "type": null
      },
      {
        "id": 2422,
        "name": "Swindon",
        "state_code": "SWD",
        "type": null
      },
      {
        "id": 2367,
        "name": "Tameside",
        "state_code": "TAM",
        "type": null
      },
      {
        "id": 2310,
        "name": "Telford and Wrekin",
        "state_code": "TFW",
        "type": null
      },
      {
        "id": 2468,
        "name": "Thurrock",
        "state_code": "THR",
        "type": null
      },
      {
        "id": 2478,
        "name": "Torbay",
        "state_code": "TOB",
        "type": null
      },
      {
        "id": 2496,
        "name": "Torfaen",
        "state_code": "TOF",
        "type": null
      },
      {
        "id": 2293,
        "name": "Trafford",
        "state_code": "TRF",
        "type": null
      },
      {
        "id": 2375,
        "name": "United Kingdom",
        "state_code": "UKM",
        "type": null
      },
      {
        "id": 2299,
        "name": "Vale of Glamorgan",
        "state_code": "VGL",
        "type": null
      },
      {
        "id": 2465,
        "name": "Wakefield",
        "state_code": "WKF",
        "type": null
      },
      {
        "id": 2338,
        "name": "Wales",
        "state_code": "WLS",
        "type": null
      },
      {
        "id": 2292,
        "name": "Walsall",
        "state_code": "WLL",
        "type": null
      },
      {
        "id": 2420,
        "name": "Warrington",
        "state_code": "WRT",
        "type": null
      },
      {
        "id": 2505,
        "name": "Warwickshire",
        "state_code": "WAR",
        "type": null
      },
      {
        "id": 2471,
        "name": "West Berkshire",
        "state_code": "WBK",
        "type": null
      },
      {
        "id": 2440,
        "name": "West Dunbartonshire",
        "state_code": "WDU",
        "type": null
      },
      {
        "id": 2354,
        "name": "West Lothian",
        "state_code": "WLN",
        "type": null
      },
      {
        "id": 2296,
        "name": "West Sussex",
        "state_code": "WSX",
        "type": null
      },
      {
        "id": 2391,
        "name": "Wiltshire",
        "state_code": "WIL",
        "type": null
      },
      {
        "id": 2482,
        "name": "Windsor and Maidenhead",
        "state_code": "WNM",
        "type": null
      },
      {
        "id": 2408,
        "name": "Wirral",
        "state_code": "WRL",
        "type": null
      },
      {
        "id": 2419,
        "name": "Wokingham",
        "state_code": "WOK",
        "type": null
      },
      {
        "id": 2510,
        "name": "Worcestershire",
        "state_code": "WOR",
        "type": null
      },
      {
        "id": 2436,
        "name": "Wrexham County Borough",
        "state_code": "WRX",
        "type": null
      }
    ]
  },
  {
    "name": "United States",
    "iso3": "USA",
    "iso2": "US",
    "numeric_code": "840",
    "phone_code": "1",
    "capital": "Washington",
    "currency": "USD",
    "currency_name": "United States dollar",
    "currency_symbol": "$",
    "tld": ".us",
    "native": "United States",
    "region": "Americas",
    "subregion": "Northern America",
    "emoji": "🇺🇸",
    "emojiU": "U+1F1FA U+1F1F8",
    "states": [
      {
        "id": 1456,
        "name": "Alabama",
        "state_code": "AL",
        "type": null
      },
      {
        "id": 1400,
        "name": "Alaska",
        "state_code": "AK",
        "type": null
      },
      {
        "id": 1424,
        "name": "American Samoa",
        "state_code": "AS",
        "type": null
      },
      {
        "id": 1434,
        "name": "Arizona",
        "state_code": "AZ",
        "type": null
      },
      {
        "id": 1444,
        "name": "Arkansas",
        "state_code": "AR",
        "type": null
      },
      {
        "id": 1402,
        "name": "Baker Island",
        "state_code": "UM-81",
        "type": null
      },
      {
        "id": 1416,
        "name": "California",
        "state_code": "CA",
        "type": null
      },
      {
        "id": 1450,
        "name": "Colorado",
        "state_code": "CO",
        "type": null
      },
      {
        "id": 1435,
        "name": "Connecticut",
        "state_code": "CT",
        "type": null
      },
      {
        "id": 1399,
        "name": "Delaware",
        "state_code": "DE",
        "type": null
      },
      {
        "id": 1437,
        "name": "District of Columbia",
        "state_code": "DC",
        "type": null
      },
      {
        "id": 1436,
        "name": "Florida",
        "state_code": "FL",
        "type": null
      },
      {
        "id": 1455,
        "name": "Georgia",
        "state_code": "GA",
        "type": null
      },
      {
        "id": 1412,
        "name": "Guam",
        "state_code": "GU",
        "type": null
      },
      {
        "id": 1411,
        "name": "Hawaii",
        "state_code": "HI",
        "type": null
      },
      {
        "id": 1398,
        "name": "Howland Island",
        "state_code": "UM-84",
        "type": null
      },
      {
        "id": 1460,
        "name": "Idaho",
        "state_code": "ID",
        "type": null
      },
      {
        "id": 1425,
        "name": "Illinois",
        "state_code": "IL",
        "type": null
      },
      {
        "id": 1440,
        "name": "Indiana",
        "state_code": "IN",
        "type": null
      },
      {
        "id": 1459,
        "name": "Iowa",
        "state_code": "IA",
        "type": null
      },
      {
        "id": 1410,
        "name": "Jarvis Island",
        "state_code": "UM-86",
        "type": null
      },
      {
        "id": 1428,
        "name": "Johnston Atoll",
        "state_code": "UM-67",
        "type": null
      },
      {
        "id": 1406,
        "name": "Kansas",
        "state_code": "KS",
        "type": null
      },
      {
        "id": 1419,
        "name": "Kentucky",
        "state_code": "KY",
        "type": null
      },
      {
        "id": 1403,
        "name": "Kingman Reef",
        "state_code": "UM-89",
        "type": null
      },
      {
        "id": 1457,
        "name": "Louisiana",
        "state_code": "LA",
        "type": null
      },
      {
        "id": 1453,
        "name": "Maine",
        "state_code": "ME",
        "type": null
      },
      {
        "id": 1401,
        "name": "Maryland",
        "state_code": "MD",
        "type": null
      },
      {
        "id": 1433,
        "name": "Massachusetts",
        "state_code": "MA",
        "type": null
      },
      {
        "id": 1426,
        "name": "Michigan",
        "state_code": "MI",
        "type": null
      },
      {
        "id": 1438,
        "name": "Midway Atoll",
        "state_code": "UM-71",
        "type": null
      },
      {
        "id": 1420,
        "name": "Minnesota",
        "state_code": "MN",
        "type": null
      },
      {
        "id": 1430,
        "name": "Mississippi",
        "state_code": "MS",
        "type": null
      },
      {
        "id": 1451,
        "name": "Missouri",
        "state_code": "MO",
        "type": null
      },
      {
        "id": 1446,
        "name": "Montana",
        "state_code": "MT",
        "type": null
      },
      {
        "id": 1439,
        "name": "Navassa Island",
        "state_code": "UM-76",
        "type": null
      },
      {
        "id": 1408,
        "name": "Nebraska",
        "state_code": "NE",
        "type": null
      },
      {
        "id": 1458,
        "name": "Nevada",
        "state_code": "NV",
        "type": null
      },
      {
        "id": 1404,
        "name": "New Hampshire",
        "state_code": "NH",
        "type": null
      },
      {
        "id": 1417,
        "name": "New Jersey",
        "state_code": "NJ",
        "type": null
      },
      {
        "id": 1423,
        "name": "New Mexico",
        "state_code": "NM",
        "type": null
      },
      {
        "id": 1452,
        "name": "New York",
        "state_code": "NY",
        "type": null
      },
      {
        "id": 1447,
        "name": "North Carolina",
        "state_code": "NC",
        "type": null
      },
      {
        "id": 1418,
        "name": "North Dakota",
        "state_code": "ND",
        "type": null
      },
      {
        "id": 1431,
        "name": "Northern Mariana Islands",
        "state_code": "MP",
        "type": null
      },
      {
        "id": 4851,
        "name": "Ohio",
        "state_code": "OH",
        "type": null
      },
      {
        "id": 1421,
        "name": "Oklahoma",
        "state_code": "OK",
        "type": null
      },
      {
        "id": 1415,
        "name": "Oregon",
        "state_code": "OR",
        "type": null
      },
      {
        "id": 1448,
        "name": "Palmyra Atoll",
        "state_code": "UM-95",
        "type": null
      },
      {
        "id": 1422,
        "name": "Pennsylvania",
        "state_code": "PA",
        "type": null
      },
      {
        "id": 1449,
        "name": "Puerto Rico",
        "state_code": "PR",
        "type": null
      },
      {
        "id": 1461,
        "name": "Rhode Island",
        "state_code": "RI",
        "type": null
      },
      {
        "id": 1443,
        "name": "South Carolina",
        "state_code": "SC",
        "type": null
      },
      {
        "id": 1445,
        "name": "South Dakota",
        "state_code": "SD",
        "type": null
      },
      {
        "id": 1454,
        "name": "Tennessee",
        "state_code": "TN",
        "type": null
      },
      {
        "id": 1407,
        "name": "Texas",
        "state_code": "TX",
        "type": null
      },
      {
        "id": 1432,
        "name": "United States Minor Outlying Islands",
        "state_code": "UM",
        "type": null
      },
      {
        "id": 1413,
        "name": "United States Virgin Islands",
        "state_code": "VI",
        "type": null
      },
      {
        "id": 1414,
        "name": "Utah",
        "state_code": "UT",
        "type": null
      },
      {
        "id": 1409,
        "name": "Vermont",
        "state_code": "VT",
        "type": null
      },
      {
        "id": 1427,
        "name": "Virginia",
        "state_code": "VA",
        "type": null
      },
      {
        "id": 1405,
        "name": "Wake Island",
        "state_code": "UM-79",
        "type": null
      },
      {
        "id": 1462,
        "name": "Washington",
        "state_code": "WA",
        "type": null
      },
      {
        "id": 1429,
        "name": "West Virginia",
        "state_code": "WV",
        "type": null
      },
      {
        "id": 1441,
        "name": "Wisconsin",
        "state_code": "WI",
        "type": null
      },
      {
        "id": 1442,
        "name": "Wyoming",
        "state_code": "WY",
        "type": null
      }
    ]
  },
  {
    "name": "United States Minor Outlying Islands",
    "iso3": "UMI",
    "iso2": "UM",
    "numeric_code": "581",
    "phone_code": "1",
    "capital": "",
    "currency": "USD",
    "currency_name": "United States dollar",
    "currency_symbol": "$",
    "tld": ".us",
    "native": "United States Minor Outlying Islands",
    "region": "Americas",
    "subregion": "Northern America",
    "emoji": "🇺🇲",
    "emojiU": "U+1F1FA U+1F1F2",
    "states": []
  },
  {
    "name": "Uruguay",
    "iso3": "URY",
    "iso2": "UY",
    "numeric_code": "858",
    "phone_code": "598",
    "capital": "Montevideo",
    "currency": "UYU",
    "currency_name": "Uruguayan peso",
    "currency_symbol": "$",
    "tld": ".uy",
    "native": "Uruguay",
    "region": "Americas",
    "subregion": "South America",
    "emoji": "🇺🇾",
    "emojiU": "U+1F1FA U+1F1FE",
    "states": [
      {
        "id": 3205,
        "name": "Artigas Department",
        "state_code": "AR",
        "type": null
      },
      {
        "id": 3213,
        "name": "Canelones Department",
        "state_code": "CA",
        "type": null
      },
      {
        "id": 3211,
        "name": "Cerro Largo Department",
        "state_code": "CL",
        "type": null
      },
      {
        "id": 3208,
        "name": "Colonia Department",
        "state_code": "CO",
        "type": null
      },
      {
        "id": 3209,
        "name": "Durazno Department",
        "state_code": "DU",
        "type": null
      },
      {
        "id": 3203,
        "name": "Flores Department",
        "state_code": "FS",
        "type": null
      },
      {
        "id": 3217,
        "name": "Florida Department",
        "state_code": "FD",
        "type": null
      },
      {
        "id": 3215,
        "name": "Lavalleja Department",
        "state_code": "LA",
        "type": null
      },
      {
        "id": 3206,
        "name": "Maldonado Department",
        "state_code": "MA",
        "type": null
      },
      {
        "id": 3218,
        "name": "Montevideo Department",
        "state_code": "MO",
        "type": null
      },
      {
        "id": 3212,
        "name": "Paysandú Department",
        "state_code": "PA",
        "type": null
      },
      {
        "id": 3210,
        "name": "Río Negro Department",
        "state_code": "RN",
        "type": null
      },
      {
        "id": 3207,
        "name": "Rivera Department",
        "state_code": "RV",
        "type": null
      },
      {
        "id": 3216,
        "name": "Rocha Department",
        "state_code": "RO",
        "type": null
      },
      {
        "id": 3220,
        "name": "Salto Department",
        "state_code": "SA",
        "type": null
      },
      {
        "id": 3204,
        "name": "San José Department",
        "state_code": "SJ",
        "type": null
      },
      {
        "id": 3219,
        "name": "Soriano Department",
        "state_code": "SO",
        "type": null
      },
      {
        "id": 3221,
        "name": "Tacuarembó Department",
        "state_code": "TA",
        "type": null
      },
      {
        "id": 3214,
        "name": "Treinta y Tres Department",
        "state_code": "TT",
        "type": null
      }
    ]
  },
  {
    "name": "Uzbekistan",
    "iso3": "UZB",
    "iso2": "UZ",
    "numeric_code": "860",
    "phone_code": "998",
    "capital": "Tashkent",
    "currency": "UZS",
    "currency_name": "Uzbekistani soʻm",
    "currency_symbol": "лв",
    "tld": ".uz",
    "native": "O‘zbekiston",
    "region": "Asia",
    "subregion": "Central Asia",
    "emoji": "🇺🇿",
    "emojiU": "U+1F1FA U+1F1FF",
    "states": [
      {
        "id": 2540,
        "name": "Andijan Region",
        "state_code": "AN",
        "type": null
      },
      {
        "id": 2541,
        "name": "Bukhara Region",
        "state_code": "BU",
        "type": null
      },
      {
        "id": 2538,
        "name": "Fergana Region",
        "state_code": "FA",
        "type": null
      },
      {
        "id": 2545,
        "name": "Jizzakh Region",
        "state_code": "JI",
        "type": null
      },
      {
        "id": 2548,
        "name": "Karakalpakstan",
        "state_code": "QR",
        "type": null
      },
      {
        "id": 2537,
        "name": "Namangan Region",
        "state_code": "NG",
        "type": null
      },
      {
        "id": 2542,
        "name": "Navoiy Region",
        "state_code": "NW",
        "type": null
      },
      {
        "id": 2543,
        "name": "Qashqadaryo Region",
        "state_code": "QA",
        "type": null
      },
      {
        "id": 2544,
        "name": "Samarqand Region",
        "state_code": "SA",
        "type": null
      },
      {
        "id": 2547,
        "name": "Sirdaryo Region",
        "state_code": "SI",
        "type": null
      },
      {
        "id": 2546,
        "name": "Surxondaryo Region",
        "state_code": "SU",
        "type": null
      },
      {
        "id": 2536,
        "name": "Tashkent",
        "state_code": "TK",
        "type": null
      },
      {
        "id": 2549,
        "name": "Tashkent Region",
        "state_code": "TO",
        "type": null
      },
      {
        "id": 2539,
        "name": "Xorazm Region",
        "state_code": "XO",
        "type": null
      }
    ]
  },
  {
    "name": "Vanuatu",
    "iso3": "VUT",
    "iso2": "VU",
    "numeric_code": "548",
    "phone_code": "678",
    "capital": "Port Vila",
    "currency": "VUV",
    "currency_name": "Vanuatu vatu",
    "currency_symbol": "VT",
    "tld": ".vu",
    "native": "Vanuatu",
    "region": "Oceania",
    "subregion": "Melanesia",
    "emoji": "🇻🇺",
    "emojiU": "U+1F1FB U+1F1FA",
    "states": [
      {
        "id": 4775,
        "name": "Malampa",
        "state_code": "MAP",
        "type": null
      },
      {
        "id": 4773,
        "name": "Penama",
        "state_code": "PAM",
        "type": null
      },
      {
        "id": 4776,
        "name": "Sanma",
        "state_code": "SAM",
        "type": null
      },
      {
        "id": 4774,
        "name": "Shefa",
        "state_code": "SEE",
        "type": null
      },
      {
        "id": 4777,
        "name": "Tafea",
        "state_code": "TAE",
        "type": null
      },
      {
        "id": 4772,
        "name": "Torba",
        "state_code": "TOB",
        "type": null
      }
    ]
  },
  {
    "name": "Vatican City State (Holy See)",
    "iso3": "VAT",
    "iso2": "VA",
    "numeric_code": "336",
    "phone_code": "379",
    "capital": "Vatican City",
    "currency": "EUR",
    "currency_name": "Euro",
    "currency_symbol": "€",
    "tld": ".va",
    "native": "Vaticano",
    "region": "Europe",
    "subregion": "Southern Europe",
    "emoji": "🇻🇦",
    "emojiU": "U+1F1FB U+1F1E6",
    "states": []
  },
  {
    "name": "Venezuela",
    "iso3": "VEN",
    "iso2": "VE",
    "numeric_code": "862",
    "phone_code": "58",
    "capital": "Caracas",
    "currency": "VEF",
    "currency_name": "Bolívar",
    "currency_symbol": "Bs",
    "tld": ".ve",
    "native": "Venezuela",
    "region": "Americas",
    "subregion": "South America",
    "emoji": "🇻🇪",
    "emojiU": "U+1F1FB U+1F1EA",
    "states": [
      {
        "id": 2044,
        "name": "Amazonas",
        "state_code": "Z",
        "type": null
      },
      {
        "id": 2050,
        "name": "Anzoátegui",
        "state_code": "B",
        "type": null
      },
      {
        "id": 4856,
        "name": "Apure",
        "state_code": "C",
        "type": null
      },
      {
        "id": 2047,
        "name": "Aragua",
        "state_code": "D",
        "type": null
      },
      {
        "id": 2049,
        "name": "Barinas",
        "state_code": "E",
        "type": null
      },
      {
        "id": 2039,
        "name": "Bolívar",
        "state_code": "F",
        "type": null
      },
      {
        "id": 4855,
        "name": "Capital District",
        "state_code": "A",
        "type": null
      },
      {
        "id": 2040,
        "name": "Carabobo",
        "state_code": "G",
        "type": null
      },
      {
        "id": 2034,
        "name": "Cojedes",
        "state_code": "H",
        "type": null
      },
      {
        "id": 2051,
        "name": "Delta Amacuro",
        "state_code": "Y",
        "type": null
      },
      {
        "id": 2035,
        "name": "Falcón",
        "state_code": "I",
        "type": null
      },
      {
        "id": 2046,
        "name": "Federal Dependencies of Venezuela",
        "state_code": "W",
        "type": null
      },
      {
        "id": 2045,
        "name": "Guárico",
        "state_code": "J",
        "type": null
      },
      {
        "id": 2038,
        "name": "Lara",
        "state_code": "K",
        "type": null
      },
      {
        "id": 2053,
        "name": "Mérida",
        "state_code": "L",
        "type": null
      },
      {
        "id": 2037,
        "name": "Miranda",
        "state_code": "M",
        "type": null
      },
      {
        "id": 2054,
        "name": "Monagas",
        "state_code": "N",
        "type": null
      },
      {
        "id": 2052,
        "name": "Nueva Esparta",
        "state_code": "O",
        "type": null
      },
      {
        "id": 2036,
        "name": "Portuguesa",
        "state_code": "P",
        "type": null
      },
      {
        "id": 2056,
        "name": "Sucre",
        "state_code": "R",
        "type": null
      },
      {
        "id": 2048,
        "name": "Táchira",
        "state_code": "S",
        "type": null
      },
      {
        "id": 2043,
        "name": "Trujillo",
        "state_code": "T",
        "type": null
      },
      {
        "id": 2055,
        "name": "Vargas",
        "state_code": "X",
        "type": null
      },
      {
        "id": 2041,
        "name": "Yaracuy",
        "state_code": "U",
        "type": null
      },
      {
        "id": 2042,
        "name": "Zulia",
        "state_code": "V",
        "type": null
      }
    ]
  },
  {
    "name": "Vietnam",
    "iso3": "VNM",
    "iso2": "VN",
    "numeric_code": "704",
    "phone_code": "84",
    "capital": "Hanoi",
    "currency": "VND",
    "currency_name": "Vietnamese đồng",
    "currency_symbol": "₫",
    "tld": ".vn",
    "native": "Việt Nam",
    "region": "Asia",
    "subregion": "South-Eastern Asia",
    "emoji": "🇻🇳",
    "emojiU": "U+1F1FB U+1F1F3",
    "states": [
      {
        "id": 3794,
        "name": "An Giang",
        "state_code": "44",
        "type": null
      },
      {
        "id": 3770,
        "name": "Bà Rịa-Vũng Tàu",
        "state_code": "43",
        "type": null
      },
      {
        "id": 3815,
        "name": "Bắc Giang",
        "state_code": "54",
        "type": null
      },
      {
        "id": 3822,
        "name": "Bắc Kạn",
        "state_code": "53",
        "type": null
      },
      {
        "id": 3804,
        "name": "Bạc Liêu",
        "state_code": "55",
        "type": null
      },
      {
        "id": 3791,
        "name": "Bắc Ninh",
        "state_code": "56",
        "type": null
      },
      {
        "id": 3796,
        "name": "Bến Tre",
        "state_code": "50",
        "type": null
      },
      {
        "id": 3785,
        "name": "Bình Dương",
        "state_code": "57",
        "type": null
      },
      {
        "id": 3830,
        "name": "Bình Định",
        "state_code": "31",
        "type": null
      },
      {
        "id": 3797,
        "name": "Bình Phước",
        "state_code": "58",
        "type": null
      },
      {
        "id": 3787,
        "name": "Bình Thuận",
        "state_code": "40",
        "type": null
      },
      {
        "id": 3778,
        "name": "Cà Mau",
        "state_code": "59",
        "type": null
      },
      {
        "id": 4925,
        "name": "Cần Thơ",
        "state_code": "CT",
        "type": null
      },
      {
        "id": 3782,
        "name": "Cao Bằng",
        "state_code": "04",
        "type": null
      },
      {
        "id": 3806,
        "name": "Đà Nẵng",
        "state_code": "DN",
        "type": null
      },
      {
        "id": 3829,
        "name": "Đắk Lắk",
        "state_code": "33",
        "type": null
      },
      {
        "id": 3823,
        "name": "Đắk Nông",
        "state_code": "72",
        "type": null
      },
      {
        "id": 3773,
        "name": "Điện Biên",
        "state_code": "71",
        "type": null
      },
      {
        "id": 3821,
        "name": "Đồng Nai",
        "state_code": "39",
        "type": null
      },
      {
        "id": 3769,
        "name": "Đồng Tháp",
        "state_code": "45",
        "type": null
      },
      {
        "id": 3813,
        "name": "Gia Lai",
        "state_code": "30",
        "type": null
      },
      {
        "id": 3779,
        "name": "Hà Giang",
        "state_code": "03",
        "type": null
      },
      {
        "id": 3802,
        "name": "Hà Nam",
        "state_code": "63",
        "type": null
      },
      {
        "id": 3810,
        "name": "Hà Nội",
        "state_code": "HN",
        "type": null
      },
      {
        "id": 3816,
        "name": "Hà Tĩnh",
        "state_code": "23",
        "type": null
      },
      {
        "id": 3827,
        "name": "Hải Dương",
        "state_code": "61",
        "type": null
      },
      {
        "id": 3783,
        "name": "Hải Phòng",
        "state_code": "HP",
        "type": null
      },
      {
        "id": 3777,
        "name": "Hậu Giang",
        "state_code": "73",
        "type": null
      },
      {
        "id": 3811,
        "name": "Hồ Chí Minh",
        "state_code": "SG",
        "type": null
      },
      {
        "id": 3799,
        "name": "Hòa Bình",
        "state_code": "14",
        "type": null
      },
      {
        "id": 3768,
        "name": "Hưng Yên",
        "state_code": "66",
        "type": null
      },
      {
        "id": 3793,
        "name": "Khánh Hòa",
        "state_code": "34",
        "type": null
      },
      {
        "id": 3800,
        "name": "Kiên Giang",
        "state_code": "47",
        "type": null
      },
      {
        "id": 3772,
        "name": "Kon Tum",
        "state_code": "28",
        "type": null
      },
      {
        "id": 3825,
        "name": "Lai Châu",
        "state_code": "01",
        "type": null
      },
      {
        "id": 3818,
        "name": "Lâm Đồng",
        "state_code": "35",
        "type": null
      },
      {
        "id": 3792,
        "name": "Lạng Sơn",
        "state_code": "09",
        "type": null
      },
      {
        "id": 3817,
        "name": "Lào Cai",
        "state_code": "02",
        "type": null
      },
      {
        "id": 3808,
        "name": "Long An",
        "state_code": "41",
        "type": null
      },
      {
        "id": 3789,
        "name": "Nam Định",
        "state_code": "67",
        "type": null
      },
      {
        "id": 3780,
        "name": "Nghệ An",
        "state_code": "22",
        "type": null
      },
      {
        "id": 3786,
        "name": "Ninh Bình",
        "state_code": "18",
        "type": null
      },
      {
        "id": 3788,
        "name": "Ninh Thuận",
        "state_code": "36",
        "type": null
      },
      {
        "id": 3801,
        "name": "Phú Thọ",
        "state_code": "68",
        "type": null
      },
      {
        "id": 3824,
        "name": "Phú Yên",
        "state_code": "32",
        "type": null
      },
      {
        "id": 3809,
        "name": "Quảng Bình",
        "state_code": "24",
        "type": null
      },
      {
        "id": 3776,
        "name": "Quảng Nam",
        "state_code": "27",
        "type": null
      },
      {
        "id": 3828,
        "name": "Quảng Ngãi",
        "state_code": "29",
        "type": null
      },
      {
        "id": 3814,
        "name": "Quảng Ninh",
        "state_code": "13",
        "type": null
      },
      {
        "id": 3803,
        "name": "Quảng Trị",
        "state_code": "25",
        "type": null
      },
      {
        "id": 3819,
        "name": "Sóc Trăng",
        "state_code": "52",
        "type": null
      },
      {
        "id": 3812,
        "name": "Sơn La",
        "state_code": "05",
        "type": null
      },
      {
        "id": 3826,
        "name": "Tây Ninh",
        "state_code": "37",
        "type": null
      },
      {
        "id": 3775,
        "name": "Thái Bình",
        "state_code": "20",
        "type": null
      },
      {
        "id": 3807,
        "name": "Thái Nguyên",
        "state_code": "69",
        "type": null
      },
      {
        "id": 3771,
        "name": "Thanh Hóa",
        "state_code": "21",
        "type": null
      },
      {
        "id": 3798,
        "name": "Thừa Thiên-Huế",
        "state_code": "26",
        "type": null
      },
      {
        "id": 3781,
        "name": "Tiền Giang",
        "state_code": "46",
        "type": null
      },
      {
        "id": 3805,
        "name": "Trà Vinh",
        "state_code": "51",
        "type": null
      },
      {
        "id": 3795,
        "name": "Tuyên Quang",
        "state_code": "07",
        "type": null
      },
      {
        "id": 3790,
        "name": "Vĩnh Long",
        "state_code": "49",
        "type": null
      },
      {
        "id": 3774,
        "name": "Vĩnh Phúc",
        "state_code": "70",
        "type": null
      },
      {
        "id": 3784,
        "name": "Yên Bái",
        "state_code": "06",
        "type": null
      }
    ]
  },
  {
    "name": "Virgin Islands (British)",
    "iso3": "VGB",
    "iso2": "VG",
    "numeric_code": "092",
    "phone_code": "+1-284",
    "capital": "Road Town",
    "currency": "USD",
    "currency_name": "United States dollar",
    "currency_symbol": "$",
    "tld": ".vg",
    "native": "British Virgin Islands",
    "region": "Americas",
    "subregion": "Caribbean",
    "emoji": "🇻🇬",
    "emojiU": "U+1F1FB U+1F1EC",
    "states": []
  },
  {
    "name": "Virgin Islands (US)",
    "iso3": "VIR",
    "iso2": "VI",
    "numeric_code": "850",
    "phone_code": "+1-340",
    "capital": "Charlotte Amalie",
    "currency": "USD",
    "currency_name": "United States dollar",
    "currency_symbol": "$",
    "tld": ".vi",
    "native": "United States Virgin Islands",
    "region": "Americas",
    "subregion": "Caribbean",
    "emoji": "🇻🇮",
    "emojiU": "U+1F1FB U+1F1EE",
    "states": []
  },
  {
    "name": "Wallis And Futuna Islands",
    "iso3": "WLF",
    "iso2": "WF",
    "numeric_code": "876",
    "phone_code": "681",
    "capital": "Mata Utu",
    "currency": "XPF",
    "currency_name": "CFP franc",
    "currency_symbol": "₣",
    "tld": ".wf",
    "native": "Wallis et Futuna",
    "region": "Oceania",
    "subregion": "Polynesia",
    "emoji": "🇼🇫",
    "emojiU": "U+1F1FC U+1F1EB",
    "states": []
  },
  {
    "name": "Western Sahara",
    "iso3": "ESH",
    "iso2": "EH",
    "numeric_code": "732",
    "phone_code": "212",
    "capital": "El-Aaiun",
    "currency": "MAD",
    "currency_name": "Moroccan Dirham",
    "currency_symbol": "MAD",
    "tld": ".eh",
    "native": "الصحراء الغربية",
    "region": "Africa",
    "subregion": "Northern Africa",
    "emoji": "🇪🇭",
    "emojiU": "U+1F1EA U+1F1ED",
    "states": []
  },
  {
    "name": "Yemen",
    "iso3": "YEM",
    "iso2": "YE",
    "numeric_code": "887",
    "phone_code": "967",
    "capital": "Sanaa",
    "currency": "YER",
    "currency_name": "Yemeni rial",
    "currency_symbol": "﷼",
    "tld": ".ye",
    "native": "اليَمَن",
    "region": "Asia",
    "subregion": "Western Asia",
    "emoji": "🇾🇪",
    "emojiU": "U+1F1FE U+1F1EA",
    "states": [
      {
        "id": 1242,
        "name": "'Adan Governorate",
        "state_code": "AD",
        "type": null
      },
      {
        "id": 1250,
        "name": "'Amran Governorate",
        "state_code": "AM",
        "type": null
      },
      {
        "id": 1237,
        "name": "Abyan Governorate",
        "state_code": "AB",
        "type": null
      },
      {
        "id": 1240,
        "name": "Al Bayda' Governorate",
        "state_code": "BA",
        "type": null
      },
      {
        "id": 1241,
        "name": "Al Hudaydah Governorate",
        "state_code": "HU",
        "type": null
      },
      {
        "id": 1243,
        "name": "Al Jawf Governorate",
        "state_code": "JA",
        "type": null
      },
      {
        "id": 1251,
        "name": "Al Mahrah Governorate",
        "state_code": "MR",
        "type": null
      },
      {
        "id": 1235,
        "name": "Al Mahwit Governorate",
        "state_code": "MW",
        "type": null
      },
      {
        "id": 1246,
        "name": "Dhamar Governorate",
        "state_code": "DH",
        "type": null
      },
      {
        "id": 1238,
        "name": "Hadhramaut Governorate",
        "state_code": "HD",
        "type": null
      },
      {
        "id": 1244,
        "name": "Hajjah Governorate",
        "state_code": "HJ",
        "type": null
      },
      {
        "id": 1233,
        "name": "Ibb Governorate",
        "state_code": "IB",
        "type": null
      },
      {
        "id": 1245,
        "name": "Lahij Governorate",
        "state_code": "LA",
        "type": null
      },
      {
        "id": 1234,
        "name": "Ma'rib Governorate",
        "state_code": "MA",
        "type": null
      },
      {
        "id": 1248,
        "name": "Raymah Governorate",
        "state_code": "RA",
        "type": null
      },
      {
        "id": 1249,
        "name": "Saada Governorate",
        "state_code": "SD",
        "type": null
      },
      {
        "id": 1232,
        "name": "Sana'a",
        "state_code": "SA",
        "type": null
      },
      {
        "id": 1236,
        "name": "Sana'a Governorate",
        "state_code": "SN",
        "type": null
      },
      {
        "id": 1247,
        "name": "Shabwah Governorate",
        "state_code": "SH",
        "type": null
      },
      {
        "id": 1239,
        "name": "Socotra Governorate",
        "state_code": "SU",
        "type": null
      },
      {
        "id": 1231,
        "name": "Ta'izz Governorate",
        "state_code": "TA",
        "type": null
      }
    ]
  },
  {
    "name": "Zambia",
    "iso3": "ZMB",
    "iso2": "ZM",
    "numeric_code": "894",
    "phone_code": "260",
    "capital": "Lusaka",
    "currency": "ZMW",
    "currency_name": "Zambian kwacha",
    "currency_symbol": "ZK",
    "tld": ".zm",
    "native": "Zambia",
    "region": "Africa",
    "subregion": "Eastern Africa",
    "emoji": "🇿🇲",
    "emojiU": "U+1F1FF U+1F1F2",
    "states": [
      {
        "id": 1986,
        "name": "Central Province",
        "state_code": "02",
        "type": null
      },
      {
        "id": 1984,
        "name": "Copperbelt Province",
        "state_code": "08",
        "type": null
      },
      {
        "id": 1991,
        "name": "Eastern Province",
        "state_code": "03",
        "type": null
      },
      {
        "id": 1987,
        "name": "Luapula Province",
        "state_code": "04",
        "type": null
      },
      {
        "id": 1988,
        "name": "Lusaka Province",
        "state_code": "09",
        "type": null
      },
      {
        "id": 1989,
        "name": "Muchinga Province",
        "state_code": "10",
        "type": null
      },
      {
        "id": 1982,
        "name": "Northern Province",
        "state_code": "05",
        "type": null
      },
      {
        "id": 1985,
        "name": "Northwestern Province",
        "state_code": "06",
        "type": null
      },
      {
        "id": 1990,
        "name": "Southern Province",
        "state_code": "07",
        "type": null
      },
      {
        "id": 1983,
        "name": "Western Province",
        "state_code": "01",
        "type": null
      }
    ]
  },
  {
    "name": "Zimbabwe",
    "iso3": "ZWE",
    "iso2": "ZW",
    "numeric_code": "716",
    "phone_code": "263",
    "capital": "Harare",
    "currency": "ZWL",
    "currency_name": "Zimbabwe Dollar",
    "currency_symbol": "$",
    "tld": ".zw",
    "native": "Zimbabwe",
    "region": "Africa",
    "subregion": "Eastern Africa",
    "emoji": "🇿🇼",
    "emojiU": "U+1F1FF U+1F1FC",
    "states": [
      {
        "id": 1956,
        "name": "Bulawayo Province",
        "state_code": "BU",
        "type": null
      },
      {
        "id": 1958,
        "name": "Harare Province",
        "state_code": "HA",
        "type": null
      },
      {
        "id": 1959,
        "name": "Manicaland",
        "state_code": "MA",
        "type": null
      },
      {
        "id": 1955,
        "name": "Mashonaland Central Province",
        "state_code": "MC",
        "type": null
      },
      {
        "id": 1951,
        "name": "Mashonaland East Province",
        "state_code": "ME",
        "type": null
      },
      {
        "id": 1953,
        "name": "Mashonaland West Province",
        "state_code": "MW",
        "type": null
      },
      {
        "id": 1960,
        "name": "Masvingo Province",
        "state_code": "MV",
        "type": null
      },
      {
        "id": 1954,
        "name": "Matabeleland North Province",
        "state_code": "MN",
        "type": null
      },
      {
        "id": 1952,
        "name": "Matabeleland South Province",
        "state_code": "MS",
        "type": null
      },
      {
        "id": 1957,
        "name": "Midlands Province",
        "state_code": "MI",
        "type": null
      }
    ]
  }
]
