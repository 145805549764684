/* eslint-disable class-methods-use-this */
import defaultDayjs from 'dayjs';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import BaseAdapterDayjs from '@date-io/dayjs';
import { buildWarning } from '../internals/utils/warning';
const localeNotFoundWarning = buildWarning(['Your locale has not been found.', 'Either the locale key is not a supported one. Locales supported by dayjs are available here: https://github.com/iamkun/dayjs/tree/dev/src/locale', "Or you forget to import the locale with `require('dayjs/locale/{localeUsed}')`", 'fallback on English locale']);
const formatTokenMap = {
  // Year
  YY: 'year',
  YYYY: 'year',
  // Month
  M: 'month',
  MM: 'month',
  MMM: {
    sectionType: 'month',
    contentType: 'letter'
  },
  MMMM: {
    sectionType: 'month',
    contentType: 'letter'
  },
  // Day of the month
  D: 'day',
  DD: 'day',
  Do: 'day',
  // Day of the week
  d: 'weekDay',
  dd: {
    sectionType: 'weekDay',
    contentType: 'letter'
  },
  ddd: {
    sectionType: 'weekDay',
    contentType: 'letter'
  },
  dddd: {
    sectionType: 'weekDay',
    contentType: 'letter'
  },
  // Meridiem
  A: 'meridiem',
  a: 'meridiem',
  // Hours
  H: 'hours',
  HH: 'hours',
  h: 'hours',
  hh: 'hours',
  // Minutes
  m: 'minutes',
  mm: 'minutes',
  // Seconds
  s: 'seconds',
  ss: 'seconds'
};
export class AdapterDayjs extends BaseAdapterDayjs {
  constructor(options) {
    super(options);
    this.isMUIAdapter = true;
    this.formatTokenMap = formatTokenMap;
    this.escapedCharacters = {
      start: '[',
      end: ']'
    };
    this.getLocaleFormats = () => {
      var _this$rawDayJsInstanc;
      const locales = (_this$rawDayJsInstanc = this.rawDayJsInstance.Ls) != null ? _this$rawDayJsInstanc : defaultDayjs.Ls;
      const locale = this.locale || 'en';
      let localeObject = locales[locale];
      if (localeObject === undefined) {
        localeNotFoundWarning();
        localeObject = locales.en;
      }
      return localeObject.formats;
    };
    this.is12HourCycleInCurrentLocale = () => {
      /* istanbul ignore next */
      return /A|a/.test(this.getLocaleFormats().LT || '');
    };
    /**
     * The current getFormatHelperText method uses an outdated format parsing logic.
     * We should use this one in the future to support all localized formats.
     */
    this.expandFormat = format => {
      const localeFormats = this.getLocaleFormats();

      // @see https://github.com/iamkun/dayjs/blob/dev/src/plugin/localizedFormat/index.js
      const t = formatBis => formatBis.replace(/(\[[^\]]+])|(MMMM|MM|DD|dddd)/g, (_, a, b) => a || b.slice(1));
      return format.replace(/(\[[^\]]+])|(LTS?|l{1,4}|L{1,4})/g, (_, a, b) => {
        const B = b && b.toUpperCase();
        return a || localeFormats[b] || t(localeFormats[B]);
      });
    };
    // Redefined here just to show how it can be written using expandFormat
    this.getFormatHelperText = format => {
      return this.expandFormat(format).replace(/a/gi, '(a|p)m').toLocaleLowerCase();
    };
    this.getWeekNumber = date => {
      return date.week();
    };
    defaultDayjs.extend(weekOfYear);
  }
}