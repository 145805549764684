import React, {useState} from "react";
import {Box, Tooltip, IconButton, Typography, Button, Select, MenuItem} from "@mui/material";
import {useUsers} from "./UsersContext";
import ForwardToInboxOutlinedIcon from "@mui/icons-material/ForwardToInboxOutlined";
import PauseCircleOutlinedIcon from "@mui/icons-material/PauseCircleOutlined";
import PlayCircleOutlinedIcon from "@mui/icons-material/PlayCircleOutlined";
import Table, {actionColumn} from "../components/Table";
import dayjs from "dayjs";
import {useNavigate} from "react-router-dom";
import {findWhere, map, pluck, sortBy} from "underscore";

const UsersTable = () => {
  const {
    users,
    groups,
    getUsersQuery,
    getUsersQueryMethods,
    getUsersInGroupQuery,
    getUsersInGroupQueryMethods,
    resendConfirmation,
    toggleUserEnablement,
    toggleEnablementQuery,
  } = useUsers();
  const [selectedGroup, setSelectedGroup] = useState("regscout");
  const isRegScout = selectedGroup === "regscout";
  const navigate = useNavigate();

  const onEnablementClick = (event, username) => {
    event.stopPropagation();
    if (toggleEnablementQuery.loading) return;
    toggleUserEnablement(username);
  };

  const onResendConfirmationClick = async (event, username) => {
    event.stopPropagation();
    await resendConfirmation(username);
  };

  const onRowClick = (params) => {
    const sub = findWhere(params.row.Attributes, {Name: "sub"})?.Value;
    navigate(`/users/${sub}`);
  };

  const columns = [
    {field: "Username", headerName: "Email", flex: 1},
    {field: "UserStatus", headerName: "User Status", width: 230},
    {field: "UserCreateDate", headerName: "Created", flex: .75,
      renderCell: (params) => dayjs(params?.row?.UserCreateDate).format("lll"),
    },
    {field: "UserLastModifiedDate", headerName: "Last Modified", flex: .75,
      renderCell: (params) => dayjs(params?.row?.UserLastModifiedDate).format("lll"),
    },
    {field: "Enabled", headerName: "Enabled", width: 100, type: "boolean"},
    {field: "actions", headerName: " ", minWidth: 75, ...actionColumn,
      renderCell: (params) => {
        const isEnabled = params.row.Enabled;
        const isUnconfirmed = params.row.UserStatus === "UNCONFIRMED";

        if (!isRegScout) return null;

        return (
          <>
            <Tooltip title={isEnabled ? "Disable User" : "Enable User"}>
              <IconButton
                aria-label={"Enable/disable user"}
                onClick={(event) => onEnablementClick(event, params.row.Username)}
                color="primary"
                disableRipple
                size="large"
                sx={{padding: 0, marginRight: 1}}
              >
                {isEnabled ? <PauseCircleOutlinedIcon /> : <PlayCircleOutlinedIcon />}
              </IconButton>
            </Tooltip>
            {isUnconfirmed && (
              <Tooltip title="Resend Confirmation">
                <IconButton
                  aria-label="Resend confirmation"
                  onClick={(event) => onResendConfirmationClick(event, params.row.Username)}
                  color="primary"
                  disableRipple
                  size="large"
                  sx={{padding: 0}}
                >
                  <ForwardToInboxOutlinedIcon />
                </IconButton>
              </Tooltip>
            )}
          </>
        );
      },
    },
  ];

  const groupsForSelect = sortBy(pluck(groups, "name"), (item) => item.toLowerCase());
  const groupMenuItems = map(groupsForSelect, (group, index) => (
    <MenuItem value={group} key={index}>{group}{group === "regscout" ? "*" : ""}</MenuItem>
  ));

  const handleSelectedGroupChange = (event) => {
    setSelectedGroup(event.target.value);
    if (event.target.value === "regscout") getUsersQuery();
    else getUsersInGroupQuery({variables: {groupName: event.target.value}});
  };

  const handleRefetch = () => {
    if (isRegScout) getUsersQueryMethods.refetch();
    else getUsersInGroupQueryMethods.refetch();
  };

  return (
    <Box>
      <Box display="flex" justifyContent="space-between">
        <Box display="flex">
          <Typography variant="h4" mb={2}>Manage Users</Typography>
          <Box ml={1} mt={0.5}>
            <Select
              id="users-table-group-select"
              value={selectedGroup}
              onChange={handleSelectedGroupChange}
              size="small"
            >
              {groupMenuItems}
            </Select>
          </Box>
        </Box>
        <Box>
          <Button
            onClick={() => navigate("/users/report")}
            sx={{display: !isRegScout ? "none" : undefined}}
          >
            View Users Report
          </Button>
          <Button
            variant="contained"
            onClick={handleRefetch}
            sx={{marginBottom: 1.5, marginTop: 1.5}}
          >
            Refresh
          </Button>
        </Box>
      </Box>
      <Table
        rows={users ?? []}
        columns={columns}
        options={{
          onRowClick,
          disableRowSelectionOnClick: true,
          getRowId: (row) => row.Username,
          sx: {cursor: "default"},
          loading: getUsersQueryMethods.loading || getUsersInGroupQueryMethods.loading,
        }}
      />
    </Box>
  );
};

export default UsersTable;
