import React, {useContext, createContext, useState} from "react";
import PropTypes from "prop-types";
import SyncRegulationsForGroup from "./gql/SyncRegulationsForGroup.graphql";
import GetOneRegulation from "../regulations/gql/getOneRegulation.graphql";
import UpdateOneRegulationGroup from "./gql/UpdateOneRegulationGroup.graphql";
import {useMutation, gql, useQuery} from "@apollo/client";
import {useParams} from "react-router-dom";
import {ALERT} from "../environment";

const GroupsContext = createContext(null);

const GroupsProvider = ({children}) => {
  const [regulationGroup, setRegulationGroup] = useState();
  const [updateRegulationGroup, updateRegulationGroupMethods] =
    useMutation(gql(UpdateOneRegulationGroup));

  const {id} = useParams();

  const getRegulationQuery = useQuery(gql(GetOneRegulation), {
    variables: {id: parseInt(id, 10)},
    onCompleted: (data) => {
      setRegulationGroup(data.regulation);
    },
    skip: !id,
  });

  const [syncGroupRegulations,
    syncGroupRegulationsMethod,
  ] = useMutation(gql(SyncRegulationsForGroup), {
    onCompleted: () => {
      ALERT.success("Regulation group updated!");
    },
  });

  const loading = (
    syncGroupRegulations.loading ||
    getRegulationQuery.loading ||
    syncGroupRegulationsMethod.loading ||
    updateRegulationGroup.loading ||
    updateRegulationGroupMethods.loading
  );

  const update = async (data) => {
    await syncGroupRegulations({variables: {
      regulationGroup: {id: regulationGroup.id},
      regulations: data.regulations,
    }});

    updateRegulationGroup({
      variables: {
        id: regulationGroup.id,
        groupName: {set: data.name},
      },
    });
  };

  return (
    <GroupsContext.Provider value={{
      update,
      loading,
      regulationGroup,
    }}>
      {children}
    </GroupsContext.Provider>
  );
};

GroupsProvider.propTypes = {
  children: PropTypes.any.isRequired,
};

const useGroups = () => {
  return useContext(GroupsContext);
};

export {useGroups, GroupsProvider};
