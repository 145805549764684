query GetTagTypes {
  tagTypes {
    id
    isDeleted
    createdAt
    updatedAt
    isDraft
    name
    displayName
    isVisible
    typeName
  }
}
