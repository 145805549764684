# import RegulationGroupFragment from "./fragments/RegulationGroup.graphql"

mutation UpdateOneRegulationGroup(
  $id: Int!,
  $groupName: StringFieldUpdateOperationsInput
) {
  updateOneRegulationGroup(where: {id: $id}, data: {
    name: $groupName
}) {
    ...RegulationGroupFragment
  }
}