import React, {useState} from "react";
import SunEditor from "suneditor-react";
import {PropTypes} from "prop-types";
import {useEditorStyles} from "./EditorStyles";
import "./editor.css";

// eslint-disable-next-line max-len
const DEFAULT_STYLE = "font-family: Roboto, Helvetica, Arial, sans-serif;font-size: 16px;height: auto;";

const Editor = ({label, name, onChange, options, value}) => {
  const [hideToolbar, setHideToolbar] = useState(options?.alwaysShowToolbar ? false : true);
  const {classes} = useEditorStyles({padding: options?.padding});

  const handleFocus = () => {
    if (options?.alwaysShowToolbar) return;
    setHideToolbar(false);
  };

  const handleBlur = () => {
    if (options?.alwaysShowToolbar) return;
    setHideToolbar(true);
  };

  return <>
    <span>{label ?? ""}</span>
    <span className={classes.displayEditor}>
      <SunEditor
        isForm
        onFocus={handleFocus}
        onBlur={handleBlur}
        hideToolbar={hideToolbar}
        autoFocus={false}
        setDefaultStyle={DEFAULT_STYLE}
        {...options}
        setOptions={{
          resizingBar: false,
          showPathLabel: false,
          buttonList: [
            ["undo", "redo"],
            ["font", "fontSize", "formatBlock"],
            ["paragraphStyle", "blockquote"],
            ["bold", "underline", "italic", "strike", "subscript", "superscript"],
            ["fontColor", "hiliteColor", "textStyle"],
            ["removeFormat"],
            "/",
            ["outdent", "indent"],
            ["align", "horizontalRule", "list", "lineHeight"],
            ["table", "link"],
            ["showBlocks", "codeView"],
          ],
        }}
        name={name}
        onChange={onChange}
        setContents={value ?? ""}
      />
    </span>
  </>;
};

Editor.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.object,
  value: PropTypes.string.isRequired,
};

export default Editor;
