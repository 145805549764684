# import NewsItemFragment from "./fragments/NewsItem.graphql"

mutation UpdateOneNewsItem(
  $id: Int!,
  $isDraft: Boolean!,
  $publisher: String!,
  $publishedAt: DateTime!,
  $source: String!,
  $title: String!
) {
  updateOneNewsItem(
    where: {id: $id},
    data: {
      isDraft: {set: $isDraft},
      publisher: {set: $publisher},
      publishedAt: {set: $publishedAt},
      source: {set: $source},
      title: {set: $title},
    }
  ) {
    ...NewsItemFragment
  }
}