import React from "react";
import {Routes, Route} from "react-router-dom";
import RegulationGroupTable from "./RegulationGroupTable";
import RegulationGroupDetails from "./RegulationGroupDetails";
import {GroupsProvider} from "./RegulationGroupContext";

const Groups = () => (
  <GroupsProvider>
    <GroupsContent />
  </GroupsProvider>

);

const GroupsContent = () => {
  return <Routes>
    <Route path="/*" element={<RegulationGroupTable />} />
    <Route path="/:id" element={<RegulationGroupDetails/>} />
  </Routes>;
};

export default Groups;
