import React from "react";
import {createRoot} from "react-dom/client";
import {CssBaseline} from "@mui/material";
import App from "./App";
import LoginProvider from "./components/login/LoginProvider";
import ApolloClientProvider from "./context/Apollo";
import AlertProvider from "./shared/AlertProvider";
import Alerts from "./shared/Alerts";
import {ConsolidatedThemeProvider} from "./shared/ConsolidatedThemeProvider";
import "@fontsource/oswald/300.css";
import "@fontsource/oswald/400.css";
import "@fontsource/oswald/500.css";
import "@fontsource/oswald/700.css";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";


const container = document.getElementById("app");
const root = createRoot(container);
root.render(<React.StrictMode>
  <CssBaseline />
  <ConsolidatedThemeProvider>
    <AlertProvider>
      <Alerts/>
      <LoginProvider>
        <ApolloClientProvider>
          <App />
        </ApolloClientProvider>
      </LoginProvider>
    </AlertProvider>
  </ConsolidatedThemeProvider>
</React.StrictMode>);
