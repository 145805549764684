import {makeStyles} from "tss-react/mui";

export const useEditorStyles = makeStyles()((theme, {padding}) => ({
  displayEditor: {
    "&> .sun-editor": {
      backgroundColor: "transparent",
      "&> .se-container .se-wrapper .sun-editor-editable": {
        padding: padding,
        height: "auto",
        color: theme.palette.text.primary,
        backgroundColor: "transparent",
      },
      "&> .se-container .se-wrapper .se-wrapper-inner": {
        minHeight: 0,
      },
    },
  },
}));

