#import RegulationFragment  from "../../regulations/gql/fragments/Regulation.graphql"

mutation SyncRegulationsForGroup(
    $regulationGroup: RegulationGroupWhereUniqueInput,
    $regulations: RegulationWhereUniqueInput,
) {
    SyncRegulationsForGroup(
        regulationGroup: $regulationGroup,
        regulations: $regulations
    ) {
        ...RegulationFragment
    }
}