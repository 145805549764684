import React from "react";
import {
  MenuItem,
} from "@mui/material";
import {map} from "underscore";
import ControlledSelect from "../components/ControlledSelect";
import {useRegulation} from "./RegulationContext";

const RegulationLegalTypeSelect = () => {
  const {loading, regulationLegalTypes} = useRegulation();
  if (loading) return null;

  const legalTypeItems = map(regulationLegalTypes, (legalType) => {
    return <MenuItem key={legalType} value={legalType}>
      {legalType}
    </MenuItem>;
  });

  return <ControlledSelect
    name="legalType"
    label="Legal Type"
    options={legalTypeItems}
    selectProps={{
      required: true,
    }}
  />;
};

export default RegulationLegalTypeSelect;
