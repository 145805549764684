import React, {useEffect, useState} from "react";
import SunEditor from "suneditor-react";
import {PropTypes} from "prop-types";
import {Controller, useFormContext} from "react-hook-form";
import {ALERT} from "../environment";
import {useEditorStyles} from "./EditorStyles";
import "./editor.css";

// eslint-disable-next-line max-len
const DEFAULT_STYLE = "font-family: Roboto, Helvetica, Arial, sans-serif;font-size: 16px;height: auto;";

const ControlledSunEditor = ({name, label, options, rules}) => {
  const formMethods = useFormContext();
  const [hideToolbar, setHideToolbar] = useState(true);
  const {classes} = useEditorStyles({padding: options?.padding});
  const isRequired = rules?.required;

  useEffect(() => {
    if (isRequired && formMethods?.formState?.errors?.[name]) {
      ALERT.error(`Field ${label} is required`);
      formMethods.clearErrors(name);
    }
  }, [formMethods?.formState?.errors?.[name]]);

  const handleFocus = () => {
    setHideToolbar(false);
  };

  const handleBlur = () => {
    setHideToolbar(true);
  };

  const labelToDisplay = !label ? "" : isRequired ? `${label} *` : label;

  return <Controller
    control={formMethods.control}
    name={name}
    rules={rules}
    render={({
      field: {onChange, value, name: fieldName},
    }) => {
      return <>
        <span>{labelToDisplay}</span>
        <span className={classes.displayEditor}>
          <SunEditor
            isForm
            onFocus={handleFocus}
            onBlur={handleBlur}
            hideToolbar={hideToolbar}
            autoFocus={false}
            setDefaultStyle={DEFAULT_STYLE}
            {...options}
            setOptions={{
              resizingBar: false,
              showPathLabel: false,
              buttonList: [
                ["undo", "redo"],
                ["font", "fontSize", "formatBlock"],
                ["paragraphStyle", "blockquote"],
                ["bold", "underline", "italic", "strike", "subscript", "superscript"],
                ["fontColor", "hiliteColor", "textStyle"],
                ["removeFormat"],
                "/",
                ["outdent", "indent"],
                ["align", "horizontalRule", "list", "lineHeight"],
                ["table", "link"],
                ["showBlocks", "codeView"],
              ],
            }}
            name={fieldName}
            onChange={onChange}
            setContents={value ?? ""}
          />
        </span>
      </>;
    }}
  />;
};

ControlledSunEditor.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  options: PropTypes.object,
  rules: PropTypes.object,
};

export default ControlledSunEditor;
