import React from "react";
import {Box, IconButton, Switch, TextField} from "@mui/material";
import AddBoxRoundedIcon from "@mui/icons-material/AddBoxRounded";
import {DateTimePicker} from "@mui/x-date-pickers";
import {Controller, useFieldArray, useFormContext} from "react-hook-form";
import dayjs from "dayjs";

const RegulationNews = () => {
  const {control, setValue} = useFormContext();
  const {append, fields} = useFieldArray({
    control: control,
    name: "newsItems",
  });

  const handleAdd = () => {
    append({
      newsItem: {
        isDraft: true, publisher: "", publishedAt: dayjs().toISOString(), source: "", title: "",
      },
      isNew: true,
    });
  };

  const markDirty = (index) => {
    if (!fields[index]?.isNew) setValue(`newsItems[${index}].isDirty`, true);
  };

  return (
    <>
      <Box ml={2} p={1}>
        News
        <IconButton color="primary" onClick={handleAdd}>
          <AddBoxRoundedIcon />
        </IconButton>
      </Box>
      <Box ml={4}>
        {fields.map((field, index) => (
          <Box
            p={2}
            key={index}
            sx={(theme) => ({
              border: `2px solid ${theme.palette.primary.main}`,
              borderTop: index != 0 ? 0 : undefined,
            })}
          >
            <Box display="flex" flexDirection="row" mb={1}>
              <Controller
                control={control}
                name={`newsItems[${index}].newsItem.title`}
                id={`newsItems-${index}-newsItem-title`}
                defaultValue={field?.[index]?.newsItem?.title}
                render={({field}) => (
                  <TextField
                    label="Title"
                    {...field}
                    size="small"
                    sx={{flexGrow: 0.5, marginRight: 1}}
                    onChange={(event) => {
                      field.onChange(event);
                      markDirty(index);
                    }}
                  />
                )}
              />
              <Controller
                control={control}
                name={`newsItems[${index}].newsItem.publisher`}
                id={`newsItems-${index}-newsItem-publisher`}
                defaultValue={field?.[index]?.newsItem?.publisher}
                render={({field}) => (
                  <TextField
                    label="Publisher"
                    {...field}
                    size="small"
                    sx={{flexGrow: 0.5}}
                    onChange={(event) => {
                      field.onChange(event);
                      markDirty(index);
                    }}
                  />
                )}
              />
            </Box>
            <Controller
              control={control}
              name={`newsItems[${index}].newsItem.source`}
              id={`newsItems-${index}-newsItem-source`}
              defaultValue={field?.[index]?.newsItem?.source}
              render={({field}) => (
                <TextField
                  label="Source"
                  {...field}
                  fullWidth
                  size="small"
                  sx={{marginBottom: 1.5}}
                  onChange={(event) => {
                    field.onChange(event);
                    markDirty(index);
                  }}
                />
              )}
            />
            <Box display="flex" flexDirection="row" mb={1}>
              <Controller
                control={control}
                name={`newsItems[${index}].newsItem.publishedAt`}
                id={`newsItems-${index}-newsItem-publishedAt`}
                defaultValue={field?.[index]?.newsItem?.publishedAt}
                render={({field}) => (
                  <DateTimePicker
                    label="Published At"
                    control={control}
                    value={dayjs(field.value)}
                    onChange={(event) => {
                      field.onChange(event);
                      markDirty(index);
                    }}
                    sx={{flexGrow: 0.5, marginRight: 1}}
                  />
                )}
              />
              <Box display="flex">
                <Controller
                  control={control}
                  name={`newsItems[${index}].newsItem.isDraft`}
                  id={`newsItems-${index}-newsItem-isDraft`}
                  defaultValue={field?.[index]?.newsItem?.isDraft}
                  render={({field}) => (
                    <Switch
                      {...field}
                      checked={field.value}
                      onChange={(event) => {
                        field.onChange(event.target.checked);
                        markDirty(index);
                      }}
                      sx={{marginTop: 1}}
                    />
                  )}
                />
                <Box sx={{marginTop: 2, lineHeight: 1.25}}>Draft</Box>
              </Box>
            </Box>
          </Box>
        ))}
      </Box>
    </>
  );
};

export default RegulationNews;
