import React from "react";
import {Checkbox, TextField, Typography} from "@mui/material";
import CheckBoxOutlineBlank from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBox from "@mui/icons-material/CheckBox";
import {useQuery, gql} from "@apollo/client";
import ControlledAutocomplete from "../components/ControlledAutocomplete";
import {useRegulation} from "./RegulationContext";
import GetRegulationGroups from "./gql/GetRegulationGroups.graphql";
import {isEmpty, map} from "underscore";

const RegulationGroupSelect = () => {
  const {regulation, loading} = useRegulation();
  const getRegulationGroupsQuery = useQuery(gql(GetRegulationGroups));

  if (
    getRegulationGroupsQuery?.loading || isEmpty(getRegulationGroupsQuery.data) ||
    loading || isEmpty(regulation)
  ) return null;

  const defaultValue = map(regulation?.existingGroups, (regulationGroupLink) => {
    return {
      name: regulationGroupLink.regulationGroup.name,
      id: regulationGroupLink.regulationGroup.id,
    };
  });

  const options = map(getRegulationGroupsQuery.data?.regulationGroups, (regulationGroup) => {
    return {
      name: regulationGroup.name,
      id: regulationGroup.id,
    };
  });

  return (
    <>
      {isEmpty(regulation?.existingGroups) && !isEmpty(regulation?.createdAt) ?
        <Typography color="error" variant="body2">
          Warning: this regulation is only visible to Admins. Add Regulation Groups below.
        </Typography> :
        null
      }
      <ControlledAutocomplete
        name="groups"
        defaultValue={defaultValue}
        props={{
          multiple: true,
          loading: getRegulationGroupsQuery.loading,
          id: "groups",
          options,
          defaultValue: defaultValue,
          getOptionLabel: (option) => option.name,
          isOptionEqualToValue: (option, value) => option.name === value.name,
          renderOption: (props, option, {selected}) => (
            <li {...props}>
              <Checkbox
                icon={<CheckBoxOutlineBlank fontSize="small" />}
                checkedIcon={<CheckBox fontSize="small" />}
                style={{marginRight: 8}}
                checked={selected}
              />
              {option.name}
            </li>
          ),
          renderInput: (params) => (
            <TextField
              {...params}
              variant="outlined"
              label="Regulation Groups"
              placeholder="Regulation Groups"
            />
          ),
        }}
      />
    </>
  );
};

export default RegulationGroupSelect;
