# import RegulationFragment from "./fragments/Regulation.graphql"

mutation CreateOneRegulation(
  $isDraft: Boolean!,
  $regulator: String!,
  $description: String,
  $exceptions: String,
  $source: String!,
  $penalties: String!,
  $typeName: String!
  $piiDefinition: String,
  $otherRequirements: String,
  $legislationPassedAt: DateTime,
  $effectiveAt: DateTime,
  $legalType: LegalType!,
) {
  createOneRegulation(data: {
    isDraft: $isDraft,
    regulator: $regulator
    description: $description
    exceptions: $exceptions
    source: $source
    penalties: $penalties
    piiDefinition: $piiDefinition
    otherRequirements: $otherRequirements
    legislationPassedAt: $legislationPassedAt
    effectiveAt: $effectiveAt
    legalType: $legalType
    type: {
      connect: {
        name: $typeName
      }
    }
  }) {
    ...RegulationFragment
  }
}
