import React, {useEffect, useState} from "react";
import {MenuItem} from "@mui/material";
import {useRegulation} from "../RegulationContext";
import {gql, useMutation} from "@apollo/client";
import GenerateNotificationTemplate from
  "../gql/NotificationTemplates/GenerateNotificationTemplate.graphql";
import {ALERT} from "../../environment";
import dayjs from "dayjs";
import {filter, findWhere, isEmpty, isUndefined, map, sortBy} from "underscore";

export const useNotificationTemplates = ({notificationType}) => {
  const {
    regulation,
    notificationLabels,
    notificationTemplate,
    whichNotificationTemplateOpen,
    setWhichNotificationTemplateOpen,
    createTemplate,
    updateTemplate,
    deleteTemplate,
  } = useRegulation();
  const handleOpen = () => setWhichNotificationTemplateOpen(notificationLabels[notificationType]);
  const handleClose = (event, reason) => {
    if (["escapeKeyDown", "backdropClick"].includes(reason)) return;
    setWhichNotificationTemplateOpen("");
  };

  const [
    generateNotificationTemplate, generateNotificationTemplateMethods,
  ] = useMutation(gql(GenerateNotificationTemplate), {
    onCompleted: (data) => {
      const content = data.generateNotificationTemplate?.notificationTemplate;
      setFormValues((currentValues) => ({...currentValues, content, forceNewTemplate: false}));
      ALERT.success("Template generated!");
    },
    onError: (error) => {
      ALERT.error("Error generating template");
    },
  });

  const applicableNotification = filter(notificationTemplate, (notification) => {
    return (!isUndefined(notification) && notification.type === notificationType);
  })?.[0] ?? {};
  const applicableTemplates = applicableNotification?.templates ?? [];
  const applicableRevisions = map(applicableTemplates, (template) => {
    return {revision: template.revision, createdAt: template.createdAt};
  });
  const latestRevision = applicableTemplates?.[0] ?? {};
  const activeRevision = applicableRevisions?.[0]?.revision ?? "none";
  const applicableNotificationId = applicableNotification?.id;

  const [formValues, setFormValues] = useState({});
  const whichTemplateType = `${whichNotificationTemplateOpen}`.toUpperCase();
  const templateInView = findWhere(notificationTemplate, {type: whichTemplateType});
  const revisionInView = findWhere(templateInView?.templates, {revision: formValues.revision});

  useEffect(() => {
    if (!regulation || isEmpty(applicableNotification)) return;
    setFormValues({
      isDraft: latestRevision?.isDraft ?? true,
      isNew: false,
      revision: activeRevision,
      content: latestRevision?.content ?? "",
      forceNewTemplate: false,
    });
  }, [regulation?.id, formValues?.reload]);

  const findTemplateValuesByRevision = (revision) => {
    return findWhere(applicableTemplates, {revision: revision}) ?? {};
  };

  const getTemplateUpdatedAtDateByRevision = (revision) => {
    if (!revision) return;
    const template = findWhere(applicableTemplates, {revision: revision});
    if (!template) return;
    return dayjs(template?.updatedAt).format("lll");
  };

  const revisionMenuItem = (templateRevision, key) => (
    <MenuItem key={key} value={templateRevision.revision}>
      {templateRevision.revision} ({dayjs(templateRevision.createdAt).format("lll")})
    </MenuItem>
  );

  const [revisionOptions, setRevisionOptions] = useState(!isEmpty(applicableRevisions) ?
    map(applicableRevisions, (templateRevision, key) => revisionMenuItem(templateRevision, key)) :
    [<MenuItem key="none" value="none">None</MenuItem>],
  );

  const handleGeneratingNotificationTemplate = async () => {
    await generateNotificationTemplate({variables: {
      regulationId: regulation.id,
      forceNewTemplate: formValues.forceNewTemplate,
    }});
  };

  const handleNewRevision = () => {
    const nextRevision = formValues?.revision === "none" ? 1 : latestRevision?.revision + 1;
    setRevisionOptions((currentRevisionOptions) => ([
      ...(currentRevisionOptions ?? []),
      revisionMenuItem({revision: nextRevision, createdAt: dayjs()}, nextRevision),
    ]));
    setFormValues({
      isDraft: true, isNew: true, revision: nextRevision, content: "", forceNewTemplate: false,
    });
  };

  const handleDeleteRevision = async () => {
    if (formValues?.isNew) {
      setFormValues({reload: true});
      return;
    }
    await deleteTemplate({variables: {templateId: revisionInView.id}});
  }

  const handleSubmit = async () => {
    if (formValues?.revision === "none") {
      handleClose();
      return;
    }
    if (!formValues?.isNew) {
      await updateTemplate({variables: {
        templateId: revisionInView.id,
        content: formValues.content,
        isDraft: formValues.isDraft,
      }});
    } else {
      await createTemplate({variables: {
        isDraft: formValues.isDraft,
        content: formValues.content,
        revision: formValues.revision,
        notificationId: applicableNotificationId,
      }});
    };
  };

  return {
    applicableNotificationId,
    findTemplateValuesByRevision,
    formValues,
    setFormValues,
    getTemplateUpdatedAtDateByRevision,
    handleClose,
    handleGeneratingNotificationTemplate,
    handleNewRevision,
    handleDeleteRevision,
    handleOpen,
    handleSubmit,
    isGenerating: generateNotificationTemplateMethods.loading,
    latestRevision,
    revisionInView,
    revisionOptions,
  };
};
