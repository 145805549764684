# import NotificationFragment from "./fragments/Notification.graphql"

mutation UpdateOneNotification(
  $id: Int!,
  $content: NullableStringFieldUpdateOperationsInput,
  $conditions: NullableStringFieldUpdateOperationsInput,
) {
  updateOneNotification(where: {id: $id}, data: {
    content: $content,
    conditions: $conditions,
  }) {
    ...NotificationFragment
  }
}