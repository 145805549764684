import React, {useState, useMemo, useContext, createContext} from "react";
import PropTypes from "prop-types";
import {responsiveFontSizes, ThemeProvider} from "@mui/material/styles";
import {CacheProvider} from "@emotion/react";
import {TssCacheProvider} from "tss-react";
import createCache from "@emotion/cache";
import {toggleTheme, initializeTheme} from "./theme";

export const ThemeWrapperContext = createContext(null);

const muiCache = createCache({key: "mui", prepend: true});
const tssCache = createCache({key: "tss"});

export const ConsolidatedThemeProvider = ({children}) => {
  const [activeTheme, setActiveTheme] = useState(initializeTheme());

  const toggleActiveTheme = useMemo(() =>
    () => toggleTheme({activeTheme, setActiveTheme}),
  [activeTheme]);

  return (
    <CacheProvider value={muiCache}>
      <TssCacheProvider value={tssCache}>
        <ThemeWrapperContext.Provider value={{activeTheme, setActiveTheme, toggleActiveTheme}}>
          <ThemeProvider theme={responsiveFontSizes(activeTheme)}>
            {children}
          </ThemeProvider>
        </ThemeWrapperContext.Provider>
      </TssCacheProvider>
    </CacheProvider>
  );
};

ConsolidatedThemeProvider.propTypes = {
  activeTheme: PropTypes.object,
  children: PropTypes.any,
};

export const useTheme = () => {
  return useContext(ThemeWrapperContext);
};
