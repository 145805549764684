query GetDistinctTagValuesOfType($tagTypeName: String!) {
  tags(where:{
    type: {
      is: {
        name: {
          equals: $tagTypeName
        }
      }
    }
  }, distinct: [value]) {
    id
    value
  }
}