# import NewsItemFragment from "./fragments/NewsItem.graphql"

mutation CreateOneNewsItem(
  $isDraft: Boolean!,
  $publisher: String!,
  $publishedAt: DateTime,
  $source: String!,
  $title: String!
  $regulationId: Int!,
) {
  createOneNewsItem(
    data: {
      isDraft: $isDraft,
      publisher: $publisher,
      publishedAt: $publishedAt,
      source: $source,
      title: $title,
      regulations: {
        create: {
          regulation: {
            connect: {
              id: $regulationId
            }
          }
        } 
      }
    }
  ) {
    ...NewsItemFragment
  }
}