import React, {useState} from "react";
import {useQuery, gql} from "@apollo/client";
import {
  Typography, Button, Box, IconButton, Checkbox, TextField,
} from "@mui/material";
import CheckBox from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlank from "@mui/icons-material/CheckBoxOutlineBlank";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {NavLink, useParams} from "react-router-dom";
import {FormProvider, useForm} from "react-hook-form";
import GetRegulationGroups from "../regulations/gql/GetRegulationGroups.graphql";
import GetRegulationGroup from "./gql/GetOneRegulationGroup.graphql";
import ControlledAutocomplete from "../components/ControlledAutocomplete";
import Regulations from "../regulations/gql/getRegulations.graphql";
import {GroupsProvider, useGroups} from "./RegulationGroupContext";
import {map} from "underscore";

const RegulationGroupDetailsContent = () => {
  const {update, loading} = useGroups();
  const {data, error} = useQuery(gql(GetRegulationGroups));

  const getRegulationsQuery = useQuery(gql(Regulations));
  const groupId = useParams();
  const formMethods = useForm();

  const [regulationGroupName, setRegulationGroupName] = useState();
  const [regulationGroupId, setRegulationGroupId] = useState();

  const getRegulationGroup = useQuery(gql(GetRegulationGroup), {
    variables: {id: parseInt(groupId.id, 10)},
    onCompleted: (data) => {
      setRegulationGroupName(data.regulationGroup.name);
      setRegulationGroupId(data.regulationGroup.id);
    },
    skip: !groupId,
  });

  const getGroupId = regulationGroupId;

  if (error) return error.message;
  if (getRegulationGroup.loading || loading) {
    return <center><Typography variant="h3">Loading</Typography></center>;
  }

  const getDefaultValues = () => {
    const regulations = [];
    for (group of data.regulationGroups) {
      if (group.id == groupId.id) {
        for (regs of group.regulations) {
          regulations.push(regs.regulation);
        }
      };
    };
    return regulations;
  };

  const defaultValue = map(getDefaultValues(), (regulationGroup) => {
    return {
      regulator: regulationGroup.regulator,
      id: regulationGroup.id,
    };
  });

  const options = map(getRegulationsQuery.data?.regulations, (regulation) => {
    return {
      regulator: regulation.regulator,
      id: regulation.id,
    };
  });

  return <>
    <Box sx={{justifyContent: "center", alignItems: "center"}}>
      <Box alignContent="center" justifyContent="center">
        <Box padding={1} display="flex">
          <IconButton
            color="primary"
            aria-label="go-back"
            component={NavLink}
            to="/groups"
            size="large"
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h4">Group Details: {getGroupId}</Typography>
        </Box>
      </Box>
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(update)}>
          <Box padding={1}>
            <TextField
              {...formMethods.register("name", {required: true})}
              defaultValue={regulationGroupName}
              variant="outlined"
              id="group-name"
              label="Group Name"
              size="small"
              fullWidth={true}
              InputLabelProps={{shrink: true}}
            />
          </Box>
          <Box padding={1}>
            <ControlledAutocomplete
              name="regulations"
              defaultValue={defaultValue}
              props ={{
                multiple: true,
                id: "regulations",
                options,
                defaultValue: defaultValue,
                disableCloseOnSelect: true,
                getOptionLabel: (option) => option.regulator,
                isOptionEqualToValue: (option, value) => option?.regulator === value?.regulator,
                renderOption: (props, options, {selected}) => (
                  <li {...props}>
                    <Checkbox
                      icon={<CheckBoxOutlineBlank fontSize="small" />}
                      checkedIcon={<CheckBox fontSize="small" />}
                      style={{marginRight: 8}}
                      checked={selected}
                    />
                    {options.regulator}
                  </li>
                ),
                style: {width: "100%"},
                renderInput: (params) => (
                  <TextField
                    {...params}
                    label ="Regulations"
                    placeholder="Regulations"
                  />
                ),
              }}
            />
          </Box>
          <Box padding={1}>
            <Button
              type="submit"
              variant="outlined"
            >
              Update
            </Button>
          </Box>
        </form>
      </FormProvider>
    </Box>
  </>;
};


const GroupsDetails = () => {
  return <GroupsProvider>
    <RegulationGroupDetailsContent />
  </GroupsProvider>;
};

export default GroupsDetails;
