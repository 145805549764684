import {makeStyles} from "tss-react/mui";

export const useLoginStyles = makeStyles()((theme) => ({
  loginPaper: {
    minHeight: theme.spacing(34),
    minWidth: theme.spacing(55),
    padding: "30px 40px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    background: theme.palette.background.paper,
  },
  closeIcon: {
    marginLeft: `calc(100% - ${theme.spacing(4)})`,
    marginTop: theme.spacing(0.5),
  },
  logo: {
    height: 43,
    width: 192,
    transform: "scale(1.2)",
    margin: "auto 0",
    backgroundImage: `url(${require("~/public/logo-light.png")})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
  },
  loginMessages: {
    fontSize: "1rem",
  },
  remember: {
    ".Mui-checked": {
      color: "#42a8d2 !important",
    },
  },
  submit: {
    borderColor: "#42a8d2",
    borderRadius: 0,
    color: "#42a8d2",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    "&:hover": {
      backgroundColor: "#42a8d2 !important",
      borderColor: "#42a8d2 !important",
      color: `${theme.palette.primary.contrastText} !important`,
    },
  },
  tagline: {
    color: "gray",
    fontFamily: "Oswald, sans-serif",
    marginTop: theme.spacing(2),
  },
  wrapper: {
    display: "flex",
    flexGrow: 1,
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    background: theme.palette.background.default,
  },
}));
