import React from "react";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  InputLabel,
  Select,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import Close from "@mui/icons-material/Close";
import {useRegulation} from "../RegulationContext";
import camelcase from "camelcase";
import Editor from "../../components/Editor";
import GenerateTemplateButtons from "./GenerateTemplateButtons";
import {useNotificationTemplates} from "./useNotificationTemplates";
import {isEmpty, isUndefined} from "underscore";

const NotificationTemplateDialog = ({notificationType}) => {
  const {
    notificationLabels,
    regulation,
    createTemplateMethods,
    updateTemplateMethods,
    deleteTemplateMethods,
    whichNotificationTemplateOpen,
  } = useRegulation();
  const {
    applicableNotificationId,
    findTemplateValuesByRevision,
    formValues,
    setFormValues,
    getTemplateUpdatedAtDateByRevision,
    handleClose,
    handleGeneratingNotificationTemplate,
    handleNewRevision,
    handleDeleteRevision,
    handleOpen,
    handleSubmit,
    isGenerating,
    revisionInView,
    revisionOptions,
  } = useNotificationTemplates({notificationType});

  if (
    !regulation ||
    createTemplateMethods.loading ||
    updateTemplateMethods.loading ||
    deleteTemplateMethods.loading ||
    isEmpty(formValues) || 
    formValues?.reload
  ) return null;

  const notificationTypeLabel = notificationLabels[notificationType];
  const doesTemplateExist = formValues?.revision !== "none" && !isEmpty(formValues?.content);
  const shouldDisableEditor = formValues.revision === "none";
  const shouldDisableSubmit = 
    formValues?.revision === "none" ||
    (
      formValues?.content === revisionInView?.content && 
      formValues?.isDraft === revisionInView?.isDraft
    );

  return (<>
    <Box display="flex" justifyContent="left">
      <Button
        onClick={handleOpen}
        disabled={isUndefined(applicableNotificationId)}
        variant="outlined"
        size="medium"
        sx={{marginBottom: 1.5, marginTop: 1.5, marginLeft: 1}}
      >
        {doesTemplateExist ? "Manage" : "Add"} {notificationTypeLabel} Template
      </Button>
      {doesTemplateExist && (
        <Box ml={1} pt={2}>
          <Typography variant="caption">Revision: {formValues.revision}</Typography>
        </Box>
      )}
    </Box>
    <Dialog
      open={whichNotificationTemplateOpen === notificationTypeLabel}
      onClose={handleClose}
      maxWidth="xl"
      fullWidth
    >
      <DialogTitle>
        <Box sx={{display: "flex", justifyContent: "space-between"}}>
            Manage {notificationTypeLabel} Notification Template: {regulation?.regulator}
          <IconButton
            onClick={handleClose}
            disableRipple
            size="large"
            sx={{padding: 0}}
          >
            <Close />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent sx={{marginTop: -1}}>
        <Box padding={1}>
          <Switch
            checked={formValues?.isDraft}
            onChange={(event) => setFormValues((currentValues) => (
              {...currentValues, isDraft: event.target.checked}
            ))}
            id="draft-switch"
          />
          is Draft?
        </Box>
        <Box padding={1} sx={{justifyContent: "space-between"}}>
          <Stack direction="row" spacing={2}>
            <InputLabel id="revision-select-label">Revision</InputLabel>
            <Select
              labelId="revision-select-label"
              id="revision"
              options={revisionOptions}
              value={formValues?.revision}
              onChange={(event) => {
                setFormValues((currentValues) => {
                  const revisionToShow = event.target.value;
                  const template = findTemplateValuesByRevision(revisionToShow);                  
                  return {
                    ...currentValues,
                    revision: revisionToShow,
                    content: template?.content ?? "",
                    isDraft: !!template?.isDraft,
                  };
                });
              }}
              sx={(theme) => ({minWidth: theme.spacing(25)})}
            >
              {revisionOptions}
            </Select>
            <Button
              onClick={handleNewRevision}
              disabled={formValues?.isNew}
              id="new-revision"
              variant="outlined"
              size="medium"
              sx={{marginY: 2}}
            >
              New Revision
            </Button>
            <Button
              onClick={handleDeleteRevision}
              disabled={formValues?.revision === "none"}
              id="delete-revision"
              variant="outlined"
              size="medium"
              sx={{marginY: 2}}
            >
              Delete Revision
            </Button>
            <GenerateTemplateButtons
              display={notificationTypeLabel === "Regulator"}
              handleGeneration={handleGeneratingNotificationTemplate}
              isGenerating={isGenerating}
              formValues={formValues}
              setFormValues={setFormValues}
            />
          </Stack>
        </Box>
        <Box padding={1} mb={1} sx={{justifyContent: "space-between"}}>
          <Editor
            label={"Edit Template"}
            name={`${camelcase(notificationType)}Template`}
            onChange={(editorContent) => setFormValues((currentValues) => (
              {...currentValues, content: editorContent}
            ))}
            options={{alwaysShowToolbar: true, disable: shouldDisableEditor}}
            value={formValues?.content}
            key={formValues?.revision}
          />
        </Box>
        <Box ml={1} mb={1}>
          <Typography variant="caption">
            Last Updated: {getTemplateUpdatedAtDateByRevision(formValues?.revision) ?? "N/A"}
          </Typography>
        </Box>
        <Button
          onClick={handleSubmit}
          variant="outlined"
          size="medium"
          color={formValues?.revision === "none" ? "error" : undefined}
          sx={{margin: 1}}
          disabled={shouldDisableSubmit}
        >
          {formValues?.revision === "none" ? "Cancel" : "Save"}
        </Button>
      </DialogContent>
    </Dialog>
  </>);
};

NotificationTemplateDialog.propTypes = {
  notificationType: PropTypes.string,
};

export default NotificationTemplateDialog;
