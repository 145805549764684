# import RegulatorLeaderFragment from "./fragments/RegulatorLeader.graphql"

mutation DeleteOneRegulatorLeader(
  $regulationId: Int!,
)
 {
	updateOneRegulation (
		where: {id: $regulationId}
		data: {
			leader: {
				delete: true
			}
		}
	) {
		id
		leader {
      ...RegulatorLeaderFragment
		}
	}
}