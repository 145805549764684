import React from "react";
import {Autocomplete} from "@mui/material";
import {Controller, useFormContext} from "react-hook-form";
import {PropTypes} from "prop-types";

const ControlledAutocomplete = ({name, defaultValue, props}) => {
  const formMethods = useFormContext();

  return <Controller
    control={formMethods.control}
    name={name}
    defaultValue={defaultValue ?? ""}
    render={({field: {onChange, value}}) => (
      <Autocomplete
        value={value}
        onChange={(_, newValue) => {
          onChange(newValue);
        }}
        {...props}
      />
    )}
  />;
};

ControlledAutocomplete.propTypes = {
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.any,
  props: PropTypes.object,
};

export default ControlledAutocomplete;
