import React from "react";
import {Controller, useFormContext} from "react-hook-form";
import {DatePicker} from "@mui/x-date-pickers";
import {PropTypes} from "prop-types";
import dayjs from "dayjs";
import {isEmpty} from "underscore";

const ControlledDatePicker = ({name, options}) => {
  const formMethods = useFormContext();

  return <Controller
    control={formMethods.control}
    name={name}
    rules={{required: false}}
    render={({
      field: {onChange, value, name: fieldName},
    }) => {
      const parsedValue = !isEmpty(value) ? dayjs(value) : "";

      return <DatePicker
        name={fieldName}
        value={parsedValue}
        {...formMethods.register(fieldName)}
        onChange={onChange}
        {...options}
        slotProps={{
          textField: {
            error: false,
          },
        }}
      />;
    }}
  />;
};

ControlledDatePicker.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.object,
};

export default ControlledDatePicker;
