mutation CreateOneNotificationTemplate(
    $isDraft: Boolean!,
    $content: String!,
    $revision: Int!,
    $notificationId: Int!,
) {
  createOneNotificationTemplate(data: {
    isDraft: $isDraft
    content: $content
    revision: $revision
    notification: {connect: {id: $notificationId}}
  }) {
    id
  }
}