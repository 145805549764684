import React from "react";
import {UsersProvider} from "./UsersContext";
import {Routes, Route} from "react-router-dom";
import UsersTable from "./UsersTable";
import UserDetails from "./UserDetails";
import UsersReport from "./UsersReport";

const Users = () => (
  <UsersProvider>
    <UsersContent />
  </UsersProvider>
);

const UsersContent = () => {
  return <Routes>
    <Route path="/" element={<UsersTable />} />
    <Route path="/report" exact element={<UsersReport />} />
    <Route path="/:sub" element={<UserDetails />} />
  </Routes>;
};

export default Users;
