import React from "react";
import {useQuery, gql} from "@apollo/client";
import {useNavigate} from "react-router-dom";
import Table from "../components/Table";
import {Box, Button, Typography} from "@mui/material";
import GetRegulationGroups from "../regulations/gql/GetRegulationGroups.graphql";
import dayjs from "dayjs";

const RegulationGroupTable = () => {
  const {loading, error, data, refetch} = useQuery(gql(GetRegulationGroups));
  const navigate = useNavigate();

  const onRowClick = (params) => {
    navigate(`/groups/${params.id}`);
  };

  const columns = [
    {field: "name", headerName: "Regulation Group", width: 600},
    {field: "createdAt", headerName: "Created", width: 300,
      renderCell: (params) => dayjs(params?.row?.createdAt).format("lll"),
    },
    {field: "updatedAt", headerName: "Updated", width: 200,
      renderCell: (params) => dayjs(params?.row?.updatedAt).format("lll"),
    },
  ];

  if (error) return error.message;
  if (loading) return <center><Typography variant="h3">Loading</Typography></center>;

  return <>
    <Box>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h4" mb={2}>Manage Regulation Groups</Typography>
        <Box>
          <Button
            variant="contained"
            onClick={() => refetch()}
            sx={{marginBottom: 1.5, marginTop: 1.5}}
          >
            Refresh
          </Button>
        </Box>
      </Box>
      <Table
        rows={data?.regulationGroups ?? []}
        columns={columns}
        options={{
          onRowClick,
          getRowId: (row) => row.id,
          sx: {cursor: "default"},
          loading,
        }}
      />
    </Box>
  </>;
};


export default RegulationGroupTable;
