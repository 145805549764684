import React from "react";
import PropTypes from "prop-types";
import {Box, Button, Paper, Typography} from "@mui/material";
import MainMenu from "../MainMenu";
import {Link, useLocation} from "react-router-dom";
import {useLogin} from "../login/LoginProvider";
import {useLayoutStyles} from "./LayoutStyles";

const Layout = ({children}) => {
  const {isUserFullAdmin} = useLogin();
  const {classes} = useLayoutStyles();
  const location = useLocation();
  const isUsers = location.pathname.indexOf("users") > -1;
  const isUserAFullAdmin = isUserFullAdmin();

  return (
    <Box className={classes.layout}>
      <Box my={2} p={2} width={1200} minHeight="100%">
        <Paper sx={{borderRadius: 0, height: "100%"}} elevation={3}>
          <Box p={2}>
            <Box pb={2} display="flex" justifyContent="space-between" alignItems="center">
              <Box display="flex">
                <Button disabled className={classes.logo} aria-label="BreachRx" />
                <Typography variant="h5" color="primary" ml={2} mt={0.5}>
                  Cyber RegScout Admin
                </Typography>
              </Box>
              <Box>
                {!isUserAFullAdmin ? null :
                  <Button component={Link} to={isUsers ? "/" : "/users"}>
                    Manage {isUsers ? "Regulations" : "Users"}
                  </Button>
                }
                <MainMenu />
              </Box>
            </Box>
            {children}
          </Box>
        </Paper>
      </Box>
    </Box>
  );
};

Layout.propTypes = {
  children: PropTypes.any,
};

export default Layout;
